import React from "react";
import styled from "styled-components/macro";

import { colors } from "variables/colors";
import { MEDIA_MIN_MEDIUM } from "variables/mediaQueries";

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 1.5rem;
  margin: 0;
  position: relative;
  border-bottom: 0.1rem solid ${(p) => p.theme.colors.whiteOpac};

  button {
    width: auto;
    padding: 1rem 2rem;
    margin: 0 0 0 4rem;
    height: 4rem;
  }

  ${MEDIA_MIN_MEDIUM} {
    padding: 0 2rem 1rem 3rem;
  }
`;

const Heading = styled.h1`
  font-size: 2rem;
  font-weight: 700;
  letter-spacing: 0;
  text-transform: uppercase;
  position: relative;
  margin: 1rem 2rem 1.5rem 1.5rem;

  ${MEDIA_MIN_MEDIUM} {
    font-size: 2.4rem;
  }

  &:before {
    content: "";
    position: absolute;
    height: 2.1rem;
    width: 0.5rem;
    left: -1.5rem;
    background: ${colors.gradient};

    ${MEDIA_MIN_MEDIUM} {
      height: 2.4rem;
    }
  }
`;

export default ({ children, heading, ...props }) => {
  return (
    <Container {...props}>
      <Heading>{heading}</Heading>
      {children}
    </Container>
  );
};
