import React from "react";
import styled from "styled-components/macro";

const Container = styled.div`
  display: flex;
  justify-content: ${(p) => (p.active ? "flex-end" : "flex-start")};
  align-items: center;
  height: 2.4rem;
  width: 4rem;
  border-radius: 1.2rem;
  background: ${(p) => p.theme.colors.opac1};
  padding: 0 0.5rem;
  cursor: pointer;
  box-shadow: inset 0px 0px 3px 0px rgba(255, 255, 255, 0.3);
`;

const Label = styled.label`
  width: 100%;
  padding-bottom: 0.8rem;
  display: flex;
  align-items: center;
  font-weight: 700;
`;

const Slider = styled.div`
  height: 1.4rem;
  width: 1.4rem;
  border-radius: 0.7rem;
  background: ${(p) => (p.active ? p.theme.colors.primary : p.theme.colors.secondary)};
`;

const Switch = ({ active, handleToggle, label, ...props }) => (
  <>
    {label && <Label>{label}</Label>}
    <Container {...props} active={active} onClick={handleToggle}>
      <Slider active={active} />
    </Container>
  </>
);

export default Switch;
