import gql from "graphql-tag";

const GET_SHIPPING_OPTION = gql`
  query getProduct($id: ID!) {
    getProduct(id: $id) {
      id
      attribute
      name
      active
      imageUrl
      created
      price {
        amount
        currencyUnit
      }
      lastUpdated
      relatedProduct {
        id
        name
      }
    }
  }
`;

export default GET_SHIPPING_OPTION;
