import React from "react";
import styled from "styled-components/macro";
import { MEDIA_MIN_MEDIUM } from "variables/mediaQueries";
import Select from "components/Ui/Select";
import FlagIcon from "components/Ui/FlagIcon";

const Container = styled.div`
  position: relative;
`;

const CurrencySelect = styled(Select)`
  padding-left: 4rem;
  margin-right: 0;
`;

const Flag = styled(FlagIcon)`
  position: absolute;
  top: 1.2rem;
  left: 2rem;

  ${MEDIA_MIN_MEDIUM} {
    top: 1rem;
  }
`;

export default ({ currencies, currency, setCurrency, ...props }) => {
  if (!currencies || !currency) return null;

  const getFlag = (currency) => {
    // More can be added
    switch (currency) {
      case "SEK":
        return "se";
      case "EUR":
        return "eu";
      case "GBP":
        return "gb";
      case "USD":
        return "us";
      case "NOK":
        return "no";
      case "DKK":
        return "dk";
      default:
        return currency;
    }
  };

  return (
    <Container {...props}>
      <Flag countryCode={getFlag(currency)} />
      <CurrencySelect
        handleChange={(event) => setCurrency(event.target.value)}
        defaultValue={currency}>
        {currencies.map((s, i) => {
          return (
            <option key={i} value={s}>
              {s}
            </option>
          );
        })}
      </CurrencySelect>
    </Container>
  );
};
