import gql from "graphql-tag";

const ORDERS_STATISTICS_REVENUE = gql`
  query ordersStatisticsRevenue(
    $startDateTime: AWSDateTime!
    $endDateTime: AWSDateTime!
    $interval: Interval
    $status: OrderStatus
    $store: String
  ) {
    ordersStatisticsRevenue(
      startDateTime: $startDateTime
      endDateTime: $endDateTime
      interval: $interval
      status: $status
      store: $store
    ) {
      key
      keyAsString
      orderCount
      stores {
        orderCount
        store
        sales
      }
    }
  }
`;
export default ORDERS_STATISTICS_REVENUE;
