import gql from "graphql-tag";

const ORDERS_STATISTICS_BY_DATE = gql`
  query ordersStatisticsByDate(
    $startDateTime: AWSDateTime!
    $endDateTime: AWSDateTime!
    $interval: Interval
    $status: OrderStatus
    $store: String
  ) {
    ordersStatisticsByDate(
      startDateTime: $startDateTime
      endDateTime: $endDateTime
      interval: $interval
      status: $status
      store: $store
    ) {
      sales {
        key
        keyAsString
        orderCount
      }
      store
    }
  }
`;
export default ORDERS_STATISTICS_BY_DATE;
