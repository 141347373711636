import React, { useState } from "react";
import styled from "styled-components/macro";
import { Query, Mutation } from "@apollo/client/react/components";
import { useForm, Controller } from "react-hook-form-old";
import uuid from "react-uuid";
import ISO6391 from "iso-639-1";

import ALL_STORES from "graphql/Store/AllStores";
import ADD_SHIPPING_OPTION from "graphql/Shipping/ShippingOption/AddShippingOption";

import { useNotification } from "context/NotificationContext";

import { MEDIA_MIN_LARGE } from "variables/mediaQueries";
import PageContainer from "components/Page/PageContainer";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Box from "components/Content/Box";
import Loader from "components/Ui/Loader";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import Input from "components/Ui/Input";
import ActionButtons from "components/ActionButtons/ActionButtons";
import ActionButton from "components/ActionButtons/ActionButton";
import ActionButtonSecondary from "components/ActionButtons/ActionButtonSecondary";
import IconButton from "components/Ui/Buttons/IconButton";
import Sidebar from "components/Shipping/Sidebar";
import ProviderChoice from "components/Shipping/ProviderChoice";
import Header from "components/Header/Header";
import FlagIcon from "components/Ui/FlagIcon";
import getCountryCode from "helpers/getCountryCodeFromLang";
import AddTranslation from "components/Shipping/AddTranslation";
import Attributes from "components/AttributeList/AttributeList";
import Attribute from "components/AttributeList/Attribute";
import Label from "components/AttributeList/Label";
import Value from "components/AttributeList/Value";

const OptionBox = styled(Box)`
  padding-bottom: 5rem;

  ${MEDIA_MIN_LARGE} {
    padding-bottom: 1rem;
  }
`;

const Container = styled(GridContainer)`
  margin-top: 3rem;

  ${MEDIA_MIN_LARGE} {
    margin-top: 4rem;
  }
`;

const OptionAttributes = styled(Attributes)`
  margin: -1.5rem 0 0;
`;

const PriceContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  ${MEDIA_MIN_LARGE} {
    justify-content: flex-start;
  }
`;

const Price = styled.div`
  width: 30%;

  ${MEDIA_MIN_LARGE} {
    width: 10%;
    margin-right: 2rem;
  }
`;

const IdContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  i {
    &:hover {
      cursor: pointer;
    }
  }

  label {
    width: 7rem;
  }

  input {
    width: 50%;
    margin: 0;
  }
`;

const IdInputContainer = styled.div`
  display: flex;
`;

const FieldBox = styled(GridItem)`
  > div {
    position: relative;
    padding-bottom: 2rem;
  }
`;

const Translations = styled.div`
  &:not(:empty) {
    padding-bottom: 3rem;
  }
`;

const Translation = styled.div`
  border-bottom: 0.1rem solid ${(p) => p.theme.colors.whiteOpac};
  display: flex;
  align-items: center;
  padding-bottom: 1rem;
  margin-bottom: 1rem;

  > div {
    display: flex;
    align-items: center;
  }

  ${MEDIA_MIN_LARGE} {
    padding-bottom: 1.5rem;
    margin-bottom: 1.5rem;
  }
`;

const AddTranslationButton = styled(ActionButtonSecondary)`
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 0;

  ${MEDIA_MIN_LARGE} {
    bottom: -1rem;
  }
`;

const LanguageField = styled.div`
  display: flex;
  position: relative;

  input {
    padding-left: 4.5rem;
  }

  img {
    position: absolute;
    top: 4.3rem;
    left: 1.5rem;

    ${MEDIA_MIN_LARGE} {
      top: 4.4rem;
    }
  }
`;

const IdInput = styled(Input)`
  height: 3rem;
`;

export default ({ history }) => {
  const { control, handleSubmit, errors } = useForm();
  const { setNotification } = useNotification();
  const [providerId, setProviderId] = useState(null);
  const [addId, setAddId] = useState(false);
  const [descriptions, setDescriptions] = useState([]);
  const [displayNames, setDisplayNames] = useState([]);
  const [showDescTranslation, setShowDescTranslation] = useState(false);
  const [showNameTranslation, setShowNameTranslation] = useState(false);

  const formatPrice = (price) => Number(price * 100).toFixed(2) | 0;

  const removeDescription = (description) =>
    setDescriptions([...descriptions].filter((d) => !(d.langCode === description.langCode)));
  const removeDisplayName = (displayName) =>
    setDisplayNames([...displayNames].filter((d) => !(d.langCode === displayName.langCode)));

  const toShippingOption = (data, price) => {
    const id = data.id ? data.id : uuid();
    return {
      variables: {
        id: id,
        name: data.name,
        imageUrl: data.imageUrl,
        price: price,
        type: "shippingOption",
        category: "Shipping",
        attribute: JSON.stringify({
          description: Object.assign(
            descriptions.reduce((obj, item) => ({ ...obj, [item.langCode]: item.translation }), {}),
            { en: data.description }
          ),
          displayName: Object.assign(
            displayNames.reduce((obj, item) => ({ ...obj, [item.langCode]: item.translation }), {}),
            { en: data.name }
          ),
        }),
        relatedProduct: providerId,
      },
    };
  };

  return (
    <>
      <Breadcrumbs
        slugs={[
          ["admin/shipping", "Shipping options"],
          ["admin/add-shipping-option/", "Add shipping option"],
        ]}
      />
      <Header heading="Shipping options" />
      <PageContainer>
        <Sidebar />
        <GridContainer>
          <Query query={ALL_STORES} variables={{ from: 0, size: 300 }}>
            {({ loading, error, data }) => {
              if (loading) return <Loader />;
              if (error)
                return (
                  <ErrorMessage>
                    An error occurred loading data, please contact support
                  </ErrorMessage>
                );
              const filteredCurrencyUnits = data.allStores.stores
                .map((store) => store.currencyUnit)
                .filter((currencyUnit, index, arr) => arr.indexOf(currencyUnit) === index);
              return (
                <Mutation
                  mutation={ADD_SHIPPING_OPTION}
                  onCompleted={() => {
                    setNotification({
                      status: "success",
                      message: `Shipping option successfully added`,
                    });
                    history.push("/admin/shipping-options");
                  }}
                  onError={() => {
                    setNotification({
                      status: "error",
                      message:
                        "An error occurred adding the shipping option, please contact support",
                    });
                  }}>
                  {(updateShipping, { loading: addShippingLoading }) => {
                    const onSubmit = (data) => {
                      const price = Object.keys(data.price).map((key) => ({
                        amount: formatPrice(data.price[key].amount),
                        currencyUnit: key,
                      }));
                      updateShipping(toShippingOption(data, price)).catch((e) => {
                        console.log(e);
                      });
                    };
                    return (
                      <GridItem columns="12">
                        <form onSubmit={handleSubmit(onSubmit)}>
                          <OptionBox
                            preHeading="Shipping option"
                            heading="Add new shipping option"
                            showGoBackButton
                            goBackOnClick={() => history.push("/admin/shipping-options")}>
                            {addShippingLoading && <Loader />}

                            <ActionButtons inBox footerOnMobile>
                              <ActionButton type="submit">
                                <i className="fal fa-fw fa-check" />
                                Save
                              </ActionButton>
                            </ActionButtons>

                            <OptionAttributes>
                              <Attribute>
                                <Label>ID:</Label>
                                <Value>
                                  {addId ? (
                                    <IdInputContainer>
                                      <Controller
                                        as={IdInput}
                                        control={control}
                                        type="text"
                                        name="id"
                                        defaultValue=""
                                        errors={errors}
                                        relativeError
                                      />
                                      <IconButton onClick={() => setAddId(false)}>
                                        <i className="fal fa-fw fa-times"></i>
                                      </IconButton>
                                    </IdInputContainer>
                                  ) : (
                                    <IdContainer>
                                      Auto generate
                                      <IconButton onClick={() => setAddId(true)}>
                                        <i className="fal fa-fw fa-pen"></i>
                                      </IconButton>
                                    </IdContainer>
                                  )}
                                </Value>
                              </Attribute>
                            </OptionAttributes>
                            <Container collapse padding="0">
                              <GridItem mobilePadding="0" desktopPadding="0 1.5rem 0 0" columns="6">
                                <Controller
                                  as={Input}
                                  control={control}
                                  rules={{ required: "This is a required field" }}
                                  type="text"
                                  defaultValue=""
                                  name="imageUrl"
                                  errors={errors}
                                  label="Image URL"
                                />
                              </GridItem>
                              <GridItem mobilePadding="0" desktopPadding="0 0 0 1.5rem" columns="6">
                                <ProviderChoice
                                  providerId={providerId}
                                  setProviderId={setProviderId}
                                />
                              </GridItem>
                            </Container>
                            <GridContainer collapse padding="0">
                              <FieldBox
                                mobilePadding="0"
                                desktopPadding="2rem 1.5rem 0 0"
                                columns="6">
                                <div>
                                  <LanguageField>
                                    <FlagIcon countryCode="gb" />
                                    <Controller
                                      as={Input}
                                      control={control}
                                      rules={{ required: "This is a required field" }}
                                      type="text"
                                      defaultValue=""
                                      name={`name`}
                                      errors={errors}
                                      label="Display name"
                                    />
                                  </LanguageField>
                                  <Translations>
                                    {displayNames.map((displayName) => {
                                      return (
                                        <Translation key={displayName.langCode}>
                                          <Label>{ISO6391.getName(displayName.langCode)}</Label>
                                          <div>
                                            <FlagIcon
                                              countryCode={getCountryCode(displayName.langCode)}
                                            />
                                            {console.log(ISO6391.getName(displayName.langCode))}
                                            <Value>{displayName.translation}</Value>
                                            <IconButton
                                              onClick={() => {
                                                removeDisplayName(displayName);
                                              }}>
                                              <i className="fal fa-fw fa-trash-alt" />
                                            </IconButton>
                                          </div>
                                        </Translation>
                                      );
                                    })}
                                  </Translations>
                                  {!showNameTranslation && (
                                    <AddTranslationButton
                                      handleOnClick={() => setShowNameTranslation(true)}>
                                      <i className="fal fa-fw fa-plus" /> Translation
                                    </AddTranslationButton>
                                  )}
                                  {showNameTranslation && (
                                    <AddTranslation
                                      control={control}
                                      errors={errors}
                                      setShowTranslation={setShowNameTranslation}
                                      translations={displayNames}
                                      setTranslations={setDisplayNames}
                                    />
                                  )}
                                </div>
                              </FieldBox>
                              <FieldBox
                                mobilePadding="0"
                                desktopPadding="2rem 0 0 1.5rem"
                                columns="6">
                                <div>
                                  <LanguageField>
                                    <FlagIcon countryCode="gb" />
                                    <Controller
                                      as={Input}
                                      control={control}
                                      rules={{ required: "This is a required field" }}
                                      type="text"
                                      defaultValue=""
                                      name={`description`}
                                      errors={errors}
                                      label="Description"
                                    />
                                  </LanguageField>
                                  <Translations>
                                    {descriptions.map((description) => {
                                      return (
                                        <Translation key={description.langCode}>
                                          <Label>{ISO6391.getName(description.langCode)}</Label>
                                          <div>
                                            <FlagIcon
                                              countryCode={getCountryCode(description.langCode)}
                                            />
                                            <Value>{description.translation}</Value>
                                            <IconButton
                                              onClick={() => {
                                                removeDescription(description);
                                              }}>
                                              <i className="far fa-trash-alt"></i>
                                            </IconButton>
                                          </div>
                                        </Translation>
                                      );
                                    })}
                                  </Translations>
                                  {!showDescTranslation && (
                                    <AddTranslationButton
                                      handleOnClick={() =>
                                        setShowDescTranslation(!showDescTranslation)
                                      }>
                                      <i className="fal fa-fw fa-plus" /> Translation
                                    </AddTranslationButton>
                                  )}
                                  {showDescTranslation && (
                                    <AddTranslation
                                      setShowTranslation={setShowDescTranslation}
                                      translations={descriptions}
                                      setTranslations={setDescriptions}
                                    />
                                  )}
                                </div>
                              </FieldBox>
                            </GridContainer>

                            <GridContainer collapse padding="0">
                              <GridItem columns="12" padding="0">
                                <h5>Price</h5>
                                <PriceContainer>
                                  {filteredCurrencyUnits.map((currencyUnit, index) => {
                                    return (
                                      <Price key={index}>
                                        <Controller
                                          as={Input}
                                          control={control}
                                          rules={{
                                            required: "This is a required field",
                                          }}
                                          type="number"
                                          label={currencyUnit}
                                          name={`price[${currencyUnit}].amount`}
                                          defaultValue={0}
                                          errors={errors}
                                        />
                                      </Price>
                                    );
                                  })}
                                </PriceContainer>
                              </GridItem>
                            </GridContainer>
                          </OptionBox>
                        </form>
                      </GridItem>
                    );
                  }}
                </Mutation>
              );
            }}
          </Query>
        </GridContainer>
      </PageContainer>
    </>
  );
};
