import React from "react";
import styled from "styled-components/macro";
import { colors } from "variables/colors";
import { MEDIA_MIN_MEDIUM } from "variables/mediaQueries";

const Container = styled.div`
  position: relative;
  width: 100%;
  margin: 0;
  padding: 2.5rem 1.5rem 1.5rem;
  border-radius: 0.6rem;

  transition: transform 0.2s;
  min-height: 32rem;

  ${MEDIA_MIN_MEDIUM} {
    padding: 3rem;
    min-height: 35rem;
  }
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  border-bottom: 0.1rem solid ${(p) => p.theme.colors.whiteOpac};
  padding-bottom: 1.5rem;
`;

const PreHeading = styled.h4`
  font-size: 1.2rem;
  text-transform: uppercase;
  width: 100%;
  margin: 0 0 0.2rem;
  color: ${colors.primary};
  font-weight: 400;
  letter-spacing: 0.15rem;

  ${MEDIA_MIN_MEDIUM} {
    font-size: 1.4rem;
  }
`;

const Heading = styled.h3`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 1.6rem;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.05rem;
  width: 100%;
  margin: 0;

  ${MEDIA_MIN_MEDIUM} {
    font-size: 2rem;
  }

  span {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .fa-check-circle {
    margin-left: 1rem;
    color: green;
  }

  .fa-times-circle {
    margin-left: 1rem;
    color: red;
  }
`;

const SubHeading = styled.span`
  font-size: 1.5rem;
  font-weight: 400;
  color: ${(p) => p.theme.colors.darkerGrey};
  text-transform: initial;
`;

const Icon = styled.div`
  color: ${(p) => p.theme.colors.white};
  margin-right: 1.5rem;
  width: 4rem;
  height: 4rem;
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${colors.gradient};
  color: ${colors.white};
  border-radius: 3rem;
  border: 0.3rem solid white;
  box-shadow: 0 0.2rem 0.8rem 0 rgb(0 0 0 / 15%);
  padding: 0.2rem 0 0 0;

  ${MEDIA_MIN_MEDIUM} {
    width: 6rem;
    height: 6rem;
    font-size: 2rem;
    padding: 0.2rem 0 0 0.1rem;
  }
`;

const Result = styled.div`
  display: flex;
  align-items: center;
`;

const Indicator = styled.div`
  margin-left: 0.5rem;
  padding-top: 0.2rem;

  font-size: 1.4rem;

  ${MEDIA_MIN_MEDIUM} {
    font-size: 1.8rem;
  }

  .fa-arrow-alt-up {
    color: green;
    transform: rotate(45deg);
  }

  .fa-arrow-alt-down {
    color: red;
    transform: rotate(-45deg);
  }
`;

const Description = styled.p`
  width: 100%;
`;

const Footer = styled.p`
  width: 100%;
  font-size: 1.2rem;
  opacity: 0.6;
  margin-bottom: 0;

  i {
    margin-right: 0.5rem;
  }
`;

const Labels = styled.div`
  position: absolute;
  bottom: ${(p) => (p.labelPos === "topRight" ? "auto" : "3rem")};
  left: ${(p) => (p.labelPos === "topRight" ? "auto" : "3rem")};
  top: ${(p) => (p.labelPos === "topRight" ? "3rem" : "auto")};
  right: ${(p) => (p.labelPos === "topRight" ? "3rem" : "auto")};

  div {
    padding: 0.3rem 0 0.3rem 2rem;
    position: relative;

    &:before {
      content: "";
      width: 1rem;
      height: 1rem;

      position: absolute;
      left: 0;
      top: 0.5rem;

      ${MEDIA_MIN_MEDIUM} {
        top: 0.8rem;
      }
    }
  }
`;

const PrimaryLabel = styled.div`
  &:before {
    background: ${colors.gradient};
  }
`;

const SecondaryLabel = styled.div`
  &:before {
    background: ${(p) => p.theme.colors.opac1};
  }
`;

export default ({
  children,
  preHeading,
  headingIcon,
  heading,
  subHeading,
  description,
  result,
  footer,
  currency,
  currentPeriodSum,
  prevPeriodSum,
  displayAsCurrency,
  primaryLabel,
  secondaryLabel,
  labelPos,
  ...props
}) => (
  <Container {...props}>
    <Header>
      {headingIcon && (
        <Icon>
          <i className={`fal fa-${headingIcon}`}></i>
        </Icon>
      )}
      <div>
        {preHeading && <PreHeading>{preHeading}</PreHeading>}
        {heading && <Heading>{heading}</Heading>}
        {subHeading && <SubHeading>{subHeading}</SubHeading>}
        {currentPeriodSum !== undefined && prevPeriodSum !== undefined && (
          <>
            {displayAsCurrency && currentPeriodSum.hasSameCurrency(prevPeriodSum) ? (
              <Result>
                {currentPeriodSum.greaterThan(prevPeriodSum) && <>+ </>}
                {currentPeriodSum.subtract(prevPeriodSum).toFormat()}
                <Indicator>
                  {prevPeriodSum.greaterThan(currentPeriodSum) ? (
                    <i className="fas fa-arrow-alt-down"></i>
                  ) : (
                    <i className="fas fa-arrow-alt-up"></i>
                  )}
                </Indicator>
              </Result>
            ) : (
              <Result>
                {currentPeriodSum > prevPeriodSum && <>+ </>}
                {currentPeriodSum - prevPeriodSum}
                <Indicator>
                  {prevPeriodSum > currentPeriodSum ? (
                    <i className="fas fa-arrow-alt-down"></i>
                  ) : (
                    <i className="fas fa-arrow-alt-up"></i>
                  )}
                </Indicator>
              </Result>
            )}
          </>
        )}
      </div>
    </Header>
    {description && <Description>{description}</Description>}
    {children}
    <Labels labelPos={labelPos}>
      {primaryLabel && <PrimaryLabel>{primaryLabel}</PrimaryLabel>}
      {secondaryLabel && <SecondaryLabel>{secondaryLabel}</SecondaryLabel>}
    </Labels>
    {footer && (
      <Footer>
        <i className="far fa-calendar-alt"></i>
        {footer}
      </Footer>
    )}
  </Container>
);
