import Dinero from "dinero.js";

export default function calculateSales(sales, currencyUnit, taxPercentage, includeVAT) {
  if (includeVAT) {
    return sales || 0;
  }
  return sales
    ? Dinero({ amount: sales, currency: currencyUnit })
        .divide(taxPercentage / 100 + 1)
        .getAmount()
    : 0;
}
