import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import ADD_DISCOUNT_CODE from "graphql/Discount/DiscountCode/AddDiscountCode";
import GET_DISCOUNT_CODE from "graphql/Discount/DiscountCode/GetDiscountCode";
import Loader from "components/Ui/Loader";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import Dialog from "components/Dialog/Dialog";
import { useNotification } from "context/NotificationContext";
import uuid from "react-uuid";
import Input from "components/Ui/Input";
import { Query } from "@apollo/client/react/components";

const DuplicateDiscountCode = ({ showDialog, id, setShowDialog, history, refetch }) => {
  const { setNotification } = useNotification();
  const [newDiscountCode, setNewDiscountCode] = useState();
  const [discountToDuplicate, setDiscountToDuplicate] = useState();

  const [addDiscountCode, { loading: duplicateLoading }] = useMutation(ADD_DISCOUNT_CODE, {
    onCompleted: () => {
      setShowDialog(false);
      setNotification({
        status: "success",
        message: `Discount code added`,
      });
      history.push("/admin/discount-codes");
    },
    onError: () => {
      setNotification({
        status: "error",
        message: `Discount code couldn't be added, please contact support`,
      });
    },
    refetchQueries: refetch,
  });

  const addNewDiscountCode = () => {
    const { created, revenueDinero, revenue, id, code, lastUpdated, ...rest } = discountToDuplicate;
    addDiscountCode({
      variables: {
        ...rest,
        code: newDiscountCode,
        id: uuid(),
        stores: discountToDuplicate?.stores?.map((store) => ({
          countryCode: store.countryCode,
          languageCode: store.languageCode,
        })),
      },
    });
  };
  return (
    <Query
      query={GET_DISCOUNT_CODE}
      variables={{ id }}
      onCompleted={({ getDiscount }) => {
        setDiscountToDuplicate(getDiscount);
      }}>
      {({ loading, error, data }) => {
        if (loading) return <Loader />;
        if (error)
          return (
            <ErrorMessage>An error occurred loading data, please contact support</ErrorMessage>
          );

        return (
          <Dialog
            preHeading={discountToDuplicate?.code}
            subHeading="Create a new discount code with parameters of existing"
            header="Duplicate discount code"
            open={showDialog}
            handleClose={() => setShowDialog(false)}
            handleOk={addNewDiscountCode}>
            {duplicateLoading && <Loader />}
            <span>New discount code:</span>
            <Input type="text" onChange={(e) => setNewDiscountCode(e.target.value)}></Input>
          </Dialog>
        );
      }}
    </Query>
  );
};

export default DuplicateDiscountCode;
