import React from "react";
import styled from "styled-components/macro";
import { MEDIA_MIN_MEDIUM } from "variables/mediaQueries";

const Container = styled.div`
  position: relative;
  width: 100%;
  padding: ${(p) => p.mobilePadding || p.padding || "0 1.5rem 1.5rem"};
  margin: 0;

  ${MEDIA_MIN_MEDIUM} {
    width: calc(100% / 12 * ${(p) => p.columns || 1});
    padding: ${(p) => p.desktopPadding || p.padding || "1.5rem"};
  }
`;

export default ({ children, columns, padding, mobilePadding, desktopPadding, ...props }) => (
  <Container
    padding={padding}
    mobilePadding={mobilePadding}
    desktopPadding={desktopPadding}
    columns={columns}
    {...props}>
    {children}
  </Container>
);
