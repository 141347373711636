import gql from "graphql-tag";

const REFUND_ORDER = gql`
  mutation RefundOrder($id: ID!, $orderLinesToBeRefunded: [InputOrderLineToBeRefunded!]!) {
    refundOrder(
      refundOrder: {
        id: $id,
        orderLinesToBeRefunded: $orderLinesToBeRefunded
        }
      ) {
        id
        status
        statusLog {
          status
          timestamp
        }
    }
  }
`;

export default REFUND_ORDER;
