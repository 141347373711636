import gql from "graphql-tag";

const DELETE_SHIPPING_OPTION = gql`
  mutation DeleteProduct($id: ID!) {
    deleteProduct(id: $id){
      id
    }
  }
`;

export default DELETE_SHIPPING_OPTION;