import React from "react";
import styled from "styled-components/macro";
import { colors } from "variables/colors";
import Button from "./Button";

const FilterButton = styled(Button)`
  width: 10rem;
  background: ${colors.gradient};
  color: ${colors.white};
  padding: 0.1rem 0.5rem 0;
  cursor: pointer;
  height: 4rem;
  border: none;
  border-radius: 0.3rem;
  margin: 0 0 0 1rem;
  text-transform: initial;

  i {
    font-size: 1.6rem;
    margin-right: 0.5rem;
  }

  &:first-of-type {
    margin-left: 2rem;
  }
`;

export default ({ children, ...props }) => <FilterButton {...props}>{children}</FilterButton>;
