export default (langCode) => {
  // More can be added
  switch (langCode) {
    case "sv":
      return "se";
    case "en":
      return "gb";
    case "ar":
      return "eg";
    case "zh":
      return "cn";
    case "hi":
      return "in";
    case "bn":
      return "bd";
    case "ja":
      return "jp";
    case "da":
      return "dk";
    case "cs":
      return "cz";
    case "et":
      return "ee";
    case "el":
      return "gr";
    default:
      return langCode;
  }
};
