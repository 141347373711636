import React from "react";
import styled from "styled-components/macro";
import { MEDIA_MIN_MEDIUM } from "variables/mediaQueries";

const Tabs = styled.ul`
  list-style: none;
  padding: 2rem 1.5rem 0;
  display: flex;
  margin: 0 0 -1.5rem;

  ${MEDIA_MIN_MEDIUM} {
    padding: 2rem 5rem 0;
  }
`;

export default ({ children, ...props }) => <Tabs {...props}>{children}</Tabs>;
