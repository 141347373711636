import React, { useState } from "react";
import styled from "styled-components/macro";
import { Query, Mutation } from "@apollo/client/react/components";
import esb from "elastic-builder";
import { useForm, Controller } from "react-hook-form-old";
import Moment from "react-moment";
import uuid from "react-uuid";
import GET_SHIPPING_RULE from "graphql/Shipping/ShippingRule/GetShippingRule";
import UPDATE_SHIPPING_RULE from "graphql/Shipping/ShippingRule/UpdateShippingRule";
import SEARCH_SHIPPING_OPTIONS from "graphql/Product/SearchShippingOptions";

import { useNotification } from "context/NotificationContext";

import { MEDIA_MIN_LARGE } from "variables/mediaQueries";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import PageContainer from "components/Page/PageContainer";
import Header from "components/Header/Header";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Box from "components/Content/Box";
import Input from "components/Ui/Input";
import Loader from "components/Ui/Loader";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import ActionButtons from "components/ActionButtons/ActionButtons";
import IconButton from "components/Ui/Buttons/IconButton";
import ActionButton from "components/ActionButtons/ActionButton";
import ActionButtonSecondary from "components/ActionButtons/ActionButtonSecondary";
import Select from "components/Ui/Select";
import MultiSelect from "components/Ui/MultiSelect";
import DeleteShippingRule from "components/Shipping/DeleteShippingRule";
import Sidebar from "components/Shipping/Sidebar";
import Attributes from "components/AttributeList/AttributeList";
import Attribute from "components/AttributeList/Attribute";
import Label from "components/AttributeList/Label";
import Value from "components/AttributeList/Value";
import Tooltip from "components/Ui/Tooltip";
import Toggle from "components/Ui/Toggle";
import TableHeader from "components/Table/Header";

const ShippingRuleBox = styled(Box)`
  padding-bottom: 8rem;

  h5 {
    margin-bottom: 3rem;
    border-bottom: 0.1rem solid ${(p) => p.theme.colors.inputBorderColor};
    padding-bottom: 2.4rem;
    width: 100%;
  }

  ${MEDIA_MIN_LARGE} {
    padding-bottom: 4rem;
  }
`;

const RuleAttributes = styled(Attributes)`
  margin: -1.5rem 0 3rem;
`;

const ConditionsHeader = styled(TableHeader)`
  > div {
    width: 25%;
  }
`;

const Conditions = styled.div`
  width: 100%;
  position: relative;
`;

const ConditionsTable = styled.div`
  width: 100%;
  position: relative;

  h5 {
    border-top: 0;
    border-bottom: 0.1rem solid ${(p) => p.theme.colors.inputBorderColor};
    padding-bottom: 2.4rem;
  }
`;

const Rule = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1rem;
  border: 0.1rem solid ${(p) => p.theme.colors.inputBorderColor};
  border-top: none;

  ${MEDIA_MIN_LARGE} {
    padding: 2rem;
  }

  > div {
    width: 22%;
    margin: 0;
  }
`;

const AddRowButton = styled(ActionButtonSecondary)`
  position: absolute;
  top: -7.5rem;
  right: 0;
`;

const Padding = styled.div`
  width: 4.6rem !important;
`;

const ConditionSelect = styled(Select)`
  height: 5.4rem;
  border-radius: 0.3rem;
  border: 0.1rem solid ${(p) => p.theme.colors.inputBorderColor};
  text-transform: initial;
  margin: 0 0 3rem;
`;

const SelectLabel = styled.div`
  display: flex;
  align-items: center;

  span {
    display: flex;
    align-items: center;
  }

  img {
    width: 2rem;
    margin-right: 1rem;
  }
`;

const ALL_SHIPPING_OPTIONS = new esb.requestBodySearch()
  .query(esb.queryStringQuery("type:shippingOption"))
  .size(1000)
  .from(0);

const itemRenderer = ({ checked, option, onClick }) => (
  <SelectLabel>
    <input type="checkbox" onChange={onClick} checked={checked} tabIndex="-1" />
    <span>
      {option.img && <img alt={option.label} src={option.img} />}
      <span>{option.label}</span>
    </span>
  </SelectLabel>
);

export default (props) => {
  const { setNotification } = useNotification();
  const { control, handleSubmit, errors } = useForm();
  const [active, setActive] = useState();
  const [condition, setCondition] = useState(null);
  const [rules, setRules] = useState([]);
  const [shippingRule, setShippingRule] = useState("");
  const [id] = useState(props.match.params.id);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const history = props.history;
  const conditionOptions = [
    {
      value: "==",
      label: "Equal",
    },
    {
      value: ">",
      label: "Greater than",
    },
    {
      value: ">=",
      label: "Greater or equal to",
    },
    {
      value: "<",
      label: "Less than",
    },
    {
      value: "<=",
      label: "Less or equal to",
    },
  ];

  const formatFromMinorUnits = (price) => Number(price/100);
  const formatPrice = (price) => Number(price * 100).toFixed(2) | 0;

  const toShippingRule = (data) => {
    const keys = Object.keys(data).filter((key) => key.includes("rule"));
    const rules = keys.map((key) => data[key]);
    return {
      variables: {
        id: id,
        name: data.name,
        templateData: JSON.stringify({
          operator: condition,
          shippingOptions: selectedProducts.map((product) => product.value),
          countryOptions: rules.map((rule) => ({
            totalPriceWithDiscount: formatPrice(rule.totalPriceWithDiscount),
            currencyUnit: rule.currency,
            shippingCountry: rule.shippingCountry,
            notForShippingCountry: rule.notForShippingCountry
              ? rule.notForShippingCountry.split(",")
              : undefined,
          })),
        }),
        active: active,
      },
    };
  };

  return (
    <>
      <Breadcrumbs
        slugs={[
          ["admin/shipping", "Shipping rules"],
          [`admin/shipping-rule/${id}`, `${shippingRule && shippingRule.name}`],
        ]}
      />
      <Header heading="Shipping rules"></Header>
      <PageContainer>
        <Sidebar />
        <GridContainer expanded>
          <Query
            query={SEARCH_SHIPPING_OPTIONS}
            variables={{ query: JSON.stringify(ALL_SHIPPING_OPTIONS.toJSON()) }}
            fetchPolicy={"cache-first"}>
            {({ loading, error, data }) => {
              if (loading) return <Loader />;
              if (error)
                return (
                  <ErrorMessage inBox>
                    An error occurred getting data, please contact support
                  </ErrorMessage>
                );

              const { products } = data.searchProducts;
              const shippingProducts = products.map((product) => ({
                value: product.id,
                img: product.imageUrl,
                label: product.name,
              }));

              return (
                <Query
                  query={GET_SHIPPING_RULE}
                  variables={{ id }}
                  onCompleted={(data) => {
                    const { getRule } = data;
                    const shippingRuleData = JSON.parse(JSON.parse(getRule.templateData));
                    setActive(data.getRule.active);
                    setShippingRule(getRule);
                    const countryOptionsWithId = shippingRuleData.countryOptions.map((co) => ({
                      ...co,
                      id: uuid(),
                    }));
                    setRules(countryOptionsWithId);
                    setSelectedProducts(
                      shippingProducts.filter((product) =>
                        shippingRuleData.shippingOptions.find((option) => option === product.value)
                      )
                    );
                    setCondition(shippingRuleData.operator);
                  }}>
                  {({ loading, error, data }) => {
                    if (loading) return <Loader />;
                    if (error)
                      return (
                        <ErrorMessage>
                          An error occurred loading data, please contact support
                        </ErrorMessage>
                      );

                    const shippingRule = data.getRule;

                    return (
                      <GridContainer>
                        <Mutation
                          mutation={UPDATE_SHIPPING_RULE}
                          onCompleted={() => {
                            setNotification({
                              status: "success",
                              message: `Shipping rule successfully updated`,
                            });
                          }}
                          onError={() => {
                            setNotification({
                              status: "error",
                              message:
                                "An error occurred updating the shipping rule, please contact support",
                            });
                          }}>
                          {(updateShipping, { loading: addShippingLoading }) => {
                            const onSubmit = (data) => {
                              updateShipping(toShippingRule(data)).catch((e) => {
                                console.log(e);
                              });
                            };

                            return (
                              <GridItem columns="12">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                  <ShippingRuleBox
                                    preHeading="Shipping rules"
                                    heading={shippingRule.name}
                                    subHeading={shippingRule.id}
                                    showGoBackButton
                                    goBackOnClick={() => history.push("/admin/shipping-rules")}>
                                    {addShippingLoading && <Loader />}

                                    <ActionButtons inBox footerOnMobile>
                                      <DeleteShippingRule history={history} id={shippingRule.id} />
                                      <ActionButton type="submit">
                                        <i className="fal fa-fw fa-check" />
                                        Save
                                      </ActionButton>
                                    </ActionButtons>

                                    <RuleAttributes>
                                      <Attribute>
                                        <Label>Active:</Label>
                                        <Value>
                                          <Toggle
                                            active={active}
                                            handleToggle={() => setActive(!active)}
                                          />
                                        </Value>
                                      </Attribute>
                                      <Attribute>
                                        <Label>Created:</Label>
                                        <Value>
                                          <Moment format="YYYY-MM-DD HH:mm">
                                            {shippingRule.created}
                                          </Moment>
                                        </Value>
                                      </Attribute>
                                      <Attribute>
                                        <Label>Last updated:</Label>
                                        <Value>
                                          <Moment format="YYYY-MM-DD HH:mm">
                                            {shippingRule.lastUpdated}
                                          </Moment>
                                        </Value>
                                      </Attribute>
                                    </RuleAttributes>

                                    <GridContainer collapse padding="0">
                                      <GridItem
                                        mobilePadding="0"
                                        desktopPadding="0 1.5rem 0 0"
                                        columns="6">
                                        <Controller
                                          as={Input}
                                          control={control}
                                          rules={{
                                            required: "This is a required field",
                                          }}
                                          type="text"
                                          name="name"
                                          label="Name"
                                          defaultValue={shippingRule.name}
                                          errors={errors}
                                        />
                                      </GridItem>
                                      <GridItem
                                        mobilePadding="0"
                                        desktopPadding="0 0 0 1.5rem"
                                        columns="6">
                                        <MultiSelect
                                          options={shippingProducts}
                                          label="Select shipping options"
                                          selected={selectedProducts}
                                          setSelected={setSelectedProducts}
                                          ItemRenderer={itemRenderer}
                                          valueRenderer={(selected) => {
                                            if (selectedProducts.length === 0) {
                                              return <span>No shipping options selected</span>;
                                            }
                                            if (
                                              selectedProducts.length === shippingProducts.length
                                            ) {
                                              return <span>All shipping options selected</span>;
                                            }
                                            return (
                                              <span>
                                                {selected.length}{" "}
                                                {selected.length === 1
                                                  ? "shipping option"
                                                  : "shipping options"}{" "}
                                                selected
                                              </span>
                                            );
                                          }}
                                          overrideStrings={{
                                            selectAll: `Select all ${products.length} shipping options`,
                                          }}
                                        />
                                      </GridItem>
                                    </GridContainer>

                                    <Conditions>
                                      <h5>Conditions</h5>

                                      <Controller
                                        as={ConditionSelect}
                                        control={control}
                                        name="shippingCondition"
                                        label="Condition"
                                        handleChange={(e) => setCondition(e.target.value)}
                                        errors={errors}>
                                        {conditionOptions.map((option) => (
                                          <option
                                            key={option.value}
                                            selected={option.value === condition}
                                            value={option.value}>
                                            {option.label}
                                          </option>
                                        ))}
                                      </Controller>

                                      <ConditionsTable>
                                        <Tooltip />
                                        <ConditionsHeader>
                                          <div>
                                            Currency code{" "}
                                            <i
                                              className="fal fa-fw fa-info-circle"
                                              data-tip="ISO-4217, ex.USD"
                                            />
                                          </div>
                                          <div>
                                            Cart value{" "}
                                            <i
                                              className="fal fa-fw fa-info-circle"
                                              data-tip="ex. 60 or 5.5"
                                            />
                                          </div>
                                          <div>
                                            Valid for specific country{" "}
                                            <i
                                              className="fal fa-fw fa-info-circle"
                                              data-tip="ISO-3166, ex. US"
                                            />
                                          </div>
                                          <div>
                                            Exclude countries{" "}
                                            <i
                                              className="fal fa-fw fa-info-circle"
                                              data-tip="ISO-3166, ex. US,UK ..."
                                            />
                                          </div>
                                          <Padding />
                                        </ConditionsHeader>

                                        <AddRowButton
                                          type="button"
                                          handleOnClick={() =>
                                            setRules((prevRules) => [...prevRules, { id: uuid() }])
                                          }>
                                          <i className="fal fa-plus"></i> Add
                                        </AddRowButton>
                                        {rules
                                          .map((rule) => (
                                            <Rule key={rule.id}>
                                              <Controller
                                                as={Input}
                                                control={control}
                                                rules={{
                                                  required: "This is a required field",
                                                  pattern: {
                                                    value: /[A-Z]{3}/,
                                                    message:
                                                      "Three letters. Only captial letter A-Z are allowed",
                                                  },
                                                }}
                                                maxLength={3}
                                                type="text"
                                                name={`rule-${rule.id}.currency`}
                                                defaultValue={rule.currencyUnit}
                                                errors={errors}
                                                relativeError
                                              />

                                              <Controller
                                                as={Input}
                                                control={control}
                                                rules={{
                                                  required: "This is a required field",
                                                }}
                                                type="number"
                                                name={`rule-${rule.id}.totalPriceWithDiscount`}
                                                defaultValue={formatFromMinorUnits(rule.totalPriceWithDiscount)}
                                                errors={errors}
                                                relativeError
                                              />

                                              <Controller
                                                as={Input}
                                                control={control}
                                                maxLength={2}
                                                type="text"
                                                name={`rule-${rule.id}.shippingCountry`}
                                                defaultValue={rule.shippingCountry}
                                                errors={errors}
                                                relativeError
                                              />

                                              <Controller
                                                as={Input}
                                                control={control}
                                                type="text"
                                                name={`rule-${rule.id}.notForShippingCountry`}
                                                defaultValue={rule.notForShippingCountry}
                                                errors={errors}
                                                relativeError
                                              />

                                              <IconButton
                                                handleOnClick={() => {
                                                  setRules(
                                                    rules.filter((ruleId) => ruleId !== rule)
                                                  );
                                                }}>
                                                <i className="fal fa-fw fa-trash-alt" />
                                              </IconButton>
                                            </Rule>
                                          ))
                                          .reverse()}
                                      </ConditionsTable>
                                    </Conditions>
                                  </ShippingRuleBox>
                                </form>
                              </GridItem>
                            );
                          }}
                        </Mutation>
                      </GridContainer>
                    );
                  }}
                </Query>
              );
            }}
          </Query>
        </GridContainer>
      </PageContainer>
    </>
  );
};
