import gql from "graphql-tag";

const UPPDATE_ORDER_EMAIL = gql`
  mutation updateOrder($id: ID!, $email: String!, $billingAddress: InputAddress!) {
    updateOrder(
      updateOrder: {
        id: $id
        email: $email
        billingAddress: $billingAddress
      }
    ) {
      id
      status
      person {
        id
        email
      }
    }
  }
`;

export default UPPDATE_ORDER_EMAIL;
