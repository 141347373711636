import React, { useState } from "react";
import styled from "styled-components/macro";
import { Mutation, Query } from "@apollo/client/react/components";
import { useForm, Controller } from "react-hook-form-old";
import uuid from "react-uuid";

import ADD_DISCOUNT_RULE from "graphql/Discount/DiscountRule/AddDiscountRule";
import GET_RULE_TEMPLATES from "graphql/GetRuleTemplates";

import { useNotification } from "context/NotificationContext";
import CategoriesInput from "components/Discount/CategoriesInput";
import CurrencyInput from "components/Discount/CurrencyInput";
import { MEDIA_MIN_LARGE } from "variables/mediaQueries";
import PageContainer from "components/Page/PageContainer";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import Header from "components/Header/Header";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Box from "components/Content/Box";
import Input from "components/Ui/Input";
import Loader from "components/Ui/Loader";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import ActionButtons from "components/ActionButtons/ActionButtons";
import ActionButton from "components/ActionButtons/ActionButton";
import ActionButtonSecondary from "components/ActionButtons/ActionButtonSecondary";
import IconButton from "components/Ui/Buttons/IconButton";
import Sidebar from "components/Discount/Sidebar";
import Select from "components/Ui/Select";
import Attributes from "components/AttributeList/AttributeList";
import Attribute from "components/AttributeList/Attribute";
import Label from "components/AttributeList/Label";
import Value from "components/AttributeList/Value";
import Tooltip from "components/Ui/Tooltip";
import Toggle from "components/Ui/Toggle";
import TableHeader from "components/Table/Header";
import DiscountProducts from "components/Discount/DiscountProducts";
import StoreSelector from "components/StoreSelector/StoreSelector";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  margin-top: 2rem;
  padding-bottom: 6rem;

  ${MEDIA_MIN_LARGE} {
    padding-bottom: 0;
  }

  h5 {
    margin-bottom: 0;
    border-top: 0.1rem solid ${(p) => p.theme.colors.whiteOpac};
    padding-top: 3.2rem;
    width: 100%;
  }
`;

const RuleAttributes = styled(Attributes)`
  margin: -1.5rem 0 0;
`;

const ConditionsHeader = styled(TableHeader)`
  > div {
    width: 30%;
  }
`;

const Conditions = styled.div`
  width: 100%;
  position: relative;

  & + & {
    margin-top: 5rem;
  }

  h5 {
    border-top: 0;
    padding: 0 0 3rem;
  }
`;

const AddRowButton = styled(ActionButtonSecondary)`
  position: absolute;
  top: 0;
  right: 0;
`;

const InfoText = styled.h6`
  margin-top: 2rem;
  i {
    margin-right: 1rem;
  }
`;

const Condition = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1rem;
  border: 0.1rem solid ${(p) => p.theme.colors.whiteOpac};
  border-top: none;

  ${MEDIA_MIN_LARGE} {
    padding: 2rem;
  }
`;

const CurrencyCondition = styled(Condition)`
  > div {
    width: 30%;
    margin: 0;
  }
`;

const Padding = styled.div`
  width: 4.6rem !important;
`;

const TemplateSelect = styled(Select)`
  text-transform: none;
  margin: 0;
`;

const StoreSelectorRule = styled(StoreSelector)`
  ${MEDIA_MIN_LARGE} {
    padding-top: 0.8rem;
    margin: 0;
  }
`;

const DiscountBox = styled(Box).attrs((props) => ({
  preHeading: "Discount rules",
  heading: "Add discount rule",
}))``;

const getFriendlyTemplateName = (template) => {
  switch (template) {
    case "Cart Rule, discount on cart within range":
      return "Cart rule | Discount on cart within range";

    case "Cart Rule, Buy One Get One in percentage":
      return "Cart rule | BOGO - Buy one get one in percentage";

    case "Cart Rule, gifts with purchase on cart with value over threshold":
      return "Cart rule | Gift(s) with purchase on cart value over threshold";

    case "Cart Rule, gifts with purchase when cart contains selected items":
      return "Cart rule | Gift(s) with purchase when cart contains selected items";

    default:
      return template;
  }
};

export default ({ history }) => {
  const { setNotification } = useNotification();
  const { control, handleSubmit, errors } = useForm();
  const [active, setActive] = useState(true);
  const [templateId, setTemplateId] = useState("none");
  const [selectedStores, setSelectedStores] = useState([]);
  const [excludedCategories, setExcludedCategories] = useState([
    { label: "Shipping", value: "Shipping" },
  ]);
  const [currencies, setCurrencies] = useState([]);
  const [templateData, setTemplateData] = useState({
    currencyOptions: [{ id: uuid() }],
  });
  const [selectedProducts, setSelectedProducts] = useState({
    products: [],
    expandedProducts: [],
  });
  const [selectedVariants, setSelectedVariants] = useState({
    products: [],
    expandedProducts: [],
  });

  const formatPrice = (price) => Number(price * 100).toFixed(2) | 0;

  return (
    <>
      <Breadcrumbs
        slugs={[
          ["admin/discounts", "Discounts"],
          ["admin/discount-rules", "Discount rules"],
          ["admin/add-discount-rule", "Add discount rule"],
        ]}
      />
      <Header heading="Discount rules" />
      <PageContainer>
        <Sidebar />
        <GridContainer>
          <Query query={GET_RULE_TEMPLATES} variables={{ type: "CART" }}>
            {({ loading, error, data: ruleTemplates }) => {
              if (loading) return <Loader />;
              if (error)
                return (
                  <ErrorMessage>
                    An error occurred loading data, please contact support
                  </ErrorMessage>
                );

              return (
                <Mutation
                  mutation={ADD_DISCOUNT_RULE}
                  onCompleted={() => {
                    setNotification({
                      status: "success",
                      message: `Discount rule successfully added`,
                    });
                    history.push("/admin/discount-rules");
                  }}
                  onError={() => {
                    setNotification({
                      status: "error",
                      message: "An error occurred adding the discount rule, please contact support",
                    });
                  }}>
                  {(addDiscountRule, { loading: addDiscountLoading }) => {
                    const onSubmit = (data) => {
                      const { currencyOptions } = data.templateData || {};
                      const toCurrencyOptions = (options) =>
                        options.map(
                          ({
                            atLeastTotalPriceWithDiscount,
                            atMostTotalPriceWithDiscount,
                            currencyUnit,
                          }) => ({
                            ...(atLeastTotalPriceWithDiscount
                              ? {
                                atLeastTotalPriceWithDiscount: formatPrice(
                                  atLeastTotalPriceWithDiscount
                                ),
                              }
                              : {}),
                            ...(atMostTotalPriceWithDiscount
                              ? {
                                atMostTotalPriceWithDiscount: formatPrice(
                                  atMostTotalPriceWithDiscount
                                ),
                              }
                              : {}),
                            ...(currencyUnit ? { currencyUnit: currencyUnit } : {}),
                          })
                        );
                      const templateData = Object.assign(
                        data.discountInPercentage
                          ? { discountInPercentage: parseInt(data.discountInPercentage, 10) }
                          : {},
                        data.minItems ? { minItems: parseInt(data.minItems, 10) } : {},
                        currencyOptions
                          ? {
                            currencyOptions: toCurrencyOptions(Object.values(currencyOptions)),
                          }
                          : {},
                        currencies.length > 0
                          ? {
                            currencies: currencies.map((c) => c.value),
                          }
                          : {},
                        selectedStores.length > 0
                          ? { stores: selectedStores.map((s) => s.value) }
                          : {},
                        handlebarTemplateType === "ADD_BOGO_PERCENTAGE"
                          ? {
                            notForCategories: excludedCategories.map((c) => c.value),
                          }
                          : {},
                        selectedProducts.products.length > 0
                          ? {
                            validForProducts: selectedProducts.products,
                          }
                          : {},
                        selectedVariants.products.length > 0
                          ? {
                            variantIds: selectedVariants.products,
                          }
                          : {}
                      );

                      addDiscountRule({
                        variables: {
                          name: data.name,
                          templateId: templateId,
                          startDate: data.startDate,
                          endDate: data.endDate,
                          templateData: JSON.stringify(templateData),
                          active: active,
                        },
                      }).catch((e) => {
                        console.log(e);
                      });
                    };

                    if (templateId === "none") {
                      const sortedTemplates = [...ruleTemplates.getRuleTemplates]
                        .sort((a, b) => a.name.localeCompare(b.name))
                      return (
                        <GridItem columns="12">
                          <DiscountBox
                            showGoBackButton
                            goBackOnClick={() => history.push("/admin/discount-rules")}>
                            <TemplateSelect
                              label="Select discount rule template"
                              value={templateId}
                              handleChange={(event) => setTemplateId(event.target.value)}>
                              <option value="none" disabled>
                                Select a template
                              </option>
                              {sortedTemplates.map((ruleTemplate) => (
                                <option key={ruleTemplate.id} value={ruleTemplate.id}>
                                  {getFriendlyTemplateName(ruleTemplate.name)}
                                </option>
                              ))}
                            </TemplateSelect>
                          </DiscountBox>
                        </GridItem>
                      );
                    }

                    const activeTemplate = ruleTemplates.getRuleTemplates.find(
                      (ruleTemplate) => ruleTemplate.id === templateId
                    );

                    const jsonSchema = JSON.parse(activeTemplate.jsonSchema);
                    const [, handlebarTemplateType] = /"type":"([^"]+)"/.exec(
                      activeTemplate.handlebarTemplate
                    );

                    const maxDiscountInPercentage =
                      handlebarTemplateType === "ADD_BOGO_PERCENTAGE" ? 100 : 99;

                    return (
                      <>
                        <GridItem columns="12">
                          <form onSubmit={handleSubmit(onSubmit)}>
                            <DiscountBox
                              showGoBackButton
                              goBackOnClick={() => history.push("/admin/discount-rules")}>
                              {addDiscountLoading && <Loader />}

                              <ActionButtons inBox footerOnMobile>
                                <ActionButton type="submit">
                                  <i className="fal fa-fw fa-check" />
                                  Save
                                </ActionButton>
                              </ActionButtons>

                              <RuleAttributes>
                                <InfoText>
                                  <i className="fal fa-info-circle"></i>
                                  {activeTemplate.name}
                                </InfoText>
                                <Attribute>
                                  <Label>Active:</Label>
                                  <Value>
                                    <Toggle
                                      active={active}
                                      handleToggle={() => setActive(!active)}
                                    />
                                  </Value>
                                </Attribute>
                              </RuleAttributes>

                              <GridContainer collapse padding="3rem 0 0">
                                <GridItem
                                  mobilePadding="3rem 0 0"
                                  desktopPadding="0 1.5rem 0 0"
                                  columns="6">
                                  <Controller
                                    as={Input}
                                    control={control}
                                    rules={{
                                      required: "This is a required field",
                                    }}
                                    defaultValue=""
                                    type="text"
                                    name="name"
                                    label="Name"
                                    errors={errors}
                                  />

                                  {jsonSchema.properties.discountInPercentage && (
                                    <Controller
                                      as={Input}
                                      control={control}
                                      rules={{
                                        required: "This is a required field",
                                        min: {
                                          value: 0,
                                          message: `Needs to be a number between 0-${maxDiscountInPercentage}`,
                                        },
                                        max: {
                                          value: maxDiscountInPercentage,
                                          message: `Needs to be a number between 0-${maxDiscountInPercentage}`,
                                        },
                                      }}
                                      defaultValue=""
                                      type="number"
                                      name="discountInPercentage"
                                      placeholder={`0 - ${maxDiscountInPercentage}`}
                                      label="Discount in percentage"
                                      icon="badge-percent"
                                      errors={errors}
                                    />
                                  )}
                                  {handlebarTemplateType === "ADD_BOGO_PERCENTAGE" && (
                                    <CategoriesInput
                                      selected={excludedCategories}
                                      setSelected={setExcludedCategories}
                                      exclude={true}
                                    />
                                  )}
                                  {jsonSchema.properties.minItems && (
                                    <>
                                      <Tooltip />
                                      <Controller
                                        as={Input}
                                        control={control}
                                        rules={{
                                          min: {
                                            value: 1,
                                            message: "Needs to be a positive number",
                                          },
                                        }}
                                        min="1"
                                        type="number"
                                        name="minItems"
                                        placeholder="1"
                                        label="Minimum items"
                                        icon="shopping-basket"
                                        defaultValue="1"
                                        errors={errors}
                                        data-tip="The minimum number of matching items in the cart required for the discount to apply"
                                      />
                                    </>
                                  )}
                                </GridItem>
                                <GridItem
                                  mobilePadding="0"
                                  desktopPadding="0 0 0 1.5rem"
                                  columns="6">
                                  <Controller
                                    as={Input}
                                    control={control}
                                    type="date"
                                    name="startDate"
                                    label="Valid from:"
                                    icon="calendar-alt"
                                    errors={errors}
                                    defaultValue=""
                                  />

                                  <Controller
                                    as={Input}
                                    control={control}
                                    type="date"
                                    name="endDate"
                                    label="Valid to:"
                                    icon="calendar-alt"
                                    errors={errors}
                                    defaultValue=""
                                  />
                                  {jsonSchema.properties.currencies && (
                                    <CurrencyInput
                                      selected={currencies}
                                      setSelected={setCurrencies}
                                    />
                                  )}
                                  {jsonSchema.properties.stores && (
                                    <>
                                      <Label>Select stores</Label>
                                      <StoreSelectorRule
                                        selectedStores={selectedStores}
                                        setSelectedStores={setSelectedStores}
                                      />
                                    </>
                                  )}
                                </GridItem>
                              </GridContainer>
                              <Container>
                                <Tooltip />
                                {jsonSchema.properties.currencyOptions && (
                                  <Conditions>
                                    <h5>Conditions</h5>
                                    <AddRowButton
                                      type="button"
                                      handleOnClick={() =>
                                        setTemplateData(({ currencyOptions = [], ...data }) => ({
                                          ...data,
                                          currencyOptions: [...currencyOptions, { id: uuid() }],
                                        }))
                                      }>
                                      <i className="fal fa-plus"></i> Add
                                    </AddRowButton>

                                    <ConditionsHeader>
                                      <div>
                                        Currency code *{" "}
                                        <i
                                          className="fal fa-fw fa-info-circle"
                                          data-tip="ISO-4217, ex.USD"
                                        />
                                      </div>
                                      <div>
                                        Minimum order value *{" "}
                                        <i
                                          className="fal fa-fw fa-info-circle"
                                          data-tip="ex. 60 or 5.5"
                                        />
                                      </div>
                                      {handlebarTemplateType === "ADD_CARTDISCOUNT_PERCENTAGE" && (
                                        <div>
                                          Maximum order value{" "}
                                          <i
                                            className="fal fa-fw fa-info-circle"
                                            data-tip="ex. 60 or 5.5"
                                          />
                                        </div>
                                      )}
                                      <Padding />
                                    </ConditionsHeader>
                                    {templateData.currencyOptions
                                      .map((rule) => (
                                        <CurrencyCondition key={rule.id}>
                                          <Controller
                                            as={Input}
                                            control={control}
                                            rules={{
                                              required: "This is a required field",
                                              pattern: {
                                                value: /[A-Z]{3}/,
                                                message: "Only capital letter A-Z are allowed",
                                              },
                                            }}
                                            maxLength={3}
                                            placeholder="USD"
                                            type="text"
                                            name={`templateData.currencyOptions.${rule.id}.currencyUnit`}
                                            errors={errors}
                                            relativeError
                                          />

                                          <Controller
                                            as={Input}
                                            control={control}
                                            rules={{
                                              required: "This is a required field",
                                            }}
                                            type="number"
                                            name={`templateData.currencyOptions.${rule.id}.atLeastTotalPriceWithDiscount`}
                                            errors={errors}
                                            relativeError
                                            placeholder="100"
                                          />

                                          {handlebarTemplateType ===
                                            "ADD_CARTDISCOUNT_PERCENTAGE" && (
                                              <Controller
                                                as={Input}
                                                control={control}
                                                type="number"
                                                name={`templateData.currencyOptions.${rule.id}.atMostTotalPriceWithDiscount`}
                                                errors={errors}
                                                relativeError
                                                placeholder="1000"
                                                defaultValue="0"
                                              />
                                            )}

                                          <IconButton
                                            handleOnClick={() =>
                                              setTemplateData(
                                                ({ currencyOptions = [], ...data }) => ({
                                                  ...data,
                                                  currencyOptions: currencyOptions.filter(
                                                    (r) => r.id !== rule.id
                                                  ),
                                                })
                                              )
                                            }>
                                            <i className="fal fa-trash-alt" />
                                          </IconButton>
                                        </CurrencyCondition>
                                      ))
                                      .reverse()}
                                  </Conditions>
                                )}
                              </Container>
                            </DiscountBox>
                          </form>
                        </GridItem>
                        {jsonSchema.properties.validForProducts && (
                          <DiscountProducts
                            selectedProducts={selectedProducts}
                            setSelectedProducts={setSelectedProducts}
                          />
                        )}
                        {jsonSchema.properties.variantIds && (
                          <DiscountProducts
                            selectVariants={true}
                            selectedProducts={selectedVariants}
                            setSelectedProducts={setSelectedVariants}
                            labelOverrides={{
                              preHeading: "Discount rule",
                              heading: "Add gift products",
                              subHeading: "",
                            }}
                          />
                        )}
                      </>
                    );
                  }}
                </Mutation>
              );
            }}
          </Query>
        </GridContainer>
      </PageContainer>
    </>
  );
};
