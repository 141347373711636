import React from "react";
import styled from "styled-components/macro";
import { colors } from "variables/colors";

const GoToPage = styled.div`
  border-right: 0.1rem solid ${(p) => p.theme.colors.whiteOpac};
  padding: 0 2rem;
  display: flex;
  align-items: center;

  input,
  button,
  select {
    height: 3rem;
    border: none;
    border-radius: 0.3rem;
  }

  input,
  select {
    border: 0.1rem solid ${(p) => p.theme.colors.inputBorderColor};
  }

  input {
    width: 5rem;
    padding: 0.1rem 1rem 0;
    margin: 0 1rem 0 1rem;
  }

  button {
    background: ${colors.gradient};
    color: ${colors.white};
    padding: 0.2rem 1rem 0;
    margin-right: 1rem;
    cursor: pointer;
  }
`;

export default ({ children, ...props }) => <GoToPage {...props}>{children}</GoToPage>;
