import gql from "graphql-tag";

const SEARCH_PRODUCTS = gql`
  query searchProducts($query: AWSJSON!) {
    searchProducts(query: $query) {
      totalHits
      products {
        id
        name
        imageUrl
        category
        created
        lastUpdated
        type
        active
      }
    }
  }
`;

export default SEARCH_PRODUCTS;
