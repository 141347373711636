import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import uuid from "react-uuid";

import { MEDIA_MIN_LARGE } from "variables/mediaQueries";
import Button from "components/Ui/Button";
import HoM from "components/Ui/HoM";
import TableHeader from "components/Table/Header";
import Products from "components/Table/Rows";
import DiscountProduct from "components/Discount/DiscountProduct";
import Column from "components/Table/Column";
import Toolbar from "components/Table/Toolbar/Toolbar";
import GoToPage from "components/Table/Toolbar/GoToPage";
import TotalItems from "components/Table/Toolbar/TotalItems";
import ItemsPerPage from "components/Table/Toolbar/ItemsPerPage";
import Pagination from "components/Pagination/Pagination";
import Loader from "components/Ui/Loader";
import Checkbox from "components/Ui/Checkbox";

const Header = styled(TableHeader)`
  padding-left: 5rem;
  padding-right: 1rem;

  ${MEDIA_MIN_LARGE} {
    padding-left: 2rem;
    padding-right: 2rem;
  }
`;

const Name = styled(Column)`
  width: 75%;
  padding: 0 1rem 0 7rem;

  ${MEDIA_MIN_LARGE} {
    width: 85%;
    padding: 0 2rem 0 ${(p) => p.paddingleft};
  }
`;

const SelectedVariantCount = styled(Column)`
  width: 25%;
  padding-left: 1rem;

  ${MEDIA_MIN_LARGE} {
    width: 18%;
  }
`;

const SortButton = styled(Button)`
  background: transparent;
  height: auto;
  width: auto;
  margin: 0;
  color: ${(p) => p.theme.colors.white};

  i {
    font-size: 1.6rem;
  }
`;

export default function ProductsTable({
  data,
  loading,
  productsPerPage,
  fetchMore,
  esbQuery,
  selectedProducts,
  setSelectedProducts,
  selectVariants,
}) {
  const [itemsPerPage, setItemsPerPage] = useState(productsPerPage);
  const [goToPageInput, setGoToPageInput] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSort, setCurrentSort] = useState("default");
  const [sortField, setSortField] = useState("selected");
  const [preventSort, setPreventSort] = useState(false);
  const [products, setProducts] = useState(data?.searchProducts.products);
  const productsTotalHits = data?.searchProducts.totalHits;

  useEffect(() => {
    const from = (currentPage - 1) * itemsPerPage;
    fetchMore({
      variables: { query: JSON.stringify(esbQuery.size(itemsPerPage).from(from).toJSON()) },
    })
      .then((result) => setProducts(result.data.searchProducts.products))
      .catch((error) => console.log(error));
    setGoToPageInput(currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, itemsPerPage, esbQuery]);

  const onSortChange = (sortField) => {
    let nextSort;
    if (currentSort === "down") nextSort = "default";
    else if (currentSort === "default") nextSort = "up";
    else if (currentSort === "up") nextSort = "down";

    setCurrentSort(nextSort);
    setSortField(sortField);
  };

  const getSortType = (field, sort) =>
    field === sortField ? sortTypes[sort].class : sortTypes.default.class;

  const sortTypes = {
    up: {
      class: "sort-up",
      fn: (a, b) => {
        const valueA = toValue(sortField, a);
        const valueB = toValue(sortField, b);
        if (valueA > valueB) return 1;
        else if (valueA < valueB) return -1;
        return 0;
      },
    },
    down: {
      class: "sort-down",
      fn: (a, b) => {
        const valueA = toValue(sortField, a);
        const valueB = toValue(sortField, b);
        if (valueA > valueB) return -1;
        else if (valueA < valueB) return 1;
        return 0;
      },
    },
    default: {
      class: "sort",
      fn: (a, _) => a,
    },
  };

  const toValue = (sortField, obj) => {
    if (sortField === "variantCount") {
      if (!preventSort) {
        return (
          obj.relatedVariants.filter((variant) => selectedProducts.products.includes(variant.id))
            .length / obj.relatedVariants.length
        );
      } else {
        return false;
      }
    } else if (sortField === "selected") {
      return !selectedProducts.products.includes(obj.id);
    } else {
      return obj[sortField].toLowerCase();
    }
  };

  const handleSelectAll = (e) => {
    const { checked } = e.target;

    setSelectedProducts((prev) =>
      selectVariants
        ? {
            ...prev,
            products: checked
              ? products?.map((p) => p.relatedVariants.map((v) => v.id)).flat()
              : [],
          }
        : { ...prev, products: checked ? products?.map((p) => p.id) : [] }
    );
  };

  return (
    <>
      <Toolbar>
        <HoM>
          <GoToPage>
            Page
            <input value={goToPageInput} onChange={(e) => setGoToPageInput(e.target.value)} />
            <button type="button" onClick={() => setCurrentPage(parseInt(goToPageInput, 10))}>
              <i className="fal fa-sync"></i>
            </button>
            of {productsTotalHits ? Math.ceil(productsTotalHits / itemsPerPage) : "-"} pages
          </GoToPage>
        </HoM>
        <ItemsPerPage setItemsPerPage={setItemsPerPage} />
        <TotalItems>
          <HoM>Total </HoM>products: <span>{productsTotalHits || "-"}</span>
        </TotalItems>
        <TotalItems>
          <HoM>Selected </HoM>
          {selectVariants ? "variants" : "products"}:{" "}
          <span>{selectedProducts.products.length || "-"}</span>
        </TotalItems>
      </Toolbar>
      <Header>
        <Checkbox onClick={(e) => handleSelectAll(e)} />
        <Name
          paddingleft={selectVariants ? "14rem" : "11rem"}
          onClick={() => {
            setPreventSort(false);
            onSortChange("name");
          }}>
          <span>Name </span>
          <SortButton>
            <i className={`fas fa-${getSortType("name", currentSort)}`} />
          </SortButton>
        </Name>
        <SelectedVariantCount
          onClick={() => {
            setPreventSort(false);
            onSortChange(selectVariants ? "variantCount" : "selected");
          }}>
          <span>{selectVariants ? "Selected variants" : "Selected"}</span>
          <SortButton>
            <i
              className={`fas fa-${getSortType(
                selectVariants ? "variantCount" : "selected",
                currentSort
              )}`}
            />
          </SortButton>
        </SelectedVariantCount>
      </Header>
      <Products>
        {loading && <Loader />}
        {products && !loading && (
          <Pagination
            itemsPerPage={itemsPerPage}
            totalItems={productsTotalHits}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}>
            {[...products].sort(sortTypes[currentSort].fn).map((product) => {
              return (
                <DiscountProduct
                  key={uuid()}
                  product={product}
                  selectedProducts={selectedProducts}
                  setSelectedProducts={setSelectedProducts}
                  setPreventSort={setPreventSort}
                  selectVariants={selectVariants}
                />
              );
            })}
          </Pagination>
        )}
      </Products>
    </>
  );
}
