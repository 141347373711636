import React, { useState } from "react";
import styled from "styled-components/macro";
import languages from "@cospired/i18n-iso-languages";

import Input from "components/Ui/Input";
import IconButton from "components/Ui/Buttons/IconButton";

const LangCode = styled.div`
  width: 11rem;
  display: flex;
  flex-shrink: 0;
`;

const LangCodeInput = styled(Input)`
  width: 10rem;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const TranslationContainer = styled.div`
  height: 10rem;
  display: flex;
  justify-content: flex-start;
  margin: 0;
  align-items: center;
`;

const ActionContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 1rem;
`;

export default ({ setShowTranslation, translations, setTranslations }) => {
  const [langCode, setLangCode] = useState("");
  const [translation, setTranslation] = useState("");
  const [codeError, setCodeError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("Invalid code");
  const codeExists = (code) => translations.find((t) => t.langCode === code);
  const addTranslation = () => {
    if (languages.isValid(langCode)) {
      if (codeExists(langCode)) {
        setErrorMessage("Code already exists");
        setCodeError(true);
      } else {
        setTranslations([...translations, { langCode: langCode, translation: translation }]);
        setLangCode("");
        setTranslation("");
        setCodeError(false);
        setShowTranslation(false);
      }
    } else {
      setErrorMessage("Invalid code");
      setCodeError(true);
    }
  };

  return (
    <Wrapper>
      <TranslationContainer>
        <LangCode>
          <LangCodeInput
            type="text"
            value={langCode}
            name="langCode"
            label="Lang code"
            errorText={errorMessage}
            error={codeError}
            onChange={(e) => setLangCode(e.target.value)}
          />
        </LangCode>
        <Input
          type="text"
          value={translation}
          name="translation"
          label="Translation"
          errorText="Error"
          onChange={(e) => setTranslation(e.target.value)}
        />
      </TranslationContainer>
      <ActionContainer>
        <IconButton onClick={() => setShowTranslation(false)}>
          <i className="fal fa-fw fa-times" />
        </IconButton>
        <IconButton onClick={() => addTranslation()}>
          <i className="fal fa-fw fa-check" />
        </IconButton>
      </ActionContainer>
    </Wrapper>
  );
};
