import React, { useContext, useState } from "react";
import moment from "moment/min/moment-with-locales";

export const AdminContext = React.createContext({});
export const useAdminContext = () => useContext(AdminContext);

const DEFAULT_STATUS = [];
const DEFAULT_DATETIME_FROM = `${moment().subtract(1, "month").format("YYYY-MM-DD")}T00:00`;
const DEFAULT_DATETIME_TO = `${moment().format("YYYY-MM-DD")}T23:59`;
const INCLUDE_LIST = [
  { value: "created", label: "Created" },
  { value: "lastUpdated", label: "Last Updated" },
];

export const AdminProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [tabs, setTabs] = useState([]);
  const [customerTabs, setCustomerTabs] = useState([]);
  const [showFilterForm, setShowFilterForm] = useState(false);
  const [filterDateTimeFrom, setFilterDateTimeFrom] = useState(DEFAULT_DATETIME_FROM);
  const [filterDateTimeTo, setFilterDateTimeTo] = useState(DEFAULT_DATETIME_TO);
  const [filterStatusOptionValues, setFilterStatusOptionValues] = useState(DEFAULT_STATUS);
  const [filterStores, setFilterStores] = useState([]);
  const [include, setInclude] = useState(INCLUDE_LIST[0].value);

  return (
    <AdminContext.Provider
      value={{
        tabs,
        user,
        setUser,
        customerTabs,
        showFilterForm,
        filterDateTimeFrom,
        filterDateTimeTo,
        filterStatusOptionValues,
        filterStores,
        include,
        setTabs,
        setCustomerTabs,
        setShowFilterForm,
        setFilterDateTimeFrom,
        setFilterDateTimeTo,
        setFilterStatusOptionValues,
        setFilterStores,
        setInclude,
      }}>
      {children}
    </AdminContext.Provider>
  );
};
