import React from "react";
import styled from "styled-components/macro";
import Dinero from "dinero.js";

const Container = styled.span``;

export default ({ amount, currencyUnit, short = false }) => {
  const money = Dinero({ amount: amount, currency: currencyUnit });
  return <Container>{short ? money.toUnit() : money.toFormat()}</Container>;
};
