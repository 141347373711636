import React, { useState, useEffect } from "react";
import { Query, Subscription } from "@apollo/client/react/components";
import esb from "elastic-builder";
import styled from "styled-components/macro";
import SEARCH_ORDERS from "graphql/Order/SearchOrders";
import SEARCH_ORDERS_DISCOUNT_EXPORT from "graphql/Order/SearchOrdersDiscountExport";
import UPDATED_ORDER_SUBSCRIPTION from "graphql/Order/UpdatedOrderSubscription";
import { MEDIA_MIN_LARGE } from "variables/mediaQueries";
import DiscountOrdersTable from "./DiscountOrdersTable";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Box from "components/Content/Box";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import Tabs from "components/Tabs/Tabs";
import Tab from "components/Tabs/Tab";
import Order from "views/Order/Order";
import SearchDiscountOrder from "components/Discount/SearchDiscountOrder";
import DiscountOrdersExport from "./DiscountOrdersExport";

const TableContainer = styled(GridContainer)`
  padding-top: 1rem;
`;

const OrderBox = styled(Box)`
  > h3 {
    padding-bottom: 1rem;
    padding-top: 0;
    margin-bottom: 0rem;

    ${MEDIA_MIN_LARGE} {
      padding-bottom: 3rem;
      padding-top: 0;
    }
  }
`;

const ORDERS_PER_PAGE = 25;

const Orders = ({ discountId, discountCode, history }) => {
  const [esbQuery, setEsbQuery] = useState(
    new esb.requestBodySearch()
      .query(
        esb
          .boolQuery()
          .must([
            esb.termQuery("discount.keyword", discountId),
            esb.matchQuery("statusLog.status", "success"),
          ])
      )
      .sort(esb.sort("lastUpdated", "desc"))
      .size(ORDERS_PER_PAGE)
      .from(0)
  );
  const [orders, setOrders] = useState();
  const [updatedOrder, setUpdatedOrder] = useState();
  const [ordersTotalHits, setOrdersTotalHits] = useState(0);
  const [activeTab, setActiveTab] = useState("table");
  const [tabs, setTabs] = useState([]);

  const closeTab = (id) => {
    setTabs(tabs.filter((item) => item.id !== id));
    if (tabs.length === 1) return;
    const tabIndex = tabs.findIndex((t) => t.id === id);
    setActiveTab(tabIndex === tabs.length - 1 ? tabs[tabIndex - 1].id : tabs[tabIndex + 1].id);
  };

  useEffect(() => {
    tabs.length > 0 && setActiveTab(tabs[tabs.length - 1].id);
  }, [tabs]);

  return (
    <Subscription
      subscription={UPDATED_ORDER_SUBSCRIPTION}
      onSubscriptionData={({ subscriptionData }) =>
        setUpdatedOrder(subscriptionData.data.updatedOrder)
      }>
      {({ _ }) => {
        return (
          <Query
            query={SEARCH_ORDERS}
            variables={{ query: JSON.stringify(esbQuery.toJSON()) }}
            onCompleted={(data) => {
              setOrders(data.searchOrders.orders);
              setOrdersTotalHits(data.searchOrders.totalHits);
            }}>
            {({ loading, error, fetchMore }) => {
              if (error)
                return (
                  <ErrorMessage inBox>
                    An error occurred getting data, please contact support
                  </ErrorMessage>
                );

              return (
                <>
                  <Tabs>
                    <Tab
                      onClick={() => setActiveTab("table")}
                      active={tabs.length === 0 || activeTab === "table"}>
                      Orders
                    </Tab>
                    {tabs.map((tab) => (
                      <Tab
                        key={tab.id}
                        tab={tab}
                        onClick={() => setActiveTab(tab.id)}
                        active={tab.id === activeTab}
                        closeTab={closeTab}>
                        {tab.id !== "table" && "#"}
                        {tab.reference}
                      </Tab>
                    ))}
                  </Tabs>
                  {tabs.length === 0 || activeTab === "table" ? (
                    <TableContainer>
                      <GridItem columns="12" padding="0" mobilePadding="1.5rem">
                        <OrderBox preHeading="Orders" heading={discountCode}>
                          <SearchDiscountOrder
                            allOrders={esbQuery}
                            discountId={discountId}
                            setEsbQuery={setEsbQuery}
                            ordersPerPage={ORDERS_PER_PAGE}
                          />
                          <DiscountOrdersTable
                            esbQuery={esbQuery}
                            orders={orders}
                            setOrders={setOrders}
                            updatedOrder={updatedOrder}
                            ordersTotalHits={ordersTotalHits}
                            loading={loading}
                            fetchMore={fetchMore}
                            history={history}
                            setTabs={setTabs}
                            tabs={tabs}
                            setActiveTab={setActiveTab}
                            ordersPerPage={ORDERS_PER_PAGE}>
                            {({ totalHits }) => (
                              <DiscountOrdersExport
                                size={totalHits}
                                query={SEARCH_ORDERS_DISCOUNT_EXPORT}
                                esbQuery={esbQuery}
                              />
                            )}
                          </DiscountOrdersTable>
                        </OrderBox>
                      </GridItem>
                    </TableContainer>
                  ) : (
                    tabs.map(
                      (tab) =>
                        activeTab === tab.id && (
                          <Order
                            key={tab.id}
                            id={tab.id}
                            updatedOrder={updatedOrder}
                            scrollToTop={false}
                          />
                        )
                    )
                  )}
                </>
              );
            }}
          </Query>
        );
      }}
    </Subscription>
  );
};

export default (props) => {
  return Orders(props);
};
