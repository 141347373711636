import gql from "graphql-tag";

const ADD_SHIPPING_OPTION = gql`
  mutation addProduct(
    $id: ID!
    $name: String!
    $type: ProductType!
    $imageUrl: String!
    $price: [InputMoney!]!
    $category: String!
    $attribute: AWSJSON!
    $relatedProduct: ID!
  ) {
    addProduct(
      products: {
        id: $id
        name: $name
        type: $type
        imageUrl: $imageUrl
        price: $price
        category: $category
        attribute: $attribute
        relatedProduct: $relatedProduct
      }
    ) {
      id
      name
      type
      price {
        amount
        currencyUnit
      }
    }
  }
`;

export default ADD_SHIPPING_OPTION;