import React from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";

import { colors } from "variables/colors";
import { sidebarWidth, sidebarWidthSimple } from "variables/general";
import { MEDIA_MIN_MEDIUM } from "variables/mediaQueries";
import Logo from "../Ui/Logo";
import ThemeToggle from "../Ui/ThemeToggle";
import { isMobile } from "helpers/getViewport";

const Container = styled.div`
  width: 100%;
  max-width: 96%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background: ${colors.background};
  background: linear-gradient(0deg, ${colors.background} 0%, ${colors.secondaryBackground} 100%);
  display: flex;
  flex-wrap: wrap;
  transition: all 0.4s;
  transform: ${(p) => (p.expanded ? "translate(-100%, 0)" : "translate(0, 0)")};
  z-index: 10001;
  padding-bottom: 15rem;
  padding-top: 2rem;

  ${MEDIA_MIN_MEDIUM} {
    transform: translate(0, 0);
    padding-top: 0;
    max-width: ${(p) => (p.expanded ? sidebarWidth : sidebarWidthSimple)};

    span {
      transition: all 0.4s;
      overflow: hidden;
      max-width: ${(p) => (p.expanded ? "20rem" : "0")};
      opacity: ${(p) => (p.expanded ? "1" : "0")};
    }
  }
`;

const ToggleWrapper = styled.div`
  position: absolute;
  right: ${(p) => (p.expanded ? "-4.5rem" : "-1.5rem")};
  top: ${(p) => (p.expanded ? "-3rem" : "0")};
  width: 4rem;
  height: 12rem;
  border-radius: 2rem;
  background-image: ${(p) => (p.expanded ? "none" : `url(${p.theme.curve})`)};
  background-repeat: no-repeat;
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 0.5rem;

  ${MEDIA_MIN_MEDIUM} {
    background-image: url(${(p) => p.theme.curve});
    right: -1.5rem;
    bottom: 6rem;
    top: auto;
  }
`;

const Toggle = styled.div`
  width: 3rem;
  height: 3rem;
  border-radius: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${colors.white};
  color: ${colors.black};
  cursor: pointer;
  padding: 0.1rem 0 0 0.2rem;
  box-shadow: 0 0.2rem 0.8rem 0 rgb(0 0 0 / 15%);

  .fa-angle-left {
    transition: transform 0.2s;
    transform: scale(1, -1);
    display: ${(p) => p.expanded && "none"};
    font-size: 2.4rem;
    padding: 0.4rem 0.2rem 0 0;
  }

  .fa-bars {
    display: ${(p) => !p.expanded && "none"};
    font-size: 1.6rem;
  }

  ${MEDIA_MIN_MEDIUM} {
    bottom: 7.7rem;
    right: -1.5rem;

    .fa-bars {
      display: none;
    }

    .fa-angle-left {
      display: block;
      transform: scale(${(p) => (p.expanded ? "1, 1" : "-1, 1")});
      padding: 0;
    }
  }
`;

const Header = styled.div`
  display: none;
  margin-top: 1rem;
  padding: 3rem;
  height: 8rem;

  ${MEDIA_MIN_MEDIUM} {
    display: flex;
    align-items: center;
    padding: 2rem 3rem;
    height: 10rem;
  }
`;

const Body = styled.div`
  align-self: flex-start;
  width: 100%;
`;

const Menu = styled.ul`
  list-style: none;
  padding: 0;
`;

const MenuItem = styled.li`
  position: relative;

  a {
    width: 100%;
    padding: 0.5rem 3rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-weight: ${(p) => (p.active ? "700" : "400")};
    letter-spacing: 0.05rem;
    font-size: 1.5rem;

    ${MEDIA_MIN_MEDIUM} {
      padding: ${(p) => (p.expanded ? "1rem 3rem 1rem 4rem" : "1rem 2.5rem")};
      font-size: 1.5rem;
    }
  }

  i {
    font-size: 2.2rem;
    margin-right: 2rem;
    padding: 0.2rem 0 0 0;
    color: ${colors.white};
    width: 5rem;
    height: 5rem;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    border: 0.1rem solid rgba(255, 255, 255, 0.15);
    border-radius: 0.5rem;
    transition: background 0.2s;
    background: ${(p) => (p.active ? colors.gradient : "transparent")};
    border: none;
  }

  &:hover {
    i {
      background: ${(p) => (p.active ? colors.gradient : "rgba(255, 255, 255, 0.1)")};
    }
  }
`;

const Divider = styled.hr`
  height: 0.1rem;
  border: none;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  margin: 1rem 2rem 0;
  display: block;
  opacity: 0.1;

  ${MEDIA_MIN_MEDIUM} {
    margin: 3rem 2rem 0;
  }
`;

const MainMenu = styled(Menu)``;

const MainMenuItem = styled(MenuItem)``;

const SecondaryMenu = styled(Menu)`
  padding-top: 0;

  ${MEDIA_MIN_MEDIUM} {
    padding-top: 3rem;
  }
`;

const SecondaryMenuItem = styled(MenuItem)`
  a {
    font-size: 1.4rem;
    display: flex;
    align-items: center;

    ${MEDIA_MIN_MEDIUM} {
      padding: ${(p) => (p.expanded ? "0.5rem 3rem 0.5rem 4rem" : "0.5rem 2.5rem")};
    }
  }

  &:after {
    border-left-color: ${(p) => (p.active ? colors.secondaryBackground : "transparent")};
  }

  i {
    font-size: 1.6rem;
    margin-right: 1.2rem;
    padding: 0.2rem 0 0 0.1rem;
  }
`;

export default ({ sidebarExpanded, setSidebarExpanded, routes, activeRoute, ...props }) => {
  const visibleRoutes = routes.filter((route) => route.visible !== false);

  return (
    <Container expanded={sidebarExpanded} {...props}>
      <ToggleWrapper expanded={sidebarExpanded}>
        <Toggle expanded={sidebarExpanded} onClick={() => setSidebarExpanded(!sidebarExpanded)}>
          <i className="fal fa-bars"></i>
          <i className="fal fa-angle-left"></i>
        </Toggle>
      </ToggleWrapper>
      <Header>
        <Logo expanded={sidebarExpanded} />
      </Header>
      <Body>
        <MainMenu>
          {visibleRoutes &&
            visibleRoutes.map((route) => (
              <MainMenuItem
                expanded={sidebarExpanded}
                key={route.path}
                onClick={() => isMobile() && setSidebarExpanded(true)}
                active={
                  activeRoute.path === route.path ||
                  (activeRoute.parentPath && activeRoute.parentPath === route.path)
                }>
                <NavLink to={route.layout + route.path}>
                  <i className={route.icon}></i>
                  <span>{route.name}</span>
                </NavLink>
              </MainMenuItem>
            ))}
        </MainMenu>
        <Divider />
        <SecondaryMenu>
          <SecondaryMenuItem
            expanded={sidebarExpanded}
            onClick={() => isMobile() && setSidebarExpanded(true)}
            active={activeRoute.path === "/stores" || activeRoute.parentPath === "/stores"}>
            <NavLink to="/admin/stores">
              <i className="fal fa-fw fa-store"></i> <span>Stores</span>
            </NavLink>
          </SecondaryMenuItem>
          <SecondaryMenuItem
            expanded={sidebarExpanded}
            onClick={() => isMobile() && setSidebarExpanded(true)}
            active={
              activeRoute.path === "/configuration" || activeRoute.parentPath === "/configuration"
            }>
            <NavLink to="/admin/configuration">
              <i className="fal fa-fw fa-cog"></i> <span>Configurations</span>
            </NavLink>
          </SecondaryMenuItem>
        </SecondaryMenu>
        <Divider />
      </Body>
      <ThemeToggle expanded={sidebarExpanded} />
    </Container>
  );
};
