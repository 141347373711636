import React from "react";
import styled from "styled-components/macro";
import { colors } from "variables/colors";
import { MEDIA_MIN_LARGE } from "variables/mediaQueries";

import Button from "components/Ui/Button";

const ActionButtonDelete = styled(Button)`
  height: 4rem;
  width: auto;
  padding: 1rem;
  margin: 0;
  background: ${(p) => p.theme.colors.darkerGrey};
  color: ${colors.white};
  border: none;
  filter: brightness(100%);
  transition: all 0.2s;

  i {
    font-size: 1.4rem;
    margin: 0.2rem 0.5rem 0 0;
  }

  ${MEDIA_MIN_LARGE} {
    height: 4.4rem;
    padding: 0 2rem;

    &:hover {
      filter: brightness(110%);
    }
  }
`;

export default ({ type, children, handleOnClick, iconOnlyMobile, ...props }) => (
  <ActionButtonDelete
    {...props}
    type={type || "button"}
    iconOnlyMobile={iconOnlyMobile}
    onClick={() => handleOnClick && handleOnClick()}>
    <i className="fal fa-fw fa-trash-alt" /> {children}
  </ActionButtonDelete>
);
