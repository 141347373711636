import React from "react";
import GridItem from "components/Grid/GridItem";
import styled from "styled-components";
import InputNew from "components/Ui/InputNew";
import { Controller } from "react-hook-form-old";
import Tooltip from "components/Ui/Tooltip";

const AmountLabel = styled.div`
  font-weight: 800;
  margin-bottom: 1rem;
  i {
    margin-right: 0.5rem;
  }
`;

const CurrencyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  div {
    margin: 1rem 0.5rem0;
  }
`;

const CurrencyInput = styled(InputNew)`
  width: 10rem;
  margin-bottom: 1rem;
`;

export default ({ currencies, control, errors}) => {

  return (
    <GridItem columns="12">
      <Tooltip></Tooltip>
      <AmountLabel>
        <i className="fal fa-fw fa-info-circle" data-tip="Only digits, ex. 60" />
        Amount
      </AmountLabel>
      <CurrencyWrapper>
        {!currencies.length && <p>No stores selected</p>}
        {currencies.map((currency) => {
          return (
            <div key={currency}>
              <Controller
                name={`price[${currency}].amount`}
                defaultValue={0}
                as={CurrencyInput}
                control={control}
                rules={{
                  required: "This is a required field",
                  min: {
                    value: 1,
                    message: "Amount needs to be greater than 0",
                  },
                }}
                type="number"
                label={currency}
                errors={errors}
              />
            </div>
          );
        })}
      </CurrencyWrapper>
    </GridItem>
  );
};
