import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client/react/hooks";
import styled, { keyframes } from "styled-components/macro";
import Dialog from "components/Dialog/Dialog";
import Box from "components/Content/Box";
import FilterButton from "components/Ui/FilterButton";
import Dinero from "dinero.js";
import { get } from "lodash";
import { json2csv } from "json-2-csv";
import moment from "moment/min/moment-with-locales";
import { useNotification } from "context/NotificationContext";

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  transition: opacity 0.3s;
  pointer-events: all;
  z-index: 10000;
  opacity: 1;
`;

const Container = styled(Box)`
  max-width: 40rem;
  margin: 0 3rem;
`;

const Header = styled.h4`
  width: 100%;
  text-transform: uppercase;
  font-weight: 400;
`;

const Loader = styled.div`
  width: 500px;
  margin: 0 auto;
  border: 4px solid transparent;
  border-radius: 0.6rem;
  position: relative;
  padding: 4px;

  &:before {
    content: "";
    border: 1px solid rgb(95, 113, 205);
    border-radius: 0.6rem;
    position: absolute;
    top: -4px;
    right: -4px;
    bottom: -4px;
    left: -4px;
  }
`;

const borealisBar = keyframes`
  0% {
    left: 0%;
    right: 100%;
    width: 0%;
  }
  10% {
    left: 0%;
    right: 75%;
    width: 25%;
  }
  90% {
    right: 0%;
    left: 75%;
    width: 25%;
  }
  100% {
    left: 100%;
    right: 0%;
    width: 0%;
  }
`;

const LoaderBar = styled.div`
  position: absolute;
  border-radius: 0.6rem;
  top: 0;
  right: 100%;
  bottom: 0;
  left: 0;
  background: linear-gradient(90deg, rgb(95, 113, 205) 0%, rgb(55, 77, 192) 100%);
  width: 0;
  animation: ${borealisBar} 2s linear infinite;
`;

function Exporter({ query, variables, onComplete, onError }) {
  const { loading, error, data } = useQuery(query, { variables });

  useEffect(() => {
    if (loading) return;
    if (error) {
      console.error(error);
      onError(error.message);
      return;
    }

    onComplete(data.searchOrders.orders);
  }, [loading, error, data, onComplete, onError]);

  return (
    <Backdrop>
      <Container>
        <Header>Generating export</Header>
        <Loader>
          <LoaderBar />
        </Loader>
      </Container>
    </Backdrop>
  );
}

const Currency = (currency) => (amount) => Dinero({ amount, currency }).toUnit();
const MoneyForCurrency = (currency) => (amount) => Dinero({ amount, currency });

export default function OrdersExport({ size, query, esbQuery }) {
  const { setNotification } = useNotification();
  const [dialog, showDialog] = useState(false);
  const [exporting, setExporting] = useState(false);

  function onCancel() {
    if (exporting) return;
    showDialog(false);
  }

  function onExport(event) {
    event.stopPropagation();
    showDialog(false);
    setExporting(true);
  }

  function onError(errorMessage) {
    setExporting(false);
    setNotification({
      status: "error",
      message: `Could not fetch orders: ${errorMessage}`,
    });
  }

  function onComplete(orders) {
    const formattedOrders = orders.map((o) => {
      const Amount = Currency(o.currencyUnit);
      const Money = MoneyForCurrency(o.currencyUnit);

      const getPercentage = (orderLines) => {
        return orderLines.find((l) => l.type === "physical")?.taxRate || 0;
      };

      const calculateVAT = (value, taxPercentage) => {
        const netAmount = value.divide(taxPercentage / 100 + 1);
        return value.subtract(netAmount);
      };

      return {
        reference: o.reference,
        created: o.created,
        lastUpdated: o.lastUpdated,
        paymentProvider: o.paymentProvider || "",
        paymentMethod: o.paymentMethod || "",
        orderAmount: Amount(o.orderAmount),
        orderAmountWithDiscount: Amount(o.orderAmountWithDiscount),
        orderNetAmount: Money(o.orderAmountWithDiscount).subtract(Money(o.orderTaxAmount)).toUnit(),
        fortnoxInvoiceNumber: get(o.customerAttribute, "fortnox.invoiceNumber", ""),
        fortnoxCreditInvoiceNumbers: get(
          o.customerAttribute,
          "fortnox.creditInvoiceNumbers",
          []
        ).join(","),
        orderTaxAmount: Amount(o.orderTaxAmount),
        creditedAmount: Amount(o.refundAmount || 0),
        creditedNetAmount: Money(o.refundAmount || 0)
          .subtract(calculateVAT(Money(o.refundAmount || 0), getPercentage(o.orderLines)))
          .toUnit(),
        currencyUnit: o.currencyUnit,
        status: o.status,
        discountCode: o.discount ? o.discount.code : "",
        familyName: get(o, "shippingAddress.familyName", ""),
        givenName: get(o, "shippingAddress.givenName", ""),
        email: get(o, "person.email", ""),
        country: get(o, "shippingAddress.country", ""),
        city: get(o, "shippingAddress.city", ""),
        streetAddress: get(o, "shippingAddress.streetAddress", ""),
        postalCode: get(o, "shippingAddress.postalCode", ""),
        region: o.shippingAddress.region || "",
        phone: get(o, "shippingAddress.phone", ""),
      };
    });

    json2csv(formattedOrders, (_, csv) => {
      const blob = new Blob([csv], { type: "text/csv" });
      const filename = `discount-orders-export-${moment().format("YYYY-MM-DD-HH_mm")}.csv`;

      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, filename);
      } else {
        const element = document.createElement("a");
        element.setAttribute("href", URL.createObjectURL(blob));
        element.setAttribute("download", filename);
        element.style.display = "none";
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      }
      setExporting(false);
    });
  }

  return (
    <>
      <FilterButton handleOnClick={() => showDialog(true)}>
        <i className="fal fa-download" /> Export
      </FilterButton>
      <Dialog
        header={`Export order${size !== 1 ? "s" : ""}`}
        text={`Do you want to export ${size === 1 ? "this" : "these"} ${size} order${
          size !== 1 ? "s" : ""
        } as a CSV?`}
        open={dialog}
        handleClose={onCancel}
        handleOk={onExport}
      />
      {exporting && (
        <Exporter
          query={query}
          variables={{ query: JSON.stringify(esbQuery.size(size).toJSON()) }}
          onComplete={onComplete}
          onError={onError}
        />
      )}
    </>
  );
}
