import React, { useState } from "react";
import Moment from "react-moment";
import styled from "styled-components/macro";
import { Query } from "@apollo/client/react/components";

import GET_PRODUCT from "graphql/Product/GetProduct";

import PageContainer from "components/Page/PageContainer";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import Header from "components/Header/Header";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Box from "components/Content/Box";
import Loader from "components/Ui/Loader";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import ProductVariants from "./ProductVariants";
import Sidebar from "components/Product/Sidebar";
import Placeholder from "components/Ui/PlaceholderImage";
import Attributes from "components/AttributeList/AttributeList";
import Attribute from "components/AttributeList/Attribute";
import Label from "components/AttributeList/Label";
import Value from "components/AttributeList/Value";

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: 100%;

  img {
    max-width: 100%;
  }
`;

const StyledAttributes = styled(Attributes)`
  margin-top: 2rem;

  li {
    &:first-child {
      border-top: 0.1rem solid ${(p) => p.theme.colors.whiteOpac};
    }
  }
`;

const NoFlex = styled.span`
  display: block !important;
`;

const Product = (id) => {
  const [productName, setProductName] = useState("");

  return (
    <>
      <Breadcrumbs
        slugs={[
          ["admin/products", "Products"],
          [`admin/product/${id}`, `${productName}`],
        ]}
      />
      <Header heading="Products"></Header>
      <PageContainer>
        <Sidebar />
        <GridContainer expanded>
          <Query
            query={GET_PRODUCT}
            variables={{ id }}
            onCompleted={(data) => setProductName(data.getProduct.name)}>
            {({ loading, error, data, subscribeToMore }) => {
              if (loading) return <Loader />;
              if (error)
                return (
                  <ErrorMessage>
                    An error occurred getting data, please contact support
                  </ErrorMessage>
                );

              const product = data.getProduct;

              return (
                <>
                  {product && (
                    <>
                      <GridItem columns="12" padding="0">
                        <GridContainer padding="0">
                          <GridItem columns="5">
                            <Box
                              preHeading="Product"
                              heading={
                                <NoFlex>
                                  {product.name}
                                  {product.active ? (
                                    <i className="fal fa-check-circle"></i>
                                  ) : (
                                    <i className="fal fa-times-circle"></i>
                                  )}
                                </NoFlex>
                              }
                              subHeading={product.id}>
                              <ImageContainer>
                                {product.imageUrl ? (
                                  <img alt={product.name} src={product.imageUrl} />
                                ) : (
                                  <Placeholder />
                                )}
                              </ImageContainer>

                              <StyledAttributes>
                                <Attribute>
                                  <Label>URL:</Label>
                                  <Value>/{product.slug}</Value>
                                </Attribute>
                                <Attribute>
                                  <Label>Category:</Label>
                                  <Value>{product.category}</Value>
                                </Attribute>
                                <Attribute>
                                  <Label>Created:</Label>
                                  <Value>
                                    <Moment format="YYYY-MM-DD, HH:mm">{product.created}</Moment>
                                  </Value>
                                </Attribute>
                                <Attribute>
                                  <Label>Last updated:</Label>
                                  <Value>
                                    <Moment format="YYYY-MM-DD, HH:mm">
                                      {product.lastUpdated}
                                    </Moment>
                                  </Value>
                                </Attribute>
                              </StyledAttributes>
                            </Box>
                          </GridItem>
                          <GridItem columns="7">
                            <ProductVariants
                              variants={product.relatedVariants.filter((p) => !p.archived)}
                              subscribeToMore={subscribeToMore}
                            />
                          </GridItem>
                        </GridContainer>
                      </GridItem>
                    </>
                  )}
                </>
              );
            }}
          </Query>
        </GridContainer>
      </PageContainer>
    </>
  );
};

export default (props) => {
  return Product(props.match.params.id.replace("_", "/"));
};
