import React from "react";

import PageContainer from "components/Page/PageContainer";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import Header from "components/Header/Header";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Sidebar from "components/Configuration/Sidebar";
import Box from "components/Content/Box";

export default () => (
  <>
    <Breadcrumbs slugs={[["admin/configurations", "Configurations"]]} />
    <Header heading="Configurations"></Header>
    <PageContainer>
      <Sidebar />
      <GridContainer>
        <GridItem columns="12">
          <Box heading="Overview"></Box>
        </GridItem>
      </GridContainer>
    </PageContainer>
  </>
);
