import React from "react";
import styled from "styled-components/macro";
import { colors } from "variables/colors";
import { MEDIA_MIN_LARGE } from "variables/mediaQueries";

const QuickFilterOption = styled.div`
  margin: 0.6rem 1.2rem 0.6rem 0;
  padding: 0.6rem 1rem 0.5rem;
  background: ${(p) => (p.active ? colors.gradient : p.theme.colors.greyOpac)};
  color: ${(p) => (p.active ? colors.white : p.theme.colors.white)};
  transition: 0.1s;
  cursor: pointer;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;

  ${MEDIA_MIN_LARGE} {
    height: 4rem;
    margin: 1rem;
    padding: 0.7rem 2rem 0.5rem;

    &:hover {
      background: ${(p) => (!p.active ? p.theme.colors.secondary : colors.gradient)};
      filter: ${(p) => (p.active ? "brightness(110%)" : "brightness(100%)")};
    }
  }
`;

export default ({ children, ...props }) => (
  <QuickFilterOption {...props}>{children}</QuickFilterOption>
);
