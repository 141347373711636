import React from "react";
import styled from "styled-components/macro";
import { MEDIA_MIN_MEDIUM } from "../../variables/mediaQueries";

const PspLink = styled.a`
  margin: 0.5rem;

  ${MEDIA_MIN_MEDIUM} {
    margin: 0 0.5rem;
  }
`;

export default ({ order }) => {

  const adyenAdminDomain = process.env.REACT_APP_ADYEN_ADMIN_DOMAIN
  const klarnaMerchantId = process.env.REACT_APP_KLARNA_MERCHANT_ID
  const klarnaAdminDomain = process.env.REACT_APP_KLARNA_ADMIN_DOMAIN

  const { paymentProvider, paymentReference } = order;

  let link;

  switch (paymentProvider) {
    case 'Klarna':
      link = klarnaMerchantId && klarnaAdminDomain ? `https://${klarnaAdminDomain}/orders/all/merchants/${klarnaMerchantId}/orders/${paymentReference}` : undefined;
      break;
    case 'Adyen':
      link = adyenAdminDomain ? `https://${adyenAdminDomain}/ca/ca/accounts/showTx.shtml?pspReference=${paymentReference}&txType=Payment` : undefined;
      break;
    default:
  }

  return link ? (
    <PspLink href={link} rel="noopener noreferrer" target="_blank"><i className="fal fa-external-link"></i></PspLink>
  ) : null
};
