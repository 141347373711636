import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import moment from "moment/min/moment-with-locales";
import { MEDIA_MIN_LARGE } from "variables/mediaQueries";
import isDiscountCodeActive from "components/Discount/Helpers/isDiscountCodeActive";
import Button from "components/Ui/Button";
import HoM from "components/Ui/HoM";
import TableHeader from "components/Table/Header";
import Discounts from "components/Table/Rows";
import Discount from "components/Table/Row";
import Column from "components/Table/Column";
import Toolbar from "components/Table/Toolbar/Toolbar";
import GoToPage from "components/Table/Toolbar/GoToPage";
import TotalItems from "components/Table/Toolbar/TotalItems";
import ItemsPerPage from "components/Table/Toolbar/ItemsPerPage";
import Pagination from "components/Pagination/Pagination";
import Loader from "components/Ui/Loader";
import Dinero from "dinero.js";
import Select from "components/Currency/Select";
import { mergeDineros, getRates } from "../../../helpers/mergeDineros";
import DuplicateDiscountCode from "components/Discount/DuplicateDiscountCode";

const CodeHeader = styled(Column)`
  width: 20%;
  padding-left: 1rem;

  ${MEDIA_MIN_LARGE} {
    width: 20%;
  }
`;

const DescriptionHeader = styled(Column)`
  width: 40%;
`;

const DiscountRow = styled(Discount)`
  max-height: 8rem;
`;

const Code = styled(CodeHeader)`
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Description = styled(DescriptionHeader)`
  justify-content: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;

const CodeTitle = styled.span`
  font-weight: 600;
`;

const CodeDescription = styled.p`
  font-size: 1.3rem;
`;

const Active = styled(Column)`
  width: 20%;
  display: flex;
  justify-content: center;

  ${MEDIA_MIN_LARGE} {
    width: 14%;
  }

  .fa-check-circle {
    color: green;
  }

  .fa-times-circle {
    color: red;
  }

  i {
    font-size: 1.8rem;
    margin-right: 0.8rem;

    ${MEDIA_MIN_LARGE} {
      font-size: 2.4rem;
    }
  }
`;

const DiscountPercentage = styled(Column)`
  width: 15%;
  display: flex;
  justify-content: center;

  ${MEDIA_MIN_LARGE} {
    width: 14%;
  }
`;

const Usage = styled(Column)`
  width: 10%;
  display: flex;
  justify-content: center;

  ${MEDIA_MIN_LARGE} {
    width: 9%;
  }
`;

const Revenue = styled(Column)`
  width: 25%;
  display: flex;
  justify-content: center;

  ${MEDIA_MIN_LARGE} {
    width: 14%;
  }
`;

const DuplicateButton = styled.div`
  display: none;
  ${MEDIA_MIN_LARGE} {
    display: block;
  }
  &:hover {
    color: grey;
  }
  i {
    font-size: 1.5rem;
  }
`;

const SortButton = styled(Button)`
  background: transparent;
  height: auto;
  width: auto;
  margin: 0;
  color: ${(p) => p.theme.colors.white};

  i {
    font-size: 1.6rem;
  }
`;

const DiscountCodeWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export default ({
  discountData,
  discountLoading,
  fetchMore,
  discountsPerPage,
  history,
  esbQuery,
  refetch,
}) => {
  const [currentSort, setCurrentSort] = useState("default");
  const [sortField, setSortField] = useState("reference");
  const [currentPage, setCurrentPage] = useState(1);
  const [goToPageInput, setGoToPageInput] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(discountsPerPage);
  const [discounts, setDiscounts] = useState();
  const [currencyUnit, setCurrencyUnit] = useState();
  const [currencies, setCurrencies] = useState();
  const [showNewDiscountDialog, setShowNewDiscountDialog] = useState();
  const [discountToDuplicate, setDiscountToDuplicate] = useState();
  const discountsTotalHits = discountData && discountData.searchDiscounts.totalHits;

  const openDuplicateCodeDialog = (discount) => {
    setDiscountToDuplicate(discount);
    setShowNewDiscountDialog(true);
  };

  const updateRevenue = async (discounts) => {
    const revenueCurrencies = [
      ...new Set(discounts.map((d) => d.revenue.map((r) => r.currencyUnit)).flat()),
    ].reverse();

    !currencies && setCurrencies(revenueCurrencies);
    !currencyUnit && setCurrencyUnit(revenueCurrencies[0]);
    const allRates = await Promise.all(
      revenueCurrencies.map((currencyUnit) => getRates(currencyUnit))
    );
    const rates = allRates.reduce((result, current) => ({ ...result, ...current }), {});
    const discountRevenues = await Promise.all(
      discounts.map((d) =>
        mergeDineros(
          d.revenue.map((c) => Dinero({ amount: c.amount, currency: c.currencyUnit })),
          currencyUnit || revenueCurrencies[0],
          rates
        )
      )
    );
    setDiscounts(discounts.map((d, index) => ({ ...d, revenueDinero: discountRevenues[index] })));
  };

  useEffect(() => {
    const from = (currentPage - 1) * itemsPerPage;
    fetchMore({
      variables: { query: JSON.stringify(esbQuery.size(itemsPerPage).from(from).toJSON()) },
    })
      .then((result) => updateRevenue(result.data.searchDiscounts.discounts))
      .catch((error) => {
        console.error(error);
      });
    setGoToPageInput(currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, itemsPerPage, esbQuery, currencyUnit]);

  useEffect(() => {
    discountData &&
      discountData.searchDiscounts.discounts &&
      updateRevenue(discountData.searchDiscounts.discounts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discountData]);

  const onSortChange = (sortField) => {
    let nextSort;
    if (currentSort === "down") nextSort = "up";
    else if (currentSort === "up") nextSort = "default";
    else if (currentSort === "default") nextSort = "down";

    setCurrentSort(nextSort);
    setSortField(sortField);
  };

  const getSortType = (field, sort) =>
    field === sortField ? sortTypes[sort].class : sortTypes.default.class;

  const sortTypes = {
    up: {
      class: "sort-up",
      fn: (a, b) => {
        const valueA = toValue(sortField, a);
        const valueB = toValue(sortField, b);
        if (valueA > valueB) return 1;
        else if (valueA < valueB) return -1;
        return 0;
      },
    },
    down: {
      class: "sort-down",
      fn: (a, b) => {
        const valueA = toValue(sortField, a);
        const valueB = toValue(sortField, b);
        if (valueA > valueB) return -1;
        else if (valueA < valueB) return 1;
        return 0;
      },
    },
    default: {
      class: "sort",
      fn: (a, _) => a,
    },
  };

  const toValue = (sortField, obj) => {
    if (sortField === "active") {
      return moment().isBetween(obj.startDate, obj.endDate);
    } else if (sortField === "revenueDinero") {
      return obj[sortField].toUnit();
    } else if (Number.isInteger(obj[sortField])) {
      return obj[sortField];
    } else {
      return obj[sortField]?.toLowerCase() ?? "";
    }
  };

  const handleDiscountClick = (discountId) => {
    history.push(`/admin/discount-code/${discountId}`);
  };

  return (
    <>
      <Toolbar>
        <HoM>
          {discountToDuplicate && (
            <DuplicateDiscountCode
              id={discountToDuplicate?.id}
              setShowDialog={setShowNewDiscountDialog}
              showDialog={showNewDiscountDialog}
              history={history}
              refetch={refetch}
            />
          )}

          <GoToPage>
            Page
            <input value={goToPageInput} onChange={(e) => setGoToPageInput(e.target.value)} />
            <button type="button" onClick={() => setCurrentPage(parseInt(goToPageInput, 10))}>
              <i className="fal fa-sync"></i>
            </button>
            of {discountsTotalHits ? Math.ceil(discountsTotalHits / itemsPerPage) : "-"} pages
          </GoToPage>
        </HoM>
        <ItemsPerPage setItemsPerPage={setItemsPerPage} />
        <TotalItems>
          <HoM>Total </HoM>discount codes: <span>{discountsTotalHits || "-"}</span>
        </TotalItems>
        <Select currencies={currencies} currency={currencyUnit} setCurrency={setCurrencyUnit} />
      </Toolbar>
      <TableHeader>
        <CodeHeader onClick={() => onSortChange("code")}>
          <span>Code </span>
          <SortButton>
            <i className={`fas fa-${getSortType("code", currentSort)}`} />
          </SortButton>
        </CodeHeader>
        <DescriptionHeader onClick={() => onSortChange("description")}>
          <span>Description </span>
          <SortButton>
            <i className={`fas fa-${getSortType("description", currentSort)}`} />
          </SortButton>
        </DescriptionHeader>
        <Active onClick={() => onSortChange("active")}>
          <span>Active </span>
          <SortButton>
            <i className={`fas fa-${getSortType("active", currentSort)}`} />
          </SortButton>
        </Active>
        <DiscountPercentage onClick={() => onSortChange("discountInPercentage")}>
          <span>
            <HoM>Discount</HoM>
          </span>
          <SortButton>
            <i className={`fas fa-${getSortType("discountInPercentage", currentSort)}`} />
          </SortButton>
        </DiscountPercentage>
        <Revenue onClick={() => onSortChange("revenueDinero")}>
          <span> Revenue </span>
          <SortButton>
            <i className={`fas fa-${getSortType("revenueDinero", currentSort)}`} />
          </SortButton>
        </Revenue>
        <Usage hideOnMobile onClick={() => onSortChange("usageCount")}>
          <span>
            Usage <HoM>count</HoM>
          </span>
          <SortButton>
            <i className={`fas fa-${getSortType("usageCount", currentSort)}`} />
          </SortButton>
        </Usage>
      </TableHeader>
      <Discounts>
        {discountLoading && <Loader />}
        {discounts && !discountLoading && (
          <Pagination
            itemsPerPage={itemsPerPage}
            totalItems={discountsTotalHits}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}>
            {[...discounts].sort(sortTypes[currentSort].fn).map((discount) => {
              const fixedDiscount = discount.discountInFixedPrice?.find(
                (d) => d.currencyUnit === currencyUnit
              );
              const fixedDiscountAmount = fixedDiscount
                ? Dinero({ amount: fixedDiscount.amount, currency: currencyUnit }).toFormat("$0")
                : "N/A";

              return (
                <DiscountRow key={discount.id}>
                  <DiscountCodeWrapper onClick={() => handleDiscountClick(discount.id)}>
                    <Code>
                      <CodeTitle>{discount.code}</CodeTitle>
                    </Code>
                    <Description>
                      {discount?.description && discount.description !== "" && (
                        <CodeDescription>{discount.description}</CodeDescription>
                      )}
                    </Description>
                    {isDiscountCodeActive(
                      discount.startDate,
                      discount.endDate,
                      discount.usageCount,
                      discount.usageLimit
                    ) ? (
                      <Active>
                        <i className={"fal fa-check-circle"} />
                      </Active>
                    ) : (
                      <Active>
                        <i className={"fal fa-times-circle"} />
                      </Active>
                    )}

                    <DiscountPercentage>
                      {" "}
                      {discount.discountInPercentage
                        ? `${discount.discountInPercentage} %`
                        : fixedDiscountAmount}{" "}
                    </DiscountPercentage>
                    <Revenue>
                      {discount.revenueDinero && discount.revenueDinero.toFormat("$0")}
                    </Revenue>
                    <Usage hideOnMobile>{discount.usageCount}</Usage>
                  </DiscountCodeWrapper>
                  <DuplicateButton onClick={() => openDuplicateCodeDialog(discount)}>
                    <i className="far fa-copy" tooltip="Duplicate code"></i>
                  </DuplicateButton>
                </DiscountRow>
              );
            })}
          </Pagination>
        )}
      </Discounts>
    </>
  );
};
