import gql from "graphql-tag";

const ALL_STORES = gql`
  query getAllStores($size: Int, $from: Int) {
    allStores(size: $size, from: $from) {
      totalHits
      stores {
        countryCode
        languageCode
        tax
        currencyUnit
        created
        lastUpdated
      }
    }
  }
`;

export default ALL_STORES;
