import gql from "graphql-tag";

const GET_PRODUCTS = gql`
  query getProducts($ids: [ID!]!) {
    getProducts(ids: $ids) {
      id
      type
      name
    }
  }
`;

export default GET_PRODUCTS;
