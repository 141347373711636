import React from "react";
import styled from "styled-components/macro";
import Button from "components/Ui/Button";

const RefundButton = styled(Button)`
  height: 4.4rem;
  width: auto;
  padding: 0 2rem;
  margin: 0 0.5rem;
`;

export default ({ children, handleOnClick, disabled }) => (
  <RefundButton onClick={(e) => handleOnClick(e)} disabled={disabled}>
    <i className="fal fa-undo"></i> {children}
  </RefundButton>
);
