import React, { useState, useCallback, useEffect } from "react";
import styled from "styled-components/macro";
import { Query } from "@apollo/client/react/components";
import esb from "elastic-builder";

import SEARCH_SHIPPING_PROVIDERS from "graphql/Product/SearchShippingProviders";

import Loader from "components/Ui/Loader";
import IconButton from "components/Ui/Buttons/IconButton";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import Select from "components/Ui/Select";
import AddProvider from "./AddProvider";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 3rem;
`;

const ProviderSelect = styled(Select)`
  height: 5rem;
  width: 100%;
  border-radius: 0.3rem;
  border: 0.1rem solid ${(p) => p.theme.colors.inputBorderColor};
  text-transform: initial;
  margin: 0;
`;

const Label = styled.label`
  width: 100%;
  padding-bottom: 0.8rem;
  display: flex;
  align-items: center;
`;

const AddProviderButton = styled(IconButton)`
  margin-top: 0.5rem;
`;

const ALL_SHIPPING_PROVIDERS = new esb.requestBodySearch()
  .sort(esb.sort("lastUpdated", "desc"))
  .query(esb.queryStringQuery("type:shippingProvider"))
  .size(100)
  .from(0);

export default ({ providerId, setProviderId }) => {
  const [esbQuery] = useState(ALL_SHIPPING_PROVIDERS);
  const [showAddProvider, setShowAddProvider] = useState(false);
  const [providers, setProviders] = useState(null);

  const handleProviderChange = useCallback(
    (event) => {
      setProviderId(event.target.value);
    },
    [setProviderId]
  );

  useEffect(() => {
    providers?.length > 0 && !providerId && setProviderId(providers[0].id);
  }, [providers, providerId, setProviderId]);

  return (
    <Query
      query={SEARCH_SHIPPING_PROVIDERS}
      variables={{ query: JSON.stringify(esbQuery.toJSON()) }}
      onCompleted={(data) => setProviders(data.searchProducts.products)}>
      {({ loading, error }) => {
        if (loading) return <Loader />;
        if (error)
          return (
            <ErrorMessage>
              An error occurred getting shipping provider, please contact support
            </ErrorMessage>
          );
        return (
          <>
            <Label>Shipping Provider</Label>
            <Container>
              <ProviderSelect
                name="shippingProvider"
                value={providerId || ""}
                handleChange={handleProviderChange}>
                {providers &&
                  providers.map((provider) => (
                    <option key={provider.id} value={provider.id}>
                      {provider.name}
                    </option>
                  ))}
              </ProviderSelect>
              <AddProviderButton large onClick={() => setShowAddProvider(!showAddProvider)}>
                {showAddProvider ? (
                  <i className="fal fa-minus"></i>
                ) : (
                  <i className="fal fa-plus"></i>
                )}
              </AddProviderButton>
            </Container>
            {showAddProvider && (
              <AddProvider
                setShowAddProvider={setShowAddProvider}
                providers={providers}
                setProviders={setProviders}
              />
            )}
          </>
        );
      }}
    </Query>
  );
};
