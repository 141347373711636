import React, { useMemo, useState } from "react";
import { ApolloClient, ApolloProvider, InMemoryCache, ApolloLink } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { createAuthLink } from "aws-appsync-auth-link";
import { createSubscriptionHandshakeLink } from "aws-appsync-subscription-link";
import { merge } from "lodash";
import Loader from "components/Ui/Loader";

const ApolloClientConfig = ({ children, authToken }) => {
  const [client, setClient] = useState();

  useMemo(() => {
    async function getAuth() {
      const url = process.env.REACT_APP_BRINK_GRAPHQL_API_URI;
      const region = process.env.REACT_APP_REGION;
      const auth = {
        type: "OPENID_CONNECT",
        jwtToken: authToken,
      };

      const parseCustomerAttribute = (value) => {
        switch (typeof value) {
          case "string":
            return parseCustomerAttribute(JSON.parse(value));
          case "object":
            return value;
          default:
            return {};
        }
      };

      const client = new ApolloClient({
        link: ApolloLink.from([
          onError(({ graphQLErrors, networkError }) => {
            if (graphQLErrors)
              graphQLErrors.forEach(({ message, locations, path }) =>
                console.log(
                  `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
                )
              );
            if (networkError) console.log(`[Network error]: ${JSON.stringify(networkError)}`);
          }),
          createAuthLink({ url, region, auth }),
          createSubscriptionHandshakeLink({ url, region, auth }),
        ]),
        cache: new InMemoryCache({
          typePolicies: {
            Order: {
              fields: {
                shippingAddress: {
                  merge: true,
                },
                customerAttribute: {
                  merge(existing, incoming) {
                    const customerAttribute = merge(
                      {},
                      parseCustomerAttribute(existing),
                      parseCustomerAttribute(incoming)
                    );
                    return customerAttribute;
                  },
                },
              },
            },
          },
          addTypename: false
        }),
        defaultOptions: {
          watchQuery: {
            fetchPolicy: 'cache-and-network'
          }
        }
      });
      setClient(client);
    }
    getAuth();
  }, [authToken]);
  if (!authToken || !client) return <Loader />;
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export { ApolloClientConfig };
