import React from "react";
import { Query } from "@apollo/client/react/components";
import SEARCH_DISCOUNT_CODE from "graphql/Discount/SearchDiscounts";
import PageContainer from "components/Page/PageContainer";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import Header from "components/Header/Header";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Box from "components/Content/Box";
import Loader from "components/Ui/Loader";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import VoucherExport from "./VoucherExport";
import Sidebar from "components/Discount/Sidebar";
import esb from "elastic-builder";
import VoucherGroupTable from "./VoucherGroupTable";
import Attributes from "components/AttributeList/AttributeList";
import Attribute from "components/AttributeList/Attribute";
import Label from "components/AttributeList/Label";
import Value from "components/AttributeList/Value";
import DiscountValue from "components/Discount/DiscountValue";
import { withRouter } from "react-router-dom";
import queryString from "query-string";

const VOUCHERS_PER_PAGE = 25;

const Voucher = (props) => {
  const id = queryString.parse(props.location.search).groupName;

  const voucherGroupQuery = esb
    .requestBodySearch()
    .query(
      esb
        .boolQuery()
        .must([esb.termsQuery("group.keyword", id), esb.termsQuery("type.keyword", "VOUCHER")])
    )
    .from(0)
    .size(VOUCHERS_PER_PAGE);

  return (
    <>
      <Breadcrumbs
        slugs={[
          ["admin/vouchers", "Discounts"],
          [`admin/voucher?groupname=${encodeURIComponent(id)}`, `${id}`],
        ]}
      />
      <Header heading="Voucher Group" />
      <PageContainer>
        <Sidebar />
        <GridContainer expanded>
          <GridItem columns="12">
            <Box
              preHeading="Voucher Group"
              heading={id}
              showGoBackButton
              goBackOnClick={() => props.history.push("/admin/vouchers")}>
              <GridItem columns="12">
                <Query
                  query={SEARCH_DISCOUNT_CODE}
                  fetchPolicy="network-only"
                  variables={{ query: JSON.stringify(voucherGroupQuery.toJSON()) }}>
                  {({ loading, error, data, fetchMore }) => {
                    if (loading) return <Loader />;
                    if (error)
                      return (
                        <ErrorMessage>
                          An error occurred loading data, please contact support
                        </ErrorMessage>
                      );

                    const firstVoucher = data?.searchDiscounts.discounts[0];
                    return (
                      <>
                        <Attributes>
                          <Attribute>
                            <Label>Discount: </Label>
                            <Value>
                              <DiscountValue discount={firstVoucher} simple />
                            </Value>
                          </Attribute>
                        </Attributes>
                        <VoucherGroupTable
                          data={data}
                          loading={loading}
                          discountsPerPage={VOUCHERS_PER_PAGE}
                          history={props.history}
                          fetchMore={fetchMore}
                          esbQuery={voucherGroupQuery}>
                          {({ voucherCodesTotalHits }) => (
                            <VoucherExport
                              group={id}
                              query={SEARCH_DISCOUNT_CODE}
                              esbQuery={voucherGroupQuery}
                              size={voucherCodesTotalHits}
                            />
                          )}
                        </VoucherGroupTable>
                      </>
                    );
                  }}
                </Query>
              </GridItem>
            </Box>
          </GridItem>
        </GridContainer>
      </PageContainer>
    </>
  );
};

export default withRouter(Voucher);
