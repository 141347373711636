import React, { useState, useEffect } from "react";
import { Query, Subscription } from "@apollo/client/react/components";
import esb from "elastic-builder";
import styled from "styled-components/macro";
import SEARCH_ORDERS from "graphql/Order/SearchOrders";
import SEARCH_ORDERS_EXPORT from "graphql/Order/SearchOrdersExport";
import UPDATED_ORDER_SUBSCRIPTION from "graphql/Order/UpdatedOrderSubscription";
import { useAdminContext } from "context/AdminContext";
import { MEDIA_MIN_LARGE } from "variables/mediaQueries";
import OrdersTable from "./OrdersTable";
import Order from "../Order/Order";
import SearchOrders from "./SearchOrders";
import OrdersExport from "./OrdersExport";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import Header from "components/Header/Header";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Box from "components/Content/Box";
import Tabs from "components/Tabs/Tabs";
import Tab from "components/Tabs/Tab";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";

const TableContainer = styled(GridContainer)`
  padding-top: 0;
`;

const OrderBox = styled(Box)`
  > h3 {
    padding-bottom: 1rem;
    padding-top: 0;
    margin-bottom: 0rem;

    ${MEDIA_MIN_LARGE} {
      padding-bottom: 3rem;
      padding-top: 0;
    }
  }
`;

const ORDERS_PER_PAGE = 25;
const ALL_ORDERS = new esb.requestBodySearch()
  .trackTotalHits(true)
  .sort(esb.sort("lastUpdated", "desc"))
  .size(ORDERS_PER_PAGE)
  .from(0);

export default function Orders(props) {
  const [esbQuery, setEsbQuery] = useState(ALL_ORDERS);
  const [orders, setOrders] = useState();
  const [activeTab, setActiveTab] = useState("table");
  const [updatedOrder, setUpdatedOrder] = useState();
  const { tabs, setTabs } = useAdminContext();
  const [ordersTotalHits, setOrdersTotalHits] = useState(0);

  const closeTab = (id) => {
    setTabs(tabs.filter((item) => item.id !== id));
    if (tabs.length === 1) return;
    const tabIndex = tabs.findIndex((t) => t.id === id);
    setActiveTab(tabIndex === tabs.length - 1 ? tabs[tabIndex - 1].id : tabs[tabIndex + 1].id);
  };

  useEffect(() => {
    tabs.length > 0 && setActiveTab(tabs[tabs.length - 1].id);
  }, [tabs]);

  return (
    <Subscription
      subscription={UPDATED_ORDER_SUBSCRIPTION}
      onSubscriptionData={({ subscriptionData }) =>
        setUpdatedOrder(subscriptionData.data.updatedOrder)
      }>
      {({ _ }) => {
        return (
          <>
            <Breadcrumbs slugs={[["admin/orders", "Orders"]]} />
            <Header heading="Orders" />
            <Tabs>
              <Tab
                onClick={() => setActiveTab("table")}
                active={tabs.length === 0 || activeTab === "table"}>
                Orders
              </Tab>
              {tabs.map((tab) => (
                <Tab
                  key={tab.id}
                  tab={tab}
                  onClick={() => setActiveTab(tab.id)}
                  active={tab.id === activeTab}
                  closeTab={closeTab}>
                  {tab.id !== "table" && "#"}
                  {tab.reference}
                </Tab>
              ))}
            </Tabs>
            {tabs.length === 0 || activeTab === "table" ? (
              <TableContainer>
                <GridItem columns="12" mobilePadding="1.5rem">
                  <OrderBox preHeading="Overview" heading="Order table">
                    <SearchOrders
                      allOrders={ALL_ORDERS}
                      setEsbQuery={setEsbQuery}
                      ordersPerPage={ORDERS_PER_PAGE}
                    />
                    <Query
                      query={SEARCH_ORDERS}
                      variables={{ query: JSON.stringify(esbQuery.toJSON()) }}
                      onCompleted={(data) => {
                        setOrders(data.searchOrders.orders);
                        setOrdersTotalHits(data.searchOrders.totalHits);
                      }}>
                      {({ loading, error, fetchMore }) => {
                        if (error)
                          return (
                            <ErrorMessage inBox>
                              An error occurred getting data, please contact support
                            </ErrorMessage>
                          );

                        return (
                          <OrdersTable
                            esbQuery={esbQuery}
                            orders={orders}
                            setOrders={setOrders}
                            updatedOrder={updatedOrder}
                            ordersTotalHits={ordersTotalHits}
                            loading={loading}
                            fetchMore={fetchMore}
                            history={props.history}
                            ordersPerPage={ORDERS_PER_PAGE}
                            setTabs={setTabs}
                            tabs={tabs}
                            setActiveTab={setActiveTab}>
                            {({ totalHits }) => (
                              <OrdersExport
                                size={totalHits}
                                query={SEARCH_ORDERS_EXPORT}
                                esbQuery={esbQuery}
                              />
                            )}
                          </OrdersTable>
                        );
                      }}
                    </Query>
                  </OrderBox>
                </GridItem>
              </TableContainer>
            ) : (
              tabs.map(
                (tab) =>
                  activeTab === tab.id && (
                    <Order key={tab.id} id={tab.id} updatedOrder={updatedOrder} />
                  )
              )
            )}
          </>
        );
      }}
    </Subscription>
  );
}
