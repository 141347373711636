import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { Mutation, Query } from "@apollo/client/react/components";
import { useForm, Controller } from "react-hook-form-old";
import ADD_DISCOUNT_CODE from "graphql/Discount/DiscountCode/AddDiscountCode";
import ALL_STORES from "graphql/Store/AllStores";
import { useNotification } from "context/NotificationContext";
import { customAlphabet } from "nanoid";
import MultiSelect from "components/Ui/MultiSelect";
import { MEDIA_MIN_LARGE } from "variables/mediaQueries";
import PageContainer from "components/Page/PageContainer";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import Header from "components/Header/Header";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Box from "components/Content/Box";
import Input from "components/Ui/Input";
import Loader from "components/Ui/Loader";
import Attributes from "components/AttributeList/AttributeList";
import Attribute from "components/AttributeList/Attribute";
import Value from "components/AttributeList/Value";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import ActionButtons from "components/ActionButtons/ActionButtons";
import ActionButton from "components/ActionButtons/ActionButton";
import Sidebar from "components/Discount/Sidebar";
import Select from "components/Ui/Select";
import DiscountCodeCurrencies from "components/Discount/DiscountCodeCurrencies";
import FlagIcon from "components/Ui/FlagIcon";
import Toggle from "components/Ui/Toggle";
import Tooltip from "components/Ui/Tooltip";
import moment from "moment/min/moment-with-locales";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  margin-top: 2rem;
  padding-bottom: 6rem;

  ${MEDIA_MIN_LARGE} {
    padding-bottom: 0;
  }

  h5 {
    margin-bottom: 0;
    border-top: 0.1rem solid ${(p) => p.theme.colors.whiteOpac};
    padding-top: 3.2rem;
    width: 100%;
  }
`;

const FixedToggle = styled(Toggle)`
  margin-left: 1rem;
  div {
    background: ${(p) => p.theme.colors.primary};
  }
`;

const RuleAttributes = styled(Attributes)`
  margin: -1.5rem 0 3rem;
`;

const ErrorLabel = styled.p`
  color: red;
`;

const Label = styled.div`
  display: flex;
  align-items: center;

  span {
    display: flex;
    align-items: center;
  }
`;

const ValidSelect = styled(Select)`
  width: 100%;
  margin: 0;
  height: 5rem;
`;

const validOptions = [
  { label: "6 months", value: 6 },
  { label: "One year", value: 12 },
  { label: "Two years", value: 24 },
];

const uppercaseAlphabet = "ABCDEFGHIJKLMNOPQRSTUVXYZ123456789";

export default ({ history }) => {
  const { setNotification } = useNotification();
  const { control, handleSubmit, errors } = useForm();
  const [validLength, setValidLength] = useState(validOptions[0].value);
  const [selectedStores, setSelectedStores] = useState([]);
  const [voucherCodesCreated, setVoucherCodesCreated] = useState(false);
  const [storesMissingLabel, setStoresMissingLabel] = useState(false);
  const [discountFixed, setDiscountFixed] = useState(false);
  const [availableCurrencies, setAvailableCurrencies] = useState([]);

  const createVoucherCode = customAlphabet(uppercaseAlphabet, 10);
  const goToVouchers = () => history.push(`/admin/vouchers`);

  const updateSelectedStores = (stores) => {
    setSelectedStores(stores);
    const currencies = stores.map((store) => store.currencyUnit);
    setAvailableCurrencies([...new Set(currencies)]);
  };

  useEffect(() => {
    voucherCodesCreated && goToVouchers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [voucherCodesCreated]);

  const formatDiscount = (price) => Number(price * 100).toFixed(2) | 0;

  return (
    <>
      <Breadcrumbs
        slugs={[
          ["admin/discounts", "Discounts"],
          ["admin/discount-rules", "Discount rules"],
          ["admin/add-discount-rule", "Add discount rule"],
        ]}
      />
      <Header heading="Voucher Group" />
      <PageContainer>
        <Sidebar />
        <GridContainer>
          <Query query={ALL_STORES} variables={{ from: 0, size: 300 }}>
            {({ loading, error, data: allStoresData }) => {
              if (loading) return <Loader />;
              if (error)
                return (
                  <ErrorMessage>
                    An error occurred loading data, please contact support
                  </ErrorMessage>
                );
              const stores =
                allStoresData &&
                allStoresData.allStores.stores
                  .map((store) => ({
                    label: store.countryCode,
                    value: store.countryCode,
                    currencyUnit: store.currencyUnit,
                    languageCode: store.languageCode,
                  }))
                  .sort((a, b) => a.value.localeCompare(b.value));
              return (
                <Mutation
                  mutation={ADD_DISCOUNT_CODE}
                  onCompleted={() => {
                    setNotification({
                      status: "success",
                      message: `Voucher group successfully added`,
                    });
                  }}
                  onError={() => {
                    setNotification({
                      status: "error",
                      message: "An error occurred adding the discount rule, please contact support",
                    });
                  }}>
                  {(addDiscountCode, { loading: addDiscountLoading }) => {
                    const onSubmit = (data) => {
                      let typeAttribute = {};
                      if (selectedStores.length < 1) {
                        setStoresMissingLabel(true);
                      } else {
                        if (data.price) {
                          const discountInFixedPrice = Object.keys(data.price).map((key) => ({
                            amount: formatDiscount(Number(data.price[key].amount)),
                            currencyUnit: key,
                          }));
                          typeAttribute = { discountInFixedPrice: discountInFixedPrice };
                        } else {
                          typeAttribute = {
                            discountInPercentage: parseInt(data.discountInPercentage),
                          };
                        }
                        for (let i = 0; i < data.numberOfVouchers; i++) {
                          const variables = {
                            type: "VOUCHER",
                            group: data.name,
                            usageLimit: parseInt(data.usageLimit),
                            code: createVoucherCode(),
                            startDate: moment().format("YYYY-MM-DD"),
                            endDate: moment().add(validLength, "month").format("YYYY-MM-DD"),
                            stores: selectedStores.map((store) => ({
                              countryCode: store.value,
                              languageCode: store.languageCode,
                            })),
                          };
                          addDiscountCode({ variables: { ...variables, ...typeAttribute } });
                          i === data.numberOfVouchers - 1 && setVoucherCodesCreated(true);
                        }
                      }
                    };

                    const itemRenderer = ({ checked, option, onClick }) => (
                      <Label>
                        <input type="checkbox" onChange={onClick} checked={checked} tabIndex="-1" />
                        <span>
                          <FlagIcon countryCode={option.value} />
                          <span>{option.label}</span>
                        </span>
                      </Label>
                    );

                    return (
                      <>
                        <GridItem columns="12">
                          <form onSubmit={handleSubmit(onSubmit)}>
                            <Box
                              preHeading="Voucher group"
                              heading="Add new voucher group"
                              showGoBackButton
                              goBackOnClick={() => goToVouchers()}>
                              {addDiscountLoading && <Loader />}

                              <ActionButtons inBox footerOnMobile>
                                <ActionButton type="submit">
                                  <i className="fal fa-fw fa-check" />
                                  Save
                                </ActionButton>
                              </ActionButtons>
                              <RuleAttributes>
                                <Attribute>
                                  <Label>Percentage / Fixed amount: </Label>
                                  <Value>
                                    <FixedToggle
                                      active={discountFixed}
                                      handleToggle={() => setDiscountFixed(!discountFixed)}
                                    />
                                  </Value>
                                </Attribute>
                              </RuleAttributes>
                              <GridContainer collapse padding="3rem 0 0">
                                <GridItem
                                  mobilePadding="3rem 0 0"
                                  desktopPadding="0 1.5rem 0 0"
                                  columns="6">
                                  <Controller
                                    as={Input}
                                    control={control}
                                    rules={{
                                      required: "This is a required field",
                                    }}
                                    defaultValue=""
                                    type="text"
                                    name="name"
                                    label="Group name"
                                    errors={errors}
                                  />
                                  <MultiSelect
                                    options={stores}
                                    label="Select stores"
                                    name="stores"
                                    selected={selectedStores}
                                    setSelected={updateSelectedStores}
                                    ItemRenderer={itemRenderer}
                                    disableSearch={true}
                                    overrideStrings={{
                                      selectSomeItems: "No stores selected",
                                      selectAll: `Select all ${stores.length} stores`,
                                    }}
                                  />
                                  {storesMissingLabel && (
                                    <ErrorLabel>No stores selected</ErrorLabel>
                                  )}
                                  <Controller
                                    as={ValidSelect}
                                    control={control}
                                    label="Valid for"
                                    name="valid"
                                    handleChange={(e) => setValidLength(e.target.value)}
                                    errors={errors}>
                                    {validOptions.map((option) => (
                                      <option
                                        key={option.value}
                                        defaultValue={option.value}
                                        value={option.value}>
                                        {option.label}
                                      </option>
                                    ))}
                                  </Controller>
                                </GridItem>
                                <GridItem
                                  mobilePadding="0"
                                  desktopPadding="0 0 0 1.5rem"
                                  columns="6">
                                  <Controller
                                    className="usageLimit"
                                    as={Input}
                                    control={control}
                                    rules={{
                                      required: "This is a required field",
                                      min: {
                                        value: 0,
                                        message: "Needs to be a number",
                                      },
                                    }}
                                    type="number"
                                    name="usageLimit"
                                    label="Usage limit"
                                    placeholder="Number of times code can be used"
                                    defaultValue=""
                                    errors={errors}
                                  />
                                  <Controller
                                    className="numberOfVouchers"
                                    as={Input}
                                    control={control}
                                    rules={{
                                      required: "This is a required field",
                                      min: {
                                        value: 0,
                                        message: "Needs to be a number",
                                      },
                                    }}
                                    type="number"
                                    name="numberOfVouchers"
                                    label="Number of vouchers"
                                    defaultValue=""
                                    errors={errors}
                                  />
                                  {!discountFixed && (
                                    <Controller
                                      as={Input}
                                      control={control}
                                      rules={{
                                        required: "This is a required field",
                                        min: {
                                          value: 0,
                                          message: `Needs to be a number between 0-`,
                                        },
                                        max: {
                                          value: 100,
                                          message: `Needs to be a number between 0-`,
                                        },
                                      }}
                                      defaultValue=""
                                      type="number"
                                      name="discountInPercentage"
                                      placeholder={`0 - 100`}
                                      label="Discount in percentage"
                                      icon="badge-percent"
                                      errors={errors}
                                    />
                                  )}
                                </GridItem>
                              </GridContainer>
                              <Container>
                                <Tooltip />
                              </Container>
                              {discountFixed && (
                                <DiscountCodeCurrencies
                                  currencies={availableCurrencies}
                                  control={control}
                                  errors={errors}
                                />
                              )}
                            </Box>
                          </form>
                        </GridItem>
                      </>
                    );
                  }}
                </Mutation>
              );
            }}
          </Query>
        </GridContainer>
      </PageContainer>
    </>
  );
};
