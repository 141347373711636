import PRODUCT_AGGREGATES from "graphql/Product/ProductAggregates";
import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { Query } from "@apollo/client/react/components";
import esb from "elastic-builder";
import { Controller } from "react-hook-form";
import Select from "components/Ui/Select";
import MultiSelect from "components/Ui/MultiSelect";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import Column from "components/Table/Column";
import IconButton from "components/Ui/Buttons/IconButton";
import Loader from "components/Ui/Loader";
import { MEDIA_MAX_MEDIUM } from "variables/mediaQueries";

const Modes = styled(Column)`
  width: 25%;
  padding-left: 1rem;

  ${MEDIA_MAX_MEDIUM} {
    width: 30%;
  }
`;

const Keys = styled(Column)`
  width: 25%;
  padding-left: 3rem;

  ${MEDIA_MAX_MEDIUM} {
    width: 30%;
    padding-left: 0.1rem;
  }
`;

const Values = styled(Column)`
  width: 40%;
  padding-left: 5rem;

  ${MEDIA_MAX_MEDIUM} {
    width: 25%;
    padding-left: 0.1rem;
  }
`;

const Remove = styled(Column)`
  width: 10%;
  padding-left: 1.5rem;

  ${MEDIA_MAX_MEDIUM} {
    width: 10%;
    padding-left: 0.1rem;
  }
`;

const SelectLabel = styled.div`
  display: flex;
  align-items: center;

  span {
    display: flex;
    align-items: center;
  }

  img {
    width: 2rem;
    margin-right: 1rem;
  }
`;

const FilterMultiSelect = styled(MultiSelect)`
  min-width: 20rem;
  height: 4rem;
  font-size: 1.3rem;
  margin: 0;
  width: 100%;

  .multi-select {
    --rmsc-height: 3.8rem;
  }

  ${MEDIA_MAX_MEDIUM} {
    min-width: 5rem;
    font-size: 0.8rem;
  }
`;

const FilterSelect = styled(Select)`
  ${MEDIA_MAX_MEDIUM} {
    font-size: 0.8rem;
    text-overflow: ellipsis;
    width: 100%;
    margin: 0;
  }
`;

const itemRenderer = ({ checked, option, onClick }) => (
  <SelectLabel>
    <input type="checkbox" onChange={onClick} checked={checked} tabIndex="-1" />
    <span>
      <span>{option.label}</span>
    </span>
  </SelectLabel>
);

const makeValuesQuery = (key) =>
  esb
    .requestBodySearch()
    .agg(
      esb
        .nestedAggregation("filterValues", "filterAttributes")
        .agg(
          esb
            .filterAggregation(
              "filterValues",
              esb.boolQuery().filter(esb.termQuery("filterAttributes.key", key))
            )
            .agg(esb.termsAggregation("filterValues", "filterAttributes.value"))
        )
    )
    .size(0);

export default ({
  modes,
  control,
  filterKeys,
  filterRule,
  filterRules,
  setFilterRules,
}) => {
  const [selectedValues, setSelectedValues] = useState(
    filterRule.values.map((value) => ({ value: value, label: value }))
  );
  const [selectedKey, setSelectedKey] = useState(filterRule.key || "none");
  const [selectedMode, setSelectedMode] = useState(filterRule.mode);
  const [filterValuesQuery, setFilterValuesQuery] = useState(makeValuesQuery(filterRule.key));

  const onChangeFilterKey = (event) => {
    setSelectedKey(event.target.value);
    setSelectedValues([]);
    setFilterValuesQuery(makeValuesQuery(event.target.value));
  };
  const onChangeFilterMode = (event) => {
    setSelectedMode(event.target.value);
  };

  useEffect(() => {
    const updatedFilterRules = filterRules.map((rule) => {
      return rule.id === filterRule.id
        ? {
            mode: selectedMode,
            key: selectedKey,
            values: selectedValues.map((v) => v.value),
            id: filterRule.id,
          }
        : rule;
    });
    setFilterRules(updatedFilterRules);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValues, selectedKey, selectedMode]);

  if (!filterValuesQuery || !filterKeys) return null;
  return (
    <>
      <Modes>
        <Controller
          control={control}
          name="mode"
          render={({ field }) => (
            <FilterSelect
              handleChange = {(e) => onChangeFilterMode(e)}
              valueName="selected"
              value={selectedMode}
              type="text">
              {modes.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </FilterSelect>
          )}
        />
      </Modes>
      <Keys>
        <Controller
          control={control}
          name="key"
          render={({ field }) => (
            <FilterSelect
              handleChange = {(e) => onChangeFilterKey(e)}
              valueName="selected"
              value={selectedKey}
              type="text">
              <option value="none" disabled>
                Select a key
              </option>
              {filterKeys &&
                filterKeys.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
            </FilterSelect>
          )}
        />
      </Keys>
      <Query
        query={PRODUCT_AGGREGATES}
        variables={{ query: JSON.stringify(filterValuesQuery.toJSON()) }}
        fetchPolicy="cache-first">
        {({ error, data, loading }) => {
          if (error)
            return <ErrorMessage>An error occurred getting data, contact support</ErrorMessage>;
          if (loading) return <Loader />;

          let filterValues = [];
          if (data?.productAggregates?.aggregations)
            filterValues = JSON.parse(
              data?.productAggregates.aggregations
            ).filterValues.filterValues.filterValues.buckets.map((bucket) => ({
              value: bucket.key,
              label: bucket.key,
            }));

          const mergedValues = filterValues
            .concat(selectedValues)
            .filter((v, i, a) => a.findIndex((t) => t.value === v.value) === i);

          return (
            <Values>
              <FilterMultiSelect
                options={mergedValues}
                selected={selectedValues}
                setSelected={setSelectedValues}
                ItemRenderer={itemRenderer}
                overrideStrings={{
                  selectAll: `Select all values`,
                }}
              />
            </Values>
          );
        }}
      </Query>
      <Remove>
        <IconButton
          handleOnClick={() => {
            setFilterRules(filterRules.filter((r) => r.id !== filterRule.id));
          }}>
          <i className="fal fa-trash-alt" />
        </IconButton>
      </Remove>
    </>
  );
};
