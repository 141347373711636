import React from "react";
import styled from "styled-components/macro";
import { useForm, Controller } from "react-hook-form";
import { Mutation, Query } from "@apollo/client/react/components";
import ALL_STORES from "graphql/Store/AllStores";
import ADD_OR_UPDATE_STORE from "graphql/Store/AddOrUpdateStore";
import PageContainer from "components/Page/PageContainer";
import { useNotification } from "context/NotificationContext";
import Sidebar from "components/Store/Sidebar";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import Header from "components/Header/Header";
import GridItem from "components/Grid/GridItem";
import Box from "components/Content/Box";
import InputNew from "components/Ui/InputNew";
import Loader from "components/Ui/Loader";
import ActionButtons from "components/ActionButtons/ActionButtons";
import ActionButton from "components/ActionButtons/ActionButton";
import GridContainer from "components/Grid/GridContainer";

const AddForm = styled.form`
  width: 100%;
  `

export default ({ history }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { setNotification } = useNotification();

  const gotoStores = () => {
    history.push({
      pathname: "/admin/stores",
    });
  };

  return (
    <>
      <Breadcrumbs
        slugs={[
          ["admin/stores", "Stores"],
          ["admin/stores/add-store", "Add new store"],
        ]}
      />
      <Header heading="Stores" />
      <PageContainer>
        <Sidebar />
        <GridContainer>
          <Query query={ALL_STORES} variables={{ from: 0, size: 300 }}>
            {({ data: stores }) => {
              return (
                <Mutation
                  mutation={ADD_OR_UPDATE_STORE}
                  onCompleted={({ addOrUpdateStore }) => {
                    setNotification({
                      status: "success",
                      message: `Store "${addOrUpdateStore.countryCode}_${addOrUpdateStore.languageCode}" successfully added`,
                    });
                    setTimeout(gotoStores, 1000);
                  }}
                  onError={() => {
                    setNotification({
                      status: "error",
                      message: "An error occurred adding store, please contact support",
                    });
                  }}>
                  {(updateStore, { loading }) => {
                    const onSubmit = (data) => {
                      if (
                        stores?.allStores.stores.find(
                          (store) => store.countryCode === data.countryCode
                        )
                      ) {
                        setNotification({
                          status: "error",
                          message: `Store with country code "${data.countryCode}" already exist`,
                        });
                        return null;
                      }

                      updateStore({
                        variables: {
                          countryCode: data.countryCode.toUpperCase(),
                          languageCode: data.languageCode.toLowerCase(),
                          tax: data.tax,
                          currencyUnit: data.currencyUnit.toUpperCase(),
                        },
                      }).catch((e) => {
                        console.log(e);
                      });
                    };

                    return (
                      <GridItem columns="12">
                        <Box
                          preHeading="Stores"
                          heading={"Add new store"}
                          showGoBackButton
                          goBackOnClick={() => history.push("/admin/stores")}>
                          <AddForm onSubmit={handleSubmit(onSubmit)}>
                            <ActionButtons inBox footerOnMobile>
                              <ActionButton type="submit">
                                <i className="fal fa-fw fa-check"></i> Save
                              </ActionButton>
                            </ActionButtons>
                            {loading && <Loader />}
                            <GridContainer padding="2rem 0" column="12">
                              <GridItem
                                mobilePadding="2rem 0 0"
                                desktopPadding="0 1.5rem 0 0"
                                columns="6">
                                <Controller
                                  control={control}
                                  name="countryCode"
                                  rules={{
                                    required: "This is a required field",
                                    pattern: {
                                      value: /^[a-zA-Z]+$/,
                                      message: "Only characters A-Z are allowed",
                                    },
                                  }}
                                  render={({ field: { onChange, onBlur } }) => (
                                    <InputNew
                                      type="text"
                                      name="countryCode"
                                      label="Country code"
                                      placeholder="a - z [ex. us]"
                                      icon="fal fa-coins"
                                      onBlur={onBlur}
                                      onChange={onChange}
                                      errors={errors}
                                    />
                                  )}
                                />
                                <Controller
                                  control={control}
                                  name="languageCode"
                                  rules={{
                                    required: "This is a required field",
                                    pattern: {
                                      value: /^[a-zA-Z]+$/,
                                      message: "Only characters a-z are allowed",
                                    },
                                  }}
                                  render={({ field: { onChange, onBlur } }) => (
                                    <InputNew
                                      type="text"
                                      name="languageCode"
                                      label="Language code"
                                      placeholder="a - z, [ex. en]"
                                      icon="fal fa-globe-asia"
                                      onBlur={onBlur}
                                      onChange={onChange}
                                      errors={errors}
                                    />
                                  )}
                                />
                              </GridItem>
                            

                              <GridItem
                                mobilePadding="1rem 0"
                                desktopPadding="0 1.5rem 0 0"
                                columns="6">
                                <Controller
                                  control={control}
                                  name="tax"
                                  rules={{
                                    required: "This is a required field",
                                    min: {
                                      value: 0,
                                      message: "Needs to be a number between 0-40",
                                    },
                                    max: {
                                      value: 40,
                                      message: "Needs to be a number between 0-40",
                                    },
                                  }}
                                  render={({ field: { onChange, onBlur } }) => (
                                    <InputNew
                                      name="tax"
                                      label="Tax"
                                      icon="fal fa-percentage"
                                      placeholder="0 - 40"
                                      onBlur={onBlur}
                                      onChange={onChange}
                                      errors={errors}
                                    />
                                  )}
                                />
                                <Controller
                                  control={control}
                                  name="currencyUnit"
                                  rules={{
                                    required: "This is a required field",
                                    maxLength: {
                                      value: 3,
                                      message: "Maximum characters is 3",
                                    },
                                    minLength: {
                                      value: 3,
                                      message: "Minimum characters is 3",
                                    },
                                    pattern: {
                                      value: /^[a-zA-Z]+$/,
                                      message: "Only characters a-z are allowed",
                                    },
                                  }}
                                  render={({ field: { onChange, onBlur } }) => (
                                    <InputNew
                                      name="currencyUnit"
                                      label="Currency unit"
                                      onBlur={onBlur}
                                      onChange={onChange}
                                      placeholder="a - z, [ex. usd]"
                                      icon="fal fa-coins"
                                      errors={errors}
                                    />
                                  )}
                                />
                              </GridItem>
                              </GridContainer>
                          </AddForm>
                        </Box>
                      </GridItem>
                    );
                  }}
                </Mutation>
              );
            }}
          </Query>
        </GridContainer>
      </PageContainer>
    </>
  );
};
