import React from "react";
import styled from "styled-components/macro";
import { MEDIA_MIN_MEDIUM } from "variables/mediaQueries";

const Column = styled.div`
  display: ${(p) => (p.hideOnMobile ? "none" : "flex")};
  align-items: center;

  ${MEDIA_MIN_MEDIUM} {
    display: flex;

    i {
      margin-bottom: 0.2rem;
    }
  }
`;

export default ({ children, hideOnMobile, ...props }) => (
  <Column hideOnMobile={hideOnMobile} {...props}>
    {children}
  </Column>
);
