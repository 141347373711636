import React from "react";
import styled from "styled-components/macro";
import uuid from "react-uuid";
import Dinero from "dinero.js";
import Chip from "components/Ui/Chip";

const OriginalPrice = styled.span`
  text-decoration: ${(p) => (p.discount && p.discount.amount > 0 ? "line-through" : "none")};
`;

const DiscountedPrice = styled.span`
  margin-left: 1rem;
`;

export default ({ price, discounts }) => (
  <>
    <strong>Prices: </strong>
    <div>
      {price ? price.map((price) => {
        const discount = discounts && discounts.find((d) => d.currencyUnit === price.currencyUnit);

        return (
          <Chip key={uuid()}>
            <OriginalPrice discount={discount}>
              {Dinero({
                amount: price.amount,
                currency: price.currencyUnit,
              }).toFormat()}
            </OriginalPrice>
            {discount && discount.amount > 0 && (
              <DiscountedPrice>
                {Dinero({
                  amount: price.amount - discount.amount,
                  currency: discount.currencyUnit,
                }).toFormat()}
              </DiscountedPrice>
            )}
          </Chip>
        );
      }): 'Prices missing'}
    </div>
  </>
);
