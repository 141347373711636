import React from "react";
import { Query } from "@apollo/client/react/components";
import Box from "components/Dashboard/Box";
import styled from "styled-components/macro";
import ChartistGraph from "react-chartist";

import ORDERS_STATISTICS_BY_DATE from "graphql/OrdersStatisticsByDate";

import Loader from "components/Ui/Loader";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import moment from "moment/min/moment-with-locales";

const Container = styled.div`
  width: 100%;
  margin: 2rem 0;
`;

export default ({ selectedStores, orderStatus }) => {
  const yesterday = moment().subtract(1, "days").startOf("day");
  const today = moment().endOf("day");

  const getPeriodAmount = (selectedStores, date, stats) =>
    stats
      .filter((s) => selectedStores.includes(s.store))
      .map((s) => {
        const sales = s.sales.find((s) => s.keyAsString === date);
        return sales ? sales.orderCount : 0;
      })
      .reduce((a, b) => a + b, 0);

  return (
    <Query
      query={ORDERS_STATISTICS_BY_DATE}
      fetchPolicy="network-only"
      variables={{
        endDateTime: today,
        startDateTime: yesterday,
        interval: "DAY",
        status: orderStatus,
      }}>
      {({ loading, error, data }) => {
        if (loading) return <Loader />;
        if (error)
          return <ErrorMessage>An error occured loading data, please contact support</ErrorMessage>;

        if (data) {
          const prevPeriodAmount = getPeriodAmount(
            selectedStores,
            yesterday.format("YYYY-MM-DD"),
            data.ordersStatisticsByDate
          );
          const currentPeriodAmount = getPeriodAmount(
            selectedStores,
            today.format("YYYY-MM-DD"),
            data.ordersStatisticsByDate
          );

          const dailySalesChart = {
            data: {
              series: [currentPeriodAmount, prevPeriodAmount],
            },
            options: {
              donut: true,
              donutWidth: 15,
              donutSolid: true,
              startAngle: 0,
              showLabel: false,
            },
          };

          return (
            <Box
              preHeading="Daily orders"
              heading={`# ${currentPeriodAmount}`}
              headingIcon="shopping-cart"
              primaryLabel="Today"
              secondaryLabel="Yesterday"
              currentPeriodSum={currentPeriodAmount}
              prevPeriodSum={prevPeriodAmount}>
              <Container>
                <ChartistGraph
                  className="ct-chart-white-colors"
                  data={dailySalesChart.data}
                  type="Pie"
                  options={dailySalesChart.options}
                />
              </Container>
            </Box>
          );
        }
      }}
    </Query>
  );
};
