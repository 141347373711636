import React, { useState } from "react";
import styled from "styled-components/macro";
import { Query, Mutation } from "@apollo/client/react/components";
import esb from "elastic-builder";
import { useForm, Controller } from "react-hook-form-old";
import uuid from "react-uuid";

import ADD_SHIPPING_RULE from "graphql/Shipping/ShippingRule/AddShippingRule";
import SEARCH_SHIPPING_OPTIONS from "graphql/Product/SearchShippingOptions";
import GET_RULE_TEMPLATES from "graphql/GetRuleTemplates";

import { MEDIA_MIN_LARGE } from "variables/mediaQueries";
import { useNotification } from "context/NotificationContext";
import PageContainer from "components/Page/PageContainer";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import Header from "components/Header/Header";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Box from "components/Content/Box";
import Input from "components/Ui/Input";
import Loader from "components/Ui/Loader";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import ActionButtons from "components/ActionButtons/ActionButtons";
import IconButton from "components/Ui/Buttons/IconButton";
import ActionButton from "components/ActionButtons/ActionButton";
import ActionButtonSecondary from "components/ActionButtons/ActionButtonSecondary";
import Select from "components/Ui/Select";
import MultiSelect from "components/Ui/MultiSelect";
import Sidebar from "components/Shipping/Sidebar";
import Attributes from "components/AttributeList/AttributeList";
import Attribute from "components/AttributeList/Attribute";
import Label from "components/AttributeList/Label";
import Value from "components/AttributeList/Value";
import Tooltip from "components/Ui/Tooltip";
import Toggle from "components/Ui/Toggle";
import TableHeader from "components/Table/Header";

const ShippingRuleBox = styled(Box)`
  padding-bottom: 8rem;

  h5 {
    margin-bottom: 3rem;
    border-bottom: 0.1rem solid ${(p) => p.theme.colors.inputBorderColor};
    padding-bottom: 2.4rem;
    width: 100%;
  }

  ${MEDIA_MIN_LARGE} {
    padding-bottom: 4rem;
  }
`;

const RuleAttributes = styled(Attributes)`
  margin: -1.5rem 0 3rem;
`;

const ConditionsHeader = styled(TableHeader)`
  > div {
    width: 25%;
  }
`;

const Conditions = styled.div`
  width: 100%;
  position: relative;
`;

const ConditionsTable = styled.div`
  width: 100%;
  position: relative;

  h5 {
    border-top: 0;
    border-bottom: 0.1rem solid ${(p) => p.theme.colors.inputBorderColor};
    padding-bottom: 2.4rem;
  }
`;

const Rule = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1rem;
  border: 0.1rem solid ${(p) => p.theme.colors.inputBorderColor};
  border-top: none;

  ${MEDIA_MIN_LARGE} {
    padding: 2rem;
  }

  > div {
    width: 22%;
    margin: 0;
  }
`;

const AddRowButton = styled(ActionButtonSecondary)`
  position: absolute;
  top: -7.5rem;
  right: 0;
`;

const Padding = styled.div`
  width: 4.6rem !important;
`;

const ConditionSelect = styled(Select)`
  height: 5.4rem;
  border-radius: 0.3rem;
  border: 0.1rem solid ${(p) => p.theme.colors.inputBorderColor};
  text-transform: initial;
  margin: 0 0 3rem;
`;

const SelectLabel = styled.div`
  display: flex;
  align-items: center;

  span {
    display: flex;
    align-items: center;
  }

  img {
    width: 2rem;
    margin-right: 1rem;
  }
`;

const ALL_SHIPPING_OPTIONS = new esb.requestBodySearch()
  .query(esb.queryStringQuery("type:shippingOption"))
  .size(1000)
  .from(0);

const itemRenderer = ({ checked, option, onClick }) => (
  <SelectLabel>
    <input type="checkbox" onChange={onClick} checked={checked} tabIndex="-1" />
    <span>
      {option.img && <img alt={option.label} src={option.img} />}
      <span>{option.label}</span>
    </span>
  </SelectLabel>
);

export default ({ history }) => {
  const { setNotification } = useNotification();
  const { control, handleSubmit, errors } = useForm();
  const [active, setActive] = useState();
  const [condition, setCondition] = useState("==");
  const [rules, setRules] = useState([`rule-${uuid()}`]);
  const [selectedProducts, setSelectedProducts] = useState([]);

  const gotoRules = () => {
    history.push({
      pathname: "/admin/shipping-rules",
    });
  };

  const conditionOptions = [
    {
      value: "==",
      label: "Equal",
    },
    {
      value: ">",
      label: "Greater than",
    },
    {
      value: ">=",
      label: "Greater or equal to",
    },
    {
      value: "<",
      label: "Less than",
    },
    {
      value: "<=",
      label: "Less or equal to",
    },
  ];

  const formatPrice = (price) => Number(price * 100).toFixed(2) | 0;

  const toShipping = (data, ruleTemplates) => {
    const keys = Object.keys(data).filter((key) => key.includes("rule-"));
    const rules = keys.map((key) => data[key]);
    return {
      variables: {
        name: data.name,
        templateId: ruleTemplates.getRuleTemplates[0].id,
        templateData: JSON.stringify({
          operator: condition,
          shippingOptions: selectedProducts.map((product) => product.value),
          countryOptions: rules.map((rule) => ({
            totalPriceWithDiscount: formatPrice(rule.totalPriceWithDiscount),
            currencyUnit: rule.currency,
            shippingCountry: rule.shippingCountry,
            notForShippingCountry: rule.notForShippingCountry
              ? rule.notForShippingCountry.split(",")
              : undefined,
          })),
        }),
        active: active,
      },
    };
  };

  return (
    <>
      <Breadcrumbs
        slugs={[
          ["admin/shipping", "Shipping rules"],
          ["admin/add-shipping-rule/", "Add shipping rule"],
        ]}
      />
      <Header heading="Shipping rules"></Header>
      <PageContainer>
        <Sidebar />
        <GridContainer>
          <Query query={GET_RULE_TEMPLATES} variables={{ type: "SHIPPING" }}>
            {({ loading, error, data: ruleTemplates }) => {
              if (loading) return <Loader />;
              if (error)
                return (
                  <ErrorMessage>
                    An error occurred loading data, please contact support
                  </ErrorMessage>
                );

              return (
                <Mutation
                  mutation={ADD_SHIPPING_RULE}
                  onCompleted={() => {
                    setNotification({
                      status: "success",
                      message: `Shipping rule successfully added`,
                    });
                    setTimeout(gotoRules, 1000);
                  }}
                  onError={() => {
                    setNotification({
                      status: "error",
                      message: "An error occurred adding the shipping rule, please contact support",
                    });
                  }}>
                  {(updateShipping, { loading: addShippingLoading }) => {
                    const onSubmit = (data) => {
                      updateShipping(toShipping(data, ruleTemplates)).catch((e) => {
                        console.log(e);
                      });
                    };

                    return (
                      <GridItem columns="12">
                        <form onSubmit={handleSubmit(onSubmit)}>
                          <ShippingRuleBox
                            preHeading="Shipping rules"
                            heading="Add new shipping rule"
                            showGoBackButton
                            goBackOnClick={() => history.push("/admin/shipping-rules")}>
                            {addShippingLoading && <Loader />}

                            <ActionButtons inBox footerOnMobile>
                              <ActionButton type="submit">
                                <i className="fal fa-fw fa-check" /> Save
                              </ActionButton>
                            </ActionButtons>

                            <RuleAttributes>
                              <Attribute>
                                <Label>Active:</Label>
                                <Value>
                                  <Toggle active={active} handleToggle={() => setActive(!active)} />
                                </Value>
                              </Attribute>
                            </RuleAttributes>

                            <GridContainer collapse padding="0">
                              <GridItem mobilePadding="0" desktopPadding="0 1.5rem 0 0" columns="6">
                                <Controller
                                  as={Input}
                                  control={control}
                                  rules={{
                                    required: "This is a required field",
                                  }}
                                  type="text"
                                  name="name"
                                  label="Name"
                                  errors={errors}
                                />
                              </GridItem>
                              <GridItem mobilePadding="0" desktopPadding="0 0 0 1.5rem" columns="6">
                                <Query
                                  query={SEARCH_SHIPPING_OPTIONS}
                                  variables={{
                                    query: JSON.stringify(ALL_SHIPPING_OPTIONS.toJSON()),
                                  }}
                                  fetchPolicy={"cache-first"}>
                                  {({ loading, error, data }) => {
                                    if (loading) return <Loader />;
                                    if (error)
                                      return (
                                        <ErrorMessage inBox>
                                          An error occurred getting data, please contact support
                                        </ErrorMessage>
                                      );

                                    const { products } = data.searchProducts;
                                    const options = products.map((product) => ({
                                      value: product.id,
                                      img: product.imageUrl,
                                      label: product.name,
                                    }));

                                    return (
                                      <MultiSelect
                                        options={options}
                                        label="Select shipping options"
                                        selected={selectedProducts}
                                        setSelected={setSelectedProducts}
                                        ItemRenderer={itemRenderer}
                                        valueRenderer={(selected) => {
                                          if (selectedProducts.length === 0) {
                                            return <span>No products selected</span>;
                                          }
                                          if (selectedProducts.length === options.length) {
                                            return <span>All products selected</span>;
                                          }
                                          return (
                                            <span>
                                              {selected.length}{" "}
                                              {selected.length === 1 ? "product" : "products"}{" "}
                                              selected
                                            </span>
                                          );
                                        }}
                                        overrideStrings={{
                                          selectAll: `Select all ${products.length} shipping options`,
                                        }}
                                      />
                                    );
                                  }}
                                </Query>
                              </GridItem>
                            </GridContainer>
                            <Conditions>
                              <h5>Conditions</h5>

                              <Controller
                                as={ConditionSelect}
                                control={control}
                                name="shippingCondition"
                                label="Condition"
                                handleChange={(e) => setCondition(e.target.value)}
                                errors={errors}>
                                {conditionOptions.map((option) => (
                                  <option
                                    key={option.value}
                                    defaultValue={option.value}
                                    value={option.value}>
                                    {option.label}
                                  </option>
                                ))}
                              </Controller>

                              <ConditionsTable>
                                <Tooltip />
                                <ConditionsHeader>
                                  <div>
                                    Currency code{" "}
                                    <i
                                      className="fal fa-fw fa-info-circle"
                                      data-tip="ISO-4217, ex.USD"
                                    />
                                  </div>
                                  <div>
                                    Cart value{" "}
                                    <i
                                      className="fal fa-fw fa-info-circle"
                                      data-tip="ex. 60 or 5.5"
                                    />
                                  </div>
                                  <div>
                                    Valid for specific country{" "}
                                    <i
                                      className="fal fa-fw fa-info-circle"
                                      data-tip="ISO-3166, ex. US"
                                    />
                                  </div>
                                  <div>
                                    Exclude countries{" "}
                                    <i
                                      className="fal fa-fw fa-info-circle"
                                      data-tip="ISO-3166, ex. US,UK ..."
                                    />
                                  </div>
                                  <Padding />
                                </ConditionsHeader>
                                <AddRowButton
                                  type="button"
                                  handleOnClick={() =>
                                    setRules((prevRules) => [...prevRules, `rule-${uuid()}`])
                                  }>
                                  <i className="fal fa-plus"></i> Add
                                </AddRowButton>
                                {rules
                                  .map((rule) => (
                                    <Rule key={rule}>
                                      <Controller
                                        as={Input}
                                        control={control}
                                        rules={{
                                          required: "This is a required field",
                                          pattern: {
                                            value: /[A-Z]{3}/,
                                            message:
                                              "Three letters. Only capital letter A-Z are allowed",
                                          },
                                        }}
                                        maxLength={3}
                                        type="text"
                                        name={`${rule}.currency`}
                                        placeholder="USD"
                                        errors={errors}
                                        relativeError
                                      />

                                      <Controller
                                        as={Input}
                                        control={control}
                                        type="number"
                                        step="any"
                                        rules={{
                                          required: "This is a required field",
                                        }}
                                        name={`${rule}.totalPriceWithDiscount`}
                                        defaultValue={rule.totalPriceWithDiscount}
                                        placeholder="1000"
                                        errors={errors}
                                        relativeError
                                      />

                                      <Controller
                                        as={Input}
                                        control={control}
                                        maxLength={2}
                                        type="text"
                                        name={`${rule}.shippingCountry`}
                                        defaultValue={rule.shippingCountry}
                                        placeholder="US"
                                        errors={errors}
                                        relativeError
                                      />

                                      <Controller
                                        as={Input}
                                        control={control}
                                        type="text"
                                        name={`${rule}.notForShippingCountry`}
                                        defaultValue={rule.notForShippingCountry}
                                        placeholder="US,UK"
                                        errors={errors}
                                        relativeError
                                      />

                                      <IconButton
                                        handleOnClick={() => {
                                          setRules(rules.filter((ruleId) => ruleId !== rule));
                                        }}>
                                        <i className="fal fa-trash-alt"></i>
                                      </IconButton>
                                    </Rule>
                                  ))
                                  .reverse()}
                              </ConditionsTable>
                            </Conditions>
                          </ShippingRuleBox>
                        </form>
                      </GridItem>
                    );
                  }}
                </Mutation>
              );
            }}
          </Query>
        </GridContainer>
      </PageContainer>
    </>
  );
};
