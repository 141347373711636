import React from "react";
import SecondarySidebar from "components/Sidebar/SecondarySidebar";

export default () => (
  <SecondarySidebar
    sections={[
      {
        title: "Products",
        layout: "/admin",
        routes: [{ name: "Show all", path: "/products" }],
      },
      {
        title: "Stock",
        layout: "/admin",
        routes: [{ name: "Status", path: "/stock" }],
      },
      {
        title: "Most sold",
        layout: "/admin",
        routes: [{ name: "Show all", path: "/most-sold-products" }],
      },
    ]}
  />
);
