import React from "react";
import Dinero from "dinero.js";
import Tooltip from "components/Ui/Tooltip";

export default function DiscountValue({
  discount,
  simple
}) {
  const formattedDiscountAmount = discount.discountInFixedPrice?.map((fp) => Dinero({ amount: fp.amount, currency: fp.currencyUnit })
    .toFormat('$0'))
    .sort()
    .join(' / ')

  if (simple)
    return discount.discountInPercentage
      ? <span>{discount.discountInPercentage} %</span>
      : <span>{formattedDiscountAmount}</span>
  return discount.discountInPercentage
    ? <span>{discount.discountInPercentage} %</span>
    : <span data-tip={formattedDiscountAmount}>
      <Tooltip />
      <span className="fa fa-coins" />
    </span>

}
