import curveLight from "images/curve-light.svg";
import curveDark from "images/curve-dark.svg";

export const getTheme = (theme) => {
  const light = {
    colors: {
      black: "#fff",
      white: "#333",
      secondaryText: "rgba(0, 0, 0, 0.5)",
      disabledText: "rgba(0, 0, 0, 0.3)",
      whiteOpac: "rgba(0, 0, 0, 0.05)",
      background: "#F9F9F9",
      backgroundOpac: "rgba(0, 0, 0, 0.03)",
      secondaryBackground: "#fff",
      backgroundHighlight: "#f9f9f9",
      primary: "#384DC0",
      primary1: "#222e73",
      primary2: "#afb8e6",
      primary3: "#ebedf9",
      secondary: "#ccc",
      inputBorderColor: "rgba(0, 0, 0, 0.05)",
      inputBackgroundColor: "#FBFBFB",
      checkboxBorder: "rgba(0, 0, 0, 0.1)",
      greyOpac: "rgba(0, 0, 0, 0.04)",
      darkerGrey: "#999",
      tooltipBackground: "#0c151c",
      opac1: "rgba(0, 0, 0, 0.1)",
    },
    curve: curveLight,
  };

  const dark = {
    colors: {
      black: "#000",
      white: "#fff",
      secondaryText: "rgba(255, 255, 255, 0.5)",
      disabledText: "rgba(255, 255, 255, 0.3)",
      whiteOpac: "rgba(255, 255, 255, 0.05)",
      background: "#080f14",
      backgroundOpac: "rgba(255, 255, 255, 0.03)",
      secondaryBackground: "#0c151c",
      backgroundHighlight: "#0d1921",
      primary: "#384DC0",
      primary1: "#85c2f5",
      primary2: "#afb8e6",
      primary3: "#ebedf9",
      secondary: "#2a4054",
      inputBorderColor: "rgba(255, 255, 255, 0.05)",
      inputBackgroundColor: "#FBFBFB",
      checkboxBorder: "rgba(255, 255, 255, 0.1)",
      greyOpac: "rgba(255, 255, 255, 0.04)",
      darkerGrey: "#999",
      tooltipBackground: "#f9f9f9",
      opac1: "rgba(255, 255, 255, 0.1)",
    },
    curve: curveDark,
  };

  return theme === "light" || !theme ? light : dark;
};

// Static colors

export const colors = {
  black: "#000",
  white: "#fff",
  background: "#0c151c",
  secondaryBackground: "#0c151c",
  primary: "#384DC0",
  gradient:
    "background: rgb(52, 153, 239); background: linear-gradient(200deg, rgb(95, 113, 205) 0%, rgb(55, 77, 192) 100%);",
};

export const shadow = "0 0.2rem 1.6rem 0 rgb(0 0 0 / 8%)";

// Alerts
export const alerts = {
  general: {
    background: "#777",
    highlight: "#ccc",
    text: "#fff",
  },
  error: {
    background: "#843333",
    highlight: "#9E362C",
    text: "#fff",
  },
  success: {
    background: "#335c33",
    highlight: "#fff",
    text: "#fff",
  },
};
