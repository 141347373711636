import React, { useState } from "react";
import { Mutation } from "@apollo/client/react/components";
import styled from "styled-components/macro";

import ADD_STOCK from "graphql/Stock/AddStock";
import UPDATE_STOCK from "graphql/Stock/UpdateStock";

import { useNotification } from "context/NotificationContext";

import Input from "components/Ui/Input";
import Dialog from "components/Dialog/Dialog";
import Loader from "components/Ui/Loader";

const StyledInput = styled(Input)`
  margin: 1rem 0;
`;

const EditStock = ({ variant, stock, setStock, editStock, handleClose }) => {
  const { setNotification } = useNotification();
  const [stockFieldValue, setStockFieldValue] = useState(stock);

  const hasStock = (variant) => variant.stock?.productId || variant.stock?.productId !== "empty"

  return (
    <>
      {hasStock(variant) && (
        <Mutation
          mutation={UPDATE_STOCK}
          onCompleted={(data) => {
            handleClose();
            setStock(data.addStock.stock);
            setNotification({
              status: "success",
              message: "Stock successfully updated",
            });
          }}>
          {(updateStock, { loading, error }) => {
            const onSubmit = () => {
              updateStock({
                variables: {
                  productId: variant.id,
                  stock: stockFieldValue,
                },
              }).catch((e) => {
                console.log(e);
              });
            };

            if (error)
              setNotification({
                status: "error",
                message: "An error occurred updating stock, contact support",
              });

            return (
              <Dialog
                preHeading="Update stock for"
                header={variant.name}
                subHeading={variant.id}
                open={editStock}
                handleClose={handleClose}
                handleOk={() => onSubmit()}>
                {loading && <Loader />}
                <StyledInput
                  value={stockFieldValue || 0}
                  type="number"
                  onChange={(e) => setStockFieldValue(e.target.value)}
                />
              </Dialog>
            );
          }}
        </Mutation>
      )}
      {!hasStock(variant) && (
        <Mutation
          mutation={ADD_STOCK}
          onCompleted={(data) => {
            handleClose();
            setStock(data.addStock.stock);
            setNotification({
              status: "success",
              message: "Stock successfully updated",
            });
          }}>
          {(addStock, { loading, error }) => {
            const onSubmit = () => {
              addStock({
                variables: {
                  productId: variant.id,
                  stock: stockFieldValue,
                },
              }).catch((e) => {
                console.log(e);
              });
            };

            if (error)
              setNotification({
                status: "error",
                message: "An error occurred updating stock, contact support",
              });

            return (
              <Dialog
                header="Update stock"
                text=""
                open={editStock}
                handleClose={handleClose}
                handleOk={() => onSubmit()}>
                {loading && <Loader />}
                <Input
                  value={stockFieldValue}
                  type="number"
                  onChange={(e) => setStockFieldValue(e.target.value)}
                />
              </Dialog>
            );
          }}
        </Mutation>
      )}
    </>
  );
};

export default EditStock;
