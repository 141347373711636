import React, { useState, useEffect } from "react";
import { Query } from "@apollo/client/react/components";
import Box from "components/Dashboard/Box";
import styled from "styled-components/macro";
import ChartistGraph from "react-chartist";
import ORDERS_STATISTICS_REVENUE from "graphql/OrdersStatisticsRevenue";
import Loader from "components/Ui/Loader";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import moment from "moment/min/moment-with-locales";
import Dinero from "dinero.js";
import { mergeDineros } from "../../../helpers/mergeDineros";
import calculateSales from "../../../helpers/sales";

const Container = styled.div`
  width: 100%;
  margin: 2rem 0;
`;

export default ({ orderStatus, currencyUnit, selectedStores, rates, includeVAT }) => {
  const yesterday = moment().subtract(1, "days").startOf("day");
  const today = moment().endOf("day");

  const [diagramData, setDiagramData] = useState();
  const [dailySalesChart, setDailySalesChart] = useState();
  const [prevPeriodSum, setPrevPeriodSum] = useState();
  const [currentPeriodSum, setCurrentPeriodSum] = useState();

  const toDataItem = async (stats, selectedStores, date, currencyUnit) => {
    const stat = stats.find((s) => s.keyAsString === date);
    if (stat) {
      const stores = stat.stores
        .filter((s) => selectedStores.find((c) => c.value === s.store))
        .map((s) => {
          const store = selectedStores.find((c) => c.value === s.store);
          return {
            sales: calculateSales(s.sales, store.currencyUnit, store.tax, includeVAT),
            currencyUnit: store.currencyUnit,
          };
        });
      return {
        key: date,
        value: await mergeDineros(
          stores.map((s) => Dinero({ amount: s.sales, currency: s.currencyUnit })),
          currencyUnit,
          rates
        ),
      };
    } else {
      return {
        key: date,
        value: Dinero({ amount: 0, currency: currencyUnit }),
      };
    }
  };

  useEffect(() => {
    const handleData = async () => {
      const [currentDiagramData, prevDiagramData] = await Promise.all([
        toDataItem(diagramData, selectedStores, today.format("YYYY-MM-DD"), currencyUnit),
        toDataItem(
          diagramData,
          selectedStores,
          yesterday.format("YYYY-MM-DD"),
          currencyUnit,
          rates
        ),
      ]);

      const prevPeriodAmount = prevDiagramData.value.toUnit();
      const currentPeriodAmount = currentDiagramData.value.toUnit();
      setPrevPeriodSum(prevDiagramData.value);
      setCurrentPeriodSum(currentDiagramData.value);

      const dailySalesChart = {
        data: {
          series: [currentPeriodAmount, prevPeriodAmount],
        },
        options: {
          donut: true,
          donutWidth: 15,
          donutSolid: true,
          startAngle: 0,
          showLabel: false,
        },
      };
      setDailySalesChart(dailySalesChart);
    };

    diagramData && rates && handleData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [diagramData, selectedStores, currencyUnit, rates, includeVAT]);

  return (
    <Query
      query={ORDERS_STATISTICS_REVENUE}
      fetchPolicy="network-only"
      onCompleted={({ ordersStatisticsRevenue }) => {
        setDiagramData(ordersStatisticsRevenue);
      }}
      variables={{
        endDateTime: today,
        startDateTime: yesterday,
        interval: "DAY",
        status: orderStatus,
      }}>
      {({ loading, error }) => {
        if (loading || !currentPeriodSum || !prevPeriodSum) return <Loader />;
        if (error)
          return <ErrorMessage>An error occured loading data, please contact support</ErrorMessage>;

        return (
          <Box
            preHeading="Daily revenue"
            heading={currentPeriodSum ? currentPeriodSum.toFormat() : ""}
            headingIcon="money-bill-alt"
            primaryLabel="Today"
            secondaryLabel="Yesterday"
            currentPeriodSum={
              currentPeriodSum ? currentPeriodSum : Dinero({ value: 0, currency: currencyUnit })
            }
            prevPeriodSum={
              prevPeriodSum ? prevPeriodSum : Dinero({ value: 0, currency: currencyUnit })
            }
            currency={currencyUnit}
            displayAsCurrency>
            {dailySalesChart && (
              <Container>
                <ChartistGraph
                  className="ct-chart-white-colors"
                  data={dailySalesChart.data}
                  type="Pie"
                  options={dailySalesChart.options}
                />
              </Container>
            )}
          </Box>
        );
      }}
    </Query>
  );
};
