import React, { useState } from "react";
import { Mutation } from "@apollo/client/react/components";

import DELETE_DISCOUNT_CODE from "graphql/Discount/DiscountCode/DeleteDiscountCode";

import { useNotification } from "context/NotificationContext";

import DeleteButton from "components/ActionButtons/DeleteButton";
import Loader from "components/Ui/Loader";
import Dialog from "components/Dialog/Dialog";

export default ({ id, history }) => {
  const [open, setOpen] = useState(false);
  const { setNotification } = useNotification();
  return (
    <Mutation
      mutation={DELETE_DISCOUNT_CODE}
      variables={{ id, deleteMode: "SOFT" }}
      onCompleted={({ deleteDiscount }) => {
        setNotification({
          status: "success",
          message: `Discount code ${deleteDiscount.code} successfully deleted`,
        });
        history.push("/admin/discount-codes");
      }}
      onError={() => {
        setNotification({
          status: "error",
          message: `Discount code ${id} could not be deleted, please contact support`,
        });
      }}>
      {(deleteDiscountCode, { loading }) => {
        return (
          <>
          {loading && <Loader/>}
            <DeleteButton handleOnClick={() => setOpen(true)}>Delete</DeleteButton>
            <Dialog
              header="Delete discount code?"
              text="Deleting the discount code will not effect already used discount codes"
              open={open}
              handleClose={() => setOpen(false)}
              handleOk={deleteDiscountCode}
            />
          </>
        );
      }}
    </Mutation>
  );
};
