import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import Moment from "react-moment";

import { MEDIA_MIN_LARGE } from "variables/mediaQueries";
import Button from "components/Ui/Button";
import HoM from "components/Ui/HoM";
import TableHeader from "components/Table/Header";
import Shippings from "components/Table/Rows";
import Shipping from "components/Table/Row";
import Column from "components/Table/Column";
import Toolbar from "components/Table/Toolbar/Toolbar";
import GoToPage from "components/Table/Toolbar/GoToPage";
import TotalItems from "components/Table/Toolbar/TotalItems";
import ItemsPerPage from "components/Table/Toolbar/ItemsPerPage";
import Pagination from "components/Pagination/Pagination";
import Loader from "components/Ui/Loader";

const SortButton = styled(Button)`
  background: transparent;
  height: auto;
  width: auto;
  margin: 0;
  color: ${(p) => p.theme.colors.white};
`;

const Column40w = styled(Column)`
  width: 75%;
  padding-left: 1rem;

  ${MEDIA_MIN_LARGE} {
    width: 40%;
    padding-left: 2rem;
  }
`;

const Column20w = styled(Column)`
  width: 20%;
`;

const Valid = styled(Column20w)`
  width: 25%;

  ${MEDIA_MIN_LARGE} {
    width: 20%;
  }

  .fa-check-circle {
    color: green;
    font-size: 2.4rem;
  }

  .fa-times-circle {
    color: red;
    font-size: 2.4rem;
  }
`;

export default function ShippingRulesTable({
  data,
  loading,
  fetchMore,
  shippingRulesPerPage,
  history,
  esbQuery,
}) {
  const [currentSort, setCurrentSort] = useState("default");
  const [sortField, setSortField] = useState("reference");
  const [currentPage, setCurrentPage] = useState(1);
  const [goToPageInput, setGoToPageInput] = useState("1");
  const [itemsPerPage, setItemsPerPage] = useState(shippingRulesPerPage);
  const [shippingsRules, setShippingRules] = useState(data?.searchRules.rules);
  const shippingRulesTotalHits = data && data.searchRules.totalHits;

  useEffect(() => {
    let isMounted = true;
    const from = (currentPage - 1) * itemsPerPage;
    fetchMore({
      variables: { query: JSON.stringify(esbQuery.size(itemsPerPage).from(from).toJSON()) },
    })
      .then((result) => isMounted && setShippingRules(result.data.searchRules.rules))
      .catch((error) => {
        console.error(error);
      });

    setGoToPageInput(currentPage);
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, itemsPerPage, esbQuery]);

  const handleShippingRuleClick = (shippingId) => {
    history.push(`/admin/shipping-rule/${shippingId}`);
  };

  const onSortChange = (sortField) => {
    let nextSort;
    if (currentSort === "down") nextSort = "up";
    else if (currentSort === "up") nextSort = "default";
    else if (currentSort === "default") nextSort = "down";

    setCurrentSort(nextSort);
    setSortField(sortField);
  };

  const getSortType = (field, sort) =>
    field === sortField ? sortTypes[sort].class : sortTypes.default.class;

  const sortTypes = {
    up: {
      class: "sort-up",
      fn: (a, b) => {
        const valueA = toValue(sortField, a);
        const valueB = toValue(sortField, b);
        if (valueA > valueB) return 1;
        else if (valueA < valueB) return -1;
        return 0;
      },
    },
    down: {
      class: "sort-down",
      fn: (a, b) => {
        const valueA = toValue(sortField, a);
        const valueB = toValue(sortField, b);
        if (valueA > valueB) return -1;
        else if (valueA < valueB) return 1;
        return 0;
      },
    },
    default: {
      class: "sort",
      fn: (a, _) => a,
    },
  };

  const toValue = (sortField, obj) => {
    if (Number.isInteger(obj[sortField]) || sortField === "active") {
      return obj[sortField];
    } else {
      return obj[sortField].toLowerCase();
    }
  };

  return (
    <>
      <Toolbar>
        <HoM>
          <GoToPage>
            Page
            <input value={goToPageInput} onChange={(e) => setGoToPageInput(e.target.value)} />
            <button type="button" onClick={(e) => setCurrentPage(parseInt(goToPageInput, 10))}>
              <i className="fal fa-sync"></i>
            </button>
            of {shippingRulesTotalHits ? Math.ceil(shippingRulesTotalHits / itemsPerPage) : "-"}{" "}
            pages
          </GoToPage>
        </HoM>
        <ItemsPerPage setItemsPerPage={setItemsPerPage} />
        <TotalItems>
          Total shipping rules: <span>{shippingRulesTotalHits || "-"}</span>
        </TotalItems>
      </Toolbar>
      <TableHeader>
        <Column40w>
          <span>Name</span>
          <SortButton onClick={() => onSortChange("code")}>
            <i className={`fas fa-${getSortType("code", currentSort)}`} />
          </SortButton>
        </Column40w>
        <Valid>
          <span>Active</span>
          <SortButton onClick={() => onSortChange("active")}>
            <i className={`fas fa-${getSortType("active", currentSort)}`} />
          </SortButton>
        </Valid>
        <Column20w hideOnMobile>
          <span>Created</span>
          <SortButton onClick={() => onSortChange("created")}>
            <i className={`fas fa-${getSortType("created", currentSort)}`} />
          </SortButton>
        </Column20w>
        <Column20w hideOnMobile>
          <span>Last updated</span>
          <SortButton onClick={() => onSortChange("lastUpdated")}>
            <i className={`fas fa-${getSortType("lastUpdated", currentSort)}`} />
          </SortButton>
        </Column20w>
      </TableHeader>
      <Shippings>
        {loading && <Loader />}
        {shippingsRules && !loading && (
          <Pagination
            itemsPerPage={itemsPerPage}
            totalItems={shippingRulesTotalHits}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}>
            {[...shippingsRules].sort(sortTypes[currentSort].fn).map((shippingRule) => (
              <Shipping
                key={shippingRule.id}
                onClick={() => handleShippingRuleClick(shippingRule.id)}>
                <Column40w>
                  <strong>{shippingRule.name}</strong>
                </Column40w>
                {shippingRule.active ? (
                  <Valid>
                    <i className={"fal fa-check-circle"} />
                  </Valid>
                ) : (
                  <Valid>
                    <i className={"fal fa-times-circle"} />
                  </Valid>
                )}
                <Column20w hideOnMobile>
                  <Moment format="YYYY-MM-DD, HH:mm">{shippingRule.created}</Moment>
                </Column20w>
                <Column20w hideOnMobile>
                  <Moment format="YYYY-MM-DD, HH:mm">{shippingRule.lastUpdated}</Moment>
                </Column20w>
              </Shipping>
            ))}
          </Pagination>
        )}
      </Shippings>
    </>
  );
}
