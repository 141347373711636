import React, { useState } from "react";
import esb from "elastic-builder";

import SearchForm from "components/Table/Search/SearchForm";

export default ({ setSearchFilters }) => {
  const [searchInput, setSearchInput] = useState("");
  const [inputError, setInputError] = useState(false);

  const searchProducts = (evt) => {
    evt.preventDefault();
    searchInput.length ? setInputError(false) : setInputError(true);
    const queryString = searchInput.split(" ").join("* ").concat("*")
    setSearchFilters([esb.queryStringQuery(queryString).analyzeWildcard()]);
  };

  return (
    <SearchForm
      autoComplete="off"
      placeholder="Name, ID, SKU..."
      inputError={inputError}
      setInputError={setInputError}
      setSearchInput={setSearchInput}
      searchFunc={searchProducts}
    />
  );
};
