import React from "react";
import SecondarySidebar from "components/Sidebar/SecondarySidebar";

export default () => (
  <SecondarySidebar
    sections={[
      {
        title: "Integrations",
        layout: "/admin",
        routes: [
          { name: "Fortnox", path: "/fortnox" },
          { name: "Klaviyo", path: "/klaviyo" },
        ],
      },

      {
        title: "Webhooks",
        layout: "/admin",
        routes: [
          { name: "Order", path: "/webhook-order" },
          { name: "Product", path: "/webhook-product" },
        ],
      },
    ]}
  />
);
