import React from "react";
import SecondarySidebar from "components/Sidebar/SecondarySidebar";

export default () => (
  <SecondarySidebar
    sections={[
      {
        title: "Stores",
        layout: "/admin",
        routes: [
              { name: "+ Add", path: "/add-store" },
              { name: "Show all", path: "/stores" },
            ]
      }
    ]}
  />
);
