import gql from "graphql-tag";

const ADD_DISCOUNT_CODE = gql`
  mutation addDiscount(
    $code: String!
    $discountInPercentage: Int
    $discountInFixedPrice: [InputMoney]
    $usageLimit: Int
    $stores: [InputDiscountStore]
    $type: DiscountType!
    $description: String
    $startDate: AWSDate!
    $endDate: AWSDate!
    $validForProducts: [String]
    $vaildForProductVariants: [String]
    $notForCategories: [String]
    $categories: [String]
    $notForProducts: [String]
    $notForProductVariants: [String]
    $group: String
    $minItems: Int
    $combine: Boolean
    $filterRules: [InputFilterRule!]
    $currencyOptions: [InputCurrencyOption!]
  ) {
    addDiscount(
      discount: {
        code: $code
        discountInPercentage: $discountInPercentage
        discountInFixedPrice: $discountInFixedPrice
        usageLimit: $usageLimit
        stores: $stores
        type: $type
        description: $description
        startDate: $startDate
        endDate: $endDate
        validForProducts: $validForProducts
        vaildForProductVariants: $vaildForProductVariants
        notForCategories: $notForCategories
        categories: $categories
        notForProducts: $notForProducts
        notForProductVariants: $notForProductVariants
        group: $group
        minItems: $minItems
        combine: $combine
        filterRules: $filterRules
        currencyOptions: $currencyOptions
      }
    ) {
      id
      code
      startDate
      endDate
      type
      description
      discountInPercentage
      discountInFixedPrice {
        amount
        currencyUnit
      }
      usageLimit
      created
      lastUpdated
      notForCategories
      categories
      minItems
      combine
      filterRules{
        mode
        key
        values
      }
      currencyOptions {
        atLeastTotalPriceWithDiscount
        currencyUnit
      }
    }
  }
`;

export default ADD_DISCOUNT_CODE;
