import Dashboard from "views/Dashboard/Dashboard";

// Products
import Products from "views/Products/Products";
import Stock from "views/Products/Stock/Stock";
import Product from "views/Product/Product";
import MostSoldProducts from "views/Products/MostSoldProducts"

// Orders
import Orders from "views/Orders/Orders";

// Customers
import Customers from "views/Customers/Customers";

// Discounts
import Discounts from "views/Discount/Discounts";
import DiscountCodes from "views/Discount/DiscountCode/DiscountCodes";
import DiscountCode from "views/Discount/DiscountCode/DiscountCode";
import AddDiscountCode from "views/Discount/DiscountCode/AddDiscountCode";
import DiscountRules from "views/Discount/DiscountRule/DiscountRules";
import DiscountRule from "views/Discount/DiscountRule/DiscountRule";
import AddDiscountRule from "views/Discount/DiscountRule/AddDiscountRule";
import Vouchers from "views/Discount/Voucher/Vouchers";
import Voucher from "views/Discount/Voucher/Voucher";
import AddVoucherGroup from "views/Discount/Voucher/AddVoucherGroup";

// Stores
import Stores from "views/Stores/Stores";
import AddStore from "views/Stores/AddStore";
import Store from "views/Stores/Store";

// Configuration
import Configuration from "views/Configuration/Configuration";
import Fortnox from "views/Configuration/Integration/Fortnox";
import Klaviyo from "views/Configuration/Integration/Klaviyo";
import OrderWebhooks from "views/Configuration/Webhooks/OrderWebhooks";
import ProductWebhooks from "views/Configuration/Webhooks/ProductWebhooks";
import AddProductWebhook from "views/Configuration/Webhooks/Product/AddProductWebhook";
import UpdateProductWebhook from "views/Configuration/Webhooks/Product/UpdateProductWebhook";
import AddOrderWebhook from "views/Configuration/Webhooks/Order/AddOrderWebhook";
import UpdateOrderWebhook from "views/Configuration/Webhooks/Order/UpdateOrderWebhook";

// Shipping
import Shipping from "views/Shipping/ShippingRule/ShippingRules";
import ShippingRule from "views/Shipping/ShippingRule/ShippingRule";
import AddShippingRule from "views/Shipping/ShippingRule/AddShippingRule";
import ShippingOptions from "views/Shipping/ShippingOption/ShippingOptions";
import ShippingOption from "views/Shipping/ShippingOption/ShippingOption";
import AddShippingOption from "views/Shipping/ShippingOption/AddShippingOption";

var dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "fal fa-fw fa-tachometer-alt-fastest",
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/products",
    name: "Products",
    icon: "fal fa-fw fa-box-full",
    component: Products,
    layout: "/admin",
  },
  {
    path: "/most-sold-products",
    name: "Most sold products",
    icon: "fal fa-fw fa-box-full",
    visible: false,
    component: MostSoldProducts,
    layout: "/admin",
  },
  {
    path: "/product/:id",
    name: "Product",
    icon: "fal fa-fw fa-box-full",
    component: Product,
    layout: "/admin",
    visible: false,
    parentPath: "/products",
  },
  {
    path: "/stock",
    name: "Stock",
    icon: "fal fa-fw fa-box-full",
    component: Stock,
    layout: "/admin",
    visible: false,
    parentPath: "/products",
  },
  {
    path: "/orders",
    name: "Orders",
    icon: "fal fa-fw fa-shopping-cart",
    component: Orders,
    layout: "/admin",
  },
  {
    path: "/customers",
    name: "Customers",
    icon: "fal fa-fw fa-user-friends",
    component: Customers,
    layout: "/admin",
  },
  {
    path: "/discounts",
    name: "Discounts",
    icon: "fal fa-fw fa-tags",
    component: Discounts,
    layout: "/admin",
  },
  {
    path: "/discount-codes",
    name: "Discount codes",
    component: DiscountCodes,
    visible: false,
    layout: "/admin",
    parentPath: "/discounts",
  },
  {
    path: "/discount-code/:id",
    name: "Discount code",
    component: DiscountCode,
    layout: "/admin",
    visible: false,
    parentPath: "/discounts",
  },
  {
    path: "/add-discount-code",
    name: "Add discount code",
    component: AddDiscountCode,
    visible: false,
    layout: "/admin",
    parentPath: "/discounts",
  },
  {
    path: "/discount-rules",
    name: "Discount rules",
    component: DiscountRules,
    visible: false,
    layout: "/admin",
    parentPath: "/discounts",
  },
  {
    path: "/discount-rule/:id",
    name: "Discount rule",
    component: DiscountRule,
    layout: "/admin",
    visible: false,
    parentPath: "/discounts",
  },
  {
    path: "/add-discount-rule",
    name: "Add discount rule",
    component: AddDiscountRule,
    visible: false,
    layout: "/admin",
    parentPath: "/discounts",
  },
  {
    path: "/vouchers",
    name: "Vouchers",
    component: Vouchers,
    visible: false,
    layout: "/admin",
    parentPath: "/discounts",
  },
  {
    path: "/voucher",
    name: "Voucher",
    component: Voucher,
    layout: "/admin",
    visible: false,
    parentPath: "/discounts",
  },
  {
    path: "/add-voucher-group",
    name: "Add voucher group",
    component: AddVoucherGroup,
    visible: false,
    layout: "/admin",
    parentPath: "/discounts",
  },
  {
    path: "/stores",
    name: "Stores",
    icon: "fal fa-fw fa-store",
    component: Stores,
    visible: false,
    layout: "/admin",
  },
  {
    path: "/add-store",
    name: "Add store",
    icon: "fal fa-fw fa-store",
    component: AddStore,
    visible: false,
    layout: "/admin",
    parentPath: "/stores",
  },
  {
    path: "/store/:id",
    name: "Store",
    component: Store,
    layout: "/admin",
    visible: false,
    parentPath: "/stores",
  },
  // Configuration
  {
    path: "/configuration",
    name: "Configuration",
    icon: "fal fa-fw fa-cog",
    component: Configuration,
    visible: false,
    layout: "/admin",
  },
  {
    path: "/fortnox",
    name: "Fortnox",
    component: Fortnox,
    visible: false,
    layout: "/admin",
    parentPath: "/configuration",
  },
  {
    path: "/klaviyo",
    name: "Klaviyo",
    component: Klaviyo,
    visible: false,
    layout: "/admin",
    parentPath: "/configuration",
  },
  {
    path: "/webhook-order",
    name: "Order",
    component: OrderWebhooks,
    visible: false,
    layout: "/admin",
    parentPath: "/configuration",
  },
  {
    path: "/webhook-product",
    name: "Product",
    component: ProductWebhooks,
    visible: false,
    layout: "/admin",
    parentPath: "/configuration",
  },
  {
    path: "/order-webhook/:id",
    name: "Edit order webhook",
    component: UpdateOrderWebhook,
    layout: "/admin",
    visible: false,
    parentPath: "/configuration",
  },
  {
    path: "/product-webhook/:id",
    name: "Edit product webhook",
    component: UpdateProductWebhook,
    layout: "/admin",
    visible: false,
    parentPath: "/configuration",
  },
  {
    path: "/add-product-webhook",
    name: "Add webhook",
    component: AddProductWebhook,
    layout: "/admin",
    visible: false,
    parentPath: "/configuration",
  },
  {
    path: "/add-order-webhook",
    name: "Add order webhook",
    component: AddOrderWebhook,
    layout: "/admin",
    visible: false,
    parentPath: "/configuration",
  },
  // Shipping
  {
    path: "/shipping-rules",
    name: "Shipping",
    icon: "fal fa-fw fa-shipping-fast",
    component: Shipping,
    layout: "/admin",
  },
  {
    path: "/shipping-rule/:id",
    name: "Shipping rule",
    component: ShippingRule,
    visible: false,
    layout: "/admin",
    parentPath: "/shipping-rules",
  },
  {
    path: "/add-shipping-rule",
    name: "Add shipping rule",
    component: AddShippingRule,
    visible: false,
    layout: "/admin",
    parentPath: "/shipping-rules",
  },
  {
    path: "/shipping-options",
    name: "Shipping options",
    component: ShippingOptions,
    visible: false,
    layout: "/admin",
    parentPath: "/shipping-rules",
  },
  {
    path: "/shipping-option/:id",
    name: "Shipping Option",
    icon: "fal fa-fw fa-box-full",
    component: ShippingOption,
    layout: "/admin",
    visible: false,
    parentPath: "/shipping-rules",
  },
  {
    path: "/add-shipping-option",
    name: "Add shipping option",
    component: AddShippingOption,
    visible: false,
    layout: "/admin",
    parentPath: "/shipping-rules",
  },
];
export default dashRoutes;
