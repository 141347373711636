import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client/react/hooks";

import REFUND_ORDER from "graphql/Order/RefundOrder";
import UPDATE_ORDER_REFUND_FEE from "graphql/Order/UpdateOrderRefundFee";
import UPDATE_ORDER_REFUND_ACTIVE from "graphql/Order/UpdateOrderRefundActive";

import { colors } from "variables/colors";
import { MEDIA_MIN_MEDIUM } from "variables/mediaQueries";
import Box from "components/Content/Box";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Money from "components/Money/Money";
import ActionButtons from "components/ActionButtons/ActionButtons";
import RefundButton from "components/ActionButtons/RefundButton";
import Checkbox from "components/Ui/Checkbox";
import Loader from "components/Ui/Loader";
import Toggle from "components/Ui/Toggle";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import Dialog from "components/Dialog/Dialog";
import isRefundable from "helpers/isRefundable";
import Attributes from "components/AttributeList/AttributeList";
import Attribute from "components/AttributeList/Attribute";
import Label from "components/AttributeList/Label";
import Value from "components/AttributeList/Value";
import TableHeader from "components/Table/Header";
import Column from "components/Table/Column";
import { useNotification } from "context/NotificationContext";

const Header = styled(TableHeader)`
  padding-right: 0;

  > div {
    &:first-child {
      margin-left: ${(p) => (p.isRefundable ? "7rem" : "4rem")};

      ${MEDIA_MIN_MEDIUM} {
        margin-left: ${(p) => (p.isRefundable ? "11rem" : "7rem")};
      }
    }
  }
`;

const RefundCheckbox = styled(Checkbox)`
  pointer-events: ${(p) => (p.disabled ? "none" : "all")};
  background: ${(p) => (p.disabled ? p.theme.colors.greyOpac : p.theme.colors.black)};
  margin-right: 2rem;
  visibility: ${(p) => (p.disabled ? "hidden" : "visible")};
`;

const Image = styled.img`
  width: 5rem;

  ${MEDIA_MIN_MEDIUM} {
    width: 7rem;
  }
`;

const Name = styled(Column)`
  width: 640%;
  padding-left: 2rem;

  ${MEDIA_MIN_MEDIUM} {
    width: 20%;
  }
`;

const UnitPrice = styled(Column)`
  width: 10%;
`;

const Quantity = styled(Column)`
  width: 10%;
  padding-right: 1rem;
`;

const TaxRate = styled(Column)`
  width: 10%;
`;

const TotalTax = styled(Column)`
  width: 10%;
`;

const Discount = styled(Column)`
  width: 10%;
`;

const TotalAmount = styled(Column)`
  width: 20%;
`;

const Lines = styled.ul`
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
`;

const Line = styled.li`
  width: 100%;
  min-height: 10rem;
  display: flex;
  align-items: center;
  margin: 0;
  border-bottom: 0.1rem solid ${(p) => p.theme.colors.whiteOpac};
  transition: background 0.2s;
  cursor: pointer;
  position: relative;
  overflow: hidden;

  ${MEDIA_MIN_MEDIUM} {
    padding-left: 2rem;
  }

  &:hover {
    background: ${(p) => p.theme.colors.background};
  }
`;

const Refunded = styled.span`
  background: ${(p) => p.theme.colors.primary};
  color: ${colors.white};
  text-transform: initial;
  margin-left: 1rem;
  padding: 0 2.5rem;
  transform: rotate(-45deg);
  z-index: 1;
  position: absolute;
  left: -3.7rem;
  font-size: 1.2rem;
  top: 1.7rem;
  pointer-events: none;
`;

const Totals = styled(Attributes)`
  width: 100%;
`;

const Total = styled(Attribute)`
  width: 100%;
  justify-content: space-between;
`;

const TotalPaid = styled(Total)`
  font-size: 1.6rem;

  ${MEDIA_MIN_MEDIUM} {
    font-size: 1.8rem;
  }
`;

const TotalValue = styled(Value)`
  justify-content: flex-end;
`;

const TotalLabel = styled(Label)`
  width: 25rem;
`;

const ToggleLabel = styled.span`
  width: 10rem;
  margin-left: 1rem;
`;

const RefundActions = styled(ActionButtons)`
  position: relative;
  padding: 2rem 0;
  top: auto;
  right: 0;

  ${MEDIA_MIN_MEDIUM} {
    position: absolute;
    top: 4rem;
    right: 2rem;
  }
`;

const Refund = styled.div`
  display: flex;
`;

const isEmpty = (value) => typeof value === "undefined" || value === null;

export default ({ order, refetch }) => {
  const currencyUnit = order.currencyUnit;
  const history = useHistory();
  const [refundOrderLines, setRefundOrderLines] = useState(new Map());
  const [disabled, setDisabled] = useState(true);
  const [refundedProducts, setRefundedProducts] = useState({});
  const [open, setOpen] = useState(false);
  const [refundActive, setRefundActive] = useState(() =>
    isEmpty(order.refundActive) ? true : order.refundActive
  );
  const [refundFeeActive, setRefundFeeActive] = useState(() =>
    isEmpty(order.refundFee) ? true : order.refundFee
  );
  const { setNotification } = useNotification();

  const [refundOrder, { loading: refundLoading, error: refundError }] = useMutation(REFUND_ORDER, {
    onCompleted: (data) => {
      setNotification({
        status: "success",
        message: `Order "${data.refundOrder.id}" successfully refunded`,
      });
    },
    onError: () => {
      setNotification({
        status: "error",
        message: `Order lines could not be refunded, please contact support`,
      });
    },
    refetchQueries: refetch,
  });

  const [
    refundActiveOrder,
    { loading: refundActiveLoading, error: refundActiveError },
  ] = useMutation(UPDATE_ORDER_REFUND_ACTIVE, {
    onCompleted: (data) => {
      setRefundActive(data.updateOrder.refundActive);
      setNotification({
        status: "success",
        message: `Refund is ${data.updateOrder.refundActive ? "activated" : "inactivated"}`,
      });
    },
    onError: () => {
      setNotification({
        status: "error",
        message: `Refund active could not be set, please contact support`,
      });
    },
    refetchQueries: refetch,
  });

  const [refundFeeOrder, { loading: refundFeeLoading, error: refundFeeError }] = useMutation(
    UPDATE_ORDER_REFUND_FEE,
    {
      onCompleted: (data) => {
        setRefundFeeActive(data.updateOrder.refundFee);
        setNotification({
          status: "success",
          message: `Refundfee is ${data.updateOrder.refundFee ? "activated" : "inactivated"}`,
        });
      },
      onError: () => {
        setNotification({
          status: "error",
          message: `Refundfee could not be set, please contact support`,
        });
      },
      refetchQueries: refetch,
    }
  );

  useEffect(() => {
    setOpen(false);
    setRefundedProducts(
      order.orderLines
        .filter(({ type }) => type === "refund")
        .reduce((refunded, orderLine) => {
          refunded[orderLine.product.id] = true;
          return refunded;
        }, {})
    );
  }, [order, setOpen]);

  const handlProductClick = (productId) => {
    history.push(`/admin/product/${productId.replace("/", "_")}`);
  };

  const handleCheckbox = async (event) => {
    event.stopPropagation();
    const id = event.target.name;
    const isChecked = event.target.checked;
    await setRefundOrderLines((prevState) => prevState.set(id, isChecked));
    setDisabled([...refundOrderLines].filter(([_, v]) => v).length === 0);
  };

  const handleRefundOrder = () => {
    const variables = {
      id: order.id,
      orderLinesToBeRefunded: [...refundOrderLines]
        .filter(([_, v]) => v)
        .map(([k, _]) => {
          const orderLine = order.orderLines.find(
            (line) => line.id === k && line.type === "physical"
          );
          return {
            productId: orderLine.product.id,
            quantity: orderLine.quantity,
          };
        }),
    };
    if (variables.orderLinesToBeRefunded.length) {
      setOpen(false);
      refundOrder({ variables: variables });
    }
  };

  const handleRefundActive = () => {
    refundActiveOrder({
      variables: { id: order.id, refundActive: isEmpty(order.refundActive) ? true : !refundActive },
    });
  };

  const handleRefundFee = () => {
    refundFeeOrder({
      variables: { id: order.id, refundFee: isEmpty(order.refundFee) ? false : !refundFeeActive },
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const orderLines = (order.orderLines || []).filter(({ type }) => {
    return !["shipment", "refund"].includes(type);
  });

  return (
    <>
      {(refundLoading || refundFeeLoading || refundActiveLoading) && <Loader />}
      {(refundError || refundFeeError || refundActiveError) && (
        <ErrorMessage>An error occurred getting data, please contact support</ErrorMessage>
      )}

      <Box preHeading="Order" heading="Products" headingIcon="box-full">
        <RefundActions inBox>
          {isRefundable(order) && (
            <Refund>
              <Value>
                <ToggleLabel>Refund active:</ToggleLabel>
                <Toggle active={refundActive} handleToggle={() => handleRefundActive()} />
              </Value>
              <Value>
                <ToggleLabel>Refund fee:</ToggleLabel>
                <Toggle active={refundFeeActive} handleToggle={() => handleRefundFee()} />
              </Value>
              <RefundButton disabled={disabled} type="button" handleOnClick={handleClickOpen}>
                Refund selected
              </RefundButton>
              <Dialog
                header="Refund order?"
                text="Refund selected orderlines"
                open={open}
                handleClose={handleClose}
                handleOk={handleRefundOrder}
              />
            </Refund>
          )}
        </RefundActions>

        <Header isRefundable={isRefundable(order)}>
          <Name>Name</Name>
          <UnitPrice hideOnMobile>Unit price</UnitPrice>
          <Quantity>Qty</Quantity>
          <TaxRate hideOnMobile>Tax rate</TaxRate>
          <TotalTax hideOnMobile>Total tax</TotalTax>
          <Discount hideOnMobile>Discount</Discount>
          <TotalAmount hideOnMobile>Total</TotalAmount>
          <TotalAmount hideOnMobile>Total with discount</TotalAmount>
        </Header>
        <Lines>
          {orderLines.map(
            ({
              id,
              product,
              quantity,
              taxRate,
              totalTaxAmount,
              totalAmount,
              totalAmountWithDiscount,
              type,
            }) => {
              return (
                <Line key={id} onClick={() => handlProductClick(product.relatedProduct.id)}>
                  {refundedProducts[product.id] && <Refunded>Refunded</Refunded>}
                  {isRefundable(order) && (
                    <RefundCheckbox
                      disabled={
                        type !== "physical" || refundedProducts[product.id] || !order.refundActive
                      }
                      name={id}
                      onClick={handleCheckbox}
                    />
                  )}
                  <Image src={`${product.imageUrl}?fm=webp&w=150`} />
                  <Name>{product.name}</Name>
                  <UnitPrice hideOnMobile>
                    <Money
                      amount={
                        product.price.find((price) => price.currencyUnit === currencyUnit).amount
                      }
                      currencyUnit={currencyUnit}
                    />
                  </UnitPrice>
                  <Quantity>{quantity}</Quantity>
                  <TaxRate hideOnMobile>{taxRate} %</TaxRate>
                  <TotalTax hideOnMobile>
                    <Money amount={totalTaxAmount} currencyUnit={currencyUnit} />
                  </TotalTax>
                  <Discount hideOnMobile>
                    <Money
                      amount={totalAmount - totalAmountWithDiscount}
                      currencyUnit={currencyUnit}
                    />
                  </Discount>
                  <TotalAmount hideOnMobile>
                    <Money amount={totalAmount} currencyUnit={currencyUnit} />
                  </TotalAmount>
                  <TotalAmount hideOnMobile>
                    <Money amount={totalAmountWithDiscount} currencyUnit={currencyUnit} />
                  </TotalAmount>
                </Line>
              );
            }
          )}
        </Lines>

        <GridContainer collapse padding="2rem 0 0">
          <GridItem columns="6"></GridItem>
          <GridItem columns="6" padding="0">
            <Totals>
              <Total>
                <TotalLabel>Amount:</TotalLabel>
                <TotalValue>
                  <Money amount={order.orderAmount} currencyUnit={order.currencyUnit} />
                </TotalValue>
              </Total>
              <Total>
                <TotalLabel>Discount:</TotalLabel>
                <TotalValue>
                  {"- "}
                  <Money
                    amount={order.orderAmount - order.orderAmountWithDiscount}
                    currencyUnit={order.currencyUnit}
                  />
                </TotalValue>
              </Total>
              {order.orderAmount !== order.orderAmountWithDiscount && (
                <>
                  <Total>
                    <TotalLabel>Amount with discount:</TotalLabel>
                    <TotalValue>
                      <Money
                        amount={order.orderAmountWithDiscount}
                        currencyUnit={order.currencyUnit}
                      />
                    </TotalValue>
                  </Total>
                </>
              )}
              <Total>
                <TotalLabel>Tax:</TotalLabel>
                <TotalValue>
                  <Money amount={order.orderTaxAmount} currencyUnit={order.currencyUnit} />
                </TotalValue>
              </Total>
              <TotalPaid>
                <TotalLabel>Total order value:</TotalLabel>
                <TotalValue>
                  <Money amount={order.orderAmountWithDiscount} currencyUnit={order.currencyUnit} />
                </TotalValue>
              </TotalPaid>
            </Totals>
          </GridItem>
        </GridContainer>
      </Box>
    </>
  );
};
