import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { Mutation, Query } from "@apollo/client/react/components";
import { useForm, Controller } from "react-hook-form";
import moment from "moment/min/moment-with-locales";
import ADD_DISCOUNT_CODE from "graphql/Discount/DiscountCode/AddDiscountCode";
import ALL_STORES from "graphql/Store/AllStores";
import SaveBar from "components/Ui/SaveBar";
import GET_DISCOUNT_TYPES from "graphql/Discount/DiscountCode/GetDiscountTypes";
import { useNotification } from "context/NotificationContext";
import { MEDIA_MIN_LARGE } from "variables/mediaQueries";
import PageContainer from "components/Page/PageContainer";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import Header from "components/Header/Header";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Box from "components/Content/Box";
import InputNew from "components/Ui/InputNew";
import TextArea from "components/Ui/TextArea";
import Loader from "components/Ui/Loader";
import MultiSelect from "components/Ui/MultiSelect";
import Select from "components/Ui/Select";
import FlagIcon from "components/Ui/FlagIcon";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import ActionButtons from "components/ActionButtons/ActionButtons";
import ActionButton from "components/ActionButtons/ActionButton";
import DiscountProducts from "components/Discount/DiscountProducts";
import CategoriesInput from "components/Discount/CategoriesInput";
import Sidebar from "components/Discount/Sidebar";
import Checkbox from "components/Ui/Checkbox";
import Tooltip from "components/Ui/Tooltip";
import Toggle from "components/Ui/Toggle";
import FilterRules from "./FilterRules";
import FilterProducts from "./FilterProducts";
import TableHeader from "components/Table/Header";
import Row from "components/Table/Row";
import Rows from "components/Table/Rows";
import uuid from "react-uuid";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin-top: 2rem;
  padding-bottom: 3.5rem;

  ${MEDIA_MIN_LARGE} {
    padding-bottom: 0;
  }
`;

const AmountInput = styled(InputNew)`
  width: 40%;
  height: 3rem;
`;

const TypeLabel = styled.label`
  width: 100%;
  padding-bottom: 0.8rem;
  display: block;
  font-weight: 700;
`;

const Label = styled.div`
  display: flex;
  align-items: center;
  span {
    display: flex;
    align-items: center;
  }
`;

const RowLabel = styled.div`
  width: 100%;
  padding-bottom: 3rem;
  padding-left: 4rem;
`;

const UsageLimit = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 2rem;

  ${MEDIA_MIN_LARGE} {
    width: 50%;
    padding-right: 1.5rem;
    margin-top: 0;
  }

  .usageLimit {
    margin: 0;
  }

  span {
    width: 100%;
    display: flex;
    align-items: center;
  }
`;

const UsageLimitCheck = styled(Checkbox)`
  position: relative;
  top: -0.5rem;
  margin-right: 1rem;
  width: 2.3rem;
  height: 2.3rem;
`;

const TypeContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${MEDIA_MIN_LARGE} {
    width: 50%;
    padding-right: 1.5rem;
  }

  p {
    margin-top: 2rem;
    background: ${(p) => p.theme.colors.primary3};
    padding: 1.2rem 2rem 1rem;
    line-height: 2rem;
    border: 0.1rem solid ${(p) => p.theme.colors.primary2};

    i {
      font-size: 1.8rem;
      margin-right: 0.5rem;
      color: ${(p) => p.theme.colors.primary};
    }
  }
`;

const RightContainer = styled(TypeContainer)`
  ${MEDIA_MIN_LARGE} {
    padding-left: 1.5rem;
  }
`;

const Info = styled.div`
  width: 100%;
  padding: 1rem;
  margin-top: 2rem;
  background: ${(p) => p.theme.colors.primary3};
  border: 0.1rem solid ${(p) => p.theme.colors.primary2};
  display: flex;
  flex-direction: column;
  div {
    display: flex;
    padding: 1rem 0;
  }
  i {
    font-size: 1.8rem;
    margin-right: 1rem;
    color: ${(p) => p.theme.colors.primary};
  }
  p {
    margin: 0;
  }
  span {
    display: block;
  }
`;

const TypeSelect = styled(Select)`
  margin: 0;
  height: 5rem;
`;

const StoreSelect = styled(MultiSelect)`
  .dropdown-heading {
    height: 4.8rem !important;
  }
`;

const ConditionsHeader = styled(TableHeader)`
  > div {
    width: 30%;
  }
`;

const Conditions = styled.div`
  width: 100%;
  position: relative;

  & + & {
    margin-top: 3rem;
  }

  h5 {
    border-top: 0;
    padding: 0 0 3rem;
  }
`;

const ConditionRow = styled(Row)`
  justify-content: space-between;
  padding: 0;
  padding-top: 3rem;
  ${MEDIA_MIN_LARGE} {
    padding: 0;
    padding-top: 3rem;
    min-height: 6rem;
  }
`;

const PlaceInput = styled.span`
  width: 100%;
  padding-left: 2rem;
`;

const getTypeExplanation = (type) => {
  switch (type) {
    case "PRODUCT":
      return "Discount on products in percentage within range";

    case "BOGO":
      return "Buy one get one in percentage";

    case "CATEGORY":
      return "Discount (in percent) that applies to products in certain categories";

    case "FIXED_PRICE":
      return "Discount on products in fixed amount";

    case "FILTER":
      return "Discount (in percent) that applies to products matching a set of rules";
    default:
      return type;
  }
};

const DEFAULT_TYPE = "PRODUCT";

export default ({ history }) => {
  const { setNotification } = useNotification();
  const {
    control,
    handleSubmit,
    formState: { errors },
    register,
    unregister,
    setValue,
  } = useForm();
  const [showUsageLimit, setShowUsageLimit] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [minItems, setMinItems] = useState(1);
  const [combine, setCombine] = useState(false);
  const [selectedStores, setSelectedStores] = useState([]);
  const [availableCurrencies, setAvailableCurrencies] = useState([]);
  const [discountToCreate, setDiscountToCreate] = useState({ type: DEFAULT_TYPE });
  const [selectedProducts, setSelectedProducts] = useState({
    products: [],
    expandedProducts: [],
  });
  const [excludedProducts, setExcludedProducts] = useState({
    products: [],
    expandedProducts: [],
  });
  const [excludedCategories, setExcludedCategories] = useState([
    { value: "Shipping", label: "Shipping" },
  ]);
  const [filterRules, setFilterRules] = useState([
    { mode: "INCLUDE", key: "", values: [], id: uuid() },
  ]);

  const getFriendlyTypeName = (name) =>
    name === "FIXED_PRICE" ? "fixed amount" : name.toLowerCase();

  const updateSelectedStores = (stores) => {
    updateDiscountToCreate({
      stores: stores.map((store) => ({
        countryCode: store.value,
        languageCode: store.languageCode,
      })),
    });
    setSelectedStores(stores);
    const currencies = stores
      .map((store) => store.currencyUnit)
      .filter(onlyUnique)
      .sort((a, b) => b.localeCompare(a));

    setAvailableCurrencies(currencies);
  };

  const onlyUnique = (value, index, self) => self.indexOf(value) === index;

  useEffect(() => {
    discountToCreate.type === "FIXED_PRICE" &&
      updateDiscountToCreate({
        discountInFixedPrice: availableCurrencies.map(
          (currencyUnit) =>
            discountToCreate.discountInFixedPrice?.find(
              (option) => option.currencyUnit === currencyUnit
            ) ?? {
              currencyUnit,
              amount: 0,
            }
        ),
        currencyOptions: availableCurrencies.map(
          (currencyUnit) =>
            discountToCreate.currencyOptions?.find(
              (option) => option.currencyUnit === currencyUnit
            ) ?? {
              currencyUnit,
              atLeastTotalPriceWithDiscount: 0,
            }
        ),
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableCurrencies]);

  const formatDiscount = (price) => Number(price * 100).toFixed(2) | 0;

  const updateDiscountToCreate = (newValue) =>
    setDiscountToCreate({ ...discountToCreate, ...newValue });

  const discountType = discountToCreate.type;

  useEffect(() => {
    discountToCreate.type === "FIXED_PRICE" && unregister("discountInPercentage");
  }, [discountToCreate.type, unregister]);

  return (
    <>
      <Breadcrumbs
        slugs={[
          ["admin/discounts", "Discounts"],
          ["admin/discount-codes", "Discount codes"],
          ["admin/add-discount-code", "Add discount codes"],
        ]}
      />
      <Header heading="Discount codes" />
      <PageContainer>
        <Sidebar />
        <GridContainer>
          <Query query={GET_DISCOUNT_TYPES}>
            {({ loading, error, data }) => {
              if (loading) return <Loader />;
              if (error)
                return (
                  <ErrorMessage>
                    An error occurred loading data, please contact support
                  </ErrorMessage>
                );

              const discountTypes = data.__type.enumValues
                .map((v) => v.name)
                .filter((n) => n !== "VOUCHER");

              return (
                <Query query={ALL_STORES} variables={{ from: 0, size: 300 }}>
                  {({ loading, error, data: allStoresData }) => {
                    if (loading) return <Loader />;
                    if (error)
                      return (
                        <ErrorMessage>
                          An error occurred loading data, please contact support
                        </ErrorMessage>
                      );

                    return (
                      <Mutation
                        mutation={ADD_DISCOUNT_CODE}
                        onCompleted={() => {
                          setNotification({
                            status: "success",
                            message: `Discount code successfully added`,
                          });
                          history.push("/admin/discount-codes");
                        }}
                        onError={(error) => {
                          setNotification({
                            status: "error",
                            message:
                              error.message === "Already exists"
                                ? "Active discount code already exists!"
                                : "An error occurred adding the discount code, please contact support",
                          });
                        }}>
                        {(addDiscount, { loading: addDiscountLoading }) => {
                          const stores =
                            allStoresData &&
                            allStoresData.allStores.stores.map((store) => ({
                              label: store.countryCode,
                              value: store.countryCode,
                              currencyUnit: store.currencyUnit,
                              languageCode: store.languageCode,
                            }));

                          const onSubmit = (data) => {
                            let typeAttribute = {};

                            if (discountType === "CATEGORY") {
                              typeAttribute = {
                                categories: selectedCategories.map((category) => category.value),
                                notForProducts: excludedProducts.products,
                                minItems: minItems,
                                combine: combine,
                              };
                            }

                            if (discountType === "PRODUCT") {
                              typeAttribute = {
                                validForProducts: selectedProducts.products,
                                minItems: minItems,
                                combine: combine,
                              };
                            }

                            if (discountType === "BOGO") {
                              typeAttribute = {
                                notForCategories: excludedCategories.map(
                                  (category) => category.value
                                ),
                                combine: combine,
                              };
                            }

                            if (discountType === "FIXED_PRICE") {
                              typeAttribute = {
                                discountInFixedPrice: discountToCreate.discountInFixedPrice.map(
                                  (option) => ({
                                    currencyUnit: option.currencyUnit,
                                    amount: formatDiscount(Number(option.amount)),
                                  })
                                ),
                                currencyOptions: discountToCreate.currencyOptions.map((option) => ({
                                  currencyUnit: option.currencyUnit,
                                  atLeastTotalPriceWithDiscount: formatDiscount(
                                    Number(option.atLeastTotalPriceWithDiscount)
                                  ),
                                })),
                              };
                            }
                            if (discountType === "FILTER") {
                              typeAttribute = {
                                filterRules: filterRules.map((filterRule) => ({
                                  mode: filterRule.mode,
                                  key: filterRule.key,
                                  values: filterRule.values,
                                })),
                                minItems: minItems,
                                combine: combine,
                              };
                            }

                            addDiscount({
                              variables: { ...discountToCreate, ...typeAttribute },
                            }).catch((e) => {
                              console.log(e);
                            });
                          };
                          const itemRenderer = ({ checked, option, onClick }) => (
                            <Label>
                              <input
                                type="checkbox"
                                onChange={onClick}
                                checked={checked}
                                tabIndex="-1"
                              />
                              <span>
                                <FlagIcon countryCode={option.value} />
                                <span>{option.label}</span>
                              </span>
                            </Label>
                          );
                          return (
                            <>
                              <GridItem columns="12">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                  <Box
                                    preHeading="Discount codes"
                                    heading="Add new discount code"
                                    subHeading="Discount code to be used by customers in checkout"
                                    showGoBackButton
                                    goBackOnClick={() => history.push("/admin/discount-codes")}>
                                    {addDiscountLoading && <Loader />}
                                    <ActionButtons inBox footerOnMobile>
                                      <ActionButton type="submit">
                                        <i className="fal fa-fw fa-check" />
                                        Save
                                      </ActionButton>
                                    </ActionButtons>
                                    <TypeContainer>
                                      <TypeLabel>Discount type</TypeLabel>
                                      <TypeSelect
                                        handleChange={(e) => {
                                          updateDiscountToCreate({ type: e.target.value });
                                        }}>
                                        {discountTypes.map((type) => (
                                          <option key={type} value={type}>
                                            {getFriendlyTypeName(type)}
                                          </option>
                                        ))}
                                      </TypeSelect>
                                    </TypeContainer>
                                    <RightContainer>
                                      <TextArea
                                        {...register("description")}
                                        onChange={(e) => {
                                          updateDiscountToCreate({ description: e.target.value });
                                          setValue("description", e.target.value);
                                        }}
                                        label="Description"
                                        errors={errors}
                                        placeholder="Description of the discount"
                                        value={discountToCreate.description || ""}
                                      />
                                    </RightContainer>
                                    <UsageLimit>
                                      <span>
                                        <UsageLimitCheck
                                          id="usageLimit"
                                          onChange={(e) => setShowUsageLimit(e.target.checked)}
                                        />
                                        <TypeLabel htmlFor="usageLimit">Usage limit</TypeLabel>
                                      </span>
                                      {showUsageLimit ? (
                                        <InputNew
                                          {...register("usageLimit", {
                                            min: {
                                              value: 1,
                                              message: "Needs to be a positive number",
                                            },
                                          })}
                                          onChange={(e) => {
                                            updateDiscountToCreate({
                                              usageLimit: e.target.value,
                                            });
                                            setValue("usageLimit", e.target.value);
                                          }}
                                          errors={errors}
                                          value={discountToCreate.usageLimit || ""}
                                          type="number"
                                          placeholder="The number of times a code can be used"
                                        />
                                      ) : (
                                        <InputNew
                                          disabled="disabled"
                                          placeholder="Unlimited"
                                          errors={errors}></InputNew>
                                      )}
                                    </UsageLimit>

                                    {!["FIXED_PRICE"].includes(discountType) && (
                                      <RightContainer>
                                        <span>
                                          <Toggle
                                            label="Combine with item discounts"
                                            data-tip="If this option is enabled, this discount code will stack with discounts on individual items"
                                            id="combine"
                                            handleToggle={() => setCombine(!combine)}
                                            active={combine}
                                          />
                                        </span>
                                      </RightContainer>
                                    )}

                                    <Container>
                                      <GridItem
                                        mobilePadding="0"
                                        desktopPadding="0 1.5rem 0 0"
                                        columns="6">
                                        <InputNew
                                          {...register("startDate", {
                                            required: "This is a required field",
                                            validate: (value) =>
                                              moment(value, "YYYY-MM-DD", true).isValid(),
                                          })}
                                          onChange={(e) => {
                                            updateDiscountToCreate({ startDate: e.target.value });
                                            setValue("startDate", e.target.value);
                                          }}
                                          type="date"
                                          label="Valid from:"
                                          icon="calendar-alt"
                                          errors={errors}
                                          value={discountToCreate.startDate || ""}
                                        />
                                        <InputNew
                                          {...register("code", {
                                            required: "This is a required field",
                                            pattern: {
                                              value: /^[A-Za-z0-9_.]+$/,
                                              message: "Only characters Aa-Zz and 0-9 are allowed",
                                            },
                                          })}
                                          onChange={(e) => {
                                            updateDiscountToCreate({ code: e.target.value });
                                            setValue("code", e.target.value);
                                          }}
                                          type="text"
                                          placeholder="Aa - Zz, 0 - 9"
                                          label="Discount code"
                                          icon="ticket"
                                          value={discountToCreate.code || ""}
                                          errors={errors}
                                        />
                                        {["PRODUCT", "CATEGORY", "FILTER"].includes(
                                          discountType
                                        ) && (
                                          <>
                                            <Tooltip />
                                            <InputNew
                                              {...register("minItems", {
                                                min: {
                                                  value: 1,
                                                  message: "Needs to be a positive number",
                                                },
                                              })}
                                              onChange={(e) => {
                                                setMinItems(e.target.value);
                                                setValue("minItems", e.target.value);
                                              }}
                                              label="Minimum items"
                                              icon="shopping-basket"
                                              errors={errors}
                                              type="number"
                                              placeholder="1"
                                              value={minItems || ""}
                                              data-tip="The minimum number of matching items in the cart required for the discount to apply"
                                            />
                                          </>
                                        )}
                                        {discountType === "CATEGORY" && (
                                          <CategoriesInput
                                            selected={selectedCategories}
                                            setSelected={setSelectedCategories}
                                          />
                                        )}
                                        {discountType === "BOGO" && (
                                          <CategoriesInput
                                            selected={excludedCategories}
                                            setSelected={setExcludedCategories}
                                            exclude={true}
                                          />
                                        )}
                                      </GridItem>
                                      <GridItem
                                        mobilePadding="0"
                                        desktopPadding="0 0 0 1.5rem"
                                        columns="6">
                                        <InputNew
                                          {...register("endDate", {
                                            required: "This is a required field",
                                            validate: (value) =>
                                              moment(value, "YYYY-MM-DD", true).isValid(),
                                          })}
                                          onChange={(e) => {
                                            updateDiscountToCreate({ endDate: e.target.value });
                                            setValue("endDate", e.target.value);
                                          }}
                                          type="date"
                                          label="Valid to:"
                                          errors={errors}
                                          value={discountToCreate.endDate || ""}
                                          icon="calendar-alt"
                                        />
                                        {discountType !== "FIXED_PRICE" && (
                                          <InputNew
                                            {...register("discountInPercentage", {
                                              required:
                                                discountType === "FIXED_PRICE"
                                                  ? false
                                                  : "This is a required field",
                                              min: {
                                                value: 0,
                                                message: "Needs to be a number between 0-100",
                                              },
                                              max: {
                                                value: 100,
                                                message: "Needs to be a number between 0-100",
                                              },
                                            })}
                                            onChange={(e) => {
                                              updateDiscountToCreate({
                                                discountInPercentage: e.target.value,
                                              });
                                              setValue("discountInPercentage", e.target.value);
                                            }}
                                            min="0"
                                            type="number"
                                            placeholder="0 - 100"
                                            errors={errors}
                                            label="Discount in percentage"
                                            icon="badge-percent"
                                            value={discountToCreate.discountInPercentage || ""}
                                          />
                                        )}
                                        <Controller
                                          control={control}
                                          name="stores"
                                          render={() => (
                                            <StoreSelect
                                              options={stores}
                                              label="Select stores"
                                              selected={selectedStores}
                                              setSelected={updateSelectedStores}
                                              ItemRenderer={itemRenderer}
                                              disableSearch={true}
                                              overrideStrings={{
                                                selectSomeItems: "No stores selected",
                                                selectAll: `Select all ${stores.length} stores`,
                                              }}
                                              rules={{ required: "This is a required field" }}
                                            />
                                          )}
                                        />
                                      </GridItem>
                                      <Info>
                                        <div>
                                          <i className="fal fa-info-circle"></i>
                                          <p>
                                            <span>{getTypeExplanation(discountType)}</span>
                                            {discountToCreate?.usageLimit > 0 && (
                                              <span>
                                                Discount code can be used{" "}
                                                {discountToCreate.usageLimit}
                                                {discountToCreate.usageLimit === "1"
                                                  ? " time "
                                                  : " times "}
                                                before it's unusable
                                              </span>
                                            )}
                                            {combine && (
                                              <span>
                                                Discount code combines with item discounts
                                              </span>
                                            )}
                                            {minItems > 1 && (
                                              <span>
                                                To be valid the cart needs to contain at least{" "}
                                                {minItems} products that are included in this
                                                discount code
                                              </span>
                                            )}
                                          </p>
                                        </div>
                                      </Info>
                                    </Container>
                                    {discountType === "FILTER" && (
                                      <FilterRules
                                        filterRules={filterRules}
                                        setFilterRules={setFilterRules}
                                        control={control}
                                      />
                                    )}
                                    <Container>
                                      {discountType === "FIXED_PRICE" &&
                                        availableCurrencies.length > 0 && (
                                          <Conditions>
                                            <Tooltip />
                                            <ConditionsHeader>
                                              <div>Currency code</div>
                                              <div>
                                                Discount amount *&nbsp;
                                                <i
                                                  className="fal fa-fw fa-info-circle"
                                                  data-tip="Only positive numbers, ex. 60"
                                                />
                                              </div>
                                              <div>
                                                Minimum order value *&nbsp;
                                                <i
                                                  className="fal fa-fw fa-info-circle"
                                                  data-tip="Only non-negative numbers, ex. 60"
                                                />
                                              </div>
                                            </ConditionsHeader>
                                            <Rows>
                                              {availableCurrencies
                                                ?.map((currencyUnit) => (
                                                  <ConditionRow key={currencyUnit}>
                                                    <RowLabel>{currencyUnit}</RowLabel>
                                                    <PlaceInput>
                                                      <AmountInput
                                                        {...register(
                                                          `discountInFixedPrice-${currencyUnit}`,
                                                          {
                                                            validate: {
                                                              positive: (v) =>
                                                                parseInt(v) > 0 ||
                                                                "Needs to be a positive number",
                                                            },
                                                          }
                                                        )}
                                                        onChange={(e) => {
                                                          setValue(
                                                            `discountInFixedPrice-${currencyUnit}`,
                                                            e.target.value
                                                          );
                                                          updateDiscountToCreate({
                                                            discountInFixedPrice:
                                                              discountToCreate.discountInFixedPrice.map(
                                                                (r) =>
                                                                  r.currencyUnit !== currencyUnit
                                                                    ? r
                                                                    : {
                                                                        currencyUnit:
                                                                          r.currencyUnit,
                                                                        amount: e.target.value,
                                                                      }
                                                              ),
                                                          });
                                                        }}
                                                        type="number"
                                                        placeholder=""
                                                        errors={errors}
                                                        defaultValue={0}
                                                      />
                                                    </PlaceInput>
                                                    <PlaceInput>
                                                      <AmountInput
                                                        {...register(
                                                          `atLeastTotalPriceWithDiscount-${currencyUnit}`,
                                                          {
                                                            min: {
                                                              value: 0,
                                                              message:
                                                                "Needs to be a non-negative number",
                                                            },
                                                          }
                                                        )}
                                                        onChange={(e) => {
                                                          setValue(
                                                            `atLeastTotalPriceWithDiscount-${currencyUnit}`,
                                                            e.target.value
                                                          );
                                                          updateDiscountToCreate({
                                                            currencyOptions:
                                                              discountToCreate.currencyOptions.map(
                                                                (r) =>
                                                                  r.currencyUnit !== currencyUnit
                                                                    ? r
                                                                    : {
                                                                        currencyUnit:
                                                                          r.currencyUnit,
                                                                        atLeastTotalPriceWithDiscount:
                                                                          e.target.value,
                                                                      }
                                                              ),
                                                          });
                                                        }}
                                                        type="number"
                                                        placeholder=""
                                                        errors={errors}
                                                        defaultValue={0}
                                                      />
                                                    </PlaceInput>
                                                  </ConditionRow>
                                                ))
                                                .reverse()}
                                            </Rows>
                                          </Conditions>
                                        )}
                                    </Container>
                                  </Box>
                                  <SaveBar history={history} />
                                </form>
                              </GridItem>
                              {discountType === "PRODUCT" && (
                                <DiscountProducts
                                  selectedProducts={selectedProducts}
                                  setSelectedProducts={setSelectedProducts}
                                />
                              )}
                              {discountType === "CATEGORY" && (
                                <DiscountProducts
                                  selectedProducts={excludedProducts}
                                  selectedCategories={selectedCategories}
                                  setSelectedProducts={setExcludedProducts}
                                  exclude={true}
                                  labelOverrides={{
                                    heading: "Exclude products",
                                    subHeading:
                                      "Select products that are excluded from the discount",
                                  }}
                                />
                              )}
                              {discountType === "FILTER" && (
                                <FilterProducts history={history} filterRules={filterRules} />
                              )}
                            </>
                          );
                        }}
                      </Mutation>
                    );
                  }}
                </Query>
              );
            }}
          </Query>
        </GridContainer>
      </PageContainer>
    </>
  );
};
