import React, { useState } from "react";
import styled from "styled-components/macro";
import { colors } from "../../variables/colors";
import { MEDIA_MIN_LARGE } from "../../variables/mediaQueries";
import { NavLink } from "react-router-dom";

const Container = styled.div`
  width: 100%;
  background: ${colors.greyOpac};
  display: flex;
  flex-wrap: wrap;
  border-right: 0.1rem solid ${(p) => p.theme.colors.whiteOpac};
  padding: 0 1.5rem 1.5rem;

  ${MEDIA_MIN_LARGE} {
    width: 35rem;
    min-height: 100%;
    padding: 3rem;
  }
`;

const Body = styled.div`
  align-self: flex-start;
  width: 100%;
`;

const MenuSection = styled.div`
  padding: 0;

  ${MEDIA_MIN_LARGE} {
    padding: 0 0 1rem 0;
  }
`;

const SectionTitle = styled.h5`
  text-transform: uppercase;
  font-size: 1.3rem;
  border-bottom: 0.1rem solid ${(p) => p.theme.colors.whiteOpac};
  padding-bottom: 2rem;
  padding-top: 1rem !important;
  font-weight: 700;
  letter-spacing: 0.05rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  i {
    transform: rotate(${(p) => (p.expanded ? "180deg" : "0deg")});
  }

  ${MEDIA_MIN_LARGE} {
    padding-bottom: 1rem;
    padding-top: 0 !important;

    i {
      display: none;
    }
  }
`;

const Menu = styled.ul`
  list-style: none;
  padding: 0;
  display: ${(p) => (p.expanded ? "block" : "none")};

  ${MEDIA_MIN_LARGE} {
    display: block;
  }
`;

const MenuItem = styled.li`
  a {
    width: 100%;
    padding: 1rem 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: ${(p) => p.theme.colors.primary1};
    padding-left: 1.2rem;
    background: ${(p) => (p.active ? p.theme.colors.greyOpac : "none")};
    border-left: 0.2rem solid ${(p) => (p.active ? p.theme.colors.primary : "transparent")};

    ${MEDIA_MIN_LARGE} {
    }
  }
`;

export default ({ sections, ...props }) => {
  const [openSection, setOpenSection] = useState(window.location.href);

  return (
    <Container {...props}>
      <Body>
        {sections.map((section) => {
          const expanded =
            openSection === section.title ||
            section.routes.find((r) => openSection.indexOf(r.path) !== -1);

          return (
            <MenuSection key={section.title}>
              <SectionTitle
                onClick={() =>
                  openSection === section.title ? setOpenSection("") : setOpenSection(section.title)
                }
                expanded={expanded}>
                {section.title}
                <i className="fal fa-chevron-down"></i>
              </SectionTitle>

              <Menu expanded={expanded}>
                {section.routes &&
                  section.routes.map((route) => (
                    <MenuItem
                      key={route.path}
                      active={window.location.href.indexOf(route.path) !== -1}>
                      <NavLink to={section.layout + route.path}>
                        <span>{route.name}</span>
                      </NavLink>
                    </MenuItem>
                  ))}
              </Menu>
            </MenuSection>
          );
        })}
      </Body>
    </Container>
  );
};
