import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { Mutation, Query } from "@apollo/client/react/components";
import { useForm } from "react-hook-form";
import moment from "moment/min/moment-with-locales";
import Moment from "react-moment";
import uuid from "react-uuid";
import DiscountOrders from "components/Discount/DiscountOrders";
import GET_DISCOUNT_CODE from "graphql/Discount/DiscountCode/GetDiscountCode";
import UPDATE_DISCOUNT_CODE from "graphql/Discount/DiscountCode/UpdateDiscountCode";
import ALL_STORES from "graphql/Store/AllStores";
import { useNotification } from "context/NotificationContext";
import { MEDIA_MIN_LARGE } from "variables/mediaQueries";
import PageContainer from "components/Page/PageContainer";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import Header from "components/Header/Header";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Box from "components/Content/Box";
import InputNew from "components/Ui/InputNew";
import TextArea from "components/Ui/TextArea";
import Loader from "components/Ui/Loader";
import MultiSelect from "components/Ui/MultiSelect";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import FlagIcon from "components/Ui/FlagIcon";
import ActionButtons from "components/ActionButtons/ActionButtons";
import ActionButton from "components/ActionButtons/ActionButton";
import DiscountProducts from "components/Discount/DiscountProducts";
import CategoriesInput from "components/Discount/CategoriesInput";
import Sidebar from "components/Discount/Sidebar";
import DeleteDiscountCode from "components/Discount/DeleteDiscountCode";
import Attributes from "components/AttributeList/AttributeList";
import Attribute from "components/AttributeList/Attribute";
import Label from "components/AttributeList/Label";
import Value from "components/AttributeList/Value";
import isDiscountCodeActive from "components/Discount/Helpers/isDiscountCodeActive";
import Tooltip from "components/Ui/Tooltip";
import Toggle from "components/Ui/Toggle";
import DuplicateDiscountCode from "components/Discount/DuplicateDiscountCode";
import FilterRules from "./FilterRules";
import FilterProducts from "./FilterProducts";
import TableHeader from "components/Table/Header";
import Row from "components/Table/Row";
import Rows from "components/Table/Rows";

const Container = styled(GridContainer)`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 2rem;
  padding-bottom: 3.5rem;

  ${MEDIA_MIN_LARGE} {
    padding-bottom: 0;
  }
`;

const CodeValue = styled(Value)`
  justify-content: flex-end;
`;

const TypeValue = styled(CodeValue)`
  text-transform: capitalize;
`;

const CodeAttributes = styled(Attributes)`
  margin: -1.5rem 0 2rem;
  display: flex;
  flex-wrap: wrap;
`;

const FormLabel = styled.div`
  display: flex;
  align-items: center;

  span {
    display: flex;
    align-items: center;
  }
`;

const Valid = styled.div`
  font-size: 2rem;

  .fa-check-circle {
    color: green;
  }

  .fa-times-circle {
    color: red;
  }
`;

const StyledMultiSelect = styled(MultiSelect)`
  .dropdown-heading {
    height: 4.8rem !important;
  }
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${MEDIA_MIN_LARGE} {
    width: 50%;
    padding-right: 1.5rem;
  }

  p {
    margin-top: 2rem;
    background: ${(p) => p.theme.colors.primary3};
    padding: 1.2rem 2rem 1rem;
    line-height: 2rem;
    border: 0.1rem solid ${(p) => p.theme.colors.primary2};

    i {
      font-size: 1.8rem;
      margin-right: 0.5rem;
      color: ${(p) => p.theme.colors.primary};
    }
  }
`;

const RightContainer = styled(LeftContainer)`
  ${MEDIA_MIN_LARGE} {
    padding-left: 1.5rem;
  }
`;

const AmountInput = styled(InputNew)`
  width: 40%;
  height: 3rem;
`;

const ConditionsHeader = styled(TableHeader)`
  > div {
    width: 30%;
  }
`;

const Conditions = styled.div`
  width: 100%;
  position: relative;

  & + & {
    margin-top: 3rem;
  }

  h5 {
    border-top: 0;
    padding: 0 0 3rem;
  }
`;

const RowLabel = styled.div`
  width: 100%;
  padding-bottom: 3rem;
  padding-left: 4rem;
`;

const ConditionRow = styled(Row)`
  justify-content: space-between;
  padding: 0;
  padding-top: 3rem;
  ${MEDIA_MIN_LARGE} {
    padding: 0;
    padding-top: 3rem;
    min-height: 6rem;
  }
`;

const PlaceInput = styled.span`
  width: 100%;
  padding-left: 2rem;
`;

const DISCOUNT_TYPE_CATEGORY = "CATEGORY";
const DISCOUNT_TYPE_PRODUCT = "PRODUCT";
const DISCOUNT_TYPE_BOGO = "BOGO";
const DISCOUNT_TYPE_FIXED_PRICE = "FIXED_PRICE";
const DISCOUNT_TYPE_FILTER = "FILTER";

export default (props) => {
  const { setNotification } = useNotification();
  const {
    control,
    handleSubmit,
    formState: { errors },
    register,
  } = useForm();
  const [discountCode, setDiscountCode] = useState("");
  const [id] = useState(props.match.params.id);
  const [discountToUpdate, setDiscountToUpdate] = useState({});
  const [availableCurrencies, setAvailableCurrencies] = useState([]);
  const [showOrderSection, setShowOrderSection] = useState(false);
  const [selectVariants, setSelectVariants] = useState(false);
  const [showNewDiscountDialog, setShowNewDiscountDialog] = useState(false);
  const [filterRules, setFilterRules] = useState([]);

  const formatDiscount = (price) => Number(price * 100).toFixed(2) | 0;
  const formatFromMinorUnits = (price) => Number(price / 100).toFixed(2) | 0;

  const updateDiscountToUpdate = (newValue) =>
    setDiscountToUpdate({ ...discountToUpdate, ...newValue });

  const getFriendlyTypeName = (name) =>
    name === "FIXED_PRICE" ? "Fixed amount" : name.toLowerCase();

  const onlyUnique = (value, index, self) => self.indexOf(value) === index;

  useEffect(() => {
    discountToUpdate.type === DISCOUNT_TYPE_FIXED_PRICE &&
      updateDiscountToUpdate({
        discountInFixedPrice: availableCurrencies.map(
          (currencyUnit) =>
            discountToUpdate.discountInFixedPrice?.find(
              (option) => option.currencyUnit === currencyUnit
            ) ?? {
              currencyUnit,
              amount: 0,
            }
        ),
        currencyOptions: availableCurrencies.map(
          (currencyUnit) =>
            discountToUpdate.currencyOptions?.find(
              (option) => option.currencyUnit === currencyUnit
            ) ?? {
              currencyUnit,
              atLeastTotalPriceWithDiscount: 0,
            }
        ),
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableCurrencies]);

  return (
    <>
      <Breadcrumbs
        slugs={[
          ["admin/discounts", "Discounts"],
          ["admin/discount-codes", "Discount codes"],
          [`admin/discount-code/${id}`, `${discountCode}`],
        ]}
      />
      <Header heading="Discount codes" />
      <PageContainer>
        <Sidebar />
        <GridContainer expanded>
          <Query
            query={GET_DISCOUNT_CODE}
            variables={{ id }}
            onCompleted={(data) => {
              setDiscountCode(data.getDiscount.code);
              setDiscountToUpdate({
                ...data.getDiscount,
                combine: data.getDiscount?.combine || false,
                discountInFixedPrice: data.getDiscount.discountInFixedPrice?.map((option) => ({
                  currencyUnit: option.currencyUnit,
                  amount: formatFromMinorUnits(option.amount),
                })),
                currencyOptions: data.getDiscount.currencyOptions?.map((option) => ({
                  currencyUnit: option.currencyUnit,
                  atLeastTotalPriceWithDiscount: formatFromMinorUnits(
                    option.atLeastTotalPriceWithDiscount
                  ),
                })),
              });

              setAvailableCurrencies(
                data.getDiscount.stores
                  .map((store) => store.currencyUnit)
                  .filter(onlyUnique)
                  .sort((a, b) => b.localeCompare(a))
              );
              setSelectVariants(
                data.getDiscount.vaildForProductVariants?.length > 0 ||
                  data.getDiscount.notForProductVariants?.length > 0
              );
              setFilterRules(
                data.getDiscount.filterRules.map((filterRule) => ({ ...filterRule, id: uuid() }))
              );
            }}>
            {({ loading, error, data }) => {
              if (loading) return <Loader />;
              if (error)
                return (
                  <ErrorMessage>
                    An error occurred loading data, please contact support
                  </ErrorMessage>
                );
              return (
                <Query
                  query={ALL_STORES}
                  variables={{ from: 0, size: 300 }}
                  onCompleted={(data) => {
                    if (data) setShowOrderSection(true);
                  }}>
                  {({ error, data: allStoresData }) => {
                    if (error)
                      return (
                        <ErrorMessage>
                          An error occurred loading data, please contact support
                        </ErrorMessage>
                      );
                    const discount = data.getDiscount;
                    return (
                      discount && (
                        <Mutation
                          mutation={UPDATE_DISCOUNT_CODE}
                          onCompleted={() => {
                            setNotification({
                              status: "success",
                              message: `Discount code successfully updated`,
                            });
                          }}
                          onError={() => {
                            setNotification({
                              status: "error",
                              message:
                                "An error occurred updating the discount code, please contact support",
                            });
                          }}>
                          {(updateDiscount, { loading: updateDiscountLoading }) => {
                            const storeOptions =
                              allStoresData &&
                              allStoresData.allStores.stores.map((store) => ({
                                label: store.countryCode,
                                value: store.countryCode,
                                currencyUnit: store.currencyUnit,
                                languageCode: store.languageCode,
                              }));

                            const stores = discount.stores.map((store) => ({
                              label: store.countryCode,
                              value: store.countryCode,
                              currencyUnit: store.currencyUnit,
                              languageCode: store.languageCode,
                            }));

                            const products =
                              discount.validForProducts?.length > 0
                                ? discount.validForProducts.map((product) => product.id)
                                : discount.vaildForProductVariants?.map((product) => product.id);

                            const notForProducts =
                              discount.notForProducts?.length > 0
                                ? discount.notForProducts.map((product) => product.id)
                                : discount.notForProductVariants?.map((product) => product.id);

                            const notForCategories = discount.notForCategories
                              ? discount.notForCategories.map((category) => ({
                                  value: category,
                                  label: category,
                                }))
                              : null;

                            const categories = discount.categories
                              ? discount.categories.map((category) => ({
                                  value: category,
                                  label: category,
                                }))
                              : null;

                            const [excludedProducts, setExcludedProducts] = useState({
                              products: notForProducts,
                              expandedProducts: [],
                            });
                            const [selectedProducts, setSelectedProducts] = useState({
                              products: products,
                              expandedProducts: [],
                            });
                            const [selectedStores, setSelectedStores] = useState(stores);
                            const [excludedCategories, setExcludedCategories] =
                              useState(notForCategories);
                            const [selectedCategories, setSelectedCategories] =
                              useState(categories);

                            const updateSelectedStores = (stores) => {
                              setSelectedStores(stores);
                              const currencies = stores
                                .map((store) => store.currencyUnit)
                                .filter(onlyUnique)
                                .sort((a, b) => b.localeCompare(a));

                              setAvailableCurrencies(currencies);
                            };

                            const onSubmit = (data) => {
                              let typeAttribute = {};

                              if (!discount.type || discount.type === DISCOUNT_TYPE_PRODUCT) {
                                const fieldName = selectVariants
                                  ? "vaildForProductVariants"
                                  : "validForProducts";
                                typeAttribute = {
                                  [fieldName]: selectedProducts.products,
                                };
                              }

                              if (discount.type === DISCOUNT_TYPE_CATEGORY) {
                                const fieldName = selectVariants
                                  ? "notForProductVariants"
                                  : "notForProducts";
                                typeAttribute = {
                                  categories: selectedCategories.map((category) => category.value),
                                  [fieldName]: excludedProducts.products,
                                };
                              }

                              if (discount.type === DISCOUNT_TYPE_BOGO) {
                                typeAttribute = {
                                  notForCategories: excludedCategories.map(
                                    (category) => category.value
                                  ),
                                };
                              }

                              if (discount.type === DISCOUNT_TYPE_FIXED_PRICE) {
                                typeAttribute = {
                                  discountInFixedPrice: discountToUpdate.discountInFixedPrice.map(
                                    (option) => ({
                                      currencyUnit: option.currencyUnit,
                                      amount: formatDiscount(option.amount),
                                    })
                                  ),
                                  currencyOptions: discountToUpdate.currencyOptions.map(
                                    (option) => ({
                                      currencyUnit: option.currencyUnit,
                                      atLeastTotalPriceWithDiscount: formatDiscount(
                                        option.atLeastTotalPriceWithDiscount
                                      ),
                                    })
                                  ),
                                };
                              }

                              if (discount.type === DISCOUNT_TYPE_FILTER) {
                                typeAttribute = {
                                  filterRules: filterRules.map((filterRule) => ({
                                    mode: filterRule.mode,
                                    key: filterRule.key,
                                    values: filterRule.values,
                                  })),
                                };
                              }

                              updateDiscount({
                                variables: {
                                  ...discountToUpdate,
                                  stores: selectedStores.map((store) => ({
                                    countryCode: store.value,
                                    languageCode: store.languageCode,
                                  })),
                                  ...typeAttribute,
                                },
                              }).catch((e) => {
                                console.log(e);
                              });
                            };

                            const itemRenderer = ({ checked, option, onClick }) => (
                              <FormLabel>
                                <input
                                  type="checkbox"
                                  onChange={onClick}
                                  checked={checked}
                                  tabIndex="-1"
                                />
                                <span>
                                  <FlagIcon countryCode={option.value} />
                                  <span>{option.label}</span>
                                </span>
                              </FormLabel>
                            );

                            return (
                              <>
                                <GridItem columns="12">
                                  <DuplicateDiscountCode
                                    id={discount.id}
                                    setShowDialog={setShowNewDiscountDialog}
                                    showDialog={showNewDiscountDialog}
                                    history={props.history}
                                  />
                                  <form onSubmit={handleSubmit(onSubmit)}>
                                    <Box
                                      preHeading="Discount code"
                                      heading={discount.code}
                                      subHeading={discount.id}
                                      showGoBackButton
                                      goBackOnClick={() =>
                                        props.history.push("/admin/discount-codes")
                                      }>
                                      {updateDiscountLoading && <Loader />}

                                      <ActionButtons inBox footerOnMobile>
                                        <ActionButton
                                          handleOnClick={() =>
                                            setShowNewDiscountDialog(!showNewDiscountDialog)
                                          }>
                                          <i className="fal fa-fw fa-copy" /> Duplicate
                                        </ActionButton>
                                        <DeleteDiscountCode
                                          id={discount.id}
                                          history={props.history}
                                        />
                                        <ActionButton type="submit">
                                          <i className="fal fa-fw fa-check" /> Save
                                        </ActionButton>
                                      </ActionButtons>
                                      <CodeAttributes>
                                        <GridItem
                                          mobilePadding="0"
                                          desktopPadding="0 1.5rem 0 0"
                                          columns="6">
                                          <Attribute>
                                            <Label>Active:</Label>
                                            <CodeValue>
                                              {isDiscountCodeActive(
                                                discount.startDate,
                                                discount.endDate,
                                                discount.usageCount,
                                                discount.usageLimit
                                              ) ? (
                                                <Valid>
                                                  <i className={"fal fa-check-circle"} />
                                                </Valid>
                                              ) : (
                                                <Valid>
                                                  <i className={"fal fa-times-circle"} />
                                                </Valid>
                                              )}
                                            </CodeValue>
                                          </Attribute>
                                          <Attribute>
                                            <Label>Discount code:</Label>
                                            <CodeValue>{discount.code}</CodeValue>
                                          </Attribute>
                                          <Attribute>
                                            <Label>Type:</Label>
                                            <TypeValue>
                                              {discount.type
                                                ? getFriendlyTypeName(discount.type)
                                                : "Product"}
                                            </TypeValue>
                                          </Attribute>
                                        </GridItem>
                                        <GridItem
                                          mobilePadding="0"
                                          desktopPadding="0 0 0 1.5rem"
                                          columns="6">
                                          <Attribute>
                                            <Label>Created:</Label>
                                            <CodeValue>
                                              <Moment format="YYYY-MM-DD HH:mm">
                                                {discount.created}
                                              </Moment>
                                            </CodeValue>
                                          </Attribute>
                                          <Attribute>
                                            <Label>Last updated:</Label>
                                            <CodeValue>
                                              <Moment format="YYYY-MM-DD HH:mm">
                                                {discount.lastUpdated}
                                              </Moment>
                                            </CodeValue>
                                          </Attribute>
                                          <Attribute>
                                            <Label>Usage count:</Label>
                                            <CodeValue>{discount.usageCount}</CodeValue>
                                          </Attribute>
                                        </GridItem>
                                      </CodeAttributes>
                                      <LeftContainer>
                                        <TextArea
                                          {...register("description")}
                                          onChange={(e) => {
                                            updateDiscountToUpdate({ description: e.target.value });
                                          }}
                                          label="Description"
                                          errors={errors}
                                          placeholder="Description of the discount"
                                          value={discountToUpdate.description || ""}
                                        />
                                      </LeftContainer>
                                      {!["FIXED_PRICE"].includes(discount.type) && (
                                        <RightContainer>
                                          <span>
                                            <Toggle
                                              label="Combine with item discounts"
                                              data-tip="If this option is enabled, this discount code will stack with discounts on individual items"
                                              id="combine"
                                              handleToggle={() =>
                                                updateDiscountToUpdate({
                                                  combine: !discountToUpdate.combine,
                                                })
                                              }
                                              active={discountToUpdate.combine}
                                            />
                                          </span>
                                        </RightContainer>
                                      )}
                                      <Container collapse padding="0">
                                        <GridItem
                                          mobilePadding="0"
                                          desktopPadding="0 1.5rem 0 0"
                                          columns="6">
                                          <InputNew
                                            {...register("startDate")}
                                            onChange={(e) => {
                                              updateDiscountToUpdate({
                                                startDate: e.target.value,
                                              });
                                            }}
                                            type="date"
                                            label="Valid from:"
                                            icon="calendar-alt"
                                            errors={errors}
                                            defaultValue={moment(discount.startDate).format(
                                              "YYYY-MM-DD"
                                            )}
                                            value={discountToUpdate?.startDate}
                                          />
                                          <InputNew
                                            {...register("usageLimit")}
                                            onChange={(e) => {
                                              updateDiscountToUpdate({
                                                usageLimit: e.target.value,
                                              });
                                            }}
                                            type="number"
                                            label="Usage limit"
                                            min="1"
                                            placeholder={
                                              discountToUpdate?.usageLimit || "Unlimited"
                                            }
                                            errors={errors}
                                            value={discountToUpdate?.usageLimit || undefined}
                                          />
                                          {discount.type !== DISCOUNT_TYPE_FIXED_PRICE && (
                                            <InputNew
                                              {...register("discountInPercentage")}
                                              onChange={(e) => {
                                                updateDiscountToUpdate({
                                                  discountInPercentage: e.target.value,
                                                });
                                              }}
                                              type="number"
                                              label="Discount in percentage"
                                              min="0"
                                              max="100"
                                              placeholder={discountToUpdate?.discountInPercentage}
                                              icon="badge-percent"
                                              errors={errors}
                                              value={discountToUpdate?.discountInPercentage}
                                              data-tip="The discount in percentage"
                                            />
                                          )}
                                        </GridItem>
                                        <GridItem
                                          mobilePadding="0"
                                          desktopPadding="0 0 0 1.5rem"
                                          columns="6">
                                          <InputNew
                                            {...register("endDate")}
                                            onChange={(e) => {
                                              updateDiscountToUpdate({
                                                endDate: e.target.value,
                                              });
                                            }}
                                            type="date"
                                            label="Valid to:"
                                            icon="calendar-alt"
                                            errors={errors}
                                            defaultValue={moment(discount.endDate).format(
                                              "YYYY-MM-DD"
                                            )}
                                            value={discountToUpdate?.endDate}
                                          />
                                          <StyledMultiSelect
                                            options={storeOptions}
                                            label="Select stores"
                                            selected={selectedStores}
                                            setSelected={updateSelectedStores}
                                            ItemRenderer={itemRenderer}
                                            disableSearch={true}
                                            overrideStrings={{
                                              selectSomeItems: "No stores selected",
                                              selectAll: `Select all ${storeOptions?.length} stores`,
                                            }}
                                          />

                                          {["PRODUCT", "CATEGORY", "FILTER"].includes(
                                            discount.type
                                          ) && (
                                            <>
                                              <Tooltip />
                                              <InputNew
                                                {...register("minItems")}
                                                onChange={(e) => {
                                                  updateDiscountToUpdate({
                                                    minItems: e.target.value,
                                                  });
                                                }}
                                                type="number"
                                                label="Minimum items"
                                                min="1"
                                                placeholder={discountToUpdate?.minItems || 1}
                                                icon="shopping-basket"
                                                errors={errors}
                                                value={discountToUpdate.minItems || 1}
                                                data-tip="The minimum number of matching items in the cart required for the discount to apply"
                                              />
                                            </>
                                          )}
                                          {discount.type === DISCOUNT_TYPE_CATEGORY && (
                                            <CategoriesInput
                                              selected={selectedCategories}
                                              setSelected={setSelectedCategories}
                                            />
                                          )}
                                          {discount.type === DISCOUNT_TYPE_BOGO && (
                                            <CategoriesInput
                                              selected={excludedCategories}
                                              setSelected={setExcludedCategories}
                                              exclude={true}
                                            />
                                          )}
                                        </GridItem>
                                      </Container>
                                      {discount.type === DISCOUNT_TYPE_FILTER && (
                                        <FilterRules
                                          filterRules={filterRules}
                                          setFilterRules={setFilterRules}
                                          control={control}
                                        />
                                      )}
                                      <Container>
                                        {discount.type === DISCOUNT_TYPE_FIXED_PRICE &&
                                          availableCurrencies.length > 0 && (
                                            <Conditions>
                                              <Tooltip />
                                              <ConditionsHeader>
                                                <div>Currency code</div>
                                                <div>
                                                  Discount amount *&nbsp;
                                                  <i
                                                    className="fal fa-fw fa-info-circle"
                                                    data-tip="Only positive numbers, ex. 60"
                                                  />
                                                </div>
                                                <div>
                                                  Minimum order value *&nbsp;
                                                  <i
                                                    className="fal fa-fw fa-info-circle"
                                                    data-tip="Only non-negative numbers, ex. 60"
                                                  />
                                                </div>
                                              </ConditionsHeader>
                                              <Rows>
                                                {availableCurrencies
                                                  ?.map((currencyUnit) => (
                                                    <ConditionRow key={currencyUnit}>
                                                      <RowLabel>{currencyUnit}</RowLabel>
                                                      <PlaceInput>
                                                        <AmountInput
                                                          {...register(
                                                            `discountInFixedPrice-${currencyUnit}`,
                                                            {
                                                              min: {
                                                                value: 1,
                                                                message:
                                                                  "Needs to be a positive number",
                                                              },
                                                            }
                                                          )}
                                                          onChange={(e) => {
                                                            updateDiscountToUpdate({
                                                              discountInFixedPrice:
                                                                discountToUpdate.discountInFixedPrice.map(
                                                                  (r) =>
                                                                    r.currencyUnit !== currencyUnit
                                                                      ? r
                                                                      : {
                                                                          currencyUnit:
                                                                            r.currencyUnit,
                                                                          amount: e.target.value,
                                                                        }
                                                                ),
                                                            });
                                                          }}
                                                          type="number"
                                                          placeholder=""
                                                          errors={errors}
                                                          defaultValue={
                                                            discountToUpdate.discountInFixedPrice?.find(
                                                              (discount) =>
                                                                discount.currencyUnit ===
                                                                currencyUnit
                                                            )?.amount || 0
                                                          }
                                                        />
                                                      </PlaceInput>
                                                      <PlaceInput>
                                                        <AmountInput
                                                          {...register(
                                                            `atLeastTotalPriceWithDiscount-${currencyUnit}`,
                                                            {
                                                              min: {
                                                                value: 0,
                                                                message:
                                                                  "Needs to be a non-negative number",
                                                              },
                                                            }
                                                          )}
                                                          onChange={(e) => {
                                                            updateDiscountToUpdate({
                                                              currencyOptions:
                                                                discountToUpdate.currencyOptions.map(
                                                                  (r) =>
                                                                    r.currencyUnit !== currencyUnit
                                                                      ? r
                                                                      : {
                                                                          currencyUnit:
                                                                            r.currencyUnit,
                                                                          atLeastTotalPriceWithDiscount:
                                                                            e.target.value,
                                                                        }
                                                                ),
                                                            });
                                                          }}
                                                          type="number"
                                                          errors={errors}
                                                          defaultValue={
                                                            discountToUpdate.currencyOptions?.find(
                                                              (threshold) =>
                                                                threshold.currencyUnit ===
                                                                currencyUnit
                                                            )?.atLeastTotalPriceWithDiscount || 0
                                                          }
                                                        />
                                                      </PlaceInput>
                                                    </ConditionRow>
                                                  ))
                                                  .reverse()}
                                              </Rows>
                                            </Conditions>
                                          )}
                                      </Container>
                                    </Box>
                                  </form>
                                </GridItem>
                                {(discount.type === DISCOUNT_TYPE_PRODUCT || !discount.type) && (
                                  <DiscountProducts
                                    selectedProducts={selectedProducts}
                                    setSelectedProducts={setSelectedProducts}
                                    selectVariants={selectVariants}
                                  />
                                )}
                                {discount.type === DISCOUNT_TYPE_CATEGORY && (
                                  <DiscountProducts
                                    selectedProducts={excludedProducts}
                                    setSelectedProducts={setExcludedProducts}
                                    selectedCategories={selectedCategories}
                                    selectVariants={selectVariants}
                                    exclude={true}
                                    labelOverrides={{
                                      heading: "Exclude products",
                                      subHeading:
                                        "Select products that are excluded from the discount",
                                    }}
                                  />
                                )}

                                {discount.type === DISCOUNT_TYPE_FILTER && (
                                  <FilterProducts
                                    history={props.history}
                                    filterRules={filterRules}
                                  />
                                )}
                              </>
                            );
                          }}
                        </Mutation>
                      )
                    );
                  }}
                </Query>
              );
            }}
          </Query>
          {showOrderSection && (
            <DiscountOrders discountId={id} discountCode={discountCode} history={props.history} />
          )}
        </GridContainer>
      </PageContainer>
    </>
  );
};
