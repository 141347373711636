import gql from "graphql-tag";

const UPDATE_DISCOUNT_CODE = gql`
  mutation updateDiscount(
    $id: ID!
    $discountInPercentage: Int
    $discountInFixedPrice: [InputMoney]
    $usageLimit: Int
    $description: String
    $startDate: AWSDate!
    $endDate: AWSDate!
    $stores: [InputDiscountStore]
    $validForProducts: [String]
    $vaildForProductVariants: [String]
    $notForProducts: [String]
    $notForProductVariants: [String]
    $notForCategories: [String]
    $categories: [String]
    $minItems: Int
    $combine: Boolean
    $filterRules: [InputFilterRule!]
    $currencyOptions: [InputCurrencyOption!]
  ) {
    updateDiscount(
      updateDiscount: {
        id: $id
        discountInPercentage: $discountInPercentage
        discountInFixedPrice: $discountInFixedPrice
        usageLimit: $usageLimit
        description: $description
        startDate: $startDate
        endDate: $endDate
        stores: $stores
        validForProducts: $validForProducts
        vaildForProductVariants: $vaildForProductVariants
        notForProducts: $notForProducts
        notForProductVariants: $notForProductVariants
        notForCategories: $notForCategories
        categories: $categories
        minItems: $minItems
        combine: $combine
        filterRules: $filterRules
        currencyOptions: $currencyOptions
      }
    ) {
      id
      code
      startDate
      endDate
      stores {
        countryCode
      }
      notForCategories
      discountInPercentage
      discountInFixedPrice {
        amount
        currencyUnit
      }
      categories
      description
      usageLimit
      created
      lastUpdated
      minItems
      combine
      filterRules {
        mode
        key
        values
      }
      currencyOptions {
        atLeastTotalPriceWithDiscount
        currencyUnit
      }
    }
  }
`;

export default UPDATE_DISCOUNT_CODE;
