import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { colors } from "variables/colors";
import { MEDIA_MIN_MEDIUM } from "variables/mediaQueries";
import underline from "images/underline.svg";
import HoM from "components/Ui/HoM";
import { useOktaAuth } from "@okta/okta-react";
import { useAdminContext } from "context/AdminContext";

const User = styled.div`
  position: absolute;
  top: 1rem;
  right: 0;
  display: flex;
  align-items: center;
  z-index: 1;

  ${MEDIA_MIN_MEDIUM} {
    top: 3rem;
    right: 2rem;
  }
`;

const UserIcon = styled.div`
  width: 4rem;
  height: 4rem;
  border-radius: 2rem;
  background: ${colors.gradient};
  color: ${colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 700;
  margin: 0 1rem 0 1rem;
  border: 0.3rem solid white;
  box-shadow: 0 0.2rem 0.8rem 0 rgb(0 0 0 / 15%);
  transition: all 0.2s;
  cursor: pointer;

  ${MEDIA_MIN_MEDIUM} {
    width: 5rem;
    height: 5rem;
    border-radius: 2.5rem;
    font-size: 2rem;
  }

  &:hover {
    box-shadow: 0 0.2rem 1.2rem 0 rgb(0 0 0 / 35%);
  }
`;

const UserInfo = styled.div`
  position: absolute;
  background: ${(p) => p.theme.colors.black};
  padding: 2rem 3rem;
  z-index: 1;
  right: 1rem;
  top: calc(100% + 1rem);
  text-align: right;
  box-shadow: 0 2px 16px 0 rgb(0 0 0 / 8%);

  span {
    display: block;
    padding: 0.5rem 0;
  }
`;

const Logout = styled.div`
  cursor: pointer;
  display: flex;
  border-top: 0.1rem solid ${(p) => p.theme.colors.whiteOpac};
  padding-top: 2rem;
  margin-top: 2rem;
  justify-content: flex-end;
  align-items: center;
  text-transform: uppercase;

  i {
    margin-right: 1rem;
    color: ${(p) => p.theme.colors.primary};
    font-size: 2rem;
    transition: all 0.2s;
  }

  &:hover {
    i {
      margin-right: 1.5rem;
    }
  }
`;

const Name = styled.span`
  padding: 0 0.5rem 0.5rem;
  background-image: url(${underline});
  background-repeat: no-repeat;
  background-position: bottom center;
`;

export default React.memo(({ simplified, ...props }) => {
  const { oktaAuth, authState } = useOktaAuth();

  const [showUserInfo, setShowUserInfo] = useState(false);
  const [logout, setLogout] = useState(null);
  const [user, setUser] = useState(null);

  const { setUser: setContextUser } = useAdminContext();

  useEffect(() => {
    async function getAuth() {
      const oktaUser = await oktaAuth.getUser();
      setUser(oktaUser);
      setContextUser(oktaUser)
      setLogout(
        () => () => oktaAuth.signOut({ postLogoutRedirectUri: `${window.location.origin}/` })
      );
    }

    getAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState]);

  if (!user) return null;
  return simplified ? (
    <Name>{user.name.replace(/(\S+)\s.*/, "$1")}</Name>
  ) : (
    <User {...props}>
      <HoM>
        <strong>{user.name}</strong>
      </HoM>
      <UserIcon onClick={() => setShowUserInfo(!showUserInfo)}>
        {`${user.name.replace(/(\S).*\s(\S).*/, "$1$2") || user.name.charAt(0)}`}
      </UserIcon>
      {showUserInfo && (
        <UserInfo>
          <span>
            <strong>{user.name}</strong>
          </span>
          <span>{user.email}</span>
          <Logout onClick={logout}>
            <i className="fal fa-sign-out"></i>
            <span> Sign out</span>
          </Logout>
        </UserInfo>
      )}
    </User>
  );
});
