import React, { useState, useEffect } from "react";
import styled from "styled-components";
import ScrollTo from "react-scroll-into-view";

const SaveBar = styled.div`
  box-shadow: 0 0.2rem 1.6rem 0 rgb(0 0 0 / 8%);
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  background: rgba(255, 255, 255, 0.75);
  width: 100%;
  height: 7rem;
  align-items: center;
  justify-content: flex-end;
  z-index: 10;
`;

const Button = styled.button`
  height: 4rem;
  width: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  padding: 0;
  transition: opacity 0.2s;
  background: ${(p) => p.theme.colors.primaryOpac};
  color: ${(p) => p.theme.colors.white};
  font-size: 2rem;
  margin-right: 1.5rem;

  &:hover {
    opacity: 0.8;
  }
`;

const TextButton = styled(Button)`
  width: 10rem;
  font-size: 1.5rem;

  i {
    padding-right: 1rem;
  }
`;

export default ({ history }) => {
  const [sticky, setSticky] = useState(false);

  const stick = () => {
    const currentPos = window.pageYOffset;
    const offset = 220;
    setSticky(currentPos > offset);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(stick, []);

  useEffect(() => {
    function watchScroll() {
      window.addEventListener("scroll", stick);
    }
    watchScroll();
    return () => {
      window.removeEventListener("scroll", stick);
    };
  });

  return sticky ? (
    <SaveBar>
      {history && (
        <TextButton onClick={() => history.goBack()}>
          <i className="fal fa-window-close"></i>
          Cancel
        </TextButton>
      )}
      <TextButton type="submit">
        <i className="fal fa-save"></i>
        Save
      </TextButton>
      <ScrollTo selector=".top">
        <Button aria-label="Go to top of page">
          <i className="fal fa-long-arrow-up"></i>
        </Button>
      </ScrollTo>
    </SaveBar>
  ) : (
    <></>
  );
};
