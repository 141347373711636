import React from "react";
import { useQuery } from "@apollo/client";

import ORDERS_STATISTICS_BY_DATE from "graphql/OrdersStatisticsByDate";

import Box from "components/Dashboard/Box";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Loader from "components/Ui/Loader";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import moment from "moment/min/moment-with-locales";
import Map from "components/Map/WorldMap";
import CountryList from "components/Lists/CountryList";

const toData = (stats, timeRange, stores) => {
  const mappedStats = stats.map((s) => {
    return {
      store: s.store,
      orderCount: timeRange.map((d) => {
        const stat = s.sales.find((s) => s.keyAsString === d);
        if (stat) {
          return {
            key: d,
            value: stat.orderCount,
          };
        } else {
          return {
            key: d,
            value: 0,
          };
        }
      }),
    };
  });

  stores.forEach((store) => {
    if (!mappedStats.find((s) => s.store === store.countryCode))
      mappedStats.push({
        store: store.countryCode,
        orderCount: timeRange.map((r) => ({ key: r, value: 0 })),
      });
  });
  return mappedStats;
};

const getTimeRange = (startDateTime, endDateTime, type, format) => {
  const from = moment(startDateTime);
  const to = moment(endDateTime);
  return [...Array(to.diff(from, type)).keys()].map((i) =>
    moment(startDateTime).add(i, type).format(format)
  );
};

export default ({ stores, selectedStores, orderStatus, period }) => {
  const obj = Object.assign(
    {},
    ...["current"].map((key) => {
      const timeRange = getTimeRange(
        period[key].startDate,
        period[key].endDate,
        period.type,
        period.format
      );

      const { loading, error, data } = useQuery(ORDERS_STATISTICS_BY_DATE, {
        variables: {
          startDateTime: period[key].startDate,
          endDateTime: period[key].endDate,
          interval: period.interval,
          status: orderStatus,
        },
      });

      if (loading) return { [key]: { loading } };
      if (error) return { [key]: { error } };
      if (data) {
        const diagramData = toData(data.ordersStatisticsByDate, timeRange, stores);
        let mapData;
        mapData = Object.assign(
          {},
          ...diagramData.map((d) => ({
            [d.store]: d.orderCount.reduce((a, b) => a + b.value, 0),
          }))
        );

        const diagramDataForStore = diagramData
          .filter((d) => selectedStores.includes(d.store))
          .map((d) => d.orderCount)
          .flat()
          .reduce((acc, curr) => {
            const item = acc.find((i) => i.key === curr.key);
            item ? (item.value += curr.value) : acc.push(curr);
            return acc;
          }, []);
        const diagramDataAmount = diagramDataForStore.map((d) => d.value);
        return {
          [key]: {
            range: timeRange,
            data: mapData || [],
            amounts: diagramDataAmount,
            sum: diagramDataAmount.reduce((a, b) => a + b, 0),
          },
        };
      }
      return null;
    })
  );

  if (obj.current.loading) return <Loader />;
  if (obj.current.error)
    return <ErrorMessage>An error occured loading data, please contact support</ErrorMessage>;
  if (!obj.current) return null;

  return (
    <>
      <GridItem columns="12" padding="0">
        <Box
          preHeading="Total orders"
          heading={`# ${obj.current.sum}`}
          headingIcon="shopping-cart"
          currentPeriodSum={obj.current.sum}
          primaryLabel={`${period.current.startDate.format(
            "YYYY-MM-DD"
          )} - ${period.current.endDate.format("YYYY-MM-DD")}`}
          labelPos="topRight"
          footer={period.label}>
          <GridContainer padding="0" collapse>
            <GridItem columns="7" padding="0">
              <Map mapData={obj.current.data} />
            </GridItem>
            <GridItem columns="5" padding="0">
              <CountryList obj={obj} />
            </GridItem>
          </GridContainer>
        </Box>
      </GridItem>
    </>
  );
};
