import React from "react";
import styled from "styled-components/macro";
import { MEDIA_MIN_MEDIUM } from "variables/mediaQueries";
import Tooltip from "components/Ui/Tooltip";

const OrderStatus = styled.div`
  display: inline-flex;
  align-items: center;

  i {
    font-size: 1.8rem;
    margin-right: 0.8rem;

    ${MEDIA_MIN_MEDIUM} {
      font-size: 2.4rem;
    }
  }

  .fa-check-circle,
  .fa-box-check,
  .fa-shipping-fast {
    color: green;
  }

  .fa-clock,
  .fa-hourglass-half,
  .fa-exchange,
  .fa-minus-circle {
    color: orange;
  }

  .fa-exclamation-circle,
  .fa-times-circle {
    color: red;
  }

  .fa-box-check,
  .fa-undo,
  .fa-hourglass-half {
    font-size: 1.4rem;

    ${MEDIA_MIN_MEDIUM} {
      font-size: 2rem;
    }
  }
`;

const formatStatus = (status) => {
  switch (status) {
    case "created":
      return {
        title: "Created",
        icon: <i className="fal fa-fw fa-clock"></i>,
        tooltip: "Order has been created",
      };
    case "success":
      return {
        title: "Successful payment",
        icon: <i className="fal fa-fw fa-check-circle"></i>,
        tooltip: "Order has been succesfully paid",
      };

    case "cancelRequested":
      return {
        title: "Cancellation requested",
        icon: <i className="fal fa-fw fa-minus-circle"></i>,
        tooltip: "Order has been requested for cancellation",
      };
    case "canceled":
      return {
        title: "Canceled",
        icon: <i className="fal fa-fw fa-times-circle"></i>,
        tooltip: "Order has been canceled",
      };

    case "refundRequested":
      return {
        title: "Refund requested",
        icon: <i className="fal fa-fw fa-exchange"></i>,
        tooltip: "Order has been requested for a refund",
      };
    case "refunded":
      return {
        title: "Refunded",
        icon: <i className="fal fa-fw fa-undo"></i>,
        tooltip: "Order has been refunded",
      };

    case "waiting":
      return {
        title: "Waiting for payment provider",
        icon: <i className="fal fa-fw fa-hourglass-half"></i>,
        tooltip: "Order is waiting for payment provider",
      };

    case "failed":
      return {
        title: "Failed",
        icon: <i className="fal fa-fw fa-exclamation-circle"></i>,
        tooltip: "Order failed",
      };

    case "shipped":
      return {
        title: "Shipped",
        icon: <i className="fal fa-fw fa-box-check"></i>,
        tooltip: "Order has been shipped",
      };

    default:
      return {
        title: "Unknown, contact support",
        icon: <i className="fal fa-question-circle"></i>,
        tooltip: "Order is in uknown state, contact support",
      };
  }
};

export default ({ status, showTitle, showIcon, useTooltipAsTitle, iconOnlyMobile }) => {
  const { icon, tooltip, title } = formatStatus(status);

  return (
    <OrderStatus data-tip={tooltip}>
      <Tooltip />
      {showIcon && icon} {!iconOnlyMobile && showTitle && !useTooltipAsTitle && title}
      {!iconOnlyMobile && useTooltipAsTitle && tooltip}
    </OrderStatus>
  );
};
