import React from "react";
import styled from "styled-components/macro";
import { ErrorMessage } from "@hookform/error-message";
import { colors, alerts } from "variables/colors";
import { MEDIA_MIN_MEDIUM } from "variables/mediaQueries";

const InputContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: ${(p) => (p.hasErrorText && !p.relativeError ? "3rem" : "0")};
`;

const Input = styled.input`
  width: 100%;
  padding: 1.6rem 1.5rem;
  padding-left: ${(p) => (p.icon ? "4rem" : "1.5rem")};
  border-radius: 0.3rem;
  color: ${(p) => (p.disabled ? p.theme.colors.disabledText : colors.black)};
  border: 0.1rem solid ${(p) => p.theme.colors.inputBorderColor};
  background: ${(p) => p.theme.colors.inputBackgroundColor};
  height: 5rem;
  appearance: none;

  ${MEDIA_MIN_MEDIUM} {
    padding: 1.6rem 2rem;
    padding-left: ${(p) => (p.icon ? "5rem" : "2rem")};
  }
`;

const Icon = styled.div`
  position: absolute;
  color: ${(p) => p.theme.colors.white};
  font-size: 1.6rem;
  margin-left: 1.5rem;
  top: ${(p) => (p.hasLabel ? "4.3rem" : "1.7rem")};

  ${MEDIA_MIN_MEDIUM} {
    font-size: 2rem;
    margin-left: 2rem;
    top: ${(p) => (p.hasLabel ? "4.5rem" : "1.6rem")};
  }
`;

const Label = styled.label`
  width: 100%;
  padding-bottom: 0.8rem;
  display: flex;
  align-items: center;
  font-weight: 700;
`;

const ErrorText = styled.span`
  width: 100%;
  color: ${alerts.error.highlight};
  position: ${(p) => (p.relativeError ? "relative" : "absolute")};
  bottom: 0;
  letter-spacing: 0;
`;

const ignoreScroll = (e) => {
  e.target.blur();
};

const InputNew = ({
  error = false,
  errorText,
  errors,
  name,
  label,
  type,
  placeholder,
  value,
  icon,
  onChange,
  min,
  max,
  relativeError,
  disabled,
  ...props
}) => (
  <InputContainer hasErrorText={errorText || errors} relativeError={relativeError}>
    {label && <Label>{label}</Label>}
    {icon && (
      <Icon hasLabel={label}>
        <i className={`fal fa-${icon}`}></i>
      </Icon>
    )}
    <Input
      onChange={(e) => {
        e.persist();
        return onChange && onChange(e);
      }}
      disabled={disabled}
      name={name}
      icon={icon}
      type={type}
      placeholder={placeholder}
      value={value}
      min={min}
      max={max}
      onWheel={ignoreScroll}
      {...props}
    />
    {errors && (
      <ErrorText relativeError={relativeError}>
        <ErrorMessage errors={errors} name={name} />
      </ErrorText>
    )}
    {error && <ErrorText>{errorText}</ErrorText>}
  </InputContainer>
);

export default React.forwardRef(InputNew);
