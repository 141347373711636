import React, { useState, useEffect } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { ApolloClientConfig } from "./ApolloClientConfig";

const OktaConfig = ({ children }) => {
  const { oktaAuth, authState } = useOktaAuth();
  const [authToken, setAuthToken] = useState();

  useEffect(() => {
    async function getAuthToken() {
      if (authState.isAuthenticated) {
        const oktaToken = await oktaAuth.getIdToken();
        setAuthToken(oktaToken);
      } else {
        oktaAuth.signInWithRedirect();
      }
    }

    getAuthToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState]);

  return <ApolloClientConfig children={children} authToken={authToken} />;
};

export default OktaConfig;
