export default function isRefundable(order) {
  const isSuccess = order.status === "success";
  const isKlarna = (order.paymentMethod || "").toLowerCase().startsWith("klarna");
  const isShipped = order.status === "shipped";
  const isRefunded = order.status === "refunded";
  const physicalLines = order.orderLines.filter((o) => o.type === 'physical')
  const refundlLines = order.orderLines.filter((o) => o.type === 'refund')
  const refundLines = physicalLines.length !== refundlLines.length

  return ((isSuccess && !isKlarna) || isShipped || isRefunded) && refundLines;
}
