import React, { useState } from "react";
import { Mutation } from "@apollo/client/react/components";
import { useForm, Controller } from "react-hook-form-old";
import { useNotification } from "context/NotificationContext";
import ActionButtons from "../ActionButtons/ActionButtons";
import ActionButton from "../ActionButtons/ActionButton";
import Dialog from "../Dialog/Dialog";
import Input from "../Ui/Input";
import Loader from "../Ui/Loader";
import ADD_POST_PURCHASE_DISCOUNT from "graphql/Order/AddPostPurchaseDiscount";
import isRefundable from "helpers/isRefundable";

export default function AddPostPurchaseDiscount({ order, refetch }) {
  const [dialog, setDialog] = useState(false);
  const { control, handleSubmit, errors } = useForm();
  const { setNotification } = useNotification();

  if (!order) return null;
  if (!isRefundable(order)) return null;
  if (order.customerAttribute.postPurchaseDiscount) return null;

  const openDialog = setDialog.bind(this, true);
  const closeDialog = setDialog.bind(this, false);

  return (
    <>
      <ActionButtons inBox>
        <ActionButton handleOnClick={openDialog}>Add discount</ActionButton>
      </ActionButtons>
      <Mutation mutation={ADD_POST_PURCHASE_DISCOUNT} refetchQueries={refetch}>
        {(addPostPurchaseDiscount, { loading }) => {
          function onSubmit({ discountInPercentage }) {
            addPostPurchaseDiscount({
              variables: {
                discountOrder: {
                  id: order.id,
                  discountInPercentage: parseInt(discountInPercentage, 10),
                },
              },
            })
              .then(() => {
                setNotification({
                  status: "success",
                  message: `Discount successfully added`,
                });
              })
              .then(closeDialog);
          }
          return (
            <Dialog
              open={dialog}
              header="Add discount"
              text="Add discount to all order lines without prior discount"
              handleClose={closeDialog}
              handleOk={handleSubmit(onSubmit)}>
              {loading && <Loader />}
              <Controller
                as={Input}
                control={control}
                rules={{
                  required: "This is a required field",
                  min: {
                    value: 1,
                    message: "Needs to be a number between 1-99",
                  },
                  max: {
                    value: 99,
                    message: "Needs to be a number between 1-99",
                  },
                  pattern: {
                    value: /^[\d]+$/,
                    message: "Only characters 0-9 are allowed",
                  },
                }}
                type="number"
                name="discountInPercentage"
                placeholder="1 - 99"
                label={<strong>Discount in percentage:</strong>}
                icon="badge-percent"
                errors={errors}
                defaultValue=""
              />
            </Dialog>
          );
        }}
      </Mutation>
    </>
  );
}
