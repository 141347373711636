import React, { useState } from "react";
import { Mutation } from "@apollo/client/react/components";
import CANCEL_ORDER from "graphql/Order/CancelOrder";
import CancelButton from "components/ActionButtons/CancelButton";
import Loader from "components/Ui/Loader";
import Dialog from "components/Dialog/Dialog";
import { useNotification } from "context/NotificationContext"

export default function CancelOrder({ id, refetch }) {
  const { setNotification } = useNotification()
  const [open, setOpen] = useState(false);

  return (
    <Mutation
      mutation={CANCEL_ORDER}
      variables={{ id }}
      refetchQueries={refetch}
      onCompleted={({ cancelOrder }) => {
        setOpen(false);
        setNotification({
          status: "error",
          message: `Order "${cancelOrder.id} has been canceled"`,
        })
      }}
      onError={(error) => {
        console.error(error)
        setNotification({
          status: "error",
          message: `Order status "canceled" could not be updated, please contact support`,
        })
      }}>
      {(cancelOrder, { loading, error }) => {
        if (loading) return <Loader />;

        const handleClickOpen = (event) => {
          event.stopPropagation();
          setOpen(true);
        };

        const handleClose = (event) => {
          event.stopPropagation();
          setOpen(false);
        };

        return (
          <>
            <CancelButton type="button" handleOnClick={handleClickOpen}>
              <i className="fal fa-ban"></i> Cancel order
            </CancelButton>
            <Dialog
              header="Cancel Order?"
              text="Request the order to be canceled by the payment provider"
              open={open}
              handleClose={handleClose}
              handleOk={cancelOrder}
            />
          </>
        );
      }}
    </Mutation>
  );
}
