import React from "react";
import styled from "styled-components/macro";
import { MEDIA_MIN_MEDIUM, MEDIA_MIN_XX_LARGE } from "variables/mediaQueries";

const TableHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  color: ${(p) => p.theme.colors.white};
  background: ${(p) => p.theme.colors.greyOpac};
  padding: 1.3rem 1rem 1.5rem;
  font-weight: 700;
  font-size: 1.1rem;
  line-height: 1.1em;
  letter-spacing: 0.05rem;
  border-radius: 0.5rem;

  > div {
    cursor: pointer;
    padding-right: 0.8rem;

    span {
      text-decoration: underline;

      ${MEDIA_MIN_MEDIUM} {
        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  .fa-sort,
  .fa-sort-up,
  .fa-sort-down {
    display: none;
    color: ${(p) => p.theme.colors.white};
  }

  button {
    height: 1.6rem;
    padding-top: 0.6rem;
  }

  ${MEDIA_MIN_MEDIUM} {
    padding: 1.8rem 2rem 2rem;
    font-size: 1.3rem;
    line-height: 1.5em;

    .fa-sort,
    .fa-sort-up,
    .fa-sort-down,
    span {
      display: inline-block;
    }
  }

  ${MEDIA_MIN_XX_LARGE} {
    font-size: 1.4rem;
    line-height: 1.6em;
  }
`;

export default ({ children, ...props }) => <TableHeader {...props}>{children}</TableHeader>;
