import React from "react";
import SecondarySidebar from "components/Sidebar/SecondarySidebar";
import editShipping from "helpers/getEditShipping";

export default () => (
  <SecondarySidebar
    sections={[
      {
        title: "Shipping options",
        layout: "/admin",
        routes: editShipping()
          ? [
              { name: "+ Add", path: "/add-shipping-option" },
              { name: "Show all", path: "/shipping-options" },
            ]
          : [{ name: "Show all", path: "/shipping-options" }],
      },
      {
        title: "Shipping rules",
        layout: "/admin",
        routes: [
          { name: "+ Add", path: "/add-shipping-rule" },
          { name: "Show all", path: "/shipping-rules" },
        ],
      },
    ]}
  />
);
