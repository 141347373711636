import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import uuid from "react-uuid";
import { Mutation } from "@apollo/client/react/components";
import { useForm, Controller } from "react-hook-form-old";
import { useAdminContext } from "context/AdminContext";

import UPDATE_ORDER_COMMENTS from "../../../graphql/Order/UpdateOrderComments";

import { useNotification } from "context/NotificationContext";
import GridItem from "components/Grid/GridItem";
import Box from "components/Content/Box";
import Attributes from "components/AttributeList/AttributeList";
import ActionButton from "components/ActionButtons/ActionButton";
import ActionButtons from "components/ActionButtons/ActionButtons";
import Comment from "./Comment";
import Input from "components/Ui/Input";
import HoM from "components/Ui/HoM";
import Loader from "components/Ui/Loader";

const NewCommentButton = styled.button`
  background: transparent;
  border: 0.1rem solid ${(p) => p.theme.colors.opac1};
  cursor: pointer;
  margin-left: 1rem;
  width: 3rem;
  height: 3rem;
  border-radius: 1.4rem;
  padding: 0.1rem 0 0 0.1rem;
`;

const AddButton = styled(ActionButton)`
  margin: 0;
`;

const NewComment = styled.div`
  width: 100%;
  div {
    display: flex;
    align-items: center;
  }
`;

const Header = styled.h5`
  width: 100%;
  padding-bottom: 1rem;
  border-bottom: 0.1rem solid ${(p) => p.theme.colors.whiteOpac};
  margin-bottom: 1rem;
`;

export default ({ customerAttribute, orderId }) => {
  const {user} = useAdminContext();
  const [comments, setComments] = useState([]);
  const [showNewComment, setShowNewComment] = useState(false);
  const { setNotification } = useNotification();
  const { control, handleSubmit, errors } = useForm();
  const useMountEffect = (fun) => useEffect(fun, []);

  const toUpdateComments = (id, customerAttribute) => ({
    variables: {
      id: id,
      customerAttribute: JSON.stringify(customerAttribute),
    },
  });

  const toCustomerAttribute = (comments) => {
    const comment = Object.assign(
      comments.reduce(
        (obj, item) => ({ ...obj, [item.id]: { value: item.value, author: item.author } }),
        {}
      )
    );
    return { comment };
  };

  const updateComment = (id, value) => {
    let updatedComments = [...comments];
    updatedComments[comments.findIndex((element) => element.id === id)] = {
      id: id,
      value: value,
      author: user.name,
    };
    setComments(updatedComments);
  };

  useMountEffect(() => {
    customerAttribute.comment &&
      setComments(
        Object.entries(customerAttribute.comment)
          .map((e) => ({ id: e[0], value: e[1].value, author: e[1].author }))
          .filter((comment) => comment.value !== "")
      );
  });

  return (
    <GridItem columns="12">
      <Mutation
        mutation={UPDATE_ORDER_COMMENTS}
        onCompleted={(data) => {
          setNotification({
            status: "success",
            message: `Comments successfully updated`,
          });

          setComments(
            Object.entries(JSON.parse(data.updateOrder.customerAttribute).comment)
              .map((e) => ({ id: e[0], value: e[1].value, author: e[1].author }))
              .filter((comment) => comment.value !== "")
          );
          setShowNewComment(false);
        }}
        onError={() => {
          setNotification({
            status: "error",
            message: "An error occurred adding comment, please contact support",
          });
        }}>
        {(updateOrderComment, { loading: updateCommentLoading }) => {
          function onEdit(id, value) {
            let updatedComments = [...comments];
            updatedComments[comments.findIndex((element) => element.id === id)] = {
              id: id,
              value: value,
              author: user.name,
            };
            updateOrderComment(toUpdateComments(orderId, toCustomerAttribute(updatedComments)));
          }
          function onRemove(id) {
            let updatedComments = [...comments];
            updatedComments[comments.findIndex((element) => element.id === id)] = {
              id: id,
              value: "",
              author: user.name,
            };
            updateOrderComment(toUpdateComments(orderId, toCustomerAttribute(updatedComments)));
          }
          function onSubmit(data) {
            const updatedComments = [
              ...comments,
              { id: uuid(), value: Object.values(data)[0], author: Object.keys(data)[0] },
            ];
            updateOrderComment(toUpdateComments(orderId, toCustomerAttribute(updatedComments)));
          }
          return (
            <Box preHeading="Order" heading="Comments" headingIcon="comment">
              {updateCommentLoading && <Loader />}
              <ActionButtons inBox>
                <AddButton handleOnClick={() => setShowNewComment(!showNewComment)}>
                  {showNewComment ? <i className="fal fa-minus" /> : <i className="fal fa-plus" />}
                  <HoM>Add comment</HoM>
                </AddButton>
              </ActionButtons>
              {showNewComment && (
                <NewComment>
                  <div>
                    <Controller
                      as={Input}
                      control={control}
                      type="text"
                      defaultValue=""
                      name={user.name}
                      label="New Comment"
                      errors={errors}
                    />
                    <NewCommentButton onClick={handleSubmit(onSubmit)}>
                      <i className="fal fa-check" />
                    </NewCommentButton>
                  </div>
                </NewComment>
              )}
              {comments.length > 0 ? (
                <>
                  <Header>Comments ({comments.length})</Header>
                  <Attributes>
                    {comments &&
                      comments.map((comment) => {
                        return (
                          <Comment
                            key={comment.id}
                            user={user}
                            comment={comment}
                            updateComment={updateComment}
                            onRemove={onRemove}
                            onEdit={onEdit}
                          />
                        );
                      })}
                  </Attributes>
                </>
              ) : (
                <p>No comments found</p>
              )}
            </Box>
          );
        }}
      </Mutation>
    </GridItem>
  );
};
