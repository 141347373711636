import React from "react";
import styled from "styled-components/macro";
import { colors } from "variables/colors";
import { MEDIA_MIN_MEDIUM } from "variables/mediaQueries";
import Loader from "./Loader";

const Button = styled.button`
  position: relative;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5.5rem;
  background: ${(p) => p.color || colors.gradient};
  color: white;
  border: none;
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 1.2rem;
  border-radius: 0.5rem;
  letter-spacing: 0.05rem !important;
  cursor: pointer;
  transition: filter 0.3s;

  ${MEDIA_MIN_MEDIUM} {
    font-size: 1.3rem;
  }

  i {
    font-size: 1.6rem;
    margin: ${(p) => (p.iconOnlyMobile ? "0" : "0 1rem 0 0")};

    ${MEDIA_MIN_MEDIUM} {
      margin-right: 1rem;
      font-size: 1.8rem;
    }
  }

  &:hover {
    filter: brightness(110%);
  }

  &:disabled {
    cursor: initial;
    opacity: 0.6;

    &:hover {
      filter: none;
    }
  }
`;

const ButtonLoader = styled(Loader)`
  background: none;
`;

export default ({
  color,
  type,
  children,
  handleOnClick,
  isLoading,
  loaderColor,
  disabled,
  iconOnlyMobile,
  ...props
}) => (
  <Button
    onClick={handleOnClick ? (e) => handleOnClick(e) : null}
    color={color}
    type={type}
    iconOnlyMobile={iconOnlyMobile}
    disabled={disabled}
    {...props}>
    {isLoading ? <ButtonLoader color={loaderColor} /> : children}
  </Button>
);
