import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components/macro";
import esb from "elastic-builder";
import { MEDIA_MIN_LARGE, MEDIA_MIN_MEDIUM } from "variables/mediaQueries";
import { Query } from "@apollo/client/react/components";
import ALL_STORES from "graphql/Store/AllStores";
import getFullCountryName from "helpers/getFullCountryName";
import SearchForm from "components/Table/Search/SearchForm";
import ClearButton from "components/Table/Filter/ClearButton";
import StoreSelector from "components/StoreSelector/StoreSelector";
import FilterButton from "components/Ui/FilterButton";
import FilterForm from "components/Filter/FilterForm";
import Timespan from "components/Table/Filter/Timespan";
import Select from "components/Ui/Select";
import moment from "moment/min/moment-with-locales";
import ButtonOutlined from "components/Ui/ButtonOutlined";

const Buttons = styled.div`
  display: flex;
  padding: 0.5rem 0;
  margin: 1.5rem auto 1.5rem;

  ${MEDIA_MIN_LARGE} {
    margin: 1.5rem 0;
  }
`;

const ShowFilterButton = styled(ButtonOutlined)`
  padding: 1.5rem 3rem;
  background: ${(p) => p.showFilter ? p.theme.colors.darkerGrey: "none"};
  color: ${(p) => p.showFilter ? p.theme.colors.black : p.theme.colors.white};
  width: 100%;
  i {
    font-size: 1.3rem;
    margin-right: 0.5rem;
  }
  margin: 1rem 0;

  ${MEDIA_MIN_MEDIUM} {
    width: 10rem;
  }
`;

const Label = styled.label`
  width: 100%;
  padding-bottom: 0.5rem;
  display: flex;
  align-items: center;

  ${MEDIA_MIN_LARGE} {
    width: auto;
    padding-bottom: 0;
  }
`;

const IncludeSelect = styled(Select)`
  display: flex;
  flex-wrap: wrap;
  min-width: 15rem;

  ${MEDIA_MIN_LARGE} {
    margin-left: 1rem;
    flex-wrap: nowrap;
  }
`;

const StoreFilter = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  ${MEDIA_MIN_LARGE} {
    flex-wrap: nowrap;
    width: auto;
    margin-left: 1rem;
  }
`;
const DEFAULT_DATETIME_FROM = `${moment().subtract(1, "month").format("YYYY-MM-DD")}T00:00`;
const DEFAULT_DATETIME_TO = `${moment().format("YYYY-MM-DD")}T23:59`;
const DEFAULT_STATUS = [];
const INCLUDE_LIST = [
  { value: "created", label: "Created" },
  { value: "lastUpdated", label: "Last Updated" },
];

export default ({ allCustomers, setEsbQuery }) => {
  const [searchInput, setSearchInput] = useState("");
  const [defaultQuery] = useState(allCustomers);
  const [inputError, setInputError] = useState(false);
  const [filterStatusOptionValues, setFilterStatusOptionValues] = useState(DEFAULT_STATUS);
  const [filterStores, setFilterStores] = useState([]);
  const [filterDateTimeFrom, setFilterDateTimeFrom] = useState(DEFAULT_DATETIME_FROM);
  const [filterDateTimeTo, setFilterDateTimeTo] = useState(DEFAULT_DATETIME_TO);
  const [include, setInclude] = useState(INCLUDE_LIST[0].value);
  const [showFilter, setShowFilter] = useState(false);

  const searchOrders = (event) => {
    event.preventDefault();
    searchInput.length ? setInputError(false) : setInputError(true);
    setEsbQuery(
      new esb.requestBodySearch().query(
        esb.queryStringQuery(searchInput.replace("@", "*")).analyzeWildcard()
      )
    );
  };

  const toggleFilter = () => {
    showFilter && clearFilter()
    setShowFilter(!showFilter)
  }

  const filterCustomers = (event) => {
    event && event.preventDefault();
    const boolQuery = new esb.boolQuery();
    boolQuery.must(
      esb.termsQuery(
        "country",
        filterStores.map((s) => s.value.toLowerCase())
      )
    );
    boolQuery.must(
      esb
        .rangeQuery(include)
        .gte(filterDateTimeFrom.replace("T", "-"))
        .lte(filterDateTimeTo.replace("T", "-"))
        .format("yyyy-MM-dd-HH:mm")
    );
    setEsbQuery(esb.requestBodySearch().query(boolQuery).sort(esb.sort("lastUpdated", "desc")));
  };

  const clearFilter = (event) => {
    event && event.preventDefault();
    clearFilterInput();
    setEsbQuery(defaultQuery);
  };

  const handleChangeInclude = (event) => {
    event.preventDefault();
    setInclude(event.target.value);
  };

  const clearFilterInput = () => {
    setFilterStatusOptionValues(DEFAULT_STATUS);
    setFilterStores([]);
  };

  const isFirstRun = useRef(true);
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    filterCustomers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterStatusOptionValues]);

  return (
    <>
      <SearchForm
        autoComplete="off"
        placeholder="E-mail, name..., (Use * as wildcard)"
        inputError={inputError}
        setInputError={setInputError}
        setSearchInput={setSearchInput}
        searchFunc={searchOrders}
      />
      <ShowFilterButton showFilter={showFilter} onClick={toggleFilter}>
      <i class="fal fa-filter"></i> Filter
      </ShowFilterButton>
      {showFilter && (
        <FilterForm>
          <Timespan>
            <div>
              <Label>From:</Label>
              <input
                type="datetime-local"
                id="fromDate"
                name="fromDate"
                value={filterDateTimeFrom}
                onChange={(e) => setFilterDateTimeFrom(e.target.value)}
              />
            </div>
            <div>
              <Label>To:</Label>
              <input
                type="datetime-local"
                id="toDate"
                name="toDate"
                value={filterDateTimeTo}
                onChange={(e) => setFilterDateTimeTo(e.target.value)}
              />
            </div>
            <div>
              <Label>Include:</Label>
              <IncludeSelect handleChange={handleChangeInclude}>
                {INCLUDE_LIST.map((s) => (
                  <option key={s.value} value={s.value}>
                    {s.label}
                  </option>
                ))}
              </IncludeSelect>
            </div>
          </Timespan>
          <StoreFilter>
            <Label>Stores:</Label>
            <Query query={ALL_STORES} variables={{ from: 0, size: 300 }}
            onCompleted={(data) => {              
              const stores = data?.allStores?.stores
              .slice()
              .sort((a, b) => a.countryCode.localeCompare(b.countryCode))
              .map((store) => ({
                value: store.countryCode,
                label: getFullCountryName(store.countryCode),
                currencyUnit: store.currencyUnit,
                tax: store.tax,
              }));
              setFilterStores(stores)
            }}
            >
        {({ data }) => {
          if (!data) return null;
            return( <StoreSelector selectedStores={filterStores} setSelectedStores={setFilterStores} />)
          }}
            </Query>
          </StoreFilter>
          <Buttons>
            <FilterButton isLoading={false} onClick={filterCustomers}>
              <i className="fal fa-sliders-h"></i> Filter
            </FilterButton>
            <ClearButton onClick={clearFilter} />
          </Buttons>
        </FilterForm>
      )}
    </>
  );
};
