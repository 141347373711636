import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { Query } from "@apollo/client/react/components";
import moment from "moment/min/moment-with-locales";
import ALL_STORES from "graphql/Store/AllStores";
import { MEDIA_MIN_MEDIUM, MEDIA_MIN_LARGE } from "variables/mediaQueries";
import Header from "components/Header/Header";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import WrapperBox from "components/Dashboard/WrapperBox";
import Name from "components/User/User";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import PeriodSelector from "components/Dashboard/PeriodSelector";
import RevenueChart from "components/Dashboard/Charts/RevenueChart";
import CustomTimeRevenueChart from "components/Dashboard/Charts/CustomTimeRevenueChart";
import NumberOfOrdersChart from "components/Dashboard/Charts/NumberOfOrdersChart";
import CustomTimeNumberOfOrdersChart from "components/Dashboard/Charts/CustomTimeNumberOfOrdersChart";
import DiscountCodeStats from "components/Dashboard/Charts/DiscountCodeStats";
import DailyRevenue from "components/Dashboard/Charts/DailyRevenue";
import DailyOrders from "components/Dashboard/Charts/DailyOrders";
import MostSoldVariants from "components/Dashboard/Charts/MostSoldVariants";
import CurrencySelect from "components/Currency/Select";
import Select from "components/Ui/Select";
import StoreSelector from "components/StoreSelector/StoreSelector";
import { getRates } from "helpers/mergeDineros";
import MostUsedPaymentMethods from "components/Dashboard/Charts/MostUsedPaymentMethods";
import MostUsedShipmentOptions from "components/Dashboard/Charts/MostUsedShipmentOptions";
import Timespan from "components/Table/Filter/Timespan";
import Input from "components/Ui/Input";

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
`;

const DashboardStoreSelector = styled(StoreSelector)`
  width: 100%;

  ${MEDIA_MIN_MEDIUM} {
    width: auto;
  }

  ${MEDIA_MIN_LARGE} {
    margin: 0 0 0 0rem;
  }
`;

const DashboardCurencySelect = styled(CurrencySelect)`
  width: 50%;
  select {
    margin: 0;
    width: 100%;
  }

  ${MEDIA_MIN_MEDIUM} {
    width: auto;
    margin: 1rem 0;
    padding-left: 1rem;
  }
`;

const CustomTime = styled(Timespan)`
  margin-top: 2rem;
  div {
    margin-left: 1rem;
  }
  input {
    height: 4rem;
    width: 17rem;
    padding: 0.1rem 1rem 0;
  }
`;

const Toolbar = styled(GridItem)`
  display: flex;
  flex-wrap: wrap;

  ${MEDIA_MIN_MEDIUM} {
    justify-content: space-between;
    flex-wrap: nowrap;
  }

  h2 {
    line-height: 3.6rem;
    margin: 2rem 0 3rem;
    font-size: 2.2rem;

    ${MEDIA_MIN_MEDIUM} {
      font-size: 3.6rem;
      margin: 3rem 0;
    }
  }
`;

const Label = styled.label`
  width: 100%;
  padding-bottom: 0.5rem;
  display: flex;
  align-items: center;

  ${MEDIA_MIN_LARGE} {
    width: auto;
    padding-bottom: 0;
  }
`;

const Tools = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;

  ${MEDIA_MIN_MEDIUM} {
    width: 60%;
    flex-wrap: wrap;
    justify-content: flex-end;
  }
`;

const Selects = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  ${MEDIA_MIN_MEDIUM} {
    flex-direction: row;
    width: auto;
    align-items: center;
  }
`;

const IncludeVATSelect = styled(Select)`
  margin: 1rem 0;
  width: 50%;

  ${MEDIA_MIN_MEDIUM} {
    padding-left: 1.5rem;
    margin: 0 0 0 1rem;
    width: auto;
  }
`;

const DEFAULT_DATETIME_FROM = moment().subtract(1, "month").startOf("day");
const DEFAULT_DATETIME_TO = moment().endOf("day");

const DEFAULT_CURRENCY = process.env.REACT_APP_DASHBOARD_DEFAULT_CURRENCY ?? "";

export default function Dashboard() {
  const [filterDateTimeFrom, setFilterDateTimeFrom] = useState(DEFAULT_DATETIME_FROM);
  const [filterDateTimeTo, setFilterDateTimeTo] = useState(DEFAULT_DATETIME_TO);
  const [customPeriod] = useState({
    id: "custom",
    length: 90,
    format: "YYYY-MM-DD",
    interval: "DAY",
    type: "days",
    shortFormat: "dd",
    current: {
      label: "Custom",
      startDate: DEFAULT_DATETIME_FROM,
      endDate: DEFAULT_DATETIME_TO,
    },
    prev: {
      label: "Custom",
      startDate: DEFAULT_DATETIME_FROM,
      endDate: DEFAULT_DATETIME_TO,
    },
  });

  const periods = {
    day: {
      id: "day",
      length: 24,
      format: "k",
      interval: "HOUR",
      type: "hour",
      shortFormat: "HH",
      current: {
        label: "Last 24h",
        startDate: moment().subtract(24, "hours").startOf("hour"),
        endDate: moment(),
      },
      prev: {
        label: "Previous 24h",
        startDate: moment().subtract(48, "hours").startOf("hour"),
        endDate: moment().subtract(24, "hours").startOf("hour"),
      },
    },
    week: {
      id: "week",
      length: 7,
      format: "YYYY-MM-DD",
      interval: "DAY",
      type: "days",
      shortFormat: "dd",
      current: {
        label: "Last 7 days",
        startDate: moment().subtract(6, "days").startOf("day"),
        endDate: moment().add(1, "days").startOf("day"),
      },
      prev: {
        label: "Previous 7 days",
        startDate: moment().subtract(13, "days").startOf("day"),
        endDate: moment().subtract(6, "days").startOf("day"),
      },
    },
    month: {
      id: "month",
      length: 30,
      format: "YYYY-MM-DD",
      interval: "DAY",
      type: "days",
      shortFormat: "dd",
      current: {
        label: "Last 30 days",
        startDate: moment().subtract(29, "days").startOf("day"),
        endDate: moment().add(1, "days").startOf("day"),
      },
      prev: {
        label: "Previous 30 days",
        startDate: moment().subtract(59, "days").startOf("day"),
        endDate: moment().subtract(29, "days").startOf("day"),
      },
    },
    quarter: {
      id: "quarter",
      length: 90,
      format: "YYYY-MM-DD",
      interval: "DAY",
      type: "days",
      shortFormat: "dd",
      current: {
        label: "Last 90 days",
        startDate: moment().subtract(89, "days").startOf("day"),
        endDate: moment().add(1, "days").startOf("day"),
      },
      prev: {
        label: "Previous 90 days",
        startDate: moment().subtract(179, "days").startOf("day"),
        endDate: moment().subtract(89, "days").startOf("day"),
      },
    },
  };

  const [currencyUnit, setCurrencyUnit] = useState();
  const [currencies, setCurrencies] = useState([]);
  const [period, setPeriod] = useState(periods.day);
  const [stores, setStores] = useState();
  const [selectedStores, setSelectedStores] = useState([]);
  const [rates, setRates] = useState();
  const [includeVAT, setIncludeVAT] = useState(true);
  const orderStatus = "success";

  const updateFromDate = (date) => {
    const updateDate = moment(date);
    if (updateDate.isValid() && updateDate.isBefore(filterDateTimeTo, "day")) {
      setFilterDateTimeFrom(updateDate);
      updateCustomPeriodFrom(date);
    }
  };

  const updateToDate = (date) => {
    const updateDate = moment(date);
    if (updateDate.isValid() && updateDate.isAfter(filterDateTimeFrom, "day")) {
      setFilterDateTimeTo(updateDate);
      updateCustomPeriodTo(date);
    }
  };

  const updateCustomPeriodFrom = (fromDate) => {
    const startDate = moment(fromDate);
    const current = {
      label: "Custom",
      startDate: startDate.startOf("day"),
      endDate: filterDateTimeTo.endOf("day"),
    };
    setPeriod({
      ...customPeriod,
      ...getCustomPeriodConfig(current.startDate, current.endDate),
      current: current,
    });
  };

  const updateCustomPeriodTo = (toDate) => {
    const endDate = moment(toDate);
    const current = {
      label: "Custom",
      startDate: filterDateTimeFrom.startOf("day"),
      endDate: endDate.endOf("day"),
    };
    setPeriod({
      ...customPeriod,
      ...getCustomPeriodConfig(current.startDate, current.endDate),
      current: current,
    });
  };

  const getCustomPeriodConfig = (startDate, endDate) => {
    if (endDate.diff(startDate, "days") >= 1) {
      return { length: 90, format: "YYYY-MM-DD", interval: "DAY", type: "days", shortFormat: "dd" };
    } else {
      return { length: 24, format: "k", interval: "HOUR", type: "hour", shortFormat: "HH" };
    }
  };

  useEffect(() => {
    const updateRates = async () => {
      const allRates = await Promise.all(currencies.map((currencyUnit) => getRates(currencyUnit)));
      setRates(allRates.reduce((result, current) => ({ ...result, ...current }), {}));
    };

    updateRates();
    setDefaultCurrency(currencies);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currencies]);

  const setDefaultCurrency = (currencies) => {
    const defaultCurrency = currencies.find((c) => c === DEFAULT_CURRENCY) ?? currencies[0];
    defaultCurrency && setCurrencyUnit(defaultCurrency);
  };

  return (
    <>
      <Breadcrumbs slugs={[["admin", "Dashboard"]]} />
      <Header heading="Dashboard"></Header>
      <Query
        query={ALL_STORES}
        variables={{ from: 0, size: 300 }}
        onCompleted={(data) => {
          const stores = data.allStores.stores;
          setStores(stores);
          setCurrencies(
            stores
              .reduce(
                (acc, curr) =>
                  !acc.includes(curr.currencyUnit) ? [...acc, curr.currencyUnit] : acc,
                []
              )
              .sort((a, b) => a.localeCompare(b))
          );
          setSelectedStores(
            stores.map((store) => ({
              value: store.countryCode,
              currencyUnit: store.currencyUnit,
              tax: store.tax,
            }))
          );
        }}>
        {({ error }) => {
          if (error)
            return (
              <ErrorMessage>An error occured loading data, please contact support</ErrorMessage>
            );

          return (
            <>
              <GridContainer expanded>
                <Container>
                  <Toolbar columns="12">
                    <h2>
                      Welcome back, <Name simplified />
                    </h2>
                    <Tools>
                      <Selects>
                        <DashboardStoreSelector
                          selectedStores={selectedStores}
                          setSelectedStores={setSelectedStores}
                        />
                        <IncludeVATSelect
                          handleChange={(e) => {
                            setIncludeVAT(e.target.value === "true");
                          }}>
                          {[
                            { key: "Include VAT", value: "true" },
                            { key: "Exclude VAT", value: "false" },
                          ].map((option) => (
                            <option key={option.key} value={option.value}>
                              {option.key}
                            </option>
                          ))}
                        </IncludeVATSelect>
                        <DashboardCurencySelect
                          currencies={currencies}
                          currency={currencyUnit}
                          setCurrency={setCurrencyUnit}
                        />
                      </Selects>
                      <PeriodSelector
                        period={period}
                        periods={periods}
                        setPeriod={setPeriod}
                        customPeriod={customPeriod}
                      />
                      {period.id === "custom" && (
                        <CustomTime>
                          <div>
                            <Label>From:</Label>
                            <Input
                              type="date"
                              id="fromDate"
                              max={filterDateTimeTo.format("YYYY-MM-DD")}
                              name="fromDate"
                              value={filterDateTimeFrom.format("YYYY-MM-DD")}
                              onChange={(e) => updateFromDate(e.target.value)}
                            />
                          </div>
                          <div>
                            <Label>To:</Label>
                            <Input
                              type="date"
                              id="toDate"
                              min={filterDateTimeFrom.format("YYYY-MM-DD")}
                              name="toDate"
                              max={moment().format("YYYY-MM-DD")}
                              value={filterDateTimeTo.format("YYYY-MM-DD")}
                              onChange={(e) => updateToDate(e.target.value)}
                            />
                          </div>
                        </CustomTime>
                      )}
                    </Tools>
                  </Toolbar>

                  <GridItem columns="6" padding="0">
                    <Container padding="0">
                      <GridItem columns="6">
                        <WrapperBox>
                          {currencyUnit && (
                            <DailyRevenue
                              orderStatus={orderStatus}
                              currencyUnit={currencyUnit}
                              selectedStores={selectedStores}
                              includeVAT={includeVAT}
                              rates={rates}
                            />
                          )}
                        </WrapperBox>
                      </GridItem>

                      <GridItem columns="6">
                        <WrapperBox>
                          <DailyOrders
                            orderStatus={orderStatus}
                            selectedStores={selectedStores.map((s) => s.value)}
                          />
                        </WrapperBox>
                      </GridItem>

                      <GridItem columns="12">
                        {currencyUnit && (
                          <WrapperBox large>
                            {period.id === "custom" ? (
                              <CustomTimeRevenueChart
                                orderStatus={orderStatus}
                                selectedStores={selectedStores}
                                currencyUnit={currencyUnit}
                                period={period}
                                includeVAT={includeVAT}
                                rates={rates}
                              />
                            ) : (
                              <RevenueChart
                                orderStatus={orderStatus}
                                selectedStores={selectedStores}
                                currencyUnit={currencyUnit}
                                period={period}
                                includeVAT={includeVAT}
                                rates={rates}
                              />
                            )}
                          </WrapperBox>
                        )}
                      </GridItem>
                    </Container>
                  </GridItem>

                  <GridItem columns="6" padding="0">
                    <DiscountCodeStats />
                  </GridItem>

                  <GridItem columns="12" padding="0">
                    <Container padding="0">
                      <GridItem columns="4">
                        <WrapperBox>
                          <MostSoldVariants
                            period={period}
                            selectedStores={selectedStores.map((s) => s.value)}
                          />
                        </WrapperBox>
                      </GridItem>

                      <GridItem columns="4">
                        <WrapperBox>
                          <MostUsedPaymentMethods
                            period={period}
                            selectedStores={selectedStores.map((s) => s.value)}
                          />
                        </WrapperBox>
                      </GridItem>

                      <GridItem columns="4">
                        <WrapperBox>
                          <MostUsedShipmentOptions
                            period={period}
                            selectedStores={selectedStores.map((s) => s.value)}
                          />
                        </WrapperBox>
                      </GridItem>
                    </Container>
                  </GridItem>

                  <GridItem columns="12">
                    <WrapperBox>
                      {stores && period.id === "custom" ? (
                        <CustomTimeNumberOfOrdersChart
                          orderStatus={orderStatus}
                          selectedStores={selectedStores.map((s) => s.value)}
                          stores={stores}
                          period={period}
                        />
                      ) : (
                        <NumberOfOrdersChart
                          orderStatus={orderStatus}
                          selectedStores={selectedStores.map((s) => s.value)}
                          stores={stores}
                          period={period}
                        />
                      )}
                    </WrapperBox>
                  </GridItem>
                </Container>
              </GridContainer>
            </>
          );
        }}
      </Query>
    </>
  );
}
