import React, { useState } from "react";
import styled from "styled-components/macro";
import { Query } from "@apollo/client/react/components";
import esb from "elastic-builder";

import SEARCH_VARIANTS from "graphql/Product/SearchVariants";

import { MEDIA_MIN_LARGE } from "variables/mediaQueries";
import PageContainer from "components/Page/PageContainer";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import Header from "components/Header/Header";
import StockTable from "./StockTable";
import SearchVariants from "./SearchVariants";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Box from "components/Content/Box";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import Sidebar from "components/Product/Sidebar";

const ProductsBox = styled(Box)`
  > h3 {
    padding-bottom: 1rem;
    margin-bottom: 0rem;

    ${MEDIA_MIN_LARGE} {
      padding-bottom: 3rem;
    }
  }
`;

const PRODUCTS_PER_PAGE = 25;

const ALL_PRODUCTS = new esb.requestBodySearch()
  .sort(esb.sort("lastUpdated", "desc"))
  .query(esb.queryStringQuery("type:productVariant AND !(archived:1)"))
  .size(PRODUCTS_PER_PAGE)
  .from(0);

export default (props) => {
  const [esbQuery, setEsbQuery] = useState(ALL_PRODUCTS);

  return (
    <>
      <Breadcrumbs
        slugs={[
          ["admin/products", "Products"],
          ["admin/stock", "Stock status"],
        ]}
      />
      <Header heading="Stock status"></Header>
      <PageContainer>
        <Sidebar />
        <GridContainer>
          <GridItem columns="12">
            <ProductsBox preHeading="Overview" heading="Stock status">
              <SearchVariants
                allOrders={ALL_PRODUCTS}
                esbQuery={esbQuery}
                setEsbQuery={setEsbQuery}
              />
              <Query
                query={SEARCH_VARIANTS}
                variables={{ query: JSON.stringify(esbQuery.toJSON()) }}
                fetchPolicy={"cache-first"}>
                {({ loading, error, data, fetchMore }) => {
                  if (error)
                    return (
                      <ErrorMessage>
                        An error occurred getting data, please contact support
                      </ErrorMessage>
                    );

                  return (
                    <StockTable
                      data={data}
                      loading={loading}
                      productsPerPage={PRODUCTS_PER_PAGE}
                      fetchMore={fetchMore}
                      history={props.history}
                      esbQuery={esbQuery}
                    />
                  );
                }}
              </Query>
            </ProductsBox>
          </GridItem>
        </GridContainer>
      </PageContainer>
    </>
  );
};
