import React from "react";
import styled from "styled-components/macro";
import { colors } from "variables/colors";
import { MEDIA_MIN_MEDIUM, MEDIA_MIN_LARGE } from "variables/mediaQueries";

const Tab = styled.li`
  height: 4.5rem;
  padding: 1.6rem 1.5rem 1.3rem;
  background: ${(p) => (p.active ? p.theme.colors.secondaryBackground : p.theme.colors.black)};
  margin-right: 1rem;
  font-weight: ${(p) => (p.active ? "700" : "700")};
  z-index: ${(p) => (p.active ? "1" : "0")};
  border-radius: 0.5rem 0.5rem 0 0;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  box-shadow: 0 -0.2rem 1.2rem 0 rgb(0 0 0 / 8%);
  text-transform: capitalize;

  ${MEDIA_MIN_MEDIUM} {
    padding: 1.6rem 2.5rem 1.3rem;
    margin-right: 1.5rem;
  }

  &:before {
    content: "";
    opacity: ${(p) => (p.active ? "1" : "0")};
    position: absolute;
    height: 0.3rem;
    top: 0;
    left: 0;
    right: 0;
    margin: 0;
    background: ${colors.gradient};
    border-radius: 0.6rem 0.6rem 0 0;
    transition: opacity 0.2s;
  }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    height: 2rem;
    bottom: -1rem;
    background: ${(p) => (p.active ? p.theme.colors.secondaryBackground : p.theme.colors.black)};
  }

  &:hover {
    &:before {
      opacity: 1;
    }
  }
`;

const CloseTab = styled.div`
  margin: 0 -1rem 0 1rem;
  font-size: 1.6rem;
  color: ${(p) => p.theme.colors.secondaryText};
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 1.2rem;
  text-align: center;
  padding: 0.4rem 0 0 0;
  transition: all 0.2s;
  border: 0.1rem solid transparent;

  ${MEDIA_MIN_MEDIUM} {
    margin: 0 -1rem 0.2rem 1.5rem;
    padding: 0.1rem 0 0 0.1rem;
  }

  ${MEDIA_MIN_LARGE} {
    &:hover {
      border: 0.1rem solid ${(p) => p.theme.colors.opac1};
    }
  }
`;

export default ({ children, active, tab, closeTab, ...props }) => (
  <Tab active={active} {...props}>
    {children}
    {tab && tab.id && (
      <CloseTab
        onClick={(e) => {
          e.stopPropagation();
          closeTab(tab.id);
        }}>
        <i className="fal fa-times"></i>
      </CloseTab>
    )}
  </Tab>
);
