import React, { useState } from "react";
import styled from "styled-components/macro";
import { useCookies } from "react-cookie";
import { colors } from "variables/colors";
import { MEDIA_MIN_MEDIUM } from "variables/mediaQueries";

const ThemeToggle = styled.div`
  padding: 0 5rem;
  height: 5rem;
  display: flex};
  align-items: center;
  padding-top: 3rem;

  ${MEDIA_MIN_MEDIUM} {
    padding: ${(p) => (p.expanded ? "0 5rem" : "0 3.5rem")};
    display: ${(p) => (p.expanded ? "flex" : "block")};
    position: absolute;
    bottom: 3rem;
    padding-top: 0;
  }
`;

const Label = styled.div`
  color: ${colors.white};
  padding: 0 1rem 0 0;
  text-align: center;

  i {
    font-size: 1.6rem;
    padding-top: 0.2rem;
  }

  ${MEDIA_MIN_MEDIUM} {
    padding: ${(p) => (p.expanded ? "0 1rem 0 0" : "0 0 0.4rem 0")};

    i {
      padding-top: 0.4rem;
    }
  }
`;

const Switch = styled.div`
  display: flex;
  justify-content: ${(p) => (p.mode === "light" ? "flex-start" : "flex-end")};
  align-items: center;
  height: 2.4rem;
  width: 4rem;
  padding: 0;
  cursor: pointer;
  position: relative;
  transition: 0.2s;

  &:before {
    content: "";
    background: ${(p) => (p.mode === "light" ? colors.white : p.theme.colors.primary)};
    opacity: 0.4;
    border-radius: 1.2rem;
    width: 4rem;
    height: 1rem;
    position: absolute;
    left: 0;
    right: 0;
  }
`;

const Slider = styled.div`
  height: 2rem;
  width: 2rem;
  border-radius: 1rem;
  background: ${(p) => colors.gradient};
  z-index: 1;
`;

export default ({ expanded, ...props }) => {
  const [cookies, setCookie] = useCookies("theme");
  const [theme, setTheme] = useState(cookies.theme || "light");

  return (
    <ThemeToggle expanded={expanded} {...props}>
      <Label expanded={expanded}>
        <i className="fas fa-adjust"></i>
      </Label>
      <Switch
        mode={theme}
        onClick={() => {
          setCookie("theme", theme === "dark" ? "light" : "dark", { path: "/" });
          setTheme(theme === "light" ? "dark" : "light");
        }}>
        <Slider />
      </Switch>
    </ThemeToggle>
  );
};
