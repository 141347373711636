import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Moment from "react-moment";
import styled from "styled-components/macro";
import { Query } from "@apollo/client/react/components";
import { merge, mergeWith } from "lodash";

import GET_ORDER from "graphql/Order/GetOrder";
import GET_ORDER_INVOICE from "graphql/Order/GetOrderInvoice";

import { MEDIA_MIN_MEDIUM } from "variables/mediaQueries";
import PageContainer from "components/Page/PageContainer";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Box from "components/Content/Box";
import Loader from "components/Ui/Loader";
import HoM from "components/Ui/HoM";
import FlagIcon from "components/Ui/FlagIcon";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import Money from "components/Money/Money";
import OrderLines from "./OrderLines";
import ActionButtons from "components/ActionButtons/ActionButtons";
import ActionButton from "components/ActionButtons/ActionButton";
import getFriendlyPaymentName from "helpers/getFriendlyPaymentName";
import getFullCountryName from "helpers/getFullCountryName";
import Attributes from "components/AttributeList/AttributeList";
import Attribute from "components/AttributeList/Attribute";
import Label from "components/AttributeList/Label";
import Value from "components/AttributeList/Value";
import History from "components/Order/History";
import Person from "components/Order/Person";
import Comments from "components/Order/Comments/Comments";
import Shipping from "components/Order/Shipping";
import MarkOrderAsShipped from "components/Order/MarkOrderAsShipped";
import CancelOrder from "components/Order/CancelOrder";
import OrderStatus from "components/Order/OrderStatus";
import UpdateOrderEmail from "components/Order/UpdateOrderEmail";
import AddPostPurchaseDiscount from "components/Order/AddPostPurchaseDiscount";
import ExtendPayment from "components/Order/ExtendPayment";
import LinkToPaymentProvider from "components/Order/LinkToPaymentProvider";

const Container = styled(GridContainer)`
  display: block;
  padding-top: 0;
`;

const OverviewBox = styled(Box)`
  min-height: 40rem;
  align-items: flex-start;
`;

const OrderStatusAttribute = styled(Attribute)`
  padding: 1.8rem 0 1.7rem;
`;

const Code = styled(Value)`
  color: ${(p) => p.theme.colors.primary};
`;

const ClickableGridContainer = styled(GridContainer)`
  cursor: pointer;
`;

const ToggleHistory = styled.div`
  position: absolute;
  right: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: 0.1rem solid ${(p) => p.theme.colors.opac1};
  padding: 0.5rem 1rem;
  border-radius: 0.3rem;
  transition: box-shadow 0.2s;
  box-shadow: none;

  &:hover {
    box-shadow: 0 0.2rem 0.8rem 0 rgb(0 0 0 / 15%);
  }

  i {
    transition: transform 0.2s;
    font-size: 1.6rem;
    transform: rotate(${(p) => (p.showHistory ? "180deg" : "0deg")});
    margin: 0.3rem 0.5rem;
  }
`;

const OrderActions = styled(ActionButtons)`
  position: relative;
  padding: 2rem 0;
  top: auto;
  right: 0;

  ${MEDIA_MIN_MEDIUM} {
    position: absolute;
    top: 4rem;
    right: 2rem;
  }
`;

const DiscountBox = styled(Box)`
  height: 100%;
  align-items: flex-start;
`;

const NoDiscount = styled.div`
  height: 100%;
`;

const isGenerateInvoiceEnabled = /true/i.test(process.env.REACT_APP_GENERATE_INVOICE_ENABLED);

export default function Order({ id, updatedOrder, scrollToTop = true }) {
  const useMountEffect = (fun) => useEffect(fun, []);
  const [order, setOrder] = useState();
  const [generateInvoice, setGenerateInvoice] = useState(false);
  const [editEmail, setEditEmail] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const history = useHistory();

  const _mergeOrder = (srcOrder, objOrder) =>
    mergeWith({}, srcOrder, objOrder, (objValue, srcValue, key) => {
      if (key === "customerAttribute") {
        const customerAttribute = merge(
          {},
          typeof srcValue === "string" ? JSON.parse(srcValue) : srcValue || {},
          typeof objValue === "string" ? JSON.parse(objValue) : objValue || {}
        );
        return customerAttribute;
      } else if (key === "statusLog") {
        return objValue || srcValue || [];
      }
    });

  const mergeOrder = (o) => setOrder((order = {}) => _mergeOrder(order, o));

  useEffect(() => {
    const shouldUpdateOrder = updatedOrder && order && updatedOrder.id === order.id;
    shouldUpdateOrder && mergeOrder(updatedOrder);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedOrder]);

  const handleDiscountClick = (discountId) => {
    history.push(`/admin/discount-code/${discountId}`);
  };

  useMountEffect(() => {
    if (scrollToTop) window.scrollTo(0, 0);
  });

  return (
    <PageContainer>
      <Container>
        <Query
          query={GET_ORDER}
          variables={{ id }}
          onCompleted={({ getOrder }) => mergeOrder(getOrder)}>
          {({ loading, error, refetch }) => {
            const { orderLines = [] } = order || {};
            const shipping = orderLines.find((l) => l.type === "shipment");

            const GetInvoiceButton = ({ order }) => {
              const disabled = order.status !== "success" && order.status !== "shipped";
              if (generateInvoice) {
                return (
                  <Query query={GET_ORDER_INVOICE} variables={{ id }} fetchPolicy="no-cache">
                    {({ data }) => {
                      if (error) return null;
                      return (
                        <a
                          href={data && data.getOrderInvoice.url}
                          target="_blank"
                          rel="noopener noreferrer">
                          <ActionButton disabled={disabled} type="button">
                            <i className="fal fa-download"></i> Download invoice PDF valid for 15
                            sec
                          </ActionButton>
                        </a>
                      );
                    }}
                  </Query>
                );
              } else {
                return (
                  <ActionButton
                    handleOnClick={() => setGenerateInvoice(true)}
                    disabled={disabled}
                    iconOnlyMobile
                    type="button">
                    <i className="fal fa-download" /> <HoM>Generate invoice PDF</HoM>
                  </ActionButton>
                );
              }
            };

            return (
              <GridContainer padding="0" collapse>
                <GridItem columns="12" mobilePadding="1.5rem">
                  <OverviewBox
                    preHeading="Overview"
                    heading={order && `Order #${order.reference}`}
                    subHeading={order && order.id}
                    headingIcon="shopping-cart">
                    {loading && <Loader />}
                    {error && (
                      <ErrorMessage>
                        An error occurred getting data, please contact support
                      </ErrorMessage>
                    )}
                    {order && (
                      <>
                        <OrderActions inBox>
                          {order.status === "success" && (
                            <CancelOrder id={order.id} order={order} refetch={refetch} />
                          )}
                          {order.status === "success" && (
                            <MarkOrderAsShipped order={order} refetch={refetch} />
                          )}
                          {isGenerateInvoiceEnabled && <GetInvoiceButton order={order} />}
                        </OrderActions>
                        <GridContainer padding="0" collapse>
                          <GridItem
                            mobilePadding="2rem 0 0"
                            desktopPadding="2rem 2rem 2rem 0"
                            columns="6">
                            <Attributes>
                              <Attribute>
                                <Label>Reference:</Label>
                                <Value>{order.reference}</Value>
                              </Attribute>
                              <OrderStatusAttribute>
                                <Label>Status:</Label>
                                <Value>
                                  <OrderStatus
                                    status={order.status}
                                    showTitle
                                    showIcon
                                    iconOnlyMobile
                                  />
                                  <ToggleHistory
                                    showHistory={showHistory}
                                    onClick={() => setShowHistory(!showHistory)}>
                                    Show history <i className="fal fa-angle-down"></i>
                                  </ToggleHistory>
                                </Value>
                                {showHistory && (
                                  <History orderDate={order.created} history={order.statusLog} />
                                )}
                              </OrderStatusAttribute>
                              <Attribute>
                                <Label>Order date:</Label>
                                <Value>
                                  <Moment format="YYYY-MM-DD HH:mm">{order.created}</Moment>
                                </Value>
                              </Attribute>
                              <Attribute>
                                <Label>Last updated:</Label>
                                <Value>
                                  <Moment format="YYYY-MM-DD HH:mm">{order.lastUpdated}</Moment>
                                </Value>
                              </Attribute>
                              {order.paymentMethod && (
                                <Attribute>
                                  <Label>Payment method:</Label>
                                  <Value>{getFriendlyPaymentName(order.paymentMethod)}</Value>
                                </Attribute>
                              )}
                            </Attributes>
                          </GridItem>
                          <GridItem
                            mobilePadding="0 0 2rem"
                            desktopPadding="2rem 0 2rem 2rem"
                            columns="6">
                            <Attributes>
                              {shipping && (
                                <>
                                  <Attribute>
                                    <Label>Shipping method:</Label>
                                    <Value>{shipping.name}</Value>
                                  </Attribute>
                                </>
                              )}

                              <Attribute>
                                <Label>Total order value:</Label>
                                <Value>
                                  <Money
                                    amount={order.orderAmountWithDiscount}
                                    currencyUnit={order.currencyUnit}
                                  />
                                </Value>
                              </Attribute>
                              {order.person && (
                                <>
                                  <Attribute>
                                    <Label>Country:</Label>
                                    <Value>
                                      <FlagIcon countryCode={order.person.country} />
                                      {getFullCountryName(order.person.country)}
                                    </Value>
                                  </Attribute>
                                  <Attribute>
                                    <Label>Customer name:</Label>
                                    <Value>
                                      {order.person.givenName} {order.person.familyName}
                                    </Value>
                                  </Attribute>
                                  <Attribute>
                                    <Label>E-mail:</Label>
                                    <Value>
                                      <a href={`mailto:${order.person.email}`}>
                                        {order.person.email}
                                      </a>
                                    </Value>
                                  </Attribute>
                                </>
                              )}
                            </Attributes>
                          </GridItem>
                        </GridContainer>
                      </>
                    )}
                  </OverviewBox>
                </GridItem>
                {order && (
                  <>
                    <GridItem columns="12">
                      <OrderLines order={order} refetch={refetch} />
                    </GridItem>
                    <Shipping order={order} shipping={shipping} />

                    {order.person && (
                      <>
                        <Person
                          person={order.person}
                          onEmailClick={() => setEditEmail(true)}
                          linkButton
                          history={history}
                        />
                        <UpdateOrderEmail
                          order={order}
                          open={editEmail}
                          onClose={() => setEditEmail(false)}
                          refetch={refetch}
                        />
                      </>
                    )}

                    <GridContainer collapse>
                      <GridItem columns="6" desktopPadding="0 1.5rem 0 0">
                        <Box preHeading="Order" heading="Payment" headingIcon="credit-card-front">
                          <ExtendPayment order={order} refetch={refetch} />
                          <Attributes>
                            {order.paymentProvider && (
                              <Attribute>
                                <Label>Payment provider:</Label>
                                <Value>{order.paymentProvider}</Value>
                              </Attribute>
                            )}
                            {order.paymentReference && (
                              <Attribute>
                                <Label>Payment reference:</Label>
                                <Value>
                                  {order.paymentReference}&nbsp;
                                  <LinkToPaymentProvider order={order} />
                                </Value>
                              </Attribute>
                            )}
                            {order.paymentMethod && (
                              <Attribute>
                                <Label>Payment method:</Label>
                                <Value>{getFriendlyPaymentName(order.paymentMethod)}</Value>
                              </Attribute>
                            )}
                            {order.paymentExpiresAt && (
                              <Attribute>
                                <Label>Payment expiry:</Label>
                                <Value>
                                  <Moment fromNow withTitle titleFormat="YYYY-MM-DD hh:mm">
                                    {order.paymentExpiresAt}
                                  </Moment>
                                </Value>
                              </Attribute>
                            )}
                            {order.potentialFraud !== null && (
                              <Attribute>
                                <Label>Potential fraud:</Label>
                                <Value>{order.potentialFraud ? "Yes" : "No"}</Value>
                              </Attribute>
                            )}
                            {order.failedReason && (
                              <Attribute>
                                <Label>Failed reason:</Label>
                                <Value>{order.failedReason}</Value>
                              </Attribute>
                            )}
                          </Attributes>
                        </Box>
                      </GridItem>
                      <GridItem columns="6" desktopPadding="0 0 0 1.5rem">
                        <DiscountBox preHeading="Order" heading="Discount" headingIcon="tags">
                          {order.discount ||
                          order.status === "shipped" ||
                          order.customerAttribute?.postPurchaseDiscount ? (
                            <>
                              <AddPostPurchaseDiscount order={order} refetch={refetch} />
                              {order.discount && (
                                <ClickableGridContainer
                                  padding="0"
                                  collapse
                                  onClick={() => handleDiscountClick(order.discount.id)}>
                                  <GridItem padding="0" columns="12">
                                    <Attributes>
                                      <Attribute>
                                        <Label>Discount ID:</Label>
                                        <Value>{order.discount.id}</Value>
                                      </Attribute>
                                      <Attribute>
                                        <Label>Discount Code:</Label>
                                        <Code>{order.discount.code}</Code>
                                      </Attribute>
                                    </Attributes>
                                  </GridItem>
                                </ClickableGridContainer>
                              )}
                              {order.customerAttribute?.postPurchaseDiscount && (
                                <GridContainer desktopPadding="0" collapse>
                                  <GridItem padding="0" columns="12">
                                    <Attributes>
                                      <Attribute>
                                        <Label>Discount Amount:</Label>
                                        <Value>
                                          <Money
                                            amount={
                                              order.customerAttribute.postPurchaseDiscount
                                                .refundAmount
                                            }
                                            currencyUnit={order.currencyUnit}
                                          />
                                        </Value>
                                      </Attribute>
                                      <Attribute>
                                        <Label>Discount Percentage:</Label>
                                        <Value>
                                          {
                                            order.customerAttribute.postPurchaseDiscount
                                              .discountInPercentage
                                          }
                                        </Value>
                                      </Attribute>
                                    </Attributes>
                                  </GridItem>
                                </GridContainer>
                              )}
                            </>
                          ) : (
                            <NoDiscount>
                              <p>No discount code used</p>
                            </NoDiscount>
                          )}
                        </DiscountBox>
                      </GridItem>
                    </GridContainer>
                    <Comments customerAttribute={order.customerAttribute} orderId={order.id} />
                  </>
                )}
              </GridContainer>
            );
          }}
        </Query>
      </Container>
    </PageContainer>
  );
}
