import React from "react";
import styled from "styled-components/macro";
import Box from "components/Content/Box";
import Button from "components/Ui/Button";
import CancelButton from "components/ActionButtons/CancelButton";

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  transition: opacity 0.3s;
  pointer-events: ${(p) => (p.open ? "all" : "none")};
  z-index: ${(p) => (p.open ? "10002" : "-1")};
  opacity: ${(p) => (p.open ? "1" : "0")};
`;

const Container = styled(Box)`
  max-width: 50rem;
  margin: 0 3rem;
`;

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 2rem;

  button {
    width: 48%;
    height: 4.4rem;
    margin: 0;
  }
`;

export default ({
  open,
  handleOk,
  handleClose,
  header,
  preHeading,
  subHeading,
  text,
  children = null,
  ...props
}) => (
  <Backdrop {...props} open={open} onClick={handleClose}>
    <Container
      className="dialogContainer"
      subHeading={subHeading}
      heading={header}
      preHeading={preHeading}
      onClick={(event) => event.stopPropagation()}>
      <p>{text}</p>
      {children}
      <Actions>
        <CancelButton type="button" handleOnClick={handleClose}>
          Cancel
        </CancelButton>
        <Button type="button" handleOnClick={handleOk} autoFocus>
          Ok
        </Button>
      </Actions>
    </Container>
  </Backdrop>
);
