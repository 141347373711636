import React from "react";
import styled from "styled-components/macro";

import { MEDIA_MIN_LARGE } from "variables/mediaQueries";

const Rows = styled.div`
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
  position: relative;
  min-height: 15rem;

  ${MEDIA_MIN_LARGE} {
    min-height: 25rem;
  }
`;

export default ({ children, ...props }) => <Rows {...props}> {children}</Rows>;
