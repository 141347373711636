import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { useQuery } from "@apollo/client";
import esb from "elastic-builder";
import SEARCH_DISCOUNT_CODES from "graphql/Discount/SearchDiscounts";
import Chartist from "react-chartist";
import { isMobile } from "helpers/getViewport";
import { MEDIA_MIN_LARGE } from "variables/mediaQueries";
import Box from "components/Dashboard/Box";
import WrapperBox from "components/Dashboard/WrapperBox";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Loader from "components/Ui/Loader";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import QuickFilter from "components/Table/Filter/QuickFilter";
import QuickFilterOption from "components/Table/Filter/QuickFilterOption";
import isDiscountCodeActive from "components/Discount/Helpers/isDiscountCodeActive";
import DiscountValue from "components/Discount/DiscountValue";
import moment from "moment/min/moment-with-locales";

const Container = styled.div`
  width: 100%;
  margin: 2rem 0;
`;

const Table = styled.ul`
  padding: 0;
  list-style: none;
  margin: 0;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  display: flex;
  align-items: center;
  height: 4rem;
  font-weight: 700;
  font-size: 1.2rem;
  padding: 1rem 0;
  margin-top: 0;
  line-height: 1.5rem;
  letter-spacing: 0.05rem;
  border-radius: 0.5rem;

  div {
    display: flex;
    align-items: center;
    width: 25%;
    justify-content: center;

    &:first-child {
      width: 50%;
      justify-content: flex-start;
    }

    &:last-child {
      justify-content: flex-end;
    }
  }
`;

const Row = styled.li`
  display: flex;
  justify-content: space-between;
  display: flex;
  align-items: center;
  height: 4rem;
  padding: 1.5rem 0 1.3rem 0;
  cursor: pointer;

  div {
    display: flex;
    align-items: center;
    width: 25%;
    justify-content: center;

    &:first-child {
      width: 50%;
      justify-content: flex-start;
    }

    &:last-child {
      justify-content: flex-end;
    }
  }
`;

const IsActive = styled.span`
  color: ${(p) => (p.isActive ? "green" : "red")};
  font-size: 0.6rem;
  margin-right: 1rem;
  margin-top: 0.2rem;
`;

const Periods = styled(QuickFilter)`
  display: flex;
  justify-content: flex-end;
  min-height: 0;
  margin-top: 1.5rem;
  width: 100%;

  ${MEDIA_MIN_LARGE} {
    padding: 0;
    justify-content: flex-end;
    margin-top: 0;
  }

  > div {
    padding-left: 1rem;
    margin-left: 2rem;

    ${MEDIA_MIN_LARGE} {
      padding-left: 2rem;
      justify-content: flex-end;
    }
  }
`;

const Period = styled(QuickFilterOption)`
  margin: 0 2rem 0 0;
  white-space: pre-wrap;

  ${MEDIA_MIN_LARGE} {
    margin: 0 2rem;
  }
`;

const now = moment().format("YYYY-MM-DD");

const MOST_USED_DISCOUNT_CODES_ALL = new esb.requestBodySearch()
  .query(esb.queryStringQuery("!(archived:1) OR !(type:VOUCHER)"))
  .sort(esb.sort("counter", "desc"))
  .size(10);

const MOST_USED_DISCOUNT_CODES_ACTIVE = new esb.requestBodySearch()
  .query(
    esb.queryStringQuery(
      `!(archived:1) AND !(type:VOUCHER) AND !(counter = usageLimit) AND (startDate:<=${now}) AND (endDate:>=${now})`
    )
  )
  .sort(esb.sort("counter", "desc"))
  .size(10);

export default () => {
  const [discountCount, setDiscountCount] = useState(0);
  const [discountCodes, setDiscountCodes] = useState([]);
  const [discountCodeChart, setDiscountCodeChart] = useState();
  const [discountCodeUsageChart, setDiscountCodeUsageChart] = useState();
  const [discountCodeUsagePeriod, setDiscountCodeUsagePeriod] = useState("active");
  const codeToDisplay = isMobile() ? 5 : 10;

  const {
    loading: activeLoading,
    error: activeError,
    data: activeData,
  } = useQuery(SEARCH_DISCOUNT_CODES, {
    variables: {
      query: JSON.stringify(MOST_USED_DISCOUNT_CODES_ACTIVE.toJSON()),
    },
  });

  const {
    loading: allLoading,
    error: allError,
    data: allData,
  } = useQuery(SEARCH_DISCOUNT_CODES, {
    variables: {
      query: JSON.stringify(MOST_USED_DISCOUNT_CODES_ALL.toJSON()),
    },
  });

  useEffect(() => {
    if (allData && activeData) {
      const { discounts: allDiscounts, totalHits: allTotalHits } = allData.searchDiscounts;
      const { discounts: activeDiscounts, totalHits: activeTotalHits } = activeData.searchDiscounts;

      setDiscountCount(allTotalHits);
      setDiscountCodes(activeDiscounts);

      setDiscountCodeChart({
        data: {
          labels: [],
          series: [activeTotalHits, allTotalHits - activeTotalHits],
        },
        options: {
          donut: true,
          donutWidth: 15,
          donutSolid: true,
          startAngle: 0,
          showLabel: false,
        },
      });

      setDiscountCodeUsageChart({
        active: {
          data: {
            labels: activeDiscounts
              .slice(0, codeToDisplay)
              .map((discountCode) => discountCode.code),
            series: [
              activeDiscounts
                .slice(0, codeToDisplay)
                .map((discountCode) => discountCode.usageCount),
            ],
          },
          options: {
            low: 0,
            high: activeDiscounts[0] ? activeDiscounts[0].usageCount : 0,
            showArea: true,
            showPoint: false,
            fullWidth: true,
            chartPadding: {
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            },
            axisY: {
              onlyInteger: true,
            },
          },
        },
        allTime: {
          data: {
            labels: allDiscounts.slice(0, codeToDisplay).map((discountCode) => discountCode.code),
            series: [
              allDiscounts.slice(0, codeToDisplay).map((discountCode) => discountCode.usageCount),
            ],
          },
          options: {
            low: 0,
            high: allDiscounts[0] ? allDiscounts[0].usageCount : 0,
            showArea: true,
            showPoint: false,
            fullWidth: true,
            chartPadding: {
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            },
            axisY: {
              onlyInteger: true,
            },
          },
        },
      });
    }
  }, [activeData, allData, codeToDisplay]);

  return (
    <GridContainer collapse padding="0">
      <GridItem columns="6">
        <WrapperBox>
          {(allLoading || activeLoading) && <Loader />}
          {(allError || activeError) && (
            <ErrorMessage>An error occured loading data, please contact support</ErrorMessage>
          )}
          {discountCodeChart && discountCount && (
            <Box
              preHeading="Discount codes"
              heading={`# ${discountCount}`}
              headingIcon="ticket-alt"
              primaryLabel="Active"
              secondaryLabel="Inactive">
              <Container>
                <Chartist
                  className="ct-chart-white-colors"
                  data={discountCodeChart.data}
                  type="Pie"
                  options={discountCodeChart.options}
                />
              </Container>
            </Box>
          )}
        </WrapperBox>
      </GridItem>
      <GridItem columns="6">
        <WrapperBox>
          {(allLoading || activeLoading) && <Loader />}
          {(allError || activeError) && (
            <ErrorMessage>An error occured loading data, please contact support</ErrorMessage>
          )}
          {discountCodes && (
            <Box preHeading="Top 3 active" heading="Discounts" headingIcon="award" highLightIcon>
              <Container>
                <Table>
                  <Header>
                    <div>Code</div>
                    <div>Value</div>
                    <div>Usage</div>
                  </Header>
                  {discountCodes.slice(0, 3).map((discountCode) => (
                    <Row key={discountCode.id}>
                      <div>
                        <IsActive
                          isActive={isDiscountCodeActive(
                            discountCode.startDate,
                            discountCode.endDate,
                            discountCode.usageCount,
                            discountCode.usageLimit
                          )}>
                          <i className="fas fa-circle"></i>
                        </IsActive>
                        {discountCode.code}
                      </div>
                      <DiscountValue discount={discountCode} />
                      <div>{discountCode.usageCount}</div>
                    </Row>
                  ))}
                </Table>
              </Container>
            </Box>
          )}
        </WrapperBox>
      </GridItem>
      <GridItem columns="12">
        <WrapperBox large>
          {(allLoading || activeLoading) && <Loader />}
          {(allError || activeError) && (
            <ErrorMessage>An error occured loading data, please contact support</ErrorMessage>
          )}
          {discountCodeUsageChart && (
            <Box
              preHeading={`Top ${codeToDisplay}`}
              heading="Discount codes used"
              headingIcon="award"
              highLightIcon>
              <Periods>
                <Period
                  onClick={() => setDiscountCodeUsagePeriod("active")}
                  active={discountCodeUsagePeriod === "active"}>
                  Active
                </Period>
                <Period
                  onClick={() => setDiscountCodeUsagePeriod("allTime")}
                  active={discountCodeUsagePeriod === "allTime"}>
                  All time
                </Period>
              </Periods>
              <Chartist
                className="ct-chart-white-colors ct-major-tenth"
                data={discountCodeUsageChart[discountCodeUsagePeriod].data}
                type="Bar"
                options={discountCodeUsageChart[discountCodeUsagePeriod].options}
                listener={{
                  draw: function (data) {
                    if (data.type === "bar") {
                      data.element.animate({
                        y2: {
                          dur: 1000,
                          from: data.y1,
                          to: data.y2,
                        },
                      });
                    }
                  },
                }}
              />
            </Box>
          )}
        </WrapperBox>
      </GridItem>
    </GridContainer>
  );
};
