import React, { useState } from "react";
import { Query } from "@apollo/client/react/components";
import PageContainer from "components/Page/PageContainer";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import ALL_STORES from "graphql/Store/AllStores";
import Header from "components/Header/Header";
import StoresTable from "views/Stores/StoresTable";
import Box from "components/Content/Box";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import Sidebar from "components/Store/Sidebar";
import SearchStores from "./SearchStores.js";

const Stores = (props) => {
  const [allStores, setAllStores] = useState([])
  const [stores, setStores] = useState([]);

  return (
    <>
      <Breadcrumbs slugs={[["admin/stores", "Stores"]]} />
      <Header heading="Stores"></Header>
      <PageContainer>
        <Sidebar />
        <GridContainer>
          <GridItem columns="12">
            <Box preHeading="Overview" heading="Stores">
              <SearchStores allStores={allStores} setStores={setStores}/>
              <Query
                query={ALL_STORES}
                variables={{from: 0, size: 300}}
                onCompleted={({ allStores }) => {
                  setStores(allStores.stores);
                  setAllStores(allStores.stores);
                }}>
                {({ loading, error, data }) => {
                  if (error)
                    return (
                      <ErrorMessage>
                        An error occured getting data, please contact support
                      </ErrorMessage>
                    );
                  return (
                    <StoresTable
                      data={data}
                      storesTotalHits={stores.length}
                      stores={stores}
                      loading={loading}
                      history={props.history}
                    />
                  );
                }}
              </Query>
            </Box>
          </GridItem>
        </GridContainer>
      </PageContainer>
    </>
  );
};

export default (props) => {
  return Stores(props);
};
