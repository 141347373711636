import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { Query } from "@apollo/client/react/components";
import esb from "elastic-builder";
import SEARCH_VARIANTS from "graphql/Product/SearchVariants";
import { MEDIA_MIN_LARGE } from "variables/mediaQueries";
import ProductsTable from "views/Products/ProductsTable";
import SearchProducts from "views/Products/SearchProducts";
import GridItem from "components/Grid/GridItem";
import Box from "components/Content/Box";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";

const ProductsBox = styled(Box)`
  > h3 {
    padding-bottom: 1rem;
    margin-bottom: 0rem;

    ${MEDIA_MIN_LARGE} {
      padding-bottom: 3rem;
    }
  }
`;

const PRODUCTS_PER_PAGE = 25;

const makeNestedEsQuery = (filterRule) =>
  esb
    .nestedQuery()
    .path("filterAttributes")
    .query(
      esb
        .boolQuery()
        .filter([
          esb.termQuery("filterAttributes.key", filterRule.key),
          esb.termsQuery("filterAttributes.value", filterRule.values),
        ])
    );

export default ({ history, filterRules }) => {
  const [esbQuery, setEsbQuery] = useState();
  const [searchFilters, setSearchFilters] = useState([]);

  useEffect(() => {
    const sort = esb.sort("lastUpdated", "desc");
    const activeProductsFilter = esb.queryStringQuery("type:productVariant AND !(archived:1)");
    const filterArrayMust =
      filterRules?.length > 0
        ? filterRules
            .filter(
              (filterRule) =>
                filterRule.mode === "INCLUDE" && filterRule.key && filterRule.values.length > 0
            )
            .map(makeNestedEsQuery)
        : [];
    const filterArrayMustNot =
      filterRules?.length > 0
        ? filterRules
            .filter(
              (filterRule) =>
                filterRule.mode === "EXCLUDE" && filterRule.key && filterRule.values.length > 0
            )
            .map(makeNestedEsQuery)
        : [];
    const nestedQuery = esb
      .boolQuery()
      .filter([activeProductsFilter, ...searchFilters, ...filterArrayMust])
      .mustNot(filterArrayMustNot);

    setEsbQuery(
      new esb.requestBodySearch().query(nestedQuery).sort(sort).size(PRODUCTS_PER_PAGE).from(0)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFilters, filterRules]);

  if (!esbQuery) return null;
  return (
    <>
      <GridItem columns="12">
        <ProductsBox preHeading="Preview" heading="Matching products" headingIcon="bags-shopping">
          <SearchProducts setSearchFilters={setSearchFilters} />
          <Query
            query={SEARCH_VARIANTS}
            variables={{ query: JSON.stringify(esbQuery.toJSON()) }}
            fetchPolicy={"cache-first"}>
            {({ loading, error, data, fetchMore }) => {
              if (error)
                return (
                  <ErrorMessage>
                    An error occurred getting data, please contact support
                  </ErrorMessage>
                );

              return (
                <ProductsTable
                  data={data}
                  loading={loading}
                  productsPerPage={PRODUCTS_PER_PAGE}
                  fetchMore={fetchMore}
                  history={history}
                  esbQuery={esbQuery}
                  useRelatedProduct={true}
                />
              );
            }}
          </Query>
        </ProductsBox>
      </GridItem>
    </>
  );
};
