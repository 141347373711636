import React, { useState } from "react";
import styled from "styled-components/macro";
import { useOktaAuth } from "@okta/okta-react";

import { colors } from "variables/colors";
import Logo from "./Ui/Logo.js";
import Button from "./Ui/Button";
import Input from "./Ui/Input";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import getClientDetails from "helpers/getClientDetails.js";

const LoginForm = styled.div`
  width: calc(100% - 6rem);
  margin: 0 auto;
  max-width: 45rem;

  i {
    color: ${colors.black};
  }

  img,
  > div {
    display: block;
    margin: 0 auto;
  }
`;

const LoginInput = styled(Input)`
  margin-bottom: 2rem;
  color: ${colors.black};
  border: 0.1rem solid rgba(255, 255, 255, 0.9);
  background rgba(255, 255, 255, 0.9);
`;

const Header = styled.div`
  height: 9rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  p {
    font-size: 1.4rem;
    margin: 2rem 0 3rem;
    text-align: center;
    color: ${colors.white};
  }
`;

const BrinkLogo = styled(Logo)`
  max-width: 7rem;
  position: absolute;
  bottom: 3rem;
  left: 0;
  right: 0;
`;

const OktaLoginForm = () => {
  const { oktaAuth } = useOktaAuth();
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [sessionToken, setSessionToken] = useState();
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setIsLoading(true);
    oktaAuth
      .signInWithCredentials({
        username: user,
        password: password,
      })
      .then((res) => {
        const sessionToken = res.sessionToken;
        setSessionToken(sessionToken);
        oktaAuth.signInWithRedirect({ sessionToken });
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        setError(true);
      });
  };

  const updateUser = (value) => {
    setUser(value);
    setError(false);
  };

  const updatePassword = (value) => {
    setPassword(value);
    setError(false);
  };

  if (sessionToken) {
    return null;
  }

  return (
    <LoginForm>
      {getClientDetails().logo}
      <form onSubmit={handleSubmit}>
        <Header>
          {error ? (
            <ErrorMessage inBox>Invalid password or username</ErrorMessage>
          ) : (
            <p>Please login below</p>
          )}
        </Header>
        <LoginInput
          onChange={(e) => updateUser(e.target.value)}
          placeholder="Email"
          id="email"
          icon="envelope"
          type="email"
        />
        <LoginInput
          onChange={(e) => updatePassword(e.target.value)}
          placeholder="Password"
          id="password"
          icon="key"
          type="password"
        />
        <Button loaderColor="white" isLoading={isLoading} type="submit">
          Login
        </Button>
      </form>
      <BrinkLogo />
    </LoginForm>
  );
};

export default OktaLoginForm;
