import React, { useState, useEffect } from "react";
import Moment from "react-moment";
import styled from "styled-components/macro";
import { Query, Mutation } from "@apollo/client/react/components";
import { useForm, Controller } from "react-hook-form-old";
import { useHistory } from "react-router-dom";
import ISO6391 from "iso-639-1";

import GET_SHIPPING_OPTION from "graphql/Shipping/ShippingOption/GetShippingOption";
import UPDATE_SHIPPING_OPTION from "graphql/Shipping/ShippingOption/UpdateShippingOption";

import { useNotification } from "context/NotificationContext";
import getCountryCode from "helpers/getCountryCodeFromLang";
import editShipping from "helpers/getEditShipping";

import { MEDIA_MIN_LARGE } from "variables/mediaQueries";
import PageContainer from "components/Page/PageContainer";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Box from "components/Content/Box";
import Loader from "components/Ui/Loader";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import Input from "components/Ui/Input";
import ActionButtons from "components/ActionButtons/ActionButtons";
import ActionButton from "components/ActionButtons/ActionButton";
import ActionButtonSecondary from "components/ActionButtons/ActionButtonSecondary";
import IconButton from "components/Ui/Buttons/IconButton";
import Sidebar from "components/Shipping/Sidebar";
import ProviderChoice from "components/Shipping/ProviderChoice";
import Header from "components/Header/Header";
import FlagIcon from "components/Ui/FlagIcon";
import Chip from "components/Ui/Chip";
import AddTranslation from "components/Shipping/AddTranslation";
import DeleteShippingOption from "components/Shipping/DeleteShippingOption";
import Attributes from "components/AttributeList/AttributeList";
import Attribute from "components/AttributeList/Attribute";
import Label from "components/AttributeList/Label";
import Value from "components/AttributeList/Value";

const OptionBox = styled(Box)`
  padding-bottom: 5rem;

  ${MEDIA_MIN_LARGE} {
    padding-bottom: 1rem;
  }
`;

const Container = styled(GridContainer)`
  padding: 0 0 3rem;
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5rem;
  width: 100%;
  border: 0.1rem solid ${(p) => p.theme.colors.whiteOpac};
  border-radius: 0.6rem;
  min-height: 18rem;

  img {
    max-width: 10rem;
  }
`;

const PriceContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-wrap: wrap;
`;

const PriceInput = styled(Input)`
  width: 10rem;
`;

const Price = styled.div`
  width: 10rem;
  margin-right: 3rem;
`;

const Translations = styled.div`
  &:not(:empty) {
    padding-bottom: 3rem;
  }
`;

const AddTranslationButton = styled(ActionButtonSecondary)`
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 0;

  ${MEDIA_MIN_LARGE} {
    bottom: -1rem;
  }
`;

const LanguageField = styled.div`
  display: flex;
  position: relative;

  input {
    padding-left: 4.5rem;
  }

  button {
    margin-top: 3.7rem;
  }

  img {
    position: absolute;
    top: 4.3rem;
    left: 1.5rem;

    ${MEDIA_MIN_LARGE} {
      top: 4.4rem;
    }
  }
`;

const ShippingOption = (id) => {
  const [shippingOption, setShippingOption] = useState("");
  const { control, handleSubmit, errors } = useForm();
  const { setNotification } = useNotification();
  const [providerId, setProviderId] = useState(null);
  const history = useHistory();
  const [descriptions, setDescriptions] = useState([]);
  const [displayNames, setDisplayNames] = useState([]);
  const [showDescTranslation, setShowDescTranslation] = useState(false);
  const [showNameTranslation, setShowNameTranslation] = useState(false);

  const formatFromMinorUnits = (price) => Number(price/100);
  const formatPrice = (price) => Number(price * 100).toFixed(2) | 0;

  useEffect(() => {
    if (shippingOption) {
      setProviderId(shippingOption.relatedProduct.id);
      setDisplayNames(
        Object.entries(JSON.parse(shippingOption.attribute).displayName).map((e) => ({
          langCode: e[0],
          translation: e[1],
        }))
      );
      setDescriptions(
        Object.entries(JSON.parse(shippingOption.attribute).description).map((e) => ({
          langCode: e[0],
          translation: e[1],
        }))
      );
    }
  }, [shippingOption]);

  const removeDescription = (description) =>
    setDescriptions([...descriptions].filter((d) => !(d.langCode === description.langCode)));
  const removeDisplayName = (displayName) =>
    setDisplayNames([...displayNames].filter((d) => !(d.langCode === displayName.langCode)));

  const updateDescription = (langCode, description) => {
    let updatedDescriptions = [...descriptions];
    updatedDescriptions[descriptions.findIndex((element) => element.langCode === langCode)] = {
      langCode: langCode,
      translation: description,
    };
    setDescriptions(updatedDescriptions);
  };
  const updateDisplayName = (langCode, displayName) => {
    let updatedDisplayNames = [...displayNames];
    updatedDisplayNames[displayNames.findIndex((element) => element.langCode === langCode)] = {
      langCode: langCode,
      translation: displayName,
    };
    setDisplayNames(updatedDisplayNames);
  };

  const toShippingOption = (data, price, id) => ({
    variables: {
      id: id,
      name: data.internalName,
      imageUrl: data.imageUrl,
      price: price,
      category: "Shipping",
      attribute: JSON.stringify({
        description: Object.assign(
          descriptions.reduce((obj, item) => ({ ...obj, [item.langCode]: item.translation }), {})
        ),
        displayName: Object.assign(
          displayNames.reduce((obj, item) => ({ ...obj, [item.langCode]: item.translation }), {})
        ),
      }),
      relatedProduct: providerId,
    },
  });

  return (
    <>
      <Breadcrumbs
        slugs={[
          ["admin/shipping-options", "Shipping options"],
          [`admin/shipping-options/${id}`, `${shippingOption.name}`],
        ]}
      />
      <Header heading="Shipping options"></Header>
      <PageContainer>
        <Sidebar />
        <GridContainer expanded>
          <Query
            query={GET_SHIPPING_OPTION}
            variables={{ id }}
            onCompleted={(data) => setShippingOption(data.getProduct)}>
            {({ loading, error }) => {
              if (loading) return <Loader />;
              if (error)
                return (
                  <ErrorMessage>
                    An error occurred getting data, please contact support
                  </ErrorMessage>
                );
              return (
                <GridContainer>
                  <Mutation
                    mutation={UPDATE_SHIPPING_OPTION}
                    onCompleted={() => {
                      setNotification({
                        status: "success",
                        message: `Shipping option successfully updated`,
                      });
                    }}
                    onError={() => {
                      setNotification({
                        status: "error",
                        message:
                          "An error occurred adding the shipping option, please contact support",
                      });
                    }}>
                    {(updateShipping, { loading: addShippingLoading }) => {
                      const onSubmit = (data) => {
                        const price = Object.keys(data.price).map((key) => ({
                          amount: formatPrice(data.price[key].amount),
                          currencyUnit: key,
                        }));
                        updateShipping(toShippingOption(data, price, shippingOption.id)).catch(
                          (e) => {
                            console.log(e);
                          }
                        );
                      };
                      return (
                        <>
                          {shippingOption && (
                            <>
                              <GridItem columns="12">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                  <OptionBox
                                    preHeading="Shipping Option"
                                    heading={
                                      <>
                                        {shippingOption.name}
                                        {shippingOption.active ? (
                                          <i className="fal fa-check-circle"></i>
                                        ) : (
                                          <i className="fal fa-times-circle"></i>
                                        )}
                                      </>
                                    }
                                    subHeading={shippingOption.id}
                                    showGoBackButton
                                    goBackOnClick={() => history.push("/admin/shipping-options")}>
                                    {editShipping() ? (
                                      <>
                                        {addShippingLoading && <Loader />}
                                        <ActionButtons inBox footerOnMobile>
                                          <DeleteShippingOption
                                            name={shippingOption.name}
                                            history={history}
                                            id={shippingOption.id}
                                          />
                                          <ActionButton type="submit">
                                            <i className="fal fa-fw fa-check" />
                                            Save
                                          </ActionButton>
                                        </ActionButtons>

                                        <Container padding="0">
                                          <GridItem
                                            mobilePadding="0"
                                            desktopPadding="0 3rem 0 0"
                                            columns="2">
                                            <ImageContainer>
                                              <img
                                                data-src="holder.js/100px180/"
                                                alt={shippingOption.name}
                                                src={shippingOption.imageUrl}
                                                data-holder-rendered="true"
                                              />
                                            </ImageContainer>
                                          </GridItem>
                                          <GridItem
                                            mobilePadding="0"
                                            desktopPadding="0 1.5rem 0 0"
                                            columns="5">
                                            <Attributes>
                                              <Attribute>
                                                <Label>Category:</Label>
                                                <Value>{shippingOption.category}</Value>
                                              </Attribute>
                                              <Attribute>
                                                <Label>Provider:</Label>
                                                <Value>{shippingOption.relatedProduct.name}</Value>
                                              </Attribute>
                                            </Attributes>
                                          </GridItem>
                                          <GridItem
                                            mobilePadding="0"
                                            desktopPadding="0 0 0 1.5rem"
                                            columns="5">
                                            <Attributes>
                                              <Attribute>
                                                <Label>Created:</Label>
                                                <Value>
                                                  <Moment format="YYYY-MM-DD HH:mm">
                                                    {shippingOption.created}
                                                  </Moment>
                                                </Value>
                                              </Attribute>
                                              <Attribute>
                                                <Label>Last updated:</Label>
                                                <Value>
                                                  <Moment format="YYYY-MM-DD HH:mm">
                                                    {shippingOption.lastUpdated}
                                                  </Moment>
                                                </Value>
                                              </Attribute>
                                            </Attributes>
                                          </GridItem>
                                        </Container>

                                        <GridContainer padding="0">
                                          <GridItem
                                            mobilePadding="0"
                                            desktopPadding="0 1.5rem 0 0"
                                            columns="6">
                                            <Controller
                                              as={Input}
                                              control={control}
                                              rules={{
                                                required: "This is a required field",
                                              }}
                                              type="text"
                                              name="internalName"
                                              defaultValue={shippingOption.name}
                                              label="Internal name"
                                              errors={errors}
                                            />
                                          </GridItem>
                                          <GridItem
                                            mobilePadding="0"
                                            desktopPadding="0 0 0 1.5rem"
                                            columns="6">
                                            <ProviderChoice
                                              providerId={providerId}
                                              setProviderId={setProviderId}
                                            />
                                          </GridItem>
                                        </GridContainer>

                                        <Controller
                                          as={Input}
                                          control={control}
                                          rules={{
                                            required: "This is a required field",
                                            pattern: {
                                              message: "",
                                            },
                                          }}
                                          type="text"
                                          name="imageUrl"
                                          defaultValue={shippingOption.imageUrl}
                                          label="Image URL"
                                          errors={errors}
                                        />

                                        <GridContainer collapse padding="0">
                                          <GridItem
                                            mobilePadding="0"
                                            desktopPadding="0 2rem 0 0"
                                            columns="6">
                                            <h5>Display Name</h5>
                                            <Translations>
                                              {displayNames.map((displayName) => {
                                                return (
                                                  <LanguageField key={displayName.langCode}>
                                                    <FlagIcon
                                                      countryCode={getCountryCode(
                                                        displayName.langCode
                                                      )}
                                                    />
                                                    <Input
                                                      required="This is a required field"
                                                      type="text"
                                                      name={`displayName-${displayName.langCode}`}
                                                      defaultValue={displayName.translation}
                                                      label={ISO6391.getName(displayName.langCode)}
                                                      onChange={(e) =>
                                                        updateDisplayName(
                                                          displayName.langCode,
                                                          e.target.value
                                                        )
                                                      }
                                                      errors={errors}
                                                    />
                                                    <IconButton
                                                      onClick={() => {
                                                        removeDisplayName(displayName);
                                                      }}>
                                                      <i className="fal fa-trash-alt"></i>
                                                    </IconButton>
                                                  </LanguageField>
                                                );
                                              })}
                                            </Translations>
                                            {!showNameTranslation && (
                                              <AddTranslationButton
                                                style={{ right: "2rem" }}
                                                handleOnClick={() =>
                                                  setShowNameTranslation(!showNameTranslation)
                                                }>
                                                <i className="fal fa-fw fa-plus" /> Translation
                                              </AddTranslationButton>
                                            )}
                                            {showNameTranslation && (
                                              <AddTranslation
                                                setShowTranslation={setShowNameTranslation}
                                                translations={displayNames}
                                                setTranslations={setDisplayNames}
                                              />
                                            )}
                                          </GridItem>
                                          <GridItem
                                            mobilePadding="0"
                                            desktopPadding="0 0 0 2rem"
                                            columns="6">
                                            <h5>Description</h5>
                                            <Translations>
                                              {descriptions.map((description) => {
                                                return (
                                                  <LanguageField key={description.langCode}>
                                                    <FlagIcon
                                                      countryCode={getCountryCode(
                                                        description.langCode
                                                      )}
                                                    />
                                                    <Input
                                                      required="This is a required field"
                                                      type="text"
                                                      name={`description-${description.langCode}`}
                                                      defaultValue={description.translation}
                                                      label={ISO6391.getName(description.langCode)}
                                                      errors={errors}
                                                      onChange={(e) =>
                                                        updateDescription(
                                                          description.langCode,
                                                          e.target.value
                                                        )
                                                      }
                                                    />
                                                    <IconButton
                                                      onClick={() => {
                                                        removeDescription(description);
                                                      }}>
                                                      <i className="fal fa-trash-alt"></i>
                                                    </IconButton>
                                                  </LanguageField>
                                                );
                                              })}
                                            </Translations>
                                            {!showDescTranslation && (
                                              <AddTranslationButton
                                                handleOnClick={() =>
                                                  setShowDescTranslation(!showDescTranslation)
                                                }>
                                                <i className="fal fa-fw fa-plus" /> Translation
                                              </AddTranslationButton>
                                            )}
                                            {showDescTranslation && (
                                              <AddTranslation
                                                setShowTranslation={setShowDescTranslation}
                                                translations={descriptions}
                                                setTranslations={setDescriptions}
                                              />
                                            )}
                                          </GridItem>
                                        </GridContainer>

                                        <h5>Price</h5>
                                        <PriceContainer>
                                          {shippingOption.price.map((price) => {
                                            return (
                                              <Price key={price.currencyUnit}>
                                                <Controller
                                                  as={PriceInput}
                                                  control={control}
                                                  rules={{
                                                    required: "This is a required field",
                                                  }}
                                                  type="number"
                                                  label={price.currencyUnit}
                                                  name={`price[${price.currencyUnit}].amount`}
                                                  defaultValue={formatFromMinorUnits(price.amount)}
                                                  errors={errors}
                                                />
                                              </Price>
                                            )
                                          })}
                                        </PriceContainer>
                                      </>
                                    ) : (
                                      <>
                                        <Container padding="0">
                                          <GridItem
                                            mobilePadding="0"
                                            desktopPadding="0 3rem 0 0"
                                            columns="2">
                                            <ImageContainer>
                                              <img
                                                data-src="holder.js/100px180/"
                                                alt={shippingOption.name}
                                                src={shippingOption.imageUrl}
                                                data-holder-rendered="true"
                                              />
                                            </ImageContainer>
                                          </GridItem>
                                          <GridItem columns="10" padding="0">
                                            <GridContainer padding="0">
                                              <GridItem padding="0" columns="6">
                                                <Attributes>
                                                  <Attribute>
                                                    <Label>Provider:</Label>
                                                    <Value>
                                                      {shippingOption.relatedProduct.name}
                                                    </Value>
                                                  </Attribute>
                                                  <Attribute>
                                                    <Label>Internal name:</Label>
                                                    <Value>{shippingOption.name}</Value>
                                                  </Attribute>
                                                </Attributes>
                                              </GridItem>
                                              <GridItem padding="0" columns="6">
                                                <Attributes>
                                                  <Attribute>
                                                    <Label>Created:</Label>
                                                    <Value>
                                                      <Moment format="YYYY-MM-DD HH:mm">
                                                        {shippingOption.created}
                                                      </Moment>
                                                    </Value>
                                                  </Attribute>
                                                  <Attribute>
                                                    <Label>Last updated:</Label>
                                                    <Value>
                                                      <Moment format="YYYY-MM-DD HH:mm">
                                                        {shippingOption.lastUpdated}
                                                      </Moment>
                                                    </Value>
                                                  </Attribute>
                                                </Attributes>
                                              </GridItem>
                                            </GridContainer>
                                            <Attributes>
                                              <Attribute>
                                                <Label>Image URL:</Label>
                                                <Value>
                                                  <a
                                                    href={shippingOption.imageUrl}
                                                    target="_blank"
                                                    rel="noopener noreferrer">
                                                    {shippingOption.imageUrl}
                                                  </a>
                                                </Value>
                                              </Attribute>
                                            </Attributes>
                                          </GridItem>
                                        </Container>

                                        <GridContainer collapse padding="0">
                                          <GridItem
                                            mobilePadding="0"
                                            desktopPadding="0 2rem 0 0"
                                            columns="6">
                                            <h5>Display Name</h5>
                                            <Attributes>
                                              {displayNames.map((displayName) => {
                                                return (
                                                  <Attribute key={displayName.langCode}>
                                                    <Label>
                                                      <FlagIcon
                                                        countryCode={getCountryCode(
                                                          displayName.langCode
                                                        )}
                                                      />
                                                      {ISO6391.getName(displayName.langCode)}:
                                                    </Label>
                                                    <Value>{displayName.translation}</Value>
                                                  </Attribute>
                                                );
                                              })}
                                            </Attributes>
                                          </GridItem>
                                          <GridItem
                                            mobilePadding="0"
                                            desktopPadding="0 0 0 2rem"
                                            columns="6">
                                            <h5>Description</h5>
                                            <Attributes>
                                              {descriptions.map((description) => {
                                                return (
                                                  <Attribute key={description.langCode}>
                                                    <Label>
                                                      <FlagIcon
                                                        countryCode={getCountryCode(
                                                          description.langCode
                                                        )}
                                                      />
                                                      {ISO6391.getName(description.langCode)}:
                                                    </Label>
                                                    <Value>{description.translation}</Value>
                                                  </Attribute>
                                                );
                                              })}
                                            </Attributes>
                                          </GridItem>
                                        </GridContainer>

                                        <h5>Price</h5>
                                        <PriceContainer>
                                          {shippingOption.price.map((price) => {
                                            return (
                                              <Chip key={price.currencyUnit}>
                                                {price.currencyUnit} {price.amount}
                                              </Chip>
                                            )
                                          })}
                                        </PriceContainer>
                                      </>
                                    )}
                                  </OptionBox>
                                </form>
                              </GridItem>
                            </>
                          )}
                        </>
                      );
                    }}
                  </Mutation>
                </GridContainer>
              );
            }}
          </Query>
        </GridContainer>
      </PageContainer>
    </>
  );
};

export default (props) => {
  return ShippingOption(props.match.params.id);
};
