import React, { useState, useEffect } from "react";
import { Query } from "@apollo/client/react/components";
import styled from "styled-components/macro";
import ORDER_AGGREGATE from "../../graphql/Order/OrderAggregate";
import Loader from "components/Ui/Loader";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import esb from "elastic-builder";
import GET_PRODUCTS from "../../graphql/Product/GetProducts";
import moment from "moment/min/moment-with-locales";
import Box from "components/Content/Box";
import Sidebar from "components/Product/Sidebar";
import PageContainer from "components/Page/PageContainer";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import PeriodSelector from "components/Product/PeriodSelector";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import Header from "components/Header/Header";

const Container = styled.div`
  width: 100%;
  margin: 2rem 0;
`;

const StyledBox = styled(Box)`
  padding: 3rem 3rem 1rem;
  min-height: 40rem;
`;

const Table = styled.ul`
  padding: 0;
  list-style: none;
  margin: 0;
`;

const VariantHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 4rem;
  font-weight: 700;
  font-size: 1.5rem;
  padding: 1rem 0;
  margin-top: 0;
  line-height: 1.5rem;
  letter-spacing: 0.05rem;
  border-radius: 0.5rem;

  div {
    display: flex;
    align-items: center;

    &:last-child {
      width: 25%;
      flex-shrink: 0;
      justify-content: flex-end;
    }
  }
`;

const Row = styled.li`
  display: flex;
  justify-content: space-between;
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  height: 4rem;
  padding: 1.5rem 0 1.3rem 0;
  cursor: pointer;

  div {
    display: flex;
    align-items: center;

    &:last-child {
      width: 25%;
      flex-shrink: 0;
      justify-content: flex-end;
    }
  }
  &:hover {
    cursor: default;
  }
`;

const DEFAULT_DATETIME_FROM = moment().subtract(1, "month").startOf("day");
const DEFAULT_DATETIME_TO = moment().endOf("day");

export default () => {
  const [mostSoldVariants, setMostSoldVariants] = useState();
  const [customPeriod] = useState({
    id: "custom",
    length: 90,
    format: "YYYY-MM-DD",
    interval: "DAY",
    type: "days",
    shortFormat: "dd",
    current: {
      label: "Custom",
      startDate: DEFAULT_DATETIME_FROM,
      endDate: DEFAULT_DATETIME_TO,
    },
    prev: {
      label: "Custom",
      startDate: DEFAULT_DATETIME_FROM,
      endDate: DEFAULT_DATETIME_TO,
    },
  });

  const periods = {
    day: {
      id: "day",
      length: 24,
      format: "k",
      interval: "HOUR",
      type: "hour",
      shortFormat: "HH",
      current: {
        label: "Last 24h",
        startDate: moment().subtract(24, "hours").startOf("hour"),
        endDate: moment(),
      },
      prev: {
        label: "Previous 24h",
        startDate: moment().subtract(48, "hours").startOf("hour"),
        endDate: moment().subtract(24, "hours").startOf("hour"),
      },
    },
    week: {
      id: "week",
      length: 7,
      format: "YYYY-MM-DD",
      interval: "DAY",
      type: "days",
      shortFormat: "dd",
      current: {
        label: "Last 7 days",
        startDate: moment().subtract(6, "days").startOf("day"),
        endDate: moment().add(1, "days").startOf("day"),
      },
      prev: {
        label: "Previous 7 days",
        startDate: moment().subtract(13, "days").startOf("day"),
        endDate: moment().subtract(6, "days").startOf("day"),
      },
    },
    month: {
      id: "month",
      length: 30,
      format: "YYYY-MM-DD",
      interval: "DAY",
      type: "days",
      shortFormat: "dd",
      current: {
        label: "Last 30 days",
        startDate: moment().subtract(29, "days").startOf("day"),
        endDate: moment().add(1, "days").startOf("day"),
      },
      prev: {
        label: "Previous 30 days",
        startDate: moment().subtract(59, "days").startOf("day"),
        endDate: moment().subtract(29, "days").startOf("day"),
      },
    },
    quarter: {
      id: "quarter",
      length: 90,
      format: "YYYY-MM-DD",
      interval: "DAY",
      type: "days",
      shortFormat: "dd",
      current: {
        label: "Last 90 days",
        startDate: moment().subtract(89, "days").startOf("day"),
        endDate: moment().add(1, "days").startOf("day"),
      },
      prev: {
        label: "Previous 90 days",
        startDate: moment().subtract(179, "days").startOf("day"),
        endDate: moment().subtract(89, "days").startOf("day"),
      },
    },
  };

  const [period, setPeriod] = useState(periods.month);

  useEffect(() => {
    setMostSoldVariants(
      new esb.requestBodySearch()
        .query(
          esb
            .boolQuery()
            .must([
              esb.matchQuery("statusLog.status", "success"),
              esb
                .rangeQuery("created")
                .gte(period.current.startDate.format("YYYY-MM-DD-HH:mm"))
                .lte(period.current.endDate.format("YYYY-MM-DD-HH:mm"))
                .format("yyyy-MM-dd-HH:mm"),
            ])
        )
        .agg(esb.termsAggregation("variants", "orderLines.productId.keyword").size(100))
    );
  }, [period]);

  if (!mostSoldVariants) return null;

  return (
    <>
      <Breadcrumbs slugs={[["admin/most-sold-products", "most-sold-products"]]} />
      <Header heading="Most sold products"></Header>
      <PageContainer>
        <Sidebar />
        <GridContainer>
          <GridItem columns="12">
            <Query
              query={ORDER_AGGREGATE}
              variables={{ query: JSON.stringify(mostSoldVariants.toJSON()) }}>
              {({ loading, error, data }) => {
                if (loading) return <Loader />;
                if (error)
                  return (
                    <ErrorMessage>
                      An error occured loading data, please contact support
                    </ErrorMessage>
                  );
                const buckets = JSON.parse(data.orderAggregates.aggregations).variants.buckets;
                return (
                  <Query
                    query={GET_PRODUCTS}
                    variables={{
                      ids: buckets.map((bucket) => bucket.key),
                    }}>
                    {({ loading, error, data: dataProducts }) => {
                      if (loading) return <Loader />;
                      if (error)
                        return (
                          <ErrorMessage>
                            An error occured loading data, please contact support
                          </ErrorMessage>
                        );
                      const products = dataProducts.getProducts;
                      return (
                        <StyledBox
                          preHeading="Top 100"
                          heading="Most sold variants"
                          headingIcon="award"
                          highLightIcon>
                          <Container>
                            <Table>
                              <PeriodSelector
                                period={period}
                                periods={periods}
                                setPeriod={setPeriod}
                                customPeriod={customPeriod}
                              />
                              <VariantHeader>
                                <div>Name</div>
                                <div>Count</div>
                              </VariantHeader>
                              {buckets
                                .filter(
                                  (bucket) =>
                                    products.find((product) => product.id === bucket.key)?.type ===
                                    "productVariant"
                                )
                                .map((bucket, index) => {
                                  const product = products.find(
                                    (product) => product.id === bucket.key
                                  );
                                  return (
                                    <Row key={bucket.key}>
                                      <div>
                                        {`${index + 1}. `}
                                        {product?.name}
                                      </div>
                                      <div>{bucket.doc_count}</div>
                                    </Row>
                                  );
                                })}
                            </Table>
                          </Container>
                        </StyledBox>
                      );
                    }}
                  </Query>
                );
              }}
            </Query>
          </GridItem>
        </GridContainer>
      </PageContainer>
    </>
  );
};
