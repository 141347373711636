import gql from "graphql-tag";

const GET_ORDER = gql`
  query getOrder($id: ID!) {
    getOrder(id: $id) {
      id
      reference
      status
      statusLog {
        status
        timestamp
      }
      paymentMethod
      paymentProvider
      paymentReference
      paymentExpiresAt
      potentialFraud
      failedReason
      currencyUnit
      orderTaxAmount
      orderAmount
      orderAmountWithDiscount
      discount {
        id
        code
      }
      refundActive
      refundFee
      customerAttribute
      created
      lastUpdated
      person {
        id
        email
        givenName
        familyName
        streetAddress
        houseNumberOrName
        postalCode
        region
        city
        country
        phone
        created
        lastUpdated
      }
      shippingAddress {
        givenName
        familyName
        email
        streetAddress
        houseNumberOrName
        postalCode
        region
        city
        country
        phone
      }
      orderLines {
        id
        name
        quantity
        taxRate
        totalAmount
        totalAmountWithDiscount
        totalTaxAmount
        type
        product {
          id
          name
          relatedProduct {
            id
          }
          imageUrl
          price {
            amount
            currencyUnit
          }
        }
      }
    }
  }
`;

export default GET_ORDER;
