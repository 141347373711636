import gql from "graphql-tag";

const SEARCH_ORDERS = gql`
  query searchOrders($query: AWSJSON!) {
    searchOrders(query: $query) {
      orders {
        id
        reference
        status
        paymentProvider
        paymentReference
        paymentMethod
        paymentExpiresAt
        orderTaxAmount
        orderAmount
        orderAmountWithDiscount
        refundAmount
        currencyUnit
        created
        lastUpdated
        shippingAddress {
          country
          givenName
          familyName
          email
          city
          streetAddress
          postalCode
          region
          phone
        }
        customerAttribute
      }
      totalHits
    }
  }
`;

export default SEARCH_ORDERS;
