import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { MEDIA_MIN_LARGE } from "variables/mediaQueries";
import Pagination from "components/Pagination/Pagination";
import Button from "components/Ui/Button";
import Tooltip from "components/Ui/Tooltip";
import HoM from "components/Ui/HoM";
import TableHeader from "components/Table/Header";
import Column from "components/Table/Column";
import Toolbar from "components/Table/Toolbar/Toolbar";
import GoToPage from "components/Table/Toolbar/GoToPage";
import TotalItems from "components/Table/Toolbar/TotalItems";
import ItemsPerPage from "components/Table/Toolbar/ItemsPerPage";
import Loader from "components/Ui/Loader";
import Customers from "components/Table/Rows";
import Customer from "components/Table/Row";
import FlagIcon from "components/Ui/FlagIcon";

const Name = styled(Column)`
  width: 25%;
  i {
    margin-right: 0;
  }
`;

const Email = styled(Column)`
  width: 40%;
`;

const Phone = styled(Column)`
  width: 25%;
`;

const Country = styled(Column)`
  width: 10%;

  img {
    margin: 0.5rem 0 0;
    padding-bottom: 0.3rem;

    ${MEDIA_MIN_LARGE} {
      padding-bottom: 0.2rem;
    }
  }
`;

const SortButton = styled(Button)`
  background: transparent;
  height: auto;
  width: auto;
  margin: 0;
  color: ${(p) => p.theme.colors.white};

  i {
    font-size: 1.6rem;
  }
`;

const MAX_RESULT_WINDOW = 10000;

export default function CustomersTable({
  customers,
  setCustomers,
  customersTotalHits,
  fetchMore,
  personsPerPage,
  esbQuery,
  loading,
  customerTabs,
  setCustomerTabs,
  setActiveTab,
  children: renderTableActions = null,
}) {
  const [itemsPerPage, setItemsPerPage] = useState(personsPerPage);
  const [currentPage, setCurrentPage] = useState(1);
  const [goToPageInput, setGoToPageInput] = useState("1");
  const [currentSort, setCurrentSort] = useState("default");
  const [sortField, setSortField] = useState("reference");

  useEffect(() => {
    let isMounted = true;
    const from = (currentPage - 1) * itemsPerPage;
    fetchMore({
      variables: {
        query: JSON.stringify(esbQuery.size(itemsPerPage).from(from).toJSON()),
      },
    })
      .then((result) => {
        document.querySelector(".top").scrollIntoView({ behavior: "smooth" });
        isMounted && setCustomers(result.data.searchPersons.persons);
      })
      .catch((error) => console.log(error));
    setGoToPageInput(currentPage);

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, itemsPerPage]);

  const handleCustomerClick = (id, reference) => {
    !customerTabs.find((item) => item.id === id) &&
      setCustomerTabs((prev) => [...prev, { id, reference }]);
    setActiveTab(id);
  };

  const toValue = (sortField, obj) => {
    if (Number.isInteger(obj[sortField])) {
      return obj[sortField];
    } else {
      return obj[sortField].toLowerCase();
    }
  };

  const sortTypes = {
    up: {
      class: "sort-up",
      fn: (a, b) => {
        const valueA = toValue(sortField, a);
        const valueB = toValue(sortField, b);
        if (valueA > valueB) return 1;
        else if (valueA < valueB) return -1;
        return 0;
      },
    },
    down: {
      class: "sort-down",
      fn: (a, b) => {
        const valueA = toValue(sortField, a);
        const valueB = toValue(sortField, b);
        if (valueA > valueB) return -1;
        else if (valueA < valueB) return 1;
        return 0;
      },
    },
    default: {
      class: "sort",
      fn: (a, _) => a,
    },
  };

  const getSortType = (field, sort) => {
    if (field === sortField) {
      return sortTypes[sort].class;
    }
    return sortTypes.default.class;
  };

  const onSortChange = (sortField) => {
    let nextSort;
    if (currentSort === "down") nextSort = "up";
    else if (currentSort === "up") nextSort = "default";
    else if (currentSort === "default") nextSort = "down";

    setCurrentSort(nextSort);
    setSortField(sortField);
  };

  return (
    <>
      <Tooltip />
      <Toolbar>
        <HoM>
          <GoToPage>
            Page
            <input value={goToPageInput} onChange={(e) => setGoToPageInput(e.target.value)} />
            <button type="button" onClick={(e) => setCurrentPage(parseInt(goToPageInput, 10))}>
              <i className="fal fa-sync"></i>
            </button>
            of {customersTotalHits ? Math.ceil(customersTotalHits / itemsPerPage) : "-"} pages
          </GoToPage>
        </HoM>
        <ItemsPerPage setItemsPerPage={setItemsPerPage} />
        <TotalItems>
          <HoM>Total </HoM>customers: <span>{customersTotalHits || "-"}</span>
        </TotalItems>
        {renderTableActions &&
          renderTableActions({ totalHits: Math.min(customersTotalHits, MAX_RESULT_WINDOW) })}
      </Toolbar>

      <TableHeader>
        <Name hideOnMobile onClick={() => onSortChange("Name")}>
          <HoM>Name</HoM>
          <SortButton>
            <i className={`fas fa-${getSortType("Name", currentSort)}`} />
          </SortButton>
        </Name>
        <Email onClick={() => onSortChange("orderEmail")}>
          <span>Email </span>
          <SortButton>
            <i className={`fas fa-${getSortType("orderEmail", currentSort)}`} />
          </SortButton>
        </Email>
        <Phone hideOnMobile onClick={() => onSortChange("orderPhone")}>
          <span>Phone number </span>
          <SortButton>
            <i className={`fas fa-${getSortType("orderPhone", currentSort)}`} />
          </SortButton>
        </Phone>
        <Country onClick={() => onSortChange("country")}>
          <HoM>Country</HoM>
          <SortButton>
            <i className={`fas fa-${getSortType("country", currentSort)}`} />
          </SortButton>
        </Country>
      </TableHeader>

      <Customers>
        {loading && <Loader />}
        {customers && !loading && (
          <Pagination
            itemsPerPage={itemsPerPage}
            totalItems={customersTotalHits}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}>
            {customers.map((customer) => (
              <Customer
                key={customer.id}
                onClick={() =>
                  handleCustomerClick(
                    customer.id,
                    `${customer.givenName.charAt(0)}. ${customer.familyName}`
                  )
                }>
                <Name hideOnMobile>
                  <strong>{`${customer.givenName} ${customer.familyName}`}</strong>
                </Name>
                <Email>
                  <span>{customer.email}</span>
                </Email>
                <Phone hideOnMobile>
                  <span>{customer.phone}</span>
                </Phone>
                <Country hideOnMobile>
                  <span>
                    {customer.country ? <FlagIcon countryCode={customer.country} /> : "-"}
                  </span>
                </Country>
              </Customer>
            ))}
          </Pagination>
        )}
      </Customers>
    </>
  );
}
