import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import Box from "components/Dashboard/Box";
import styled from "styled-components/macro";
import ChartistGraph from "react-chartist";
import moment from "moment-timezone";
import Dinero from "dinero.js";
import ORDERS_STATISTICS_REVENUE from "graphql/OrdersStatisticsRevenue";
import Loader from "components/Ui/Loader";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import { mergeDineros } from "../../../helpers/mergeDineros";
import calculateSales from "../../../helpers/sales";

const Container = styled.div`
  width: 100%;
  margin: 3rem 0 0;
`;

export default ({ selectedStores, orderStatus, currencyUnit, period, rates, includeVAT }) => {
  const [dailySalesChart, setDailySalesChart] = useState();
  const [currentPeriodSum, setCurrentPeriodSum] = useState(0);

  const getTimeRange = (startDateTime, endDateTime, type, format) => {
    const from = moment(startDateTime);
    const to = moment(endDateTime);
    return [...Array(to.diff(from, type)).keys()].map((i) =>
      moment(startDateTime).add(i, type).format(format)
    );
  };

  const toDataItem = async (stats, selectedStores, date, currencyUnit) => {
    const stat = stats.find((s) => s.keyAsString === date);
    const key = date.length > 2 ? moment(date).format(period.shortFormat) : date;
    if (stat) {
      const stores = stat.stores
        .filter((s) => selectedStores.find((c) => c.value === s.store))
        .map((s) => {
          const store = selectedStores.find((c) => c.value === s.store);
          return {
            sales: calculateSales(s.sales, store.currencyUnit, store.tax, includeVAT),
            currencyUnit: selectedStores.find((c) => c.value === s.store).currencyUnit,
          };
        });
      return {
        key: key,
        value: await mergeDineros(
          stores.map((s) => Dinero({ amount: s.sales, currency: s.currencyUnit })),
          currencyUnit,
          rates
        ),
      };
    } else {
      return {
        key: key,
        value: Dinero({ amount: 0, currency: currencyUnit }),
      };
    }
  };

  const toData = (stats, selectedStores, timeRange, currencyUnit) =>
    timeRange.map((d) => toDataItem(stats, selectedStores, d, currencyUnit));

  const {
    loading: currentLoading,
    error: currentError,
    data: currentStats,
  } = useQuery(ORDERS_STATISTICS_REVENUE, {
    variables: {
      startDateTime: period.current.startDate,
      endDateTime: period.current.endDate,
      interval: period.interval,
      status: orderStatus,
    },
  });

  const {
    loading: prevLoading,
    error: prevError,
    data: prevStats,
  } = useQuery(ORDERS_STATISTICS_REVENUE, {
    variables: {
      startDateTime: period.prev.startDate,
      endDateTime: period.prev.endDate,
      interval: period.interval,
      status: orderStatus,
    },
  });

  useEffect(() => {
    const handleData = async () => {
      const currentTimeRange = getTimeRange(
        period.current.startDate,
        period.current.endDate,
        period.type,
        period.format
      );

      const currentDiagramData = await Promise.all(
        toData(currentStats.ordersStatisticsRevenue, selectedStores, currentTimeRange, currencyUnit)
      );

      const currentPeriodAmount = currentDiagramData.map((n) => n.value.toUnit());
      const currentData =
        currentDiagramData.length > 0
          ? currentDiagramData.map((d) => d.value).reduce((a, b) => a.add(b))
          : null;
      setCurrentPeriodSum(currentData);

      const labels =
        period.current.endDate.diff(period.current.startDate, "days") < 40
          ? getTimeRange(
              period.current.startDate,
              period.current.endDate,
              period.type,
              period.shortFormat,
              true
            )
          : null;

      const dailySalesChart = {
        data: {
          labels: labels,
          series: [currentPeriodAmount],
        },
        options: {
          low: 0,
          high: Math.max.apply(Math, currentPeriodAmount),
          showArea: true,
          showPoint: false,
          fullWidth: true,
          chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
          axisY: {
            onlyInteger: true,
            offset: 60,
            labelInterpolationFnc: function (value) {
              return Dinero({
                amount: value * 100,
                currency: currencyUnit,
              })
                .toFormat("$0,0")
                .replace(",000", "K")
                .replace(",500", ",5K");
            },
          },
        },
      };
      setDailySalesChart(dailySalesChart);
    };
    currentStats && prevStats && rates && handleData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStats, prevStats, selectedStores, currencyUnit, rates, includeVAT]);

  if (currentLoading || prevLoading) return <Loader />;
  if (currentError || prevError)
    return <ErrorMessage>An error occured loading data, please contact support</ErrorMessage>;

  return (
    <Box
      preHeading="Revenue"
      heading={currentPeriodSum ? currentPeriodSum.toFormat() : ""}
      headingIcon="money-bill-alt"
      currency={currencyUnit}
      currentPeriodSum={
        currentPeriodSum ? currentPeriodSum : Dinero({ value: 0, currency: currencyUnit })
      }
      displayAsCurrency
      primaryLabel={`${period.current.startDate.format(
        "YYYY-MM-DD"
      )} - ${period.current.endDate.format("YYYY-MM-DD")}`}
      labelPos="topRight"
      footer={period.label}>
      {dailySalesChart && (
        <Container>
          <ChartistGraph
            className="ct-chart-white-colors ct-major-tenth"
            data={dailySalesChart ? dailySalesChart.data : {}}
            type="Line"
            options={dailySalesChart ? dailySalesChart.options : {}}
            listener={{
              draw: (data) => {
                if (data.type === "line" || data.type === "area") {
                  data.element.animate({
                    d: {
                      begin: 0,
                      dur: 500,
                      from: data.path
                        .clone()
                        .scale(1, 0)
                        .translate(0, data.chartRect.height())
                        .stringify(),
                      to: data.path.clone().stringify(),
                    },
                  });
                }
              },
            }}
          />
        </Container>
      )}
    </Box>
  );
};
