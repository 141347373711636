import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client/react/hooks";
import styled, { keyframes } from "styled-components/macro";
import Dialog from "components/Dialog/Dialog";
import Box from "components/Content/Box";
import FilterButton from "components/Ui/FilterButton";
import { json2csv } from "json-2-csv";
import moment from "moment/min/moment-with-locales";
import { useNotification } from "context/NotificationContext";
import Checkbox from "../../components/Ui/Checkbox";
import uuid from "react-uuid";
import ButtonOutlined from "../../components/Ui/ButtonOutlined";

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  transition: opacity 0.3s;
  pointer-events: all;
  z-index: 10000;
  opacity: 1;
`;

const Container = styled(Box)`
  max-width: 40rem;
  margin: 0 3rem;
`;
const ExportDialog = styled(Dialog)`
  overflow: scroll;
  .dialogContainer {
    max-width: none;
    width: 100%;
    max-width: 60rem;
  }
  p {
    width: 100%;
  }
`;
const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0.5rem;
  width: 20rem;
  input {
    margin-right: 1rem;
  }
`;

const CheckboxWrapper = styled.div`
  display: ${(p) => (p.showFields ? "flex" : "none")};
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  `;

const SelectAllWrapper = styled.div``;

const ShowFieldsButton = styled(ButtonOutlined)`
  height: 5rem;
  width: 100%;
  margin: 3rem 0;
`;
const Header = styled.h4`
  width: 100%;
  text-transform: uppercase;
  font-weight: 400;
`;

const Loader = styled.div`
  width: 500px;
  margin: 0 auto;
  border: 4px solid transparent;
  border-radius: 0.6rem;
  position: relative;
  padding: 4px;

  &:before {
    content: "";
    border: 1px solid rgb(95, 113, 205);
    border-radius: 0.6rem;
    position: absolute;
    top: -4px;
    right: -4px;
    bottom: -4px;
    left: -4px;
  }
`;

const borealisBar = keyframes`
  0% {
    left: 0%;
    right: 100%;
    width: 0%;
  }
  10% {
    left: 0%;
    right: 75%;
    width: 25%;
  }
  90% {
    right: 0%;
    left: 75%;
    width: 25%;
  }
  100% {
    left: 100%;
    right: 0%;
    width: 0%;
  }
`;

const LoaderBar = styled.div`
  position: absolute;
  border-radius: 0.6rem;
  top: 0;
  right: 100%;
  bottom: 0;
  left: 0;
  background: linear-gradient(90deg, rgb(95, 113, 205) 0%, rgb(55, 77, 192) 100%);
  width: 0;
  animation: ${borealisBar} 2s linear infinite;
`;

const ACTIVE_DEFAULT = true;

const FIELDS = {
  id: ACTIVE_DEFAULT,
  email: ACTIVE_DEFAULT,
  givenName: ACTIVE_DEFAULT,
  familyName: ACTIVE_DEFAULT,
  streetAddress: ACTIVE_DEFAULT,
  paymentMethod: ACTIVE_DEFAULT,
  houseNumberOrName: ACTIVE_DEFAULT,
  postalCode: ACTIVE_DEFAULT,
  region: ACTIVE_DEFAULT,
  city: ACTIVE_DEFAULT,
  country: ACTIVE_DEFAULT,
  phone: ACTIVE_DEFAULT,
  created: ACTIVE_DEFAULT,
  lastUpdated: ACTIVE_DEFAULT,
};

function Exporter({ query, variables, onComplete, onError }) {
  const { loading, error, data } = useQuery(query, { variables });

  useEffect(() => {
    if (loading) return;
    if (error) {
      console.error(error);
      onError(error.message);
      return;
    }
    onComplete(data.searchPersons.persons);
  }, [loading, error, data, onComplete, onError]);

  return (
    <Backdrop>
      <Container>
        <Header>Generating export</Header>
        <Loader>
          <LoaderBar />
        </Loader>
      </Container>
    </Backdrop>
  );
}

export default function CustomersExport({ size, query, esbQuery }) {
  const [activeFields, setActiveFields] = useState(FIELDS);
  const { setNotification } = useNotification();
  const [dialog, showDialog] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [showFields, setShowFields] = useState(false);
  const [selectAll, setSelectAll] = useState(true);

  const updateField = (field) =>
    setActiveFields((activeFields) => ({ ...activeFields, [field]: !activeFields[field] }));

  function onCancel() {
    if (exporting) return;
    showDialog(false);
  }

  function onExport(event) {
    event.stopPropagation();
    showDialog(false);
    setExporting(true);
  }

  function onError(errorMessage) {
    setExporting(false);
    setNotification({
      status: "error",
      message: `Could not fetch customers: ${errorMessage}`,
    });
  }

  const updateAllFields = (active) => {
    const activeFieldsCopy = { ...activeFields };
    Object.keys(activeFieldsCopy).forEach((key) => {
      activeFieldsCopy[key] = active;
    });
    setActiveFields(activeFieldsCopy);
  };

  const toggleSelectAll = (active) => {
    setSelectAll(active);
    updateAllFields(active);
  };

  function onComplete(customers) {
    const formattedCustomers = customers.map((c) => {
      return {
        ...(activeFields["id"] && { id: c.id }),
        ...(activeFields["email"] && { email: c.email }),
        ...(activeFields["familyName"] && { familyName: c.familyName }),
        ...(activeFields["givenName"] && { givenName: c.givenName }),
        ...(activeFields["country"] && { country: c.country }),
        ...(activeFields["city"] && { city: c.city }),
        ...(activeFields["streetAddress"] && { streetAddress: c.streetAddress }),
        ...(activeFields["houseNumberOrName"] && { houseNumberOrName: c.houseNumberOrName }),
        ...(activeFields["postalCode"] && { postalCode: c.postalCode }),
        ...(activeFields["region"] && { region: c.region }),
        ...(activeFields["phone"] && { phone: c.phone }),
      };
    });

    json2csv(formattedCustomers, (_, csv) => {
      const blob = new Blob([csv], { type: "text/csv" });
      const filename = `customers-export-${moment().format("YYYY-MM-DD-HH_mm")}.csv`;

      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, filename);
      } else {
        const element = document.createElement("a");
        element.setAttribute("href", URL.createObjectURL(blob));
        element.setAttribute("download", filename);
        element.style.display = "none";
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      }
      setExporting(false);
    });
  }

  return (
    <>
      <FilterButton handleOnClick={() => showDialog(true)}>
        <i className="fal fa-download" /> Export
      </FilterButton>
      <ExportDialog
        header={`Export customer${size !== 1 ? "s" : ""}`}
        text={`Do you want to export ${size === 1 ? "this" : "these"} ${size} customer${
          size !== 1 ? "s" : ""
        } as a CSV?`}
        open={dialog}
        handleClose={onCancel}
        handleOk={onExport}>
        <SelectAllWrapper>
          <CheckboxContainer>
            <Checkbox onChange={() => toggleSelectAll(!selectAll)} checked={selectAll} />
            Select all fields
          </CheckboxContainer>
        </SelectAllWrapper>
        <ShowFieldsButton handleOnClick={() => setShowFields(!showFields)}>
          {showFields ? "Hide custom fields" : "Select custom fields"}
        </ShowFieldsButton>
        <CheckboxWrapper showFields={showFields}>
          {Object.entries(activeFields).map((field) => (
            <CheckboxContainer key={uuid()}>
              <Checkbox onChange={() => updateField([field[0]])} checked={field[1]} />
              {field[0]}
            </CheckboxContainer>
          ))}
        </CheckboxWrapper>
      </ExportDialog>
      {exporting && (
        <Exporter
          query={query}
          variables={{
            query: JSON.stringify(esbQuery.size(size).toJSON()),
            ...activeFields,
          }}
          onComplete={onComplete}
          onError={onError}
        />
      )}
    </>
  );
}
